import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Entry from '../components/TrackableItem';
import {
  resetState,
  postItem,
  addAlert,
  postResetState,
  handleGoBack,
  getFilterPatients,
  editItem,
  getFilterPaceCenters,
} from '../actions/trackable';
import getLogsByOrder from '../actions/logs';
import { setNavigationStepbackButton } from '../actions/navigation';
import { nav } from '../components/TrackableItem/constants';
import { useAuth0 } from '../auth0';
import { ITEM_STATUS } from '../config/constants';

const initialState = {
  itemId: '',
  description: '',
  patientName: '',
  deliveryLine: '',
  deliveryCity: '',
  deliveryState: '',
  deliveryPostalCode: '',
  paceCenter: '',
  paceCenterId: '',
};

const TrackableItem = ({ editMode }) => {
  const [entry, setEntry] = useState(initialState);
  const navHistory = useHistory();
  const {
    isLoading, error,
    isSuccessPaceCenter,
    isSuccessTrackableItem,
    paceCenters, navigation,
    searchOutcome, pendingDataToPaginate, editionCompleted,
  } = useSelector((state) => state.trackableState);
  const { permissionError } = useSelector((state) => state.custodyState);
  const {
    code, patient, history, organization, location,
    description, editableItem, loading, patientId, status,
    organizationName,
  } = useSelector((state) => state.logsState);
  const { userRole } = useSelector((state) => state.navigationState);
  const [enableButton, setEnableButton] = useState(true);
  const [searchValue, handleSearchValue] = useState('');
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [filterValue, handleFilterValue] = useState('');
  const [listEmpty, setListEmpty] = useState(false);
  const [disableSearch, setDisableSearch] = useState(true);
  const [disableFooterButton, handleDisableFooterButton] = useState(true);
  const { user, getTokenSilently } = useAuth0();
  const [token, setToken] = useState('');
  const onEditionMode = useState(editMode !== undefined ? editMode : false);
  const { orderId } = useParams();
  const [onLoadingEdit, setOnLoadingEdit] = useState(false);
  const [patientRemoteId, setPatientRemoteId] = useState(null);

  (async () => {
    setToken(await getTokenSilently());
  })();

  const dispatch = useDispatch();

  useEffect(() => {
    if (orderId !== undefined && !onLoadingEdit) {
      (async () => {
        const tok2 = await getTokenSilently();
        dispatch(getLogsByOrder(orderId, tok2));
      })();
      setOnLoadingEdit(true);
    }
  },
  [orderId]);

  useEffect(() => {
    if (editMode && patientId !== null && filterValue === '' && navigation === nav.code.PATIENT_SEARCH) {
      setPatientRemoteId(patientId);
    } else if (filterValue !== '' && !listEmpty && navigation === nav.code.PATIENT_SEARCH) {
      setPatientRemoteId(filterValue);
    }
  }, [filterValue, patientId]);

  useEffect(() => {
    if (onEditionMode && organization !== null && status === ITEM_STATUS.PENDING
      && history.filter((e) => e.status === ITEM_STATUS.PENDING).length === 1) {
      setEntry({
        ...entry,
        itemId: code,
        description: description !== null ? description : '',
        paceCenter: organizationName,
        paceCenterId: organization,
        deliveryLine: location.address !== null && location.address.line !== null ? location.address.line : '',
        deliveryCity: location.address !== null && location.address.city !== null ? location.address.city : '',
        deliveryState: location.address !== null && location.address.state !== null ? location.address.state : '',
        deliveryPostalCode: location.address !== null && location.address.postalCode !== null ? location.address.postalCode : '',
        patientName: patient !== null ? patient : '',
      });
    }
  }, [organization]);

  const handleChangeEntry = (event) => {
    if (entry.patientName === '' && entry.item !== '') {
      setEntry({
        ...entry,
        [event.target.name]: event.target.value,
        deliveryLine: '',
        deliveryCity: '',
        deliveryState: '',
        deliveryPostalCode: '',
      });
    }
    if (event.target.name === 'itemId') {
      setEntry({
        ...entry,
        [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, '').trim().toUpperCase(),
      });
    } else {
      setEntry({
        ...entry,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleBarcodeRead = (value) => {
    setEntry({
      ...entry,
      itemId: value.trim().toUpperCase(),
    });
    dispatch(handleGoBack(nav.code.TRACKABLE_ITEM_FORM));
  };

  const serializeEntry = () => ({
    itemId: entry.itemId,
    description: entry.description !== '' ? entry.description : null,
    organizationId: entry.paceCenterId,
    patient: {
      id: null,
      remoteId: patientRemoteId !== undefined ? patientRemoteId : null,
      names: [
        {
          id: entry.patientName !== '' ? entry.patientName : null,
          use: 'official',
          text: entry.patientName !== '' ? entry.patientName : null,
          family: '',
          prefix: '',
          sufix: '',
        },
      ],
    },
    location: {
      id: null,
      remoteId: editMode && location.remoteId !== null ? location.remoteId : null,
      name: entry.patientName !== '' && entry.deliveryLine !== '' ? entry.patientName : null,
      description: entry.patientName !== '' && entry.deliveryLine !== '' ? `The home Adress of ${entry.patientName}` : null,
      address: {
        line: [entry.deliveryLine],
        city: entry.deliveryCity,
        state: entry.deliveryState,
        postalCode: entry.deliveryPostalCode,
      },
    },
  });

  const handlePostTrackableItem = () => async () => {
    const log = {
      trackableItem: serializeEntry(),
      user: user.name,
    };
    if (editMode && !isLoading) {
      dispatch(editItem(log, orderId, token));
    } else if (!isLoading) {
      dispatch(postItem(log, token));
    }
  };

  const handleOncLickScan = () => {
    dispatch(handleGoBack(nav.code.BARCODE_SCANNER));
  };

  const handleOncLickPatient = () => {
    dispatch(resetState());
    handleSearchValue('');
    setSearchCompleted(false);
    dispatch(handleGoBack(nav.code.PATIENT_SEARCH));
  };

  const handleOnCancelEdition = () => async () => {
    navHistory.goBack();
  };

  const handleSearch = async () => {
    if (searchValue !== '' && navigation === nav.code.PATIENT_SEARCH) {
      await dispatch(getFilterPatients(searchValue, 0, token));
      setSearchCompleted(true);
    } else if (navigation === nav.code.PACE_CENTERS_SEARCH) {
      await dispatch(getFilterPaceCenters(searchValue, 0, token));
      setSearchCompleted(true);
    }
  };

  const handlePagination = (page) => {
    if (navigation === nav.code.PATIENT_SEARCH) {
      dispatch(getFilterPatients(searchValue, page, token));
    } else if (navigation === nav.code.PACE_CENTERS_SEARCH) {
      dispatch(getFilterPaceCenters(searchValue, page, token));
    }
  };

  const handleCancelNewPatient = () => {
    setSearchCompleted(false);
  };

  const handleNewPatient = () => {
    setEntry({
      ...entry,
      patientName: listEmpty
        ? searchValue : searchOutcome.filter((e) => e.remoteId === filterValue)[0].name,
    });
    dispatch(handleGoBack(nav.code.TRACKABLE_ITEM_FORM));
    setSearchCompleted(false);
  };

  useEffect(() => {
    dispatch(handleGoBack(nav.code.TRACKABLE_ITEM_FORM));
    const currentPage = editMode ? 'Edit Trackable Item' : 'New Trackable Item';
    dispatch(setNavigationStepbackButton(currentPage));
    dispatch(resetState());
  }, []);

  useEffect(() => {
    if (searchValue.length > 0 && searchOutcome.length > 0) {
      setListEmpty(false);
    } else if (searchValue.length > 0) {
      setListEmpty(true);
    }
  }, [searchOutcome]);

  useEffect(() => {
    if (searchValue === '' && !disableSearch) {
      setDisableSearch(true);
    } else if (searchValue !== '' && disableSearch) {
      setDisableSearch(false);
    }
  }, [searchValue]);

  useEffect(() => {
    if (filterValue !== '') { handleDisableFooterButton(false); } else { handleDisableFooterButton(true); }
  }, [filterValue]);

  useEffect(() => {
    if (isSuccessTrackableItem && !editMode) {
      dispatch(addAlert(entry.itemId));
      dispatch(postResetState());
      setEntry(initialState);
    } else if (editionCompleted) {
      dispatch(postResetState());
      navHistory.push(`/orders/${entry.itemId}/logs`);
    }
  }, [isSuccessTrackableItem, editionCompleted]);

  useEffect(() => {
    if (entry.itemId !== '' && entry.paceCenter !== '') {
      setEnableButton(false);
    } else { setEnableButton(true); }
  }, [entry]);

  const handleOnClickSearchButtonCenter = () => {
    handleSearchValue('');
    setSearchCompleted(false);
    handleFilterValue('');
    dispatch(handleGoBack(nav.code.PACE_CENTERS_SEARCH));
  };

  const handleOnClickFooter = () => {
    setEntry({
      ...entry,
      paceCenter: searchOutcome.filter((e) => e.remoteId === filterValue)[0].name,
      paceCenterId: searchOutcome.filter((e) => e.remoteId === filterValue)[0].id,
    });
    dispatch(handleGoBack(nav.code.TRACKABLE_ITEM_FORM));
    setSearchCompleted(false);
  };

  return (
    <Entry
      value={entry}
      error={error || false}
      navigationValue={navigation}
      isLoading={isLoading}
      isSuccessPaceCenter={isSuccessPaceCenter}
      isSuccessTrackableItem={isSuccessTrackableItem}
      onPostTrackableItem={handlePostTrackableItem}
      onChangeValue={handleChangeEntry}
      paceCenters={paceCenters}
      enableButton={enableButton}
      onClickScan={handleOncLickScan}
      onBarcodeRead={handleBarcodeRead}
      onClickPatient={handleOncLickPatient}
      onClickCancelEdition={handleOnCancelEdition}
      searchValue={searchValue}
      onChangeSearchValue={handleSearchValue}
      searchOutcome={searchOutcome}
      onClickSearch={handleSearch}
      filterValue={filterValue}
      onChangeFilterValue={handleFilterValue}
      onPagination={handlePagination}
      pendingDataToPaginate={pendingDataToPaginate}
      searchCompleted={searchCompleted}
      isListEmpty={listEmpty}
      disableSearch={disableSearch}
      disableFooterButton={disableFooterButton}
      onClickNewPatient={handleNewPatient}
      onClickCancelPatient={handleCancelNewPatient}
      permissionError={permissionError || false}
      onEditionMode={onEditionMode}
      editableItem={editableItem}
      loadingLogs={loading}
      userRole={userRole}
      onClickSearchButtonCenter={handleOnClickSearchButtonCenter}
      onClickFooter={handleOnClickFooter}
      disableSearchCenter={editMode}
    />
  );
};

export { TrackableItem as default };
