import React from 'react';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Step from './Step';
import StepperDate from './StepperDate';
import { STEP_TYPES } from '../../config/constants';

const addType = (list, type) => list.map((el) => ({ ...el, type }));

const verticaStepperStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 1.75),
    marginBottom: theme.spacing(20),
  },
}));

export default function VerticalStepper({
  digitalSignature, history: logsHistory = [], status, parent, comments = [], manuallyCreated,
}) {
  const styles = verticaStepperStyle();
  const history = [
    ...addType(logsHistory, STEP_TYPES.LOG),
    ...addType(comments, STEP_TYPES.COMMENT),
  ].sort((a, b) => moment(a.time).diff(moment(b.time)));

  const lastIndex = history.length - 1;
  let lastTime;
  const stepsGroup = history.map((step, index) => {
    const { type } = step;
    if (!lastTime || moment(lastTime).format('DD-MM-YYYYY') !== moment(step.time).format('DD-MM-YYYYY')) {
      lastTime = step.time;
      return (
        <React.Fragment key={step.time}>
          <StepperDate date={lastTime} />
          <Step
            itemStatus={status}
            data={step}
            key={step.time}
            last={lastIndex === index}
            parent={parent}
            type={type}
            manuallyCreated={manuallyCreated}
            digitalSignature={digitalSignature}
          />
        </React.Fragment>
      );
    }
    return (
      <Step
        itemStatus={status}
        data={step}
        key={step.time}
        last={lastIndex === index}
        parent={parent}
        type={type}
        digitalSignature={digitalSignature}
      />
    );
  });
  return (
    <Box display="flex" alignItems="flex-start" className={styles.container} flexDirection="column" flexGrow={1}>
      {stepsGroup}
    </Box>
  );
}
