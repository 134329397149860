import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InfiniteScroll from 'react-infinite-scroller';
import Radio from '../../assets/Radio';

const checkboxStyle = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(4, 2.5),
    color: 'black !important',
  },
  boderBottom: {
    borderBottom: `${theme.spacing(0.125)}px solid black`,
  },
  newPatient: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(4, 2.5),
    textAlign: 'center',
  },
  box: {
    display: 'flex',
  },
  spreadBox: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    boxShadow: 'none',
    textTransform: 'none',
    padding: theme.spacing(0.75, 0.5),
    margin: theme.spacing(0.4),
    minWidth: theme.spacing(6.2),
  },
}));

export default function RadioButtonList({
  list,
  filterValue,
  pendingDataToPaginate,
  onPagination,
  onChangeFilterValue,
  searchCompleted,
  isListEmpty,
  onClickNewPatient,
  onClickCancelPatient,
}) {
  const styles = checkboxStyle();

  const handleStatusValue = (event) => {
    event.stopPropagation();
    onChangeFilterValue(event.target.value);
  };

  const radioElements = list.map((element, index) => {
    const isLast = index === list.length - 1;
    const label = element.name ? element.name : String(element.id);

    return (
      <Box key={element.id}>
        <RadioGroup aria-label="centers" name="centers" value={filterValue} onChange={handleStatusValue}>
          <FormControlLabel
            value={element.remoteId.toString()}
            control={<Radio />}
            label={label}
          />
        </RadioGroup>
        {!isLast && (
          <Box className={styles.boderBottom} display="flex" flexGrow={1} />
        )}
      </Box>
    );
  });

  // New element until we work on adding patients through CofC
  const patientNotFoundElement = (
    <Box className={styles.newPatient} textAlign="center">
      <Typography variant="subtitle1">
        The patient was not found in the system.
      </Typography>
      <Box
        component="span"
        m={1}
        className={`${styles.spreadBox} ${styles.box}`}
      >
      </Box>
    </Box>
  );

  // Element to add patients to CofC database, not to be used until better clarification on where patients should be stored and their purpose
  // const newPatientElement = (
  //   <Box className={styles.newPatient} textAlign="center">
  //     <Typography variant="subtitle1">
  //       The patient was not found in the system,
  //       would you like to add it?
  //     </Typography>
  //     <Box
  //       component="span"
  //       m={1}
  //       className={`${styles.spreadBox} ${styles.box}`}
  //     >
  //       <Box>
  //         <Button
  //           variant="contained"
  //           color="primary"
  //           className={styles.button}
  //           onClick={onClickNewPatient}
  //         >
  //           <Box color="common.white" component="span">
  //             YES
  //           </Box>
  //         </Button>
  //       </Box>
  //       <Box>
  //         <Button
  //           variant="contained"
  //           color="default"
  //           className={styles.button}
  //           onClick={onClickCancelPatient}
  //         >
  //           <Box component="span">
  //             NO
  //           </Box>
  //         </Button>
  //       </Box>
  //     </Box>
  //   </Box>
  // );

  return (
    <>
      {searchCompleted && !isListEmpty && list.length > 0
        && (
        <Box component={FormControl} display="flex" flexGrow={1}>
          <InfiniteScroll
            pageStart={0}
            loadMore={onPagination}
            hasMore={pendingDataToPaginate}
          >
            {radioElements}
          </InfiniteScroll>
        </Box>
        )}
      {searchCompleted && isListEmpty && list.length === 0
         && (
           <Box>
             {patientNotFoundElement}
           </Box>
         )}
    </>
  );
}
