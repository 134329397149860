import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

const Banner = ({ show, text, onHideBanner }) => {
  const handleDismiss = (event) => {
    event.stopPropagation();
    onHideBanner();
  };

  return (
    show
    && (
    <Alert
      color="none"
      icon={false}
      action={(
        <Button onClick={handleDismiss}>
          <Typography color="primary" align="right">OK</Typography>
        </Button>
      )}
    >
      <Typography>{text}</Typography>

    </Alert>
    )
  );
};

export { Banner as default };
