import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Slide,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.white,
    padding: theme.spacing(0.75, 2),
  },
  Typography: {
    align: 'center',
  },
  fontSize: {
    title: 'fontWeightMedium',
    content: 'fontWeightRegular',
    [theme.breakpoints.up('md')]: {
      title: 'fontWeightRegular',
      content: 'Light',
    },
    [theme.breakpoints.up('xs')]: {
      title: 'fontWeightRegular',
      content: 'Light',
    },
  },
  action: {
    buttonSize: 'medium',
    [theme.breakpoints.up('xs')]: {
      buttonSize: 'small',
    },
  },
}));

const DialogStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    borderRadius: 5,
    minWidth: '95%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '380px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(DialogStyle)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AddToHomeScreenDialog({
  openDialog, onChangeAcceptedPrompt,
  onChangeCancelPrompt, onChangeClickAwayPrompt, dialogContent,
  showButtons, showAppleShareIcon,
}) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onChangeCancelPrompt}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      onBackdropClick={onChangeClickAwayPrompt}
      TransitionComponent={Transition}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle align="center" id="customized-dialog-title" onClose={onChangeCancelPrompt}>
        <Box fontWeight={classes.fontSize.title} justifyContent="center">
          Door2Door
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box fontWeight={classes.fontSize.content} fontSize={15} textAlign="center" display="flex" justifyContent="center">
          <Typography variant="body2" gutterBottom>
            {dialogContent}
          </Typography>
        </Box>
        <Box fontWeight={classes.fontSize.content} fontSize={15} textAlign="center" justifyContent="center" display={showAppleShareIcon}>
          <Typography variant="body2" gutterBottom>
            Tap
            <img
              src="/static/images/AppleShare.png"
              style={{ margin: 'auto 5px' }}
              alt="Add to Homescreen"
              height="20"
              width="20"
            />
            then &quot;Add to Home Screen&quot;
          </Typography>
        </Box>
        <Box display={showButtons}>
          <Typography variant="body2" gutterBottom />
        </Box>
        <Box display="flex" justifyContent="center">
          <DialogActions>
            <Box display={showButtons}>
              <Button
                onClick={onChangeAcceptedPrompt}
                variant="contained"
                color="primary"
                className={classes.button}
                size="small"
              >
                ADD TO HOME SCREEN
              </Button>
            </Box>
            <Box display={showButtons}>
              <Button
                onClick={onChangeCancelPrompt}
                variant="contained"
                size="small"
              >
                CANCEL
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
