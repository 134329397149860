import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Entry from '../components/UserCreation';
import {
  resetUserCreationState, getRoles, getPaceCentersList, handleGoBack,
  resetPaceCentersSearchState, postUserCreation, addAlert, postResetState,
  postBulkUsersCreation,
} from '../actions/dashboard';
import { setNavigationStepbackButton } from '../actions/navigation';
import WithLoading from '../components/WithLoadingList';
import { mobileDetection } from '../services/utility';
import { nav } from '../components/UserCreation/constants';
import { useAuth0 } from '../auth0';

const EntryWithLoading = WithLoading(Entry);

const initialState = {
  username: '',
  email: '',
  rol: '',
  paceCenters: [],
};

const UserCreationContainer = ({ bulk }) => {
  const bulkCreation = bulk;
  const [entry, setEntry] = useState(initialState);
  const {
    error, formError, isLoading, userId,
    rolesList, searchOutcome, paceCentersList,
    navigation, loading, totalOutcome, pendingDataToPaginate, alerts,
    loadingCreation, successCreation, page, jobId, jobDescription,
  } = useSelector((state) => state.dashboardState);
  const browserSettings = useState(mobileDetection(window.navigator));
  const { getTokenSilently, user } = useAuth0();
  const [token, setToken] = useState('');
  const [enableButton, setEnableButton] = useState(true);
  const [searchValue, handleSearchValue] = useState('');
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [disableSearch, setDisableSearch] = useState(true);
  const [selectedPaceCentersList, setSelectedPaceCentersList] = useState(paceCentersList);
  const [filterValue, handleFilterValue] = useState([]);
  const [errors, setErrors] = useState({});
  const [username, setUsername] = useState(initialState.username);
  const [highlightText, handleHighlightText] = useState('');
  const [pag, setPag] = useState(0);
  const [file, setFile] = useState(null);
  const [fileDescription, setFileDescription] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    const currentPage = 'Users Creation';
    dispatch(setNavigationStepbackButton(currentPage));
    if (!bulk) {
      dispatch(resetUserCreationState());
    }
  }, []);

  useEffect(() => {
    if (searchValue === '' && !disableSearch) {
      setDisableSearch(true);
    } else if (searchValue !== '' && disableSearch) {
      setDisableSearch(false);
    }
  }, [searchValue]);

  const getToken = async () => {
    setToken(await getTokenSilently());
  };

  useEffect(() => {
    if (user && token) {
      dispatch(getRoles(token));
    } else {
      getToken();
    }
  }, [dispatch, user, token]);

  const validate = () => {
    const temp = { ...errors };
    if (entry.email !== '') {
      const re = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
      temp.email = re.test(entry.email)
        ? ''
        : 'Email is not valid.';
    }
    setErrors({
      ...temp,
    });
  };

  useEffect(() => {
    if (entry.username !== '' && entry.email !== '' && entry.rol !== '') {
      if (entry.rol !== 'Admin' && entry.paceCenters.length === 0) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    } else {
      setEnableButton(true);
    }
    validate();
  }, [entry]);

  useEffect(() => {
    if (formError !== null && !successCreation) {
      setErrors({
        ...errors,
        email: formError,
      });
    }
    if (bulk && !successCreation && formError !== null) {
      dispatch(addAlert(formError));
    }
  }, [formError]);

  useEffect(() => {
    if (file !== null && fileDescription !== '') {
      setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  }, [file, fileDescription]);

  useEffect(() => {
    if (successCreation && !bulk) {
      dispatch(addAlert(userId));
      dispatch(postResetState());
      setEntry(initialState);
    } else if (successCreation && bulk) {
      dispatch(addAlert(jobId));
      setFile(null);
      setFileDescription('');
    }
  }, [successCreation]);

  const handleChangeEntry = (event) => {
    if (event.target.name === 'username') {
      setEntry({
        ...entry,
        // eslint-disable-next-line no-useless-escape
        [event.target.name]: event.target.value.replace(/[^ \u00f1\u00d1a-zA-Z0-9]/gi, ''),
      });
    } else {
      setEntry({
        ...entry,
        [event.target.name]: event.target.value,
      });
    }

    if (event.target.name === 'email') {
      validate();
    }
  };

  const handleSearch = async () => {
    handleHighlightText(searchValue);
    handleFilterValue([]);
    dispatch(resetPaceCentersSearchState());
    setPag(0);
    await dispatch(getPaceCentersList(searchValue, 0, token));
    setSearchCompleted(true);
  };

  const handlePagination = () => {
    setPag(pag + 1);
    dispatch(getPaceCentersList(searchValue, pag, token));
  };

  const handleOnCLickDeleteElement = async (element) => {
    setSelectedPaceCentersList(selectedPaceCentersList.filter((obj) => obj.id !== element));
    setEntry({
      ...entry,
      paceCenters: entry.paceCenters.filter((obj) => obj.id !== element),
    });
  };

  const handleOnClickPaceCenters = () => {
    handleSearchValue('');
    handleFilterValue([]);
    setSearchCompleted(false);
    dispatch(resetPaceCentersSearchState());
    dispatch(handleGoBack(nav.code.PACE_CENTERS_SEARCH));
  };

  const handleOnClickFooterButton = () => {
    const filterList = searchOutcome.filter((obj) => filterValue.includes(obj.id));
    const tmpValues = selectedPaceCentersList;
    filterList.forEach((e) => {
      if (selectedPaceCentersList.findIndex((o) => o.id === e.id) === -1) {
        tmpValues.push(e);
      }
    });
    setSelectedPaceCentersList(tmpValues);
    setEntry({
      ...entry,
      paceCenters: selectedPaceCentersList,
    });
    dispatch(handleGoBack(nav.code.USER_CREATION_FORM));
    setSearchCompleted(false);
  };

  const postNewUserCreation = () => {
    validate();
    setUsername(entry.username);
    const log = {
      username: entry.username,
      email: entry.email,
      rolId: rolesList.filter((e) => e.name === entry.rol)[0].id,
      paceCenters: entry.rol !== 'Admin' ? entry.paceCenters.map((e) => `Organization/${e.remoteId}`) : null,
    };
    if (errors.email === '') {
      dispatch(postUserCreation(log, token));
    }
  };

  const postNewBulkUsersCreation = () => {
    if (file !== null && fileDescription !== '' && !loadingCreation) {
      dispatch(postBulkUsersCreation(fileDescription, file, token));
    }
  };

  return (
    <EntryWithLoading
      value={entry}
      onChangeValue={handleChangeEntry}
      searchOutcome={searchOutcome}
      totalOutcome={totalOutcome}
      filterValue={filterValue}
      onChangeFilterValue={handleFilterValue}
      onChangeSearchValue={handleSearchValue}
      paceCentersList={selectedPaceCentersList}
      handlePaceCentersList={setSelectedPaceCentersList}
      rolesList={rolesList}
      error={error}
      loading={isLoading}
      browserSettings={browserSettings}
      bulkCreation={bulkCreation}
      enableButton={enableButton}
      onClickSearch={handleSearch}
      onClickSearchButton={handleOnClickPaceCenters}
      onPagination={handlePagination}
      searchCompleted={searchCompleted}
      status={false}
      navigation={navigation}
      onClickDeleteElement={handleOnCLickDeleteElement}
      searchLoading={loading}
      pendingDataToPaginate={pendingDataToPaginate}
      onClickFooterButton={handleOnClickFooterButton}
      disableFooterButton={filterValue.length === 0}
      onPostNewUser={postNewUserCreation}
      errors={errors}
      alerts={alerts}
      loadingCreation={loadingCreation}
      username={username}
      highlightText={highlightText}
      startPage={page}
      onChangeFile={setFile}
      onPostNewBulkUsersCreation={postNewBulkUsersCreation}
      successCreation={successCreation}
      fileDescription={fileDescription}
      onChangeFileDescription={setFileDescription}
      jobDescription={jobDescription}
    />
  );
};

export { UserCreationContainer as default };
