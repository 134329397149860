import { combineReducers } from 'redux';
import logsState from './reducers/logs';
import suppliesState from './reducers/supplies';
import custodyState from './reducers/custody';
import deliverState from './reducers/deliver';
import navigationState from './reducers/navigation';
import filtersState from './reducers/filters';
import commentsState from './reducers/comments';
import trackableState from './reducers/trackable';
import reportState from './reducers/reports';
import dashboardState from './reducers/dashboard';
import jobsState from './reducers/jobs';
import loginState from './reducers/login';

export default combineReducers({
  logsState,
  suppliesState,
  custodyState,
  deliverState,
  navigationState,
  filtersState,
  commentsState,
  trackableState,
  reportState,
  dashboardState,
  jobsState,
  loginState
});
