import * as actionTypes from '../action_types/filters';
import {
  getCustodiansFilter,
  getPatientsFilter,
  getItemsFilter,
  getPaceCenters,
} from '../services/serviceClient';
import { FILTER_TYPES } from '../config/constants';

export const setSuppliesFilters = ({
  filterType, value, startDate, endDate, expanded,
}) => ({
  type: actionTypes.SET_SUPPLIES_FILTERS,
  filterType,
  payload: {
    value,
    startDate,
    endDate,
    expanded,
  },
});

export const setAllSuppliesFilters = ({ filtersListToApply }) => ({
  type: actionTypes.SET_ALL_SUPPLIES_FILTERS,
  filtersListToApply,
});

export const setFiltersToShow = ({ filters }) => ({
  type: actionTypes.SHOW_FILTERS,
  payload: { ...filters },
});

export const setFiltersExpanded = ({
  filterType, value, startDate, endDate, expanded, tmpValue,
}) => ({
  type: actionTypes.FILTER_TO_EXPAND,
  filterType,
  payload: {
    value,
    startDate,
    endDate,
    expanded,
    tmpValue,
  },
});

export const getFilterElementsByType = (keyword, type, page, token) => ({
  type: actionTypes.GET_FILTER_ELEMENTS_BY_TYPE,
  async payload() {
    let getFilters = null;
    if (type === FILTER_TYPES.PATIENT) {
      getFilters = getPatientsFilter;
    } else if (type === FILTER_TYPES.ITEM) {
      getFilters = getItemsFilter;
    } else if (type === FILTER_TYPES.CUSTODIAN) {
      getFilters = getCustodiansFilter;
    } else if (type === FILTER_TYPES.CENTER) {
      getFilters = getPaceCenters;
    }

    const rawData = await getFilters(keyword, page, token);
    let result = { list: [], pageData: { total: 0 }, page };

    if (type === FILTER_TYPES.PATIENT) {
      result = { list: [...rawData.patients], pageData: { ...rawData.pageData }, page };
    } else if (type === FILTER_TYPES.ITEM) {
      const items = rawData.supplyDeliveries.map((item) => ({ id: item.code }));
      result = { list: [...items], pageData: { ...rawData.pageData }, page };
    } else if (type === FILTER_TYPES.CUSTODIAN) {
      result = { list: [...rawData.results], pageData: { ...rawData.pageData }, page };
    } else if (type === FILTER_TYPES.CENTER) {
      result = { list: [...rawData.organizations], pageData: { ...rawData.pageData }, page };
    }

    return result;
  },
});

export const handleGoBack = (navigation) => ({
  type: actionTypes.FILTER_MENU_HANDLE_GO_BACK,
  payload: {
    navigation,
  },
});

export const resetState = () => ({
  type: actionTypes.RESET_FILTERS,
});

export const resetOnSearchState = () => ({
  type: actionTypes.RESET_ON_SEARCH_FILTERS,
});

export const resetOnBackMenuState = () => ({
  type: actionTypes.RESET_ON_BACK_MENU_FILTERS,
});

export const clearElementFromListSate = ({
  filterType, element, value, tmpValue,
}) => ({
  type: actionTypes.CLEAR_ELEMENT_FROM_LIST,
  filterType,
  payload: {
    element,
    value,
    tmpValue,
  },
});
