import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { removeAlert } from '../../actions/dashboard';

const AUTOREMOVE_TIMER = 10000;

let message = '';

const useStyles = makeStyles(() => ({
  goToLog: {
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'undeline',
  },
}));

const AlertWrap = ({
  element, jobId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  message = `a new Users reports have been generated as ${element}. Please follow the link.`;

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(removeAlert(element));
    }, AUTOREMOVE_TIMER);
  }, []);

  const goToLog = () => {
    history.push(`/settings/jobs/${String(jobId)}`);
  };
  return (
    <Box mt={1}>
      <Alert severity="success">
        {message}
        <Button size="small" className={classes.goToLog} color={classes.color} onClick={goToLog}>
          View
        </Button>
      </Alert>
    </Box>
  );
};

export { AlertWrap as default };
