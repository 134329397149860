import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '../../assets/Radio';
import { SORT } from '../../config/constants';


const styles = makeStyles(() => ({
  box: {
    cursor: 'default',
  },
  root: {
    '&..MuiFormControlLabel-root': {
      cursor: 'default',
    },
  },
}));

export default function OrderByDates({
  filterValue, onChangeFilterValue,
}) {
  const classes = styles();

  const handleStatusValue = (event) => {
    event.stopPropagation();
    onChangeFilterValue(event.target.value);
  };

  return (
    <Box className={classes.box} component={FormControl} display="flex" flexGrow={1}>
      <RadioGroup aria-label="status" name="status" value={filterValue} onChange={handleStatusValue}>
        <FormControlLabel
          classes={{
            root: classes.box,
          }}
          value={SORT.CREATED_DATE_DESC}
          control={<Radio />}
          label={SORT.LABEL.CREATED_DATE_DESC}
        />
        <FormControlLabel
          classes={{
            root: classes.box,
          }}
          value={SORT.CREATED_DATE_ASC}
          control={<Radio />}
          label={SORT.LABEL.CREATED_DATE_ASC}
        />
        <FormControlLabel
          classes={{
            root: classes.box,
          }}
          value={SORT.UPDATED_DATE_DESC}
          control={<Radio />}
          label={SORT.LABEL.UPDATED_DATE_DESC}
        />
        <FormControlLabel
          classes={{
            root: classes.box,
          }}
          value={SORT.UPDATED_DATE_ASC}
          control={<Radio />}
          label={SORT.LABEL.UPDATED_DATE_ASC}
        />
      </RadioGroup>
    </Box>
  );
}
