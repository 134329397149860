import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '../../assets/Radio';
import { ACTIVITY_TYPES } from '../../config/constants';


const styles = makeStyles(() => ({
  box: {
    cursor: 'default',
  },
  root: {
    '&..MuiFormControlLabel-root': {
      cursor: 'default',
    },
  },
}));

export default function FilterByActivity({
  filterValue, onChangeFilterValue, startDateRange,
  onChangeStartDateRange, endDateRange, onChangeEndDateRange, customActivity,
}) {
  const classes = styles();
  let customBox = '';

  const handleStatusValue = (event) => {
    event.stopPropagation();
    onChangeFilterValue(event.target.value);
  };

  const handleChangeStartDate = (event) => {
    onChangeStartDateRange(event);
  };

  const handleChangeEndDate = (event) => {
    onChangeEndDateRange(event);
  };

  if (customActivity) {
    customBox = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          value={startDateRange}
          maxDate={endDateRange}
          format="MM/dd/yyyy"
          label="Start Date"
          onChange={handleChangeStartDate}
        />
        <DatePicker
          value={endDateRange}
          minDate={startDateRange}
          maxDate={new Date()}
          format="MM/dd/yyyy"
          label="End Date"
          onChange={handleChangeEndDate}
        />
      </MuiPickersUtilsProvider>
    );
  }


  return (
    <Box className={classes.box} component={FormControl} display="flex" flexGrow={1}>
      <RadioGroup aria-label="status" name="status" value={filterValue} onChange={handleStatusValue}>
        <FormControlLabel
          classes={{
            root: classes.box,
          }}
          value={ACTIVITY_TYPES.LAST7}
          control={<Radio />}
          label="Last 7 days"
        />
        <FormControlLabel
          classes={{
            root: classes.box,
          }}
          value={ACTIVITY_TYPES.LAST30}
          control={<Radio />}
          label="Last 30 days"
        />
        <FormControlLabel
          classes={{
            root: classes.box,
          }}
          value={ACTIVITY_TYPES.CUSTOM}
          control={<Radio />}
          label="Custom"
        />
      </RadioGroup>
      <>
        <br />
        {customBox}
      </>
    </Box>
  );
}
