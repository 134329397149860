import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';

const replaceSpecialCharacters = (value) => {
  let replacedText = value.replace('++', '+');
  replacedText = replacedText.replace('+-', '+');
  replacedText = replacedText.replace('+.', '+');
  replacedText = replacedText.replace('+@', '+');
  replacedText = replacedText.replace('+_', '+');
  replacedText = replacedText.replace('@@', '@');
  replacedText = replacedText.replace('@+', '@');
  replacedText = replacedText.replace('@-', '@');
  replacedText = replacedText.replace('@.', '@');
  replacedText = replacedText.replace('@_', '@');
  replacedText = replacedText.replace('..', '.');
  replacedText = replacedText.replace('.@', '.');
  replacedText = replacedText.replace('.+', '.');
  replacedText = replacedText.replace('.-', '.');
  replacedText = replacedText.replace('._', '.');
  replacedText = replacedText.replace('--', '-');
  replacedText = replacedText.replace('-@', '-');
  replacedText = replacedText.replace('-.', '-');
  replacedText = replacedText.replace('-+', '-');
  replacedText = replacedText.replace('-_', '-');
  replacedText = replacedText.replace('_.', '_');
  replacedText = replacedText.replace('_+', '_');
  replacedText = replacedText.replace('_-', '_');
  replacedText = replacedText.replace('_@', '_');
  return replacedText;
};

const TextFieldInput = ({
  label,
  autoComplete,
  inputPrefix,
  isMultiline,
  placeholder,
  disabled,
  onChange,
  debounceTime,
  debounce,
  value: propsValue,
  processInput,
  variant,
  isFilterByRole,
}) => {
  const [value, setValue] = useState(propsValue);
  const debounceRef = useRef(null);

  if (isFilterByRole && !!value) {
    setValue('');
  }

  const handleOnChange = (event) => {
    const processedValue = processInput ? processInput(event.target.value) : event.target.value;
    // eslint-disable-next-line no-useless-escape
    let cutValue = processedValue.replace(/[^ @\u00f1\u00d1a-zA-Z0-9-_+.]/gi, '');
    cutValue = replaceSpecialCharacters(cutValue);

    if (value.length === 0 || value !== cutValue) {
      setValue(cutValue);
    }

    if (debounce) {
      if (debounceRef.current !== null) clearTimeout(debounceRef.current);
      event.persist();
      debounceRef.current = setTimeout(() => {
        debounceRef.current = null;
        onChange(event.target.value);
      }, debounceTime);
    } else {
      onChange(event);
    }
  };

  return (
    <TextField
      label={label}
      onChange={handleOnChange}
      value={isFilterByRole ? '' : value}
      autoComplete={autoComplete ? 'on' : 'off'}
      variant={variant}
      fullWidth={!inputPrefix}
      multiline={isMultiline}
      rows={isMultiline ? 4 : undefined}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

TextFieldInput.propTypes = {
  autoComplete: PropTypes.bool,
  inputPrefix: PropTypes.node,
  isMultiline: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  debounceTime: PropTypes.number,
  debounce: PropTypes.bool,
  value: PropTypes.string,
  processInput: PropTypes.func,
};

TextFieldInput.defaultProps = {
  autoComplete: false,
  inputPrefix: null,
  isMultiline: false,
  placeholder: '',
  disabled: false,
  onChange: () => {},
  debounceTime: 250,
  debounce: true,
  value: '',
  processInput: null,
};

export default TextFieldInput;
