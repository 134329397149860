import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { grey } from '@material-ui/core/colors';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpansionPanel, { ExpansionPanelSummary, ExpansionPanelDetails } from '../ExpansionPanel';
import SupplyBundleList from './SupplyBundleList';
import CommentBubble from './CommentBubble';
import { addExpanded, removeExpanded } from '../../actions/supplies';
import {
  IN_PROGRESS, DELIVERED, PACKAGED, ARCHIVED, LOG_TYPES,
} from '../../config/constants';

const UNMOUNT_TIMER = 1200;

const supplyBUndleStyle = makeStyles((theme) => ({
  code: {
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  icon: {
    marginLeft: theme.spacing(0.625),
  },
  label: {
    padding: theme.spacing(0.625),
    textDecoration: 'none',
    color: 'inherit',
  },
  underlineBold: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  expansion: {
    marginRight: theme.spacing(0.625),
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  packageStyle: {
    width: '100px',
    [theme.breakpoints.up(280)]: {
      width: '100px',
    },
    [theme.breakpoints.up(330)]: {
      width: '150px',
    },
    [theme.breakpoints.up(500)]: {
      width: '200px',
    },
    [theme.breakpoints.up(600)]: {
      width: '230px',
    },
    [theme.breakpoints.up(650)]: {
      width: '240px',
    },
    [theme.breakpoints.up(750)]: {
      width: '260px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '430px',
    },
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  custodianStyle: {
    width: '60px',
    textAlign: 'right',
    [theme.breakpoints.up(350)]: {
      width: '70px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(370)]: {
      width: '80px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(550)]: {
      width: '100px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(600)]: {
      width: '110px',
      textAlign: 'right',
    },
  },
  defaultWidth: {
    [theme.breakpoints.up(300)]: {
      width: '80px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(370)]: {
      width: '85px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(550)]: {
      width: '100px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(600)]: {
      width: '110px',
      textAlign: 'right',
    },
  },
  patientStyle: {
    width: '100px',
    [theme.breakpoints.up(280)]: {
      width: '100px',
    },
    [theme.breakpoints.up(330)]: {
      width: '150px',
    },
    [theme.breakpoints.up(500)]: {
      width: '200px',
    },
    [theme.breakpoints.up(600)]: {
      width: '230px',
    },
    [theme.breakpoints.up(650)]: {
      width: '240px',
    },
    [theme.breakpoints.up(750)]: {
      width: '260px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '430px',
    },
    lineHeight: 'normal',
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: [
      'PT Mono',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#82BC00',
    },
    secondary: {
      main: '#530070',
    },
  },
});

export default function SupplyBundle({
  user, status, code, date, list, type, patient, comments,
}) {
  const styles = supplyBUndleStyle();
  const longWidth = useMediaQuery('(min-width:1100px)');
  const desktopWidth = useMediaQuery('(min-width:600px)');
  const tableWidth = useMediaQuery('(min-width:770px)');
  const [expanded, setExpanded] = React.useState(false);
  const [showPanel, setShowPanel] = React.useState(false);
  const [timer, setTimer] = React.useState(null);
  let expansionIcon = null;
  const expansionColor = 'secondary.main';
  let statusStyle = null;
  let custodian = null;
  const dispatch = useDispatch();
  const openList = useSelector((state) => state.suppliesState.openList);
  const underlineBoldText = (text) => <span className={styles.underlineBold}>{text}</span>;
  const handleChange = (panel, open) => (event) => {
    event.stopPropagation();
    if (open) {
      setExpanded(panel);
      dispatch(addExpanded(panel));
    } else {
      setExpanded(open);
      dispatch(removeExpanded(panel));
    }
  };

  const custStyle = (codes) => {
    if ((!desktopWidth || !tableWidth) && codes.length > 17 && custodian.length > 20) {
      return styles.custodianStyle;
    } if (!desktopWidth && (codes.length > 17 || custodian.length > 20)) {
      return styles.defaultWidth;
    }
    return '';
  };

  if (expanded) {
    expansionIcon = <RemoveIcon style={{ fontSize: 15 }} onClick={handleChange(code, false)} />;
  } else {
    expansionIcon = <AddIcon style={{ fontSize: 15 }} onClick={handleChange(code, true)} />;
  }
  if ([IN_PROGRESS, PACKAGED, ARCHIVED].includes(status)) {
    statusStyle = 'secondary.main';
    custodian = user;
  } else if (status === DELIVERED) {
    statusStyle = grey[600];
    custodian = 'Delivered';
  }

  React.useEffect(() => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    if (!!expanded !== showPanel) {
      if (expanded) {
        setShowPanel(!!expanded);
      } else {
        setTimer(setTimeout(() => setShowPanel(expanded), UNMOUNT_TIMER));
      }
    }
  }, [expanded]);

  React.useEffect(() => {
    openList.forEach((element) => {
      if (element === code) {
        setExpanded(element);
      }
    });
  }, []);

  return (
    <ExpansionPanel square expanded={expanded === code}>
      <ExpansionPanelSummary aria-controls={`${code}-content`} id={`${code}-header`}>
        <Box
          flexGrow={1}
          className={styles.label}
          borderRadius={3}
          border={1}
          borderColor="secondary.main"
          display="flex"
        >
          <Box display="flex" color={expansionColor} className={styles.expansion}>
            {expansionIcon}
          </Box>
          <Box to={`/orders/${code}/logs`} component={Link} className={styles.link}>
            <Box display="flex" alignItems="flex-end">
              <ThemeProvider theme={theme}>
                <Box
                  fontFamily="PT Mono"
                  className={styles.code}
                  color={statusStyle}
                  display="flex"
                >
                  <Typography
                    variant="body2"
                    component="div"
                    className={(code.length > 11 && !longWidth) || (code.length > 34 && longWidth)
                      ? styles.packageStyle : styles.code}
                    noWrap
                  >
                    {type === LOG_TYPES.SHIPMENT ? 'SHIPMENT-' : 'ORDER-'}
                    {code}
                  </Typography>
                  <CommentBubble comments={comments} />
                </Box>
              </ThemeProvider>
            </Box>
            <Box color="text.primary">
              {type === LOG_TYPES.ORDER && patient && (
                <>
                  <Box display="flex" alignItems="flex-end">
                    <Box display="flex">
                      <Typography variant="body2" className={patient !== undefined ? styles.patientStyle : styles.code} noWrap>
                        To:
                        {' '}
                        {underlineBoldText(patient)}
                      </Typography>
                      <br />
                    </Box>
                  </Box>
                </>
              )}
              {date && (
              <>
                Updated
                {' '}
                {moment(date).calendar()}
              </>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            flexGrow={1}
            alignContent="start"
            flexShrink={0}
          >
            <Typography variant="body2" component="span" noWrap className={custStyle(code)}>
              {custodian}
            </Typography>
          </Box>
        </Box>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {list && list.length > 0 && showPanel && (
          <SupplyBundleList list={list} SupplyBundleComponent={SupplyBundle} />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
