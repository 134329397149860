import React from 'react';
import {
  Container, Box, Divider, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { helperText } from './constants';
import Button from '../../assets/Button';
import DispatchButton from '../../assets/DispatchButton';
import TextField from '../../assets/TextField';
import RolMenuItem from './RolMenuItem';
import { CHARACTER_LIMIT } from '../../config/constants';
import PaceCentersSection from './PaceCentersSection';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    width: '100%',
    color: 'primary',
    alignContent: 'center',
    backgroundColor: theme.palette.secondary.light,
    disabled: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  searchButton: {
    right: theme.spacing(-1),
    fontWeight: 'bold',
  },
  fieldset: {
    border: 'none',
    padding: '0',
  },
  container: {
    marginTop: '40px',
  },
  caption: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.black,
    opacity: '0.6',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  textInput: {
    width: '98%',
    marginRight: theme.spacing(3),
    spacing: 2,
  },
  readAgreement: {
    margin: theme.spacing(3, 0, 4, 0),
    textAlign: 'justify',
  },
  inputRootLabel: {
    '&$disabled': {
      color: '#616161',
    },
  },
  inputRootText: {
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  disabled: {},
  buttonControl: {
    width: '100%',
  },
}));

const UserCreationForm = ({
  value, onChangeValue, onPostNewUser, rolesList,
  enableButton, isLoading, onClickSearchButton,
  paceCentersList, onClickDeleteElement, errors,
  loadingCreation,
}) => {
  const classes = useStyles();
  const handleChangeValue = (event) => {
    event.stopPropagation();
    onChangeValue(event);
  };

  const paceCentersMenu = () => value.rol !== '' && value.rol !== 'Admin'
    && (
      <>
        <Box>
          <br />
          <Divider variant="inset" />
        </Box>
        <Box
          className={classes.searchButton}
          justifyContent="space-between"
          display="flex"
        >
          <Box>
            <Typography
              sx={{ mt: 0.5, ml: 9 }}
              display="block"
            >
              paceCenters
            </Typography>
          </Box>
          <Box>
            <Button
              color="secondary"
              onClick={onClickSearchButton}
              className={classes.searchButton}
            >
              SEARCH
            </Button>
          </Box>
        </Box>
        <Box>
          <PaceCentersSection
            paceCentersList={paceCentersList}
            onClickDeleteElement={onClickDeleteElement}
          />
        </Box>
        <Box>
          <br />
          <Divider />
        </Box>
      </>
    );

  return (
    <Box>
      <Container className={classes.container}>
        <Box>
          <TextField
            label="Full name"
            variant="filled"
            value={value.username}
            name="username"
            onChange={handleChangeValue}
            autoComplete="none"
            helperText={helperText.FULLNAME}
            fullWidth
            inputProps={{ maxLength: CHARACTER_LIMIT.FULLNAME }}
          />
        </Box>
        <Box>
          <br />
        </Box>
        <Box>
          <TextField
            label="Email"
            variant="filled"
            value={value.email}
            name="email"
            onChange={handleChangeValue}
            autoComplete="none"
            type="email"
            helperText={errors !== undefined && errors.email !== '' ? errors.email : helperText.EMAIL}
            error={errors !== undefined && errors.email !== undefined && errors.email !== ''}
            fullWidth
            inputProps={{ maxLength: CHARACTER_LIMIT.EMAIL }}
          />
        </Box>
        <Box>
          <br />
        </Box>
        <Box>
          <RolMenuItem
            rolesList={rolesList}
            value={value}
            onChangeValue={onChangeValue}
          />
        </Box>
        <Box>
          {paceCentersMenu}
        </Box>
        <DispatchButton
          className={classes.button}
          disabled={enableButton || isLoading}
          disableElevation
          onClick={onPostNewUser}
          variant="cofc"
          loading={loadingCreation}
          label="CREATE NEW USER"
        />
      </Container>
    </Box>
  );
};

export { UserCreationForm as default };
