import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import getLogsByOrder from '../actions/logs';
import DeliverPackage from '../components/DeliverPackage';
import WithLoading from '../components/WithLoading';
import { setNavigationStepbackButton } from '../actions/navigation';
import postSupplyToBeDelivered, { resetState } from '../actions/deliver';
import { resetState as resetCustodyState } from '../actions/custody';
import { useAuth0 } from '../auth0';
import { DELIVER_TYPES, DELIVERED } from '../config/constants';

const DeliverPackageWithLoading = WithLoading(DeliverPackage);

export default function DeliverPackageContainer() {
  const { orderId } = useParams();
  const history = useHistory();
  const logs = useSelector((state) => state.logsState);
  const isCustodySuccess = useSelector((state) => state.custodyState.isSuccess);
  const { error, isSuccess, isLoading } = useSelector((state) => state.deliverState);
  const dispatch = useDispatch();
  const [deliverTo, setDeliverTo] = useState(DELIVER_TYPES.PATIENT);
  const [giverName, setGiverName] = useState('');
  const [caregiverFirstname, handleCaregiverFirstname] = useState('');
  const [caregiverLastname, setCaregiverLastname] = useState('');
  const [atttestGiver, handleAttestGiver] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState(false);
  const [disableDeliverButton, setDisableDeliverButton] = useState(true);
  const [signature, setSignature] = useState('');
  const signatureRef = useRef({});

  const { user, getTokenSilently } = useAuth0();

  useEffect(() => {
    if (orderId) {
      dispatch(resetState());
      dispatch(setNavigationStepbackButton('Package'));
      if (isCustodySuccess) {
        dispatch(resetCustodyState());
      }
      (async () => {
        const token = await getTokenSilently();
        dispatch(getLogsByOrder(orderId, token));
      })();
    }
  }, [orderId, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(resetState());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (deliverTo === DELIVER_TYPES.PATIENT) {
      setGiverName(logs.location.name);
      setDisableCheckbox(false);
      if (logs.location.name === 'undefined' || logs.location.name === null) {
        setDeliverTo(DELIVER_TYPES.CAREGIVER);
      }
    } else {
      setGiverName(`${caregiverFirstname} ${caregiverLastname}`);
      setDisableCheckbox(!(caregiverFirstname && caregiverLastname));
    }
  }, [caregiverFirstname, caregiverLastname, deliverTo, logs]);

  useEffect(() => {
    setDisableDeliverButton(!atttestGiver);
  }, [atttestGiver]);

  const handlerSaveSignature = () => {
    setSignature(signatureRef.current.getTrimmedCanvas().toDataURL('image/png'));
  };

  const handlerClearSignature = () => {
    signatureRef.current.clear();
    setSignature('');
  };

  const handleCancelDeliver = () => {
    history.goBack();
  };

  const handleChangeDeliverTo = (deliver) => {
    setDeliverTo(deliver);
    handleCaregiverFirstname('');
    setCaregiverLastname('');
    handleAttestGiver(false);
  };

  const handleDeliverSupply = async () => {
    const log = {
      status: DELIVERED,
      deliveredTo: deliverTo,
      location: logs.location,
      user: user.name,
      signature: signature.length > 250 ? signature : null,
    };
    if (caregiverFirstname) {
      log.caregiverFirstname = caregiverFirstname;
    }
    if (caregiverLastname) {
      log.caregiverLastname = caregiverLastname;
    }
    const token = await getTokenSilently();
    if (!isLoading) {
      dispatch(postSupplyToBeDelivered(orderId, log, token));
    }
  };
  return (
    <DeliverPackageWithLoading
      logs={logs}
      loading={logs.loading}
      error={logs.error}
      deliverTo={deliverTo}
      giverName={giverName}
      caregiverFirstname={caregiverFirstname}
      caregiverLastname={caregiverLastname}
      atttestGiver={atttestGiver}
      disableCheckbox={disableCheckbox}
      disableDeliverButton={disableDeliverButton}
      onCancelDeliver={handleCancelDeliver}
      onChangeDeliverTo={handleChangeDeliverTo}
      onChangeCaregiverFirstname={handleCaregiverFirstname}
      onChangeCaregiverLastname={setCaregiverLastname}
      onAttestGiver={handleAttestGiver}
      onDeliverSupply={handleDeliverSupply}
      signatureRef={signatureRef}
      onSaveSignature={handlerSaveSignature}
      onClearSignature={handlerClearSignature}
      isLoading={isLoading}
    />
  );
}
