import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/login';

const initialState = {
  loginType: null
};

const updateLoginType = (state, action) => updateObject(state, {
  ...initialState,
  loginType: action.payload.type
});


export default createReducer(initialState, {
  [actionTypes.POST_LOGIN_TYPE]: updateLoginType,
});
