import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/navigation';
import { ROLES_TYPES } from '../config/constants';

const initialState = {
  currentPageName: '',
  showNavigationDrawer: true,
  showStepBackButton: false,
  showCloseButton: false,
  sessionExtended: false,
  userRole: null,
  newUserConfig: false,
};

const setNavigationStep = (state, action) => updateObject(state, {
  currentPageName: action.payload.currentPageName,
  showNavigationDrawer: action.payload.showNavigationDrawer,
  showStepBackButton: action.payload.showStepBackButton,
  showCloseButton: action.payload.showCloseButton,
});

const setNavigationExtendSession = (state, action) => updateObject(state, {
  sessionExtended: action.payload.sessionExtended,
});

const setNewUserConfiguration = (state, action) => updateObject(state, {
  newUserConfig: action.payload.newUserConfig,
});

const setNavigationUserRole = (state, action) => {
  let rol = null;
  if (action.payload.userRole.findIndex((e) => e === ROLES_TYPES.ADMIN) > -1) {
    rol = ROLES_TYPES.ADMIN;
  } else if (action.payload.userRole.findIndex((e) => e === ROLES_TYPES.MANAGER) > -1) {
    rol = ROLES_TYPES.MANAGER;
  } else if (action.payload.userRole.findIndex((e) => e === ROLES_TYPES.COMMON) > -1) {
    rol = ROLES_TYPES.COMMON;
  }
  return updateObject(state, {
    userRole: rol,
  });
};

export default createReducer(initialState, {
  [actionTypes.SET_NAVIGATION_DRAWER]: setNavigationStep,
  [actionTypes.SET_NAVIGATION_STEPBACK_BUTTON]: setNavigationStep,
  [actionTypes.SET_NAVIGATION_CLOSE_BUTTON]: setNavigationStep,
  [actionTypes.SET_NAVIGATION_EXTEND_SESSION]: setNavigationExtendSession,
  [actionTypes.SET_NAVIGATION_USER_ROLE]: setNavigationUserRole,
  [actionTypes.SET_NEW_USER_CONFIGURATION]: setNewUserConfiguration,
});
