import React from 'react';
import FilterByStatus from './FilterByStatus';
import FilterBySearch from './FilterBySearch';
import FilterByActivity from './FilterByActivity';
import FilterByUpdatedDate from './FilterByUpdatedDate';
import FilterByEntry from './FilterByEntry';
import OrderByDates from './OrderByDates';
import { FILTER_TYPES } from '../../config/constants';

export default function Filters({
  filterType,
  filterValue,
  searchOutcome,
  loading,
  error,
  pendingDataToPaginate,
  onClickFooterButton,
  onDisableFooterButton,
  disableFooterButton,
  searchValue,
  highLightText,
  onChangeFilterValue,
  onChangeSearchValue,
  onClickSearch,
  onPagination,
  startDateRange,
  onChangeStartDateRange,
  endDateRange,
  onChangeEndDateRange,
  customActivity,
  paceCenters,
  disableSearch,
  startPage,
  totalOutcome,
  onSearchCompleted,
}) {
  let FilterContent = FilterBySearch;
  let textInputLabel = '';
  let matchSearchText = false;
  if (filterType === FILTER_TYPES.STATUS) {
    FilterContent = FilterByStatus;
  } else if (filterType === FILTER_TYPES.CUSTODIAN) {
    textInputLabel = 'Enter custodian name';
  } else if (filterType === FILTER_TYPES.PATIENT) {
    textInputLabel = 'Enter patient name';
  } else if (filterType === FILTER_TYPES.ITEM) {
    textInputLabel = 'Enter item ID';
    matchSearchText = true;
  } else if (filterType === FILTER_TYPES.ACTIVITY) {
    FilterContent = FilterByActivity;
  } else if (filterType === FILTER_TYPES.ENTRY) {
    FilterContent = FilterByEntry;
  } else if (filterType === FILTER_TYPES.UPDATED_DATE) {
    FilterContent = FilterByUpdatedDate;
  } else if (filterType === FILTER_TYPES.CENTER) {
    textInputLabel = 'Enter Center ID or name';
  } else if (filterType === FILTER_TYPES.SORT) {
    FilterContent = OrderByDates;
  }

  const handleChangeValue = (value) => {
    onDisableFooterButton(!value.length);
    onChangeFilterValue(value);
  };

  const handleChangeStartDate = (value) => {
    onDisableFooterButton(false);
    onChangeStartDateRange(value);
  };

  const handleChangeEndDate = (value) => {
    onDisableFooterButton(false);
    onChangeEndDateRange(value);
  };

  return (
    <>
      <FilterContent
        filterValue={filterValue}
        searchValue={searchValue}
        textInputLabel={textInputLabel}
        searchOutcome={searchOutcome}
        loading={loading}
        error={error}
        matchSearchText={matchSearchText}
        highLightText={highLightText}
        pendingDataToPaginate={pendingDataToPaginate}
        onChangeFilterValue={handleChangeValue}
        onChangeSearchValue={onChangeSearchValue}
        onClickSearch={onClickSearch}
        onPagination={onPagination}
        startDateRange={startDateRange}
        onChangeStartDateRange={handleChangeStartDate}
        endDateRange={endDateRange}
        onChangeEndDateRange={handleChangeEndDate}
        customActivity={customActivity}
        paceCenters={paceCenters}
        disableSearch={disableSearch}
        startPage={startPage}
        totalOutcome={totalOutcome}
        onSearchCompleted={onSearchCompleted}
        onClickFooterButton={onClickFooterButton}
        disableFooterButton={disableFooterButton}
        filterType={filterType}
      />
    </>
  );
}
