import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Filters from '../components/Filters';
import {
  getFilterElementsByType,
  setFiltersExpanded, handleGoBack,
} from '../actions/filters';
import { nav } from '../components/FiltersMenu/constants';
import { FILTER_TYPES, ACTIVITY_TYPES } from '../config/constants';
import { useAuth0 } from '../auth0';

export default function FiltersContainer({ filterToRender, filterToApply }) {
  const filterType = filterToRender;
  const { getTokenSilently } = useAuth0();

  const initialValue = useSelector((state) => (state.filtersState[filterType]
    ? state.filtersState[filterType].value : []));
  const tmpValue = useSelector((state) => (state.filtersState[filterType]
    && state.filtersState.reset === false
    ? state.filtersState[filterType].tmpValue : initialValue));
  const searchOutcome = useSelector((state) => state.filtersState.searchOutcome);
  const totalOutcome = useSelector((state) => state.filtersState.totalOutcome);
  const expanded = useSelector((state) => (state.filtersState[filterType] ? state.filtersState[filterType].expanded : ''));
  const { paceCenters } = useSelector((state) => state.trackableState);
  const loading = useSelector((state) => state.filtersState.loading);
  const error = useSelector((state) => state.filtersState.error);
  const pendingDataToPaginate = useSelector((state) => state.filtersState.pendingDataToPaginate);
  const [disableFooterButton, handleDisableFooterButton] = useState(true);
  const [filterValue, handleFilterValue] = useState(FILTER_TYPES.STATUS === filterType
    || FILTER_TYPES.ACTIVITY === filterType || FILTER_TYPES.ENTRY === filterType
    || FILTER_TYPES.UPDATED_DATE === filterType || FILTER_TYPES.SORT === filterType
    ? tmpValue : []);
  const [searchValue, handleSearchValue] = useState('');
  const [highLightText, handleHighLightText] = useState('');
  const initialStartDate = useSelector((state) => (state.filtersState[filterType] ? state.filtersState[filterType].startDate : ''));
  const initialEndDate = useSelector((state) => (state.filtersState[filterType] ? state.filtersState[filterType].endDate : ''));
  const resetOnBack = useSelector((state) => state.filtersState.resetOnBack);
  const [startDateRange, handleStarDateRange] = useState(initialStartDate);
  const [endDateRange, handleEndDateRange] = useState(initialEndDate);
  const [currentFilterValue, setCurrentFilterValue] = useState(filterValue);
  const [isCustomActivity, handleIsCustomActivity] = useState(false);
  const [disableSearch, setDisableSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [token, setToken] = useState('');
  const dispatch = useDispatch();

  (async () => {
    setToken(await getTokenSilently());
  })();

  useEffect(() => {
    const filter = {
      filterType,
      value: filterValue,
      startDate: startDateRange,
      endDate: endDateRange,
      expanded,
      tmpValue: filterValue,
    };
    if (filterType !== FILTER_TYPES.ITEM
      && filterType !== FILTER_TYPES.CUSTODIAN
      && filterType !== FILTER_TYPES.PATIENT
      && filterType !== FILTER_TYPES.CENTER && !resetOnBack) {
      filterToApply(filter);
      if (initialValue !== filterValue && tmpValue !== filterValue) {
        dispatch(setFiltersExpanded(filter));
      }
    }
  }, [startDateRange, endDateRange, filterValue]);

  const handleApplyFilter = () => {
    const filterList = searchOutcome.filter((obj) => filterValue.includes(obj.id));
    const tmpValues = tmpValue;
    filterList.forEach((e) => {
      if (tmpValue.findIndex((o) => o.id === e.id) === -1) {
        tmpValues.push(e);
      }
    });

    const filter = {
      filterType,
      value: initialValue.concat(searchOutcome.filter((obj) => filterValue.includes(obj.id))),
      startDate: startDateRange,
      endDate: endDateRange,
      expanded: true,
      tmpValue: tmpValue.length === 0 ? filterList : tmpValues,
    };
    dispatch(setFiltersExpanded(filter));
    dispatch(handleGoBack(nav.code.FILTER_MENU));
  };

  const handleSearch = async () => {
    handleHighLightText(searchValue);
    setCurrentPage(0);
    await dispatch(getFilterElementsByType(searchValue, filterType, currentPage, token));
    setSearchCompleted(true);
  };

  const handlePagination = () => {
    setCurrentPage(currentPage + 1);
    dispatch(getFilterElementsByType(searchValue, filterType, currentPage, token));
  };

  const addDays = (startDate, numberOfDays) => {
    const returnDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + numberOfDays,
      startDate.getHours(),
      startDate.getMinutes(),
      startDate.getSeconds(),
    );
    return returnDate;
  };

  const setDates = (value) => {
    if (value === ACTIVITY_TYPES.CUSTOM) {
      if (currentFilterValue !== filterValue) {
        handleStarDateRange(new Date());
        handleEndDateRange(new Date());
      } else {
        handleStarDateRange(startDateRange);
        handleEndDateRange(endDateRange);
      }
      handleIsCustomActivity(true);
    } else {
      handleEndDateRange(new Date());
      handleIsCustomActivity(false);
      if (value === ACTIVITY_TYPES.LAST7) {
        handleStarDateRange(addDays(new Date(), -7));
      } else if (value === ACTIVITY_TYPES.LAST30) {
        handleStarDateRange(addDays(new Date(), -30));
      }
    }

    setCurrentFilterValue(filterValue);
    if (filterValue.length && currentFilterValue !== filterValue) {
      handleDisableFooterButton(false);
    }
  };

  useEffect(() => {
    if (filterValue === ACTIVITY_TYPES.LAST7 || filterValue === ACTIVITY_TYPES.LAST30
      || filterValue === ACTIVITY_TYPES.CUSTOM) {
      setDates(filterValue);
    }
  }, [filterValue]);

  useEffect(() => {
    if (searchValue === ' ') {
      handleSearchValue(searchValue.trim());
    }
    if (searchValue === '' && !disableSearch && filterType !== FILTER_TYPES.CENTER) {
      setDisableSearch(true);
    } else if (searchValue !== '' && disableSearch && filterType !== FILTER_TYPES.CENTER) {
      setDisableSearch(false);
    }
  }, [searchValue]);

  return (
    <Filters
      filterType={filterType}
      filterValue={filterValue}
      searchValue={searchValue}
      highLightText={highLightText}
      disableFooterButton={disableFooterButton}
      loading={loading}
      error={error}
      searchOutcome={searchOutcome}
      pendingDataToPaginate={pendingDataToPaginate}
      onChangeFilterValue={handleFilterValue}
      onClickFooterButton={handleApplyFilter}
      onDisableFooterButton={handleDisableFooterButton}
      onChangeSearchValue={handleSearchValue}
      onClickSearch={handleSearch}
      onPagination={handlePagination}
      startDateRange={startDateRange}
      onChangeStartDateRange={handleStarDateRange}
      endDateRange={endDateRange}
      onChangeEndDateRange={handleEndDateRange}
      customActivity={isCustomActivity}
      paceCenters={paceCenters}
      disableSearch={disableSearch}
      startPage={currentPage}
      totalOutcome={totalOutcome}
      onSearchCompleted={searchCompleted}
    />
  );
}
