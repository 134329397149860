import React from 'react';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const filterChipStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));
export default function FilterChip({ listValues, filterToRender, onClickDeleteElement }) {
  const styles = filterChipStyle();

  const handleDelete = (event, filter, element) => {
    event.stopPropagation();
    onClickDeleteElement(filter, element);
  };

  const chipList = listValues !== undefined && listValues.length > 0
    ? listValues
      .map((value) => (
        <Chip
          label={value.name !== undefined ? value.name : value.id}
          onDelete={(e) => handleDelete(e, filterToRender, value.id)}
          color="primary"
          variant="outlined"
          key={value.id}
        />
      )) : '';

  return (
    <Box className={styles.root}>
      { chipList }
    </Box>
  );
}
