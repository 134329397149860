import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/logs';

const initialState = {
  code: '',
  status: '',
  date: '',
  type: '',
  parent: null,
  patient: '',
  history: [],
  comments: [],
  user: '',
  location: '',
  loading: false,
  error: null,
  manuallyCreated: false,
  organization: null,
  organizationName: null,
  description: null,
  editableItem: false,
  patientId: null,
  signature: null,
};

const getLogsByOrder = (state, action) => {
  if (!action.payload.location) {
  // eslint-disable-next-line no-param-reassign
    action.payload.location = {
      name: action.payload.patient,
      status: 'undefined',
      description: 'undefined',
      address: null,
    };
  }

  return updateObject(state, {
    history: action.payload.history,
    comments: action.payload.comments,
    code: action.payload.code,
    status: action.payload.status,
    date: action.payload.date,
    type: action.payload.type,
    parent: action.payload.parent,
    patient: action.payload.patient,
    user: action.payload.user,
    location: action.payload.location,
    loading: false,
    error: null,
    manuallyCreated: action.payload.manuallyCreated,
    organization: action.payload.organization,
    organizationName: action.payload.organizationName,
    description: action.payload.description,
    editableItem: action.payload.manuallyCreated && action.payload.history.filter((e) => e.status === 'in-progress').length === 1,
    patientId: action.payload.patientId,
    signature: action.payload.signature,
  });
};

const requestFailed = (state, action) => updateObject(state, {
  ...initialState,
  error: action.payload.message,
});

const cleanLogs = (state) => updateObject(state, {
  ...initialState,
  loading: true,
});

const resetState = (state) => updateObject(state, initialState);

export default createReducer(initialState, {
  [actionTypes.GET_LOGS_PENDING]: cleanLogs,
  [actionTypes.GET_LOGS_FULFILLED]: getLogsByOrder,
  [actionTypes.GET_LOGS_REJECTED]: requestFailed,
  [actionTypes.LOGS_RESET_STATE]: resetState,
});
