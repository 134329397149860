const nav = {
  label: {
    MANUAL_ENTRY: 'MANUAL ENTRY',
    BARCODE_SCAN: 'BARCODE SCAN',
  },
  code: {
    MANUAL_ENTRY: 0,
    BARCODE_SCAN: 1,
  },
};

const status = {
  ENTER_ID: 'Shipment, order or package ID',
  WRONG_ID: 'The Shipment, order or package ID is invalid. Please try again',
  WRONG_BARCODE: 'The barcode ID is invalid. Please try again',
};

const scan = {
  code: {
    GOOD_BARCODE: true,
    BAD_BARCODE: false,
  },
  sound: {
    // eslint-disable-next-line prefer-template
    GOOD_BARCODE: process.env.PUBLIC_URL + '/sounds/checkoutScanner.wav',
    // eslint-disable-next-line prefer-template
    BAD_BARCODE: process.env.PUBLIC_URL + '/sounds/computerError.wav',
  },
};

export {
  nav, status, scan,
};
