import * as actionTypes from '../action_types/dashboad';
import {
  getUsers, getUserById, postDeactivateUserById,
  postActivateUserById, getSystemRoles, getPaceCenters, postNewUser,
  postNewBulkUsers, postEditionUser, generateUsersReport, getUsersByRole,
} from '../services/serviceClient';

const resetState = () => ({
  type: actionTypes.DASHBOARD_RESET_STATE,
});

const resetUserCreationState = () => ({
  type: actionTypes.RESET_USER_CREATION_STATE,
});

const resetPaceCentersSearchState = () => ({
  type: actionTypes.RESET_PACE_CENTERS_SEARCH_STATE,
});

const handleGoBack = (navigation) => ({
  type: actionTypes.DASHBOARD_HANDLE_GO_BACK,
  payload: {
    navigation,
  },
});

const getUsersList = (keyword, page, token) => ({
  type: actionTypes.GET_USERS,
  async payload() {
    const rawData = await getUsers(keyword, page, token);
    let result = { list: [], pageData: { total: 0 }, page };

    result = {
      list: [...rawData.users],
      pageData: { total: rawData.total, page, pageSize: 10 },
      page,
    };

    return result;
  },
});

const userById = (keyword, token) => ({
  type: actionTypes.GET_USER_BY_ID,
  async payload() {
    const rawData = await getUserById(keyword, token);
    let result = { user: [], role: null, paceCenters: [] };

    result = {
      user: rawData.user.data,
      rol: rawData.user.rol,
      paceCenters: rawData.user.paceCenters,
    };
    return result;
  },
});

const deactivateUserById = (userId, token) => ({
  type: actionTypes.POST_DEACTIVATION_USER_BY_ID,
  async payload() {
    const rawData = await postDeactivateUserById(userId, token);

    const result = {
      userId: rawData.id,
      blocked: rawData.blocked,
      user: rawData.user.data,
    };
    return result;
  },
});

const activateUserById = (userId, token) => ({
  type: actionTypes.POST_ACTIVATION_USER_BY_ID,
  async payload() {
    const rawData = await postActivateUserById(userId, token);

    const result = {
      userId: rawData.id,
      blocked: rawData.blocked,
      user: rawData.user.data,
    };
    return result;
  },
});

const getPaceCentersList = (keyword, page, token) => ({
  type: actionTypes.GET_PACE_CENTERS_LIST,
  async payload() {
    const rawData = await getPaceCenters(keyword, page, token);

    const result = {
      list: [...rawData.organizations],
      pageData: {
        total: rawData.pageData.total,
        pageSize: 10,
      },
      page,
    };

    return result;
  },
});

const getRoles = (token) => ({
  type: actionTypes.GET_ROLES,
  async payload() {
    const rawData = await getSystemRoles(token);

    const result = {
      roles: [...rawData.roles],
    };

    return result;
  },
});

const postUserCreation = (log, token) => ({
  type: actionTypes.POST_SINGLE_USER_CREATION,
  async payload() {
    const rawData = await postNewUser(log, token);
    return rawData;
  },
});

const postBulkUsersCreation = (keyword, log, token) => ({
  type: actionTypes.POST_BULK_USERS_CREATION,
  async payload() {
    const rawData = await postNewBulkUsers(keyword, log, token);
    return {
      jobId: rawData.jobId,
      status: rawData.status,
      description: rawData.description,
    };
  },
});

const addAlert = (alert) => ({
  type: actionTypes.ADD_ALERT,
  payload: {
    alert,
  },
});

const removeAlert = (alert) => ({
  type: actionTypes.REMOVE_ALERT,
  payload: {
    alert,
  },
});

const postResetState = () => ({
  type: actionTypes.POST_RESET_STATE,
});

const postUserEdition = (userId, log, token) => ({
  type: actionTypes.POST_SINGLE_USER_EDITION,
  async payload() {
    const rawData = await postEditionUser(userId, log, token);
    return rawData;
  },
});

const usersReportGeneration = (token) => ({
  type: actionTypes.USERS_REPORT_GENERATION,
  async payload() {
    const rawData = await generateUsersReport(token);
    return { jobId: rawData.jobId, description: rawData.description };
  },
});

const setRoleToFilter = (role) => ({
  type: actionTypes.SET_ROLE_TO_FILTER,
  payload: {
    role,
  },
});

const getUsersListByRole = (role, page, token) => ({
  type: actionTypes.GET_USERS_BY_ROLE,
  async payload() {
    const rawData = await getUsersByRole(role, page, token);
    let result = { list: [], pageData: { total: 0 }, page };

    result = {
      list: [...rawData.users],
      pageData: { total: rawData.total, page, pageSize: 10 },
      page,
    };

    return result;
  },
});

export {
  getUsersList, userById, resetState, handleGoBack,
  deactivateUserById, activateUserById, getPaceCentersList, getRoles,
  resetUserCreationState, resetPaceCentersSearchState, postUserCreation,
  addAlert, removeAlert, postResetState, postBulkUsersCreation,
  postUserEdition, usersReportGeneration, getUsersListByRole, setRoleToFilter,
};
