import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SuppliesList from '../components/SuppliesList';
import NewUserConfiguration from '../components/NewUserConfiguration';
import WithLoadingList from '../components/WithLoadingList';
import { setNavigationDrawer } from '../actions/navigation';
import getSuppliesList from '../actions/supplies';
import { useAuth0 } from '../auth0';

const SuppliesListWithLoading = WithLoadingList(SuppliesList);

export default function SuppliesListContainer() {
  const supplies = useSelector((state) => state.suppliesState.list);
  const page = useSelector((state) => state.suppliesState.page);
  const pageData = useSelector((state) => state.suppliesState.pageData);
  const loading = useSelector((state) => state.suppliesState.loading);
  const error = useSelector((state) => state.suppliesState.error);
  const errorGetPaceCenters = useSelector((state) => state.trackableState.error);
  const pendingDataToPaginate = useSelector((state) => state.suppliesState.pendingDataToPaginate);
  const filters = useSelector((state) => state.filtersState);
  const reset = useSelector((state) => state.filtersState.reset);
  const newUser = useSelector((state) => state.navigationState.newUserConfig);
  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const { getTokenSilently, user } = useAuth0();

  (async () => {
    setToken(await getTokenSilently());
  })();

  const handlePagination = (pag) => {
    dispatch(getSuppliesList(pag, token, filters.queryParams));
  };

  useEffect(() => {
    if (reset) {
      if (user && token) {
        handlePagination(page);
      }
    }
  }, [reset]);

  useEffect(() => {
    if (user && token) {
      dispatch(setNavigationDrawer('Packages'));
      dispatch(getSuppliesList(page, token, filters.queryParams));
    }
  }, [dispatch, user, token]);

  useEffect(() => {
    if (newUser) {
      setTimeout(() => {
        history.go(0);
      }, 5000);
    }
  }, [newUser]);

  return (
    <>
      {!newUser
      && (
      <SuppliesListWithLoading
        supplies={supplies}
        loading={loading}
        error={errorGetPaceCenters || error}
        page={page}
        pageData={pageData}
        pendingDataToPaginate={pendingDataToPaginate}
        handlePagination={handlePagination}
      />
      )}
      {newUser
      && <NewUserConfiguration />}
    </>
  );
}
