import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useAuth0 } from '../auth0';
import NavigationDrawer from '../components/Navigation/NavigationDrawer';
import NavigationNav from '../components/Navigation/NavigationNav';
import UserAgreement from '../components/UserAgreement';
import SessionTimeout from '../components/SessionTimeout';
import AddToHomeScreen from '../components/AddToHomeScreen';
import useShouldShowPrompt from '../hooks/useShouldShowPrompt';
import { setUserRoleOnNavigation, setNewUserConfiguration } from '../actions/navigation';
import { resetState as resetCustodyState } from '../actions/custody';
import { resetState as resetDeliverState } from '../actions/deliver';
import { resetState as resetSuppliesState } from '../actions/supplies';
import { resetState as resetJobStatusState } from '../actions/jobs';
import { resetUserCreationState as resetUserCreation, handleGoBack as handleBackOnUserCreation, resetState as resetUserState } from '../actions/dashboard';
import { getOrganizations, handleGoBack as handleBackOnTrakeable, resetState as resetTrackableSate } from '../actions/trackable';
import { resetState as resetReportState, handleGoBack as handleBackOnReport } from '../actions/reports';
import { handleGoBack as handleBackOnFilterMenu, resetState as resetFilterState, resetOnBackMenuState } from '../actions/filters';
import { AUTH0_METADATA, AUTH0_ROLES, AUTH0_LOGIN_COUNT } from '../config/constants';
import { agreementDate } from '../config/variables';
import { setUserAgreementAuth0 } from '../services/serviceClient';
import { mobileDetection } from '../services/utility';
import { nav as trackableNav } from '../components/TrackableItem/constants';
import { nav as filterNav } from '../components/FiltersMenu/constants';
import { nav as reportNav } from '../components/Reports/constants';
import { nav as userCreationNav } from '../components/UserCreation/constants';
import { nav as userEditionNav } from '../components/UserEdition/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
}));

export default function NavigationContainer({ children }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isCustodySuccess = useSelector((state) => state.custodyState.isSuccess);
  const isDeliverSuccess = useSelector((state) => state.deliverState.isSuccess);
  const isJobStatusSuccess = useSelector((state) => state.jobsState.isSuccess);
  const jobsList = useSelector((state) => state.jobsState.list);
  const [openModal, setOpenModal] = useState(false);
  const [token, setToken] = useState('');
  const classes = useStyles();
  const {
    getTokenSilently, isAuthenticated, logout, user, loading,
  } = useAuth0();

  let Menu = null;
  let buttonType = null;
  let AddToHome = null;
  let showDialog = false;
  const currentPage = useSelector((state) => state.navigationState.currentPageName);
  const showNavigationDrawer = useSelector((state) => state.navigationState.showNavigationDrawer);
  const showStepBackButton = useSelector((state) => state.navigationState.showStepBackButton);
  const showCloseButton = useSelector((state) => state.navigationState.showCloseButton);
  const { paceCenters } = useSelector((state) => state.trackableState);
  const trackableNavigation = useSelector((state) => state.trackableState.navigation);
  const filterMenuNavigation = useSelector((state) => state.filtersState.navigation);
  const reportNavigation = useSelector((state) => state.reportState.navigation);
  const userCreationNavigation = useSelector((state) => state.dashboardState.navigation);
  const userEditionNavigation = useSelector((state) => state.dashboardState.navigation);
  const errorGetPaceCenters = useSelector((state) => state.trackableState.error);
  const [installPromptEvent, setInstallPromptEvent] = useState();
  const [openDialog, setOpenDialog] = useState(true);
  const browserSettings = useState(mobileDetection(window.navigator));
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(
    `${browserSettings[0].browser}_${browserSettings[0].system}`,
  );
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    const beforeInstallPromptHandler = (event) => {
      event.preventDefault();
      setInstallPromptEvent(event);
    };
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  }, []);

  const onInstallPrompt = () => {
    setOpenDialog(false);
    installPromptEvent.prompt();

    installPromptEvent.userChoice.then((choice) => {
      if (choice.outcome !== 'accepted') {
        handleUserSeeingInstallPrompt();
      }
      setInstallPromptEvent(null);
    });
  };

  const onCancelPrompt = () => {
    setOpenDialog(false);
    handleUserSeeingInstallPrompt();
  };

  const onClickAwayPrompt = () => {
    setOpenDialog(false);
  };

  const getPaceCentersList = async () => {
    dispatch(getOrganizations(await getTokenSilently()));
  };

  useEffect(() => {
    if (isAuthenticated && user && user[AUTH0_METADATA] && (!user[AUTH0_METADATA].userAgreement
      || !user[AUTH0_METADATA].dateAcceptingAgreement
        || moment(agreementDate).isAfter(user[AUTH0_METADATA].dateAcceptingAgreement))) {
      (async () => {
        setToken(await getTokenSilently());
      })();
      setOpenModal(true);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (isAuthenticated && paceCenters !== undefined) {
      (async () => {
        if (user[AUTH0_LOGIN_COUNT] <= 1 && user[AUTH0_ROLES].length === 0) {
          dispatch(setNewUserConfiguration());
        } else {
          setUserRole(user[AUTH0_ROLES]);
          getPaceCentersList();
          dispatch(setUserRoleOnNavigation(user[AUTH0_ROLES]));
        }
      })();
    }
  }, [loading]);

  const resetOnBack = async () => {
    await dispatch(resetOnBackMenuState());
  };

  const handleGoBack = () => {
    if (isCustodySuccess) {
      dispatch(resetCustodyState());
    } else if (isDeliverSuccess) {
      dispatch(resetDeliverState());
    } else if (isJobStatusSuccess
        && jobsList.length > 0
        && location.pathname === '/settings/jobs') {
      dispatch(resetJobStatusState());
    }
    dispatch(resetSuppliesState());
    if ((currentPage === 'New Trackable Item' || currentPage === 'Edit Trackable Item') && trackableNavigation) {
      dispatch(handleBackOnTrakeable(trackableNav.code.TRACKABLE_ITEM_FORM));
    } else if ((currentPage === 'New Trackable Item' || currentPage === 'Edit Trackable Item') && !trackableNavigation) {
      dispatch(resetTrackableSate());
      history.goBack();
    } else if (currentPage === 'Sorting & Filters' && filterMenuNavigation === filterNav.code.SEARCH_FILTER) {
      dispatch(handleBackOnFilterMenu(filterNav.code.FILTER_MENU));
    } else if (currentPage === 'Sorting & Filters' && filterMenuNavigation === filterNav.code.FILTER_MENU) {
      resetOnBack();
      history.goBack();
    }
    if (currentPage === 'Reports' && (reportNavigation === reportNav.code.PREVIEW_TABLE || reportNavigation === reportNav.code.PACE_CENTERS_SEARCH)) {
      dispatch(handleBackOnReport(reportNav.code.MENU));
    } else if (currentPage === 'Reports' && reportNavigation === reportNav.code.MENU) {
      dispatch(resetReportState());
      history.goBack();
    } else if (currentPage === 'Package' && location.pathname.search('logs') > -1) {
      history.push('/');
    } else if (currentPage === 'Users Creation' && userCreationNavigation === userCreationNav.code.USER_CREATION_FORM) {
      dispatch(resetUserCreation());
      history.goBack();
    } else if (currentPage === 'Users Creation' && userCreationNavigation === userCreationNav.code.PACE_CENTERS_SEARCH) {
      dispatch(handleBackOnUserCreation(userCreationNav.code.USER_CREATION_FORM));
    } else if (currentPage === 'User Edition' && userEditionNavigation === userEditionNav.code.PACE_CENTERS_SEARCH) {
      dispatch(handleBackOnUserCreation(userEditionNav.code.USER_EDITION_FORM));
    } else if (currentPage === 'User Edition' && userEditionNavigation === userEditionNav.code.USER_EDITION_FORM) {
      history.goBack();
    } else if (currentPage === 'User Detail') {
      history.push('/settings/users');
    } else if (currentPage === 'Package') {
      history.goBack();
    } else if (currentPage === 'User Management') {
      dispatch(resetUserState());
      history.push('/');
    } else if (currentPage === 'Jobs Status') {
      dispatch(resetJobStatusState());
      history.push('/settings/users');
    } else if (currentPage === 'Job Detail') {
      history.goBack();
    } else if (currentPage === 'Users Report' && reportNavigation === reportNav.code.MENU) {
      dispatch(resetReportState());
      history.push('/settings/users');
    } else if (currentPage === 'Users Report' && reportNavigation === reportNav.code.PACE_CENTERS_SEARCH) {
      dispatch(handleBackOnReport(reportNav.code.MENU));
    }
    if (currentPage !== 'Reports' && currentPage !== 'New Trackable Item'
        && currentPage !== 'Sorting & Filters' && currentPage !== 'Edit Trackable Item'
        && currentPage !== 'Package' && currentPage !== 'Settings'
        && currentPage !== 'Users Creation' && currentPage !== 'User Detail'
        && currentPage !== 'User Edition' && currentPage !== 'Job Detail'
        && currentPage !== 'User Management' && currentPage !== 'Jobs Status'
        && currentPage !== 'Users Report') {
      history.goBack();
    }
  };

  const handleCloseModal = (event) => {
    event.stopPropagation();
    (async () => {
      const response = await setUserAgreementAuth0(user.sub, token);
      if (response && response.user_metadata && response.user_metadata.userAgreement) {
        setOpenModal(false);
      }
    })();
  };

  const handleClearFilters = () => {
    dispatch(resetFilterState());
  };

  const handleOnRedirectFilter = () => {
    history.push('/filter');
  };

  if (showNavigationDrawer) {
    Menu = NavigationDrawer;
  } else if (showStepBackButton) {
    Menu = NavigationNav;
    buttonType = 'stepbackButton';
  } else if (showCloseButton) {
    Menu = NavigationNav;
    buttonType = 'closeButton';
  }

  if (userShouldBePromptedToInstall && browserSettings[0].isMobile
      && browserSettings[0].browser === 'CHROME' && installPromptEvent !== undefined) {
    showDialog = true;
  } else if (userShouldBePromptedToInstall && browserSettings[0].isMobile
    && browserSettings[0].browser !== 'CHROME' && !browserSettings[0].isStandalone) {
    showDialog = true;
  }
  if (showDialog) {
    AddToHome = (
      <AddToHomeScreen
        openDialog={openDialog}
        onChangeAcceptedPrompt={onInstallPrompt}
        onChangeCancelPrompt={onCancelPrompt}
        onChangeClickAwayPrompt={onClickAwayPrompt}
        browserSettings={browserSettings}
      />
    );
  }

  const redirectLogoutURL = () => logout({ returnTo: window.location.origin });

  return (
    <div className={classes.root}>
      {(!loading && isAuthenticated && paceCenters.length > 0)
      && (
        <>
          <Menu
            user={user}
            isAuthenticated={isAuthenticated}
            logout={redirectLogoutURL}
            onGoBack={handleGoBack}
            currentPage={currentPage}
            buttonType={buttonType}
            paceCenters={!errorGetPaceCenters}
            onClearFilters={handleClearFilters}
            filterMenuNavigation={filterMenuNavigation}
            onRedirectFilter={handleOnRedirectFilter}
            userRole={userRole}
          />
          <UserAgreement openModal={openModal} onClose={handleCloseModal} />
          <SessionTimeout isAuthenticated={isAuthenticated} logout={redirectLogoutURL} />
          {AddToHome}
        </>
      )}
      {(!loading && isAuthenticated && errorGetPaceCenters !== null)
      && (
        <>
          <Menu
            user={user}
            isAuthenticated={isAuthenticated}
            logout={redirectLogoutURL}
            onGoBack={handleGoBack}
            currentPage={currentPage}
            buttonType={buttonType}
            paceCenters={!errorGetPaceCenters}
            onRedirectFilter={handleOnRedirectFilter}
            userRole={userRole}
          />
        </>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}
