
import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import UserInfo from './UserInfo';
import SwitchUserStatusDialog from './SwitchUserStatusDialog';

const userdetailStyle = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2.5),
  },
  attest: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  canvas: {
    margin: theme.spacing(0, 0, 7, 0),
  },
}));

export default function UserDetail({
  userData,
  userRol,
  userStatus,
  paceCentersList,
  error,
  loading,
  browserSettings,
  handleEventOptions,
  disableStatus,
  anchorEl,
  setAnchorEl,
  open,
  onCancelDialog,
  onChangeStatusDialog,
  isSuccess,
}) {
  const styles = userdetailStyle();
  return (
    <>
      <Container>
        <Box className={styles.container}>
          {
      !error && !loading && isSuccess && (
        <>
          <UserInfo
            userData={userData}
            userRol={userRol}
            userStatus={userStatus}
            paceCentersList={paceCentersList}
            error={error}
            loading={loading}
            browserSettings={browserSettings}
            handleEventOptions={handleEventOptions}
            disableStatus={disableStatus}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
          <SwitchUserStatusDialog
            open={open}
            onCancelDialog={onCancelDialog}
            onChangeStatusDialog={onChangeStatusDialog}
            userStatus={userData.status}
          />
        </>
      )
      }
        </Box>
      </Container>
    </>
  );
}
