import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/trackable';

const initialState = {
  isLoading: false,
  error: null,
  isSuccessPaceCenter: false,
  isSuccessTrackableItem: false,
  paceCenters: [],
  alerts: [],
  navigation: 0,
  searchOutcome: [],
  loading: false,
  pendingDataToPaginate: false,
  editionCompleted: false,
};

const sendOrg = (state) => updateObject(state, {
  ...initialState,
  isLoading: true,
});

const getOrgToTrackableItem = (state, action) => updateObject(state, {
  ...initialState,
  isSuccessPaceCenter: true,
  paceCenters: action.payload.organizations,
});

const sendItem = (state) => updateObject(state, {
  isloading: true,
});

const postTrackableItem = (state) => updateObject(state, {
  isSuccessTrackableItem: true,
  isloading: false,
});

const requestFailed = (state, action) => {
  let error;

  if (action.payload.response !== undefined) {
    error = action.payload.response.data.error.details;
  } else {
    error = action.payload.message;
  }
  return updateObject(state, {
    isLoading: false,
    error,
    searchOutcome: [],
  });
};

const resetState = (state) => updateObject(state, {
  ...initialState,
  isSuccessPaceCenter: true,
  paceCenters: state.paceCenters,
});

const postResetState = (state) => updateObject(state, {
  isLoading: false,
  error: null,
  isSuccessPaceCenter: true,
  isSuccessTrackableItem: false,
  editionCompleted: false,
});

const addAlert = (state, action) => updateObject(state, {
  alerts: [...state.alerts, action.payload.alert],
});

const removeAlert = (state, action) => updateObject(state, {
  alerts: state.alerts.filter((alert) => alert !== action.payload.alert),
});

const clearAlerts = (state) => updateObject(state, { alerts: initialState.alerts });

const handleGoBack = (state, action) => updateObject(state,
  { navigation: action.payload.navigation });

const pendingSearchOutcome = (state) => updateObject(state, {
  pendingDataToPaginate: false,
  loading: true,
  error: null,
});

const getFilterPatients = (state, action) => {
  const tmpState = updateObject(state, {
    searchOutcome: action.payload.page > 0
      ? state.searchOutcome.concat(action.payload.list) : action.payload.list,
    loading: false,
    error: null,
  });
  return updateObject(tmpState, {
    pendingDataToPaginate: action.payload.pageData.total > tmpState.searchOutcome.length,
  });
};

const sendEditItem = (state) => updateObject(state, {
  isloading: true,
});

const postEditTrackableItem = (state) => updateObject(state, {
  isSuccessTrackableItem: true,
  isloading: false,
  editionCompleted: true,
});

const requestPaceCentersList = (state) => updateObject(state, {
  pendingDataToPaginate: false,
  isLoading: true,
  error: null,
});

const getPaceCenterList = (state, action) => {
  const tmpState = updateObject(state, {
    searchOutcome: action.payload.page > 0
      ? state.searchOutcome.concat(action.payload.list) : action.payload.list,
    totalOutcome: action.payload.pageData.total,
    isLoading: false,
    error: null,
    isSuccess: true,
    page: action.payload.page,
  });

  return updateObject(tmpState, {
    pendingDataToPaginate: action.payload.pageData.total > tmpState.searchOutcome.length,
  });
};

export default createReducer(initialState, {
  [actionTypes.GET_TRACKABLE_PACE_CENTERS_PENDING]: sendOrg,
  [actionTypes.GET_TRACKABLE_PACE_CENTERS_FULFILLED]: getOrgToTrackableItem,
  [actionTypes.GET_TRACKABLE_PACE_CENTERS_REJECTED]: requestFailed,
  [actionTypes.POST_TRACKABLE_ITEM_PENDING]: sendItem,
  [actionTypes.POST_TRACKABLE_ITEM_FULFILLED]: postTrackableItem,
  [actionTypes.POST_TRACKABLE_ITEM_REJECTED]: requestFailed,
  [actionTypes.POST_TRACKABLE_ITEM_RESET_STATE]: postResetState,
  [actionTypes.TRACKABLE_ITEM_RESET_STATE]: resetState,
  [actionTypes.ADD_ALERT]: addAlert,
  [actionTypes.REMOVE_ALERT]: removeAlert,
  [actionTypes.CLEAR_ALERTS]: clearAlerts,
  [actionTypes.TRACKABLE_ITEM_HANDLE_GO_BACK]: handleGoBack,
  [actionTypes.GET_FILTER_PATIENTS_PENDING]: pendingSearchOutcome,
  [actionTypes.GET_FILTER_PATIENTS_FULFILLED]: getFilterPatients,
  [actionTypes.GET_FILTER_PATIENTS_REJECTED]: requestFailed,
  [actionTypes.POST_EDIT_TRACKABLE_ITEM_PENDING]: sendEditItem,
  [actionTypes.POST_EDIT_TRACKABLE_ITEM_FULFILLED]: postEditTrackableItem,
  [actionTypes.POST_EDIT_TRACKABLE_ITEM_REJECTED]: requestFailed,
  [actionTypes.GET_FILTER_PACECENTERS_PENDING]: requestPaceCentersList,
  [actionTypes.GET_FILTER_PACECENTERS_FULFILLED]: getPaceCenterList,
  [actionTypes.GET_FILTER_PACECENTERS_REJECTED]: requestFailed,
});
