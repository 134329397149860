import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import SessionTimeoutDialog from './SessionTimeoutDialog';
import { setNavigationExtendSession } from '../actions/navigation';
import { SESSION } from '../config/constants';

let countdownInterval;
let timeout;

export default function SessionTimeout({ isAuthenticated, logout }) {
  const sessionExtended = useSelector((state) => state.navigationState.sessionExtended);
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const [idleTimeout] = useState(SESSION.TIMEOUT * 60 * 1000);
  const idleTimer = useRef(null);
  const dispatch = useDispatch();

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };
  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
    dispatch(setNavigationExtendSession());
  };

  const onIdle = () => {
    const delay = 1000 * 1;

    if (sessionExtended) {
      logout();
    }

    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = SESSION.COUNTDOWN;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(countDown -= 1);
          } else {
            logout();
          }
        }, 1000);
      }, delay);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onIdle={onIdle}
        debounce={250}
        timeout={idleTimeout}
      />
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={logout}
        open={timeoutModalOpen}
      />
    </>
  );
}
