import { useState } from 'react';
import moment from 'moment';

const getInstallPromptLastSeenAt = (promptName) => localStorage.getItem(promptName);

const setInstallPromptSeenToday = (promptName) => {
  const today = moment().toISOString();
  localStorage.setItem(promptName, today);
};

const getUserShouldBePromptedToInstall = (promptName, daysToWaitBeforePromptingAgain) => {
  const lastPrompt = moment(getInstallPromptLastSeenAt(promptName));
  const daysSinceLastPrompt = moment().diff(lastPrompt, 'days');
  return Number.isNaN(daysSinceLastPrompt) || daysSinceLastPrompt > daysToWaitBeforePromptingAgain;
};

export default function useShouldShowPrompt(promptName, daysToWaitBeforePromptingAgain = 1) {
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(
    getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain),
  );

  const handleUserSeeingInstallPrompt = () => {
    setUserShouldBePromptedToInstall(false);
    setInstallPromptSeenToday(promptName);
  };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
}
