import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '../../assets/Radio';
import { ENTRY_TYPES } from '../../config/constants';

const styles = makeStyles(() => ({
  box: {
    cursor: 'default',
  },
}));

export default function FilterByEntry({ filterValue, onChangeFilterValue }) {
  const classes = styles();
  const handleStatusValue = (event) => {
    event.stopPropagation();
    onChangeFilterValue(event.target.value);
  };

  return (
    <Box className={classes.box} component={FormControl} display="flex" flexGrow={1}>
      <RadioGroup aria-label="status" name="status" value={filterValue} onChange={handleStatusValue}>
        <FormControlLabel className={classes.box} value={ENTRY_TYPES.ALL} control={<Radio />} label="All entries" />
        <FormControlLabel className={classes.box} value={ENTRY_TYPES.MANUAL} control={<Radio />} label="Manual" />
        <FormControlLabel className={classes.box} value={ENTRY_TYPES.SYSTEM} control={<Radio />} label="System" />
      </RadioGroup>
    </Box>
  );
}
