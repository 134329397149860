import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import { setNavigationStepbackButton } from '../actions/navigation';

const page404Style = makeStyles((theme) => ({
  number: {
    marginTop: theme.spacing(6),
  },
  label: {
    marginTop: theme.spacing(3),
  },
}));

export default function Page404() {
  const styles = page404Style();
  const theme = useTheme();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNavigationStepbackButton('Page Not Found'));
  }, [dispatch]);
  return (
    <Box display="flex" flexGrow={1} alignItems="center" flexDirection="column">
      <Typography variant="h1" className={styles.number}>
        <Box fontFamily="PT Mono" component="span" color={grey[700]}>
          {404}
        </Box>
      </Typography>
      <Typography variant="h6" className={styles.label}>
        <Box fontFamily="PT Mono" component="span" color={grey[500]}>
          We could not find this page.
        </Box>
      </Typography>
      <Box color={grey[600]}>
        <MoodBadIcon style={{ fontSize: `${theme.spacing(7)}px` }} />
      </Box>
    </Box>
  );
}
