import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '../assets/Checkbox';
import Button from '../assets/Button';
import UserAgreementContent from './UserAgreementContent';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '5%',
    left: '2%',
    right: '2%',
    bottom: '5%',
    overflowY: 'scroll',
    overflowX: 'scroll',
    [theme.breakpoints.up('sm')]: {
      left: '5%',
      right: '5%',
    },
    [theme.breakpoints.up('md')]: {
      left: '10%',
      right: '10%',
    },
  },
  button: {
    padding: theme.spacing(0.75, 2),
  },
  readAgreement: {
    margin: theme.spacing(3, 0, 4, 0),
    textAlign: 'justify',
  },
}));

export default function UserAgreement({ openModal = false, onClose }) {
  const styles = useStyles();
  const labelText = 'Check here to indicate that you have read and agree to the terms and conditions above';
  const [disableAgreeButton, setDisableAgreeButton] = useState(true);
  const [acceptAgreement, setAcceptAgreement] = useState(false);

  const handleAcceptAgreement = (event) => {
    event.stopPropagation();
    setAcceptAgreement(event.target.checked);
    setDisableAgreeButton(!event.target.checked);
  };
  return (
    <Box>
      <Modal
        open={openModal}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEscapeKeyDown
      >
        <Box className={styles.paper} display="flex" flexDirection="column">
          <Box id="simple-modal-description" flexGrow={1}>
            <UserAgreementContent />
          </Box>
          <Box className={styles.readAgreement} justifyContent="center">
            <FormControlLabel
              control={<Checkbox name="readAgreement" checked={acceptAgreement} onChange={handleAcceptAgreement} />}
              label={<Typography variant="subtitle2">{labelText}</Typography>}
            />
          </Box>
          <Box justifyContent="center">
            <Button
              disabled={disableAgreeButton}
              disableElevation
              onClick={onClose}
              variant="cofc"
              className={styles.button}
            >
              Agree and proceed
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
