import * as actionTypes from '../action_types/deliver';
import { postDeliverSupply } from '../services/serviceClient';

const postSupplyToBeDelivered = (id, log, token) => ({
  type: actionTypes.POST_DELIVER,
  async payload() {
    const rawData = await postDeliverSupply(id, log, token);
    return rawData;
  },
});

export const resetState = () => ({
  type: actionTypes.POST_DELIVER_RESET_STATE,
});

export default postSupplyToBeDelivered;
