import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const barcodeViewStyle = makeStyles((theme) => ({
  frame: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: theme.spacing(8),
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      margin: theme.spacing(4),
    },
  },
  frameBorderTopLeft: {
    borderTop: `${theme.spacing(0.6)}px solid black`,
    borderLeft: `${theme.spacing(0.6)}px solid black`,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  frameBorderTopRight: {
    borderTop: `${theme.spacing(0.6)}px solid black`,
    borderRight: `${theme.spacing(0.6)}px solid black`,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  frameBorderBottomLeft: {
    borderBottom: `${theme.spacing(0.6)}px solid black`,
    borderLeft: `${theme.spacing(0.6)}px solid black`,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  frameBorderBottomRight: {
    borderBottom: `${theme.spacing(0.6)}px solid black`,
    borderRight: `${theme.spacing(0.6)}px solid black`,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export default function BarcodeView({ loading }) {
  const styles = barcodeViewStyle();
  return (
    <>
      {!loading
        && (
        <Box className={styles.frame}>
          <Box display="flex" flexGrow={1} flexDirection="column" height="100%">
            <Box display="flex" flexGrow={1}>
              <Box flexGrow={1}>
                <Box className={styles.frameBorderTopLeft}>
                  <br />
                </Box>
              </Box>
              <Box>
                <Box className={styles.frameBorderTopRight}>
                  <br />
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexGrow={1} alignItems="flex-end">
              <Box flexGrow={1}>
                <Box className={styles.frameBorderBottomLeft}>
                  <br />
                </Box>
              </Box>
              <Box>
                <Box className={styles.frameBorderBottomRight}>
                  <br />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        )}
    </>
  );
}
