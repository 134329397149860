import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

export const ExpansionPanelSummary = withStyles({
  root: {
    padding: '0',
    '&$expanded': {
      minHeight: '0',
    },
  },
  content: {
    margin: '0',
    '&$expanded': {
      padding: '0',
      margin: '0',
    },
  },
  expanded: {
    margin: '0',
  },
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles({
  root: {
    padding: '0',
  },
})(MuiExpansionPanelDetails);

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
  },
})(MuiExpansionPanel);

export default ExpansionPanel;
