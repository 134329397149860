import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import TrackableItemForm from './TrackableItemForm';
import BarcodeEntry from './BarcodeEntry';
import SearchPatient from './SearchPatient';
import SearchPaceCenter from './SearchPaceCenter';
import AlertWrap from './AlertWrap';
import { ROLES_TYPES } from '../../config/constants';
import { nav, messages } from './constants';

const Styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.625, 0),
    margin: theme.spacing(2, 2.5),
    position: 'relative',
  },
  bottom: {
    padding: theme.spacing(5, 0),
  },
  patient: {
    maxWidth: '100%',
    minWidth: '100%',
  },
}));

export default function TrackableItem({
  value,
  error,
  navigationValue,
  isLoading,
  isSuccessPaceCenter,
  onPostTrackableItem,
  onChangeValue,
  paceCenters,
  enableButton,
  onClickScan,
  onBarcodeRead,
  onClickPatient,
  onClickCancelEdition,
  searchValue,
  onChangeSearchValue,
  searchOutcome,
  onClickSearch,
  filterValue,
  onChangeFilterValue,
  onPagination,
  pendingDataToPaginate,
  searchCompleted,
  isListEmpty,
  disableSearch,
  disableFooterButton,
  onClickNewPatient,
  onClickCancelPatient,
  permissionError,
  onEditionMode,
  editableItem,
  loadingLogs,
  userRole,
  onClickSearchButtonCenter,
  onClickFooter,
  disableSearchCenter,
}) {
  const styles = Styles();
  const alerts = useSelector((state) => state.trackableState.alerts);

  const errorMessage = userRole !== ROLES_TYPES.MANAGER
    ? messages.role.MANAGER : messages.role.OTHER;

  let newItemOrEdit = false;
  if (!onEditionMode[0]) {
    newItemOrEdit = true;
  } else if (!editableItem && onEditionMode[0]) {
    newItemOrEdit = false;
  } else if (editableItem && onEditionMode[0] && userRole === ROLES_TYPES.MANAGER) {
    newItemOrEdit = true;
  }

  return (
    <>
      {isSuccessPaceCenter && navigationValue === nav.code.TRACKABLE_ITEM_FORM && newItemOrEdit
        && (
        <Container disableGutters>
          <TrackableItemForm
            value={value}
            onChangeValue={onChangeValue}
            error={!!error}
            errorMessage={error}
            isLoading={isLoading}
            onPostTrackableItem={onPostTrackableItem}
            paceCenters={paceCenters}
            enableButton={enableButton}
            onClickScan={onClickScan}
            onClickPatient={onClickPatient}
            onEditionMode={onEditionMode[0]}
            onClickCancelEdition={onClickCancelEdition}
            onClickSearchButtonCenter={onClickSearchButtonCenter}
            disableSearchCenter={disableSearchCenter}
          />
          <Container>
            {
            alerts.map((alert) => <AlertWrap key={alert} element={alert} />)
          }
          </Container>
          <Box className={styles.bottom} />
        </Container>
        )}
      {isSuccessPaceCenter && navigationValue === nav.code.BARCODE_SCANNER && newItemOrEdit
      && (
      <Container disableGutters>
        <BarcodeEntry
          value={value.ItemId}
          onChangeValue={onBarcodeRead}
          isLoading={isLoading}
          permissionError={permissionError}
        />
      </Container>
      )}
      {((error && !isSuccessPaceCenter) || ((!editableItem && onEditionMode[0]
        && !loadingLogs) || (onEditionMode[0] && userRole !== ROLES_TYPES.MANAGER)))
          && (
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Box display="flex" justifyContent="center" width={1} className={styles.container}>
                <Alert severity="error">{onEditionMode[0] ? errorMessage : error}</Alert>
              </Box>
            </Box>
          )}
      {isSuccessPaceCenter && navigationValue === nav.code.PATIENT_SEARCH && newItemOrEdit
          && (
          <SearchPatient
            onChangeValue={onBarcodeRead}
            isLoading={isLoading}
            searchValue={searchValue}
            onChangeSearchValue={onChangeSearchValue}
            searchOutcome={searchOutcome}
            onClickSearch={onClickSearch}
            filterValue={filterValue}
            onChangeFilterValue={onChangeFilterValue}
            onPagination={onPagination}
            pendingDataToPaginate={pendingDataToPaginate}
            searchCompleted={searchCompleted}
            isListEmpty={isListEmpty}
            disableSearch={disableSearch}
            disableFooterButton={disableFooterButton}
            onClickNewPatient={onClickNewPatient}
            onClickCancelPatient={onClickCancelPatient}
          />
          )}
      {isSuccessPaceCenter && navigationValue === nav.code.PACE_CENTERS_SEARCH && newItemOrEdit
          && (
          <SearchPaceCenter
            onChangeValue={onChangeValue}
            isLoading={isLoading}
            searchValue={searchValue}
            onChangeSearchValue={onChangeSearchValue}
            searchOutcome={searchOutcome}
            onClickSearch={onClickSearch}
            filterValue={filterValue}
            onChangeFilterValue={onChangeFilterValue}
            onPagination={onPagination}
            pendingDataToPaginate={pendingDataToPaginate}
            searchCompleted={searchCompleted}
            disableSearch={false}
            disableFooterButton={disableFooterButton}
            onClickFooter={onClickFooter}
          />
          )}
    </>
  );
}
