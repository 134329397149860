import { auth0Namespace } from './variables';

export const PACKAGED = 'packaged';
export const IN_PROGRESS = 'in-progress';
export const DELIVERED = 'delivered';
export const ARCHIVED = 'archived';
export const SYSTEM_LOG_NAME = 'TRHC';

export const AUTH0_ROLES = `${auth0Namespace}/roles`;
export const AUTH0_LOGIN_COUNT = `${auth0Namespace}/loginCount`;
export const AUTH0_METADATA = `${auth0Namespace}/metadata`;
export const AUTH0_SCOPE = 'update:current_user_metadata';

export const ROLES_TYPES = {
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  COMMON: 'Common',
};

export const LOG_TYPES = {
  PACKAGE: 'package',
  ORDER: 'order',
  SHIPMENT: 'shipment',
};

export const DELIVER_TYPES = {
  PATIENT: 'patient',
  CAREGIVER: 'caregiver',
  RECIPIENT: 'recipient',
};

export const FILTER_TYPES = {
  SORT: 'Sort By',
  STATUS: 'status',
  CUSTODIAN: 'custodian',
  PATIENT: 'patient',
  ITEM: 'item ID',
  ACTIVITY: 'Creation Date',
  ENTRY: 'entry type',
  UPDATED_DATE: 'Latest Activity',
  CENTER: 'center',
};

export const JOB_TYPES = {
  USERS_IMPORT: 'usersImport',
  USERS_EXPORT: 'usersExport',
};

export const SORT = {
  CREATED_DATE_ASC: 'created_date_asc',
  CREATED_DATE_DESC: 'created_date_desc',
  UPDATED_DATE_ASC: 'updated_date_asc',
  UPDATED_DATE_DESC: 'updated_date_desc',
  LABEL: {
    CREATED_DATE_DESC: 'Most recently created',
    CREATED_DATE_ASC: 'Older created first',
    UPDATED_DATE_DESC: 'Latest activity',
    UPDATED_DATE_ASC: 'Older activity',
  },
  PARAMS: {
    TYPE: {
      CREATED_DATE: 'created_at',
      UPDATED_DATE: 'updated_at',
    },
    ORDER: {
      ASC: 'ASC',
      DESC: 'DESC',
    },
  },
};

export const STEP_TYPES = {
  COMMENT: 'COMMENT',
  LOG: 'LOG',
};

export const SESSION = {
  TIMEOUT: 15,
  COUNTDOWN: 120,
};

export const ACTIVITY_TYPES = {
  LAST7: 'last7',
  LAST30: 'last30',
  CUSTOM: 'custom',
};

export const PWA = {
  BROWSER: {
    CHROME: 'CHROME',
    SAFARI: 'SAFARI',
    OTHER: 'OTHER',
  },
  SYSTEM: {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
  },
};

export const TRACKABLE_ITEM = {
  COLOR: {
    MAIN: '#F77F00',
    HOVER: '#F9A54C',
    SECONDARY_HOVER: '#F0F0F0',
    SECONDARY: '#D5D5D5',
  },
};

export const UNDERLINE = {
  COLOR: '#00002A',
};

export const ENTRY_TYPES = {
  ALL: 'all',
  SYSTEM: 'system',
  MANUAL: 'manual',
};

export const UPDATED_TYPES = {
  LAST7: 'last7',
  LAST30: 'last30',
  CUSTOM: 'custom',
};

export const CAMERA = {
  ERROR: {
    MESSAGE: 'Camera permissions were denied, please allow them on the browser settings and refresh the page.',
  },
};

export const REPORT_TYPES = {
  DELIVERY_STATUS: {
    LABEL: 'Delivery Status',
    VALUE: 'delivery',
    EXPORT_NAME: 'delivery_status',
    COLUMNS: [
      { dataKey: 'organizationName', title: 'Center' },
      { dataKey: 'type', title: 'Item Type' },
      { dataKey: 'remoteId', title: 'Item Number' },
      { dataKey: 'created_at', title: 'Creation Date (UTC)' },
      { dataKey: 'patientName', title: 'Patient' },
      { dataKey: 'status', title: 'Status' },
      { dataKey: 'custodian', title: 'Custodian/Person who delivered' },
      { dataKey: 'delivered_at', title: 'Delivered Date/Time (UTC)' },
      { dataKey: 'signature', title: 'Signature' },
    ],
    COLUMNS_STYLES: {
      organizationName: { cellWidth: 40 },
      type: { cellWidth: 'auto' },
      remoteId: { cellWidth: 50 },
      created_at: { cellWidth: 'auto' },
      patientName: { cellWidth: 30 },
      status: { cellWidth: 'auto' },
      custodian: { cellWidth: 'auto' },
      delivered_at: { cellWidth: 'auto' },
      signature: { cellWidth: 'auto' },
    },
  },
  EVENTS_LOG: {
    LABEL: 'Events logs',
    VALUE: 'logs',
    EXPORT_NAME: 'events_log',
    COLUMNS: [
      { dataKey: 'organizationName', title: 'Center' },
      { dataKey: 'type', title: 'Item Type' },
      { dataKey: 'remoteId', title: 'Item Number' },
      { dataKey: 'user', title: 'User' },
      { dataKey: 'updated_at', title: 'Event Date/Time (UTC)' },
      { dataKey: 'status', title: 'Action' },
    ],
    COLUMNS_STYLES: {
      organizationName: { cellWidth: 40 },
      type: { cellWidth: 'auto' },
      remoteId: { cellWidth: 50 },
      user: { cellWidth: 40 },
      updated_at: { cellWidth: 'auto' },
      status: { cellWidth: 'auto' },
    },
  },
  FORMAT: {
    CSV: 'CSV',
    PDF: 'PDF',
  },
};

export const ITEM_STATUS = {
  PENDING: 'in-progress',
  DELIVERED: 'delivered',
  ARCHIVED: 'archived',
  PACKAGED: 'packaged',
};

export const CHARACTER_LIMIT = {
  ITEM: 240,
  DESCRIPTION: 128,
  PATIENT_NAME: 128,
  ADDRESS: 128,
  CITY: 30,
  STATE: 30,
  POSTAL_CODE: 15,
  FIRST_NAME: 64,
  LAST_NAME: 64,
  COMMENTS: 255,
  FULLNAME: 128,
  EMAIL: 128,
  PACECENTER: 128,
};
