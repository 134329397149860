import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const PatientLocationStyle = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(4.125),
    border: 'none',
    padding: 0,
  },
  typographyStyle: {
    width: '200px',
    [theme.breakpoints.up(280)]: {
      width: '150px',
    },
    [theme.breakpoints.up(330)]: {
      width: '250px',
    },
    [theme.breakpoints.up(350)]: {
      width: '270px',
    },
    [theme.breakpoints.up(500)]: {
      width: '350px',
    },
    [theme.breakpoints.up(600)]: {
      width: '400px',
    },
    [theme.breakpoints.up(750)]: {
      width: '500px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '800px',
    },
    lineHeight: 'normal',
  },
}));

export default function PatientLocation({
  location,
}) {
  const styles = PatientLocationStyle();
  return (
    <Box display="flex" className={styles.marginLeft}>
      <Typography variant="body2" className={styles.typographyStyle} noWrap>
        {location.name}
        {location.address
        && (
          <>
            <br />
            {location.address.line[0]}
            <br />
            {location.address.city}
            {', '}
            {location.address.state}
            {' '}
            {location.address.postalCode}
          </>
        )}
      </Typography>
    </Box>
  );
}
