import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Menu from './Menu';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';
import { nav } from './constants';
import SearchPaceCenter from './SearchPaceCenter';

const Styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.625, 0),
    margin: theme.spacing(2, 2.5),
    position: 'relative',
  },
  bottom: {
    padding: theme.spacing(5, 0),
  },
  patient: {
    maxWidth: '100%',
    minWidth: '100%',
  },
}));

export default function Report({
  value,
  onChangeValue,
  paceCenters,
  navigationValue,
  onClickGenerateReport,
  handleStartDateRange,
  handleEndDateRange,
  browserSettings,
  disableButton,
  loadingReport,
  onGenerateReport,
  onClickDownload,
  onClickPreview,
  page,
  list,
  pageData,
  handlePagination,
  onClickSearchButtonCenter,
  searchValue,
  onChangeSearchValue,
  onClickSearch,
  searchCompleted,
  isLoading,
  handleCentersPagination,
  searchOutcome,
  filterValue,
  onChangeFilterValue,
  onClickFooter,
  pendingDataToPaginate,
  disableFooterButton,
  loadingCSV,
  loadingPDF,
  disablePreviewButton,
  disableCSVButton,
  disablePDFButton,
}) {
  const classes = Styles();
  const widthSize = useMediaQuery('(min-width:540px)');
  const smallDevices = useMediaQuery('(max-width:320px)');
  return (
    <>
      {navigationValue === nav.code.MENU
      && (
        <Container disableGutters>
          <Box className={classes.container}>
            <Menu
              value={value}
              onChangeValue={onChangeValue}
              paceCenters={paceCenters}
              onClickGenerateReport={onClickGenerateReport}
              handleStartDateRange={handleStartDateRange}
              handleEndDateRange={handleEndDateRange}
              browserSettings={browserSettings}
              disableButton={disableButton}
              loadingReport={loadingReport}
              onGenerateReport={onGenerateReport}
              list={list}
              onClickDownload={onClickDownload}
              onClickPreview={onClickPreview}
              pageData={pageData}
              smallDevices={smallDevices}
              onClickSearchButtonCenter={onClickSearchButtonCenter}
              loadingCSV={loadingCSV}
              loadingPDF={loadingPDF}
              disablePreviewButton={disablePreviewButton}
              disableCSVButton={disableCSVButton}
              disablePDFButton={disablePDFButton}
            />
          </Box>
        </Container>
      )}
      {navigationValue === nav.code.PACE_CENTERS_SEARCH
      && (
      <SearchPaceCenter
        onChangeValue={onChangeValue}
        isLoading={isLoading}
        searchValue={searchValue}
        onChangeSearchValue={onChangeSearchValue}
        searchOutcome={searchOutcome}
        onClickSearch={onClickSearch}
        filterValue={filterValue}
        onChangeFilterValue={onChangeFilterValue}
        onPagination={handleCentersPagination}
        pendingDataToPaginate={pendingDataToPaginate}
        searchCompleted={searchCompleted}
        disableSearch={false}
        disableFooterButton={disableFooterButton}
        onClickFooter={onClickFooter}
      />
      )}
      {navigationValue === nav.code.PREVIEW_TABLE && (widthSize || !browserSettings[0].isMobile)
      && (
      <DesktopTable
        reportType={value.reportType}
        page={page}
        list={list}
        pageData={pageData}
        handlePagination={handlePagination}
      />
      )}
      {navigationValue === nav.code.PREVIEW_TABLE && (!widthSize && browserSettings[0].isMobile)
      && (
      <MobileTable
        reportType={value.reportType}
        page={page}
        list={list}
        pageData={pageData}
        handlePagination={handlePagination}
      />
      )}
    </>
  );
}
