import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/custody';

const initialState = {
  isLoading: false,
  error: null,
  isSuccess: false,
  alerts: [],
  permissionError: false,
};

const postLogToOrder = (state) => updateObject(state, {
  ...initialState,
  alerts: state.alerts,
  isSuccess: true,
});

const requestFailed = (state, action) => updateObject(state, {
  ...initialState,
  alerts: state.alerts,
  error: action.payload.response.data.error.details,
});

const postResetState = (state) => updateObject(state, { ...initialState, alerts: state.alerts });

const postResetOnError = (state, action) => updateObject((state, {
  ...initialState,
  alerts: state.alerts,
  error: action.payload.error,
}));

const sendLog = (state) => updateObject(state, {
  ...initialState,
  alerts: state.alerts,
  isLoading: true,
});

const addAlert = (state, action) => updateObject(state, {
  alerts: [...state.alerts, action.payload.alert],
});

const removeAlert = (state, action) => updateObject(state, {
  alerts: state.alerts.filter((alert) => alert !== action.payload.alert),
});

const clearAlerts = (state) => updateObject(state, { alerts: initialState.alerts });

const cameraPermissionError = (state) => updateObject(state,
  {
    permissionError: true,
  });

export default createReducer(initialState, {
  [actionTypes.POST_LOGS_PENDING]: sendLog,
  [actionTypes.POST_LOGS_FULFILLED]: postLogToOrder,
  [actionTypes.POST_LOGS_REJECTED]: requestFailed,
  [actionTypes.POST_LOGS_RESET_STATE]: postResetState,
  [actionTypes.ADD_ALERT]: addAlert,
  [actionTypes.REMOVE_ALERT]: removeAlert,
  [actionTypes.CLEAR_ALERTS]: clearAlerts,
  [actionTypes.POST_LOGS_RESET_ON_ERROR]: postResetOnError,
  [actionTypes.CAMERA_PERMISSION_ERROR]: cameraPermissionError,
});
