import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { styled } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ControlPointRoundedIcon from '@material-ui/icons/ControlPointRounded';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextField from '../../assets/TextField';
import DispatchButton from '../../assets/DispatchButton';
import { helperText, characterLimit } from './constants';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    width: '100%',
    color: 'primary',
    alignContent: 'center',
    backgroundColor: theme.palette.secondary.light,
    disabled: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  container: {
    marginTop: '40px',
  },
  cardBox: {
    alignContent: 'center',
    textAlign: 'center',
  },
  uploadButton: {
    alignContent: 'center',
    textAlign: 'center',
  },
  descriptionBox: {
    alignContent: 'center',
    textAlign: 'center',
    marginRight: theme.spacing(1),
    marginleft: theme.spacing(1),
  },
  carActions: {
    padingTop: 30,
    paddingRight: 30,
  },
  caption: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.black,
    opacity: '0.6',
  },
  typographyButton: {
    marginRight: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  textInput: {
    width: '98%',
    marginRight: theme.spacing(3),
    spacing: 2,
  },
  inputRootLabel: {
    '&$disabled': {
      color: '#616161',
    },
  },
  inputRootText: {
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  disabled: {},
  buttonControl: {
    width: '100%',
  },
}));

const Input = styled('input')({
  display: 'none',
});

const BulkUsersCreation = ({
  onChangeFile, onPostNewBulkUsersCreation, enableButton,
  loading, loadingCreation, fileDescription, onChangeFileDescription,
}) => {
  const styles = useStyles();

  const handleOnChangeInput = (event) => {
    onChangeFile(event.target.files[0]);
  };
  const handleOnChangeDescription = (event) => {
    event.stopPropagation();
    onChangeFileDescription(event.target.value);
  };

  return (
    <Box>
      <Box className={styles.cardBox}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
              BULK UPLOAD USERS
            </Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemAvatar>
                  <ControlPointRoundedIcon />
                </ListItemAvatar>
                <ListItemText primary="Bulk creates users in the system by uploading a spreadsheet with their information" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ControlPointRoundedIcon />
                </ListItemAvatar>
                <ListItemText primary="The spreadsheet should contain the name, email, role, and center IDs of each user, using the following format per line: Name; Email; Role; Center IDs" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ControlPointRoundedIcon />
                </ListItemAvatar>
                <ListItemText primary="CSV should not contain a header in the first row, and columns order must follow the order detailed `John Smith; john.smith@email.com; Common; [23,44,45]`" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ControlPointRoundedIcon />
                </ListItemAvatar>
                <ListItemText primary="Role can be one of: [Admin, Manager, Common]" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ControlPointRoundedIcon />
                </ListItemAvatar>
                <ListItemText primary="Center IDs should be written inside square brackets, and separated between commas" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ControlPointRoundedIcon />
                </ListItemAvatar>
                <ListItemText primary="The file format should be a CSV file" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ControlPointRoundedIcon />
                </ListItemAvatar>
                <ListItemText primary="The maximum Users per File is 1000 users" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <ControlPointRoundedIcon />
                </ListItemAvatar>
                <ListItemText primary="Center IDs should be written inside square brackets, and separated between commas" />
              </ListItem>
            </List>
          </CardContent>
          <CardActions disableSpacing className={styles.CardActions}>
            <Box flexGrow={1} className={styles.descriptionBox}>
              <TextField
                label="Description"
                variant="filled"
                value={fileDescription}
                name="itemId"
                onChange={handleOnChangeDescription}
                autoComplete="false"
                helperText={helperText.DESCRIPTION}
                fullWidth
                inputProps={{ maxLength: characterLimit.DESCRIPTION }}
              />
              <Box className={styles.uploadButton}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="temp-id">
                  <Input accept=".csv" id="temp-id" type="file" onChange={(e) => handleOnChangeInput(e)} />
                  <IconButton color="secondary" aria-label="upload picture" component="span">
                    <Typography className={styles.typographyButton}>
                      UPLOAD CSV
                    </Typography>
                    <CloudUploadIcon />
                  </IconButton>
                </label>
              </Box>
            </Box>
          </CardActions>
        </Card>
      </Box>
      <Box>
        <DispatchButton
          className={styles.button}
          disabled={enableButton || loading}
          disableElevation
          onClick={onPostNewBulkUsersCreation}
          variant="cofc"
          loading={loadingCreation}
          label="CREATE USERS"
        />
      </Box>
    </Box>
  );
};

export default BulkUsersCreation;
