import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../assets/TextField';
import { CHARACTER_LIMIT } from '../../config/constants';

const deliverFooterStyle = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(4.125),
    border: 'none',
    padding: 0,
  },
}));

export default function CaregiverForm({
  disableForm,
  caregiverFirstname,
  caregiverLastname,
  onChangeCaregiverFirstname,
  onChangeCaregiverLastname,
}) {
  const styles = deliverFooterStyle();

  const handleChangeCaregiverFirstname = (event) => {
    event.stopPropagation();
    onChangeCaregiverFirstname(event.target.value);
  };
  const handleChangeCaregiverLastname = (event) => {
    event.stopPropagation();
    onChangeCaregiverLastname(event.target.value);
  };
  return (
    <Box className={styles.marginLeft} display="flex" flexGrow={1} component="fieldset" flexDirection="column">
      <TextField
        label="First name"
        variant="filled"
        value={caregiverFirstname}
        disabled={disableForm}
        onChange={handleChangeCaregiverFirstname}
        fullWidth
        inputProps={{ maxLength: CHARACTER_LIMIT.FIRST_NAME }}
      />
      <br />
      <TextField
        label="Last name"
        variant="filled"
        value={caregiverLastname}
        disabled={disableForm}
        onChange={handleChangeCaregiverLastname}
        fullWidth
        inputProps={{ maxLength: CHARACTER_LIMIT.LAST_NAME }}
      />
    </Box>
  );
}
