import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PaceCentersChips from './PaceCentersChips';
import { helperText } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    alignContent: 'center',
    alignItems: 'center',
  },
}));

const PaceCentersSection = ({ paceCentersList, onClickDeleteElement }) => {
  const styles = useStyles();

  return (
    <>
      {paceCentersList.length > 0
    && (
    <PaceCentersChips
      paceCentersList={paceCentersList}
      onClickDeleteElement={onClickDeleteElement}
      editMode
    />
    )}
      {paceCentersList.length === 0
    && (
      <Box className={styles.container}>
        <Typography align="center">
          {helperText.PACE_CENTERS}
        </Typography>
      </Box>
    )}
    </>
  );
};

export default PaceCentersSection;
