import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const filterChipStyle = makeStyles((theme) => ({
  button: {
    boxShadow: 'none',
    textTransform: 'none',
    padding: theme.spacing(0.75, 0.5),
    margin: theme.spacing(0.4),
    minWidth: theme.spacing(6.2),
  },
  bubble: {
    borderRadius: theme.spacing(12.5),
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(0.6),
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.spacing(1.3),
    lineHeight: `${theme.spacing(1.625)}px`,
  },
  biggerBubble: {
    width: theme.spacing(2.4),
  },
}));
export default function FilterChip({
  onChangeFilter, chipKey, text, color = 'default',
}) {
  const styles = filterChipStyle();
  const textColor = color === 'primary' ? 'common.white' : '';

  const handleOnClick = (element) => {
    onChangeFilter(element);
  };

  return (
    <Button variant="contained" color={color} className={styles.button} onClick={() => handleOnClick(chipKey)}>
      <Box color={textColor} component="span">
        {text}
      </Box>
    </Button>
  );
}
