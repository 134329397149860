import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import UserGenerationForm from './UserGenerationForm';
import SearchPaceCenter from './SearchPaceCenter';
import AlertWrap from './AlertWrap';
import { nav } from './constants';

const dashboardStyle = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2.5),
  },
  attest: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  canvas: {
    margin: theme.spacing(0, 0, 7, 0),
  },
}));

export default function UserReport({
  value,
  onChangeValue,
  error,
  loading,
  navigation,
  enableButton,
  onClickSearchButton,
  searchCompleted,
  alerts,
  loadingGeneration,
  onClickGenerateReport,
  onClickSearchButtonCenter,
  isLoading,
  searchValue,
  onChangeSearchValue,
  searchOutcome,
  onClickSearch,
  filterValue,
  jobId,
  onChangeFilterValue,
  onPagination,
  pendingDataToPaginate,
  disableFooterButton,
  onClickFooter,
  handleCentersPagination,
  loadingReport,
  disablePaceCenterSearch,
  disableGenerateReport,
}) {
  const styles = dashboardStyle();

  return (
    <>
      <Box>
        {!error && navigation === nav.code.MENU
          && (
          <Container disableGutters>
            <Box className={styles.container}>
              <UserGenerationForm
                value={value}
                onChangeValue={onChangeValue}
                error={error}
                enableButton={enableButton}
                onClickSearchButton={onClickSearchButton}
                onPagination={onPagination}
                searchCompleted={searchCompleted}
                onClickGenerateReport={onClickGenerateReport}
                onClickSearchButtonCenter={onClickSearchButtonCenter}
                loading={loading}
                loadingReport={loadingReport}
                disablePaceCenterSearch={disablePaceCenterSearch}
                disableGenerateReport={disableGenerateReport}
              />
            </Box>
          </Container>
          )}
        {navigation === nav.code.PACE_CENTERS_SEARCH
      && (
      <SearchPaceCenter
        isLoading={isLoading}
        searchValue={searchValue}
        onChangeSearchValue={onChangeSearchValue}
        searchOutcome={searchOutcome}
        onClickSearch={onClickSearch}
        filterValue={filterValue}
        onChangeFilterValue={onChangeFilterValue}
        onPagination={handleCentersPagination}
        pendingDataToPaginate={pendingDataToPaginate}
        searchCompleted={searchCompleted}
        disableSearch={false}
        disableFooterButton={disableFooterButton}
        onClickFooter={onClickFooter}
      />
      )}
        <>
          <Container>
            {!loadingGeneration && navigation === nav.code.MENU
            && alerts.map((alert) => (
              <AlertWrap
                key={alert}
                element={alert}
                jobId={jobId}
              />
            ))}
          </Container>
        </>
      </Box>
    </>
  );
}
