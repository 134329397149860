import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';


const addLogButtonStyle = makeStyles((theme) => ({
  button: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: theme.spacing(0),
    color: theme.palette.primary.main,
  },
  icon: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const AddLogButton = (props) => {
  const classes = addLogButtonStyle();

  const buttonProps = {
    ...props,
    disableRipple: true,
    className: classes.button,
  };

  return (
    <IconButton {...buttonProps}>
      <SvgIcon className={classes.icon} viewBox="0 0 56 56">
        <circle cx="28" cy="28" r="28" />
        <path d="M35.2847 28.7153L35.2847 27.0836L28.8158 27.0836V20.6148H27.1842V27.0836L20.7153 27.0836L20.7153 28.7153H27.1842L27.1842 35.1842H28.8158L28.8158 28.7153H35.2847Z" fill="white" />
      </SvgIcon>
    </IconButton>
  );
};

export { AddLogButton as default };
