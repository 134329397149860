import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  Container,
} from '@material-ui/core';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { useSelector } from 'react-redux';
import ManualEntry from './ManualEntry';
import BarcodeEntry from './BarcodeEntry';
import BarcodeIcon from './BarcodeIcon';
import AlertWrap from './AlertWrap';
import { nav } from './constants';

const bottomNavigationActionStyle = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  selected: {
    color: `${theme.palette.primary.main} !important`,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  label: {
    fontSize: '14px !important',
    borderBottom: 'none',
  },
}));

const bottomNavigationStyle = makeStyles(() => ({
  root: {
    height: '70px',
  },
}));

const TakeCustody = ({
  value,
  navigationValue,
  isLoading,
  error,
  isScanning,
  isSuccess,
  onChangeNavigation,
  onPostLog,
  onChangeValue,
  onIsScanning,
  onPostLogAutomatically,
  permissionError,
  onPlaySound,
  playDummySounds,
}) => {
  const classesBottomNavigationAction = bottomNavigationActionStyle();
  const classesBottomNavigation = bottomNavigationStyle();
  const classes = { container: classesBottomNavigation, action: classesBottomNavigationAction };
  const alerts = useSelector((state) => state.custodyState.alerts);
  const inputRef = useRef(null);

  return (
    <>
      <div ref={inputRef} onClick={() => playDummySounds()} onKeyDown={() => playDummySounds()} aria-hidden="true">
        <BottomNavigation
          value={navigationValue}
          onChange={(_event, newValue) => onChangeNavigation(newValue)}
          classes={{ root: classes.container.root }}
          showLabels
        >
          <BottomNavigationAction
            label={nav.label.MANUAL_ENTRY}
            icon={(
              <KeyboardIcon />
          )}
            classes={{
              root: classes.action.root,
              selected: classes.action.selected,
              label: classes.action.label,
            }}
          />
          <BottomNavigationAction
            label={nav.label.BARCODE_SCAN}
            icon={(
              <BarcodeIcon />
          )}
            classes={{
              root: classes.action.root,
              selected: classes.action.selected,
              label: classes.action.label,
            }}
          />
        </BottomNavigation>
      </div>

      {
        navigationValue === nav.code.BARCODE_SCAN
          ? (
            <>
              <BarcodeEntry
                value={value}
                error={!!error}
                errorMessage={error}
                isLoading={isLoading}
                isSuccess={isSuccess}
                isScanning={isScanning}
                onChangeValue={onChangeValue}
                onPostLog={onPostLog}
                onPostLogAutomatically={onPostLogAutomatically}
                onIsScanning={onIsScanning}
                permissionError={permissionError}
                onPlaySound={onPlaySound}
              />
              <Container>
                {
                  alerts.map((alert) => <AlertWrap key={alert} element={alert} />)
                }
              </Container>
            </>
          )
          : (
            <>
              <ManualEntry
                value={value}
                onChangeValue={onChangeValue}
                error={!!error}
                errorMessage={error}
                isLoading={isLoading}
                onPostLog={onPostLog}
              />
              <Container>
                {
                  alerts.map((alert) => <AlertWrap key={alert} element={alert} />)
                }
              </Container>
            </>
          )
      }

    </>
  );
};

TakeCustody.propTypes = {
  value: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  navigationValue: PropTypes.number.isRequired,
  onChangeNavigation: PropTypes.func.isRequired,
  onPostLog: PropTypes.func.isRequired,
};

export { TakeCustody as default };
