import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DispatchButton from '../../assets/DispatchButton';
import { TRACKABLE_ITEM } from '../../config/constants';

const deliverFooterStyle = makeStyles((theme) => ({
  container: {
    bottom: 0,
    padding: theme.spacing(1.2, 1.5, 2.5, 1.5),
    position: 'fixed',
  },
  cancelButton: {
    padding: theme.spacing(0.75, 2),
    textTransform: 'uppercase',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  button: {
    width: 'inherit',
    padding: theme.spacing(0.75, 2),
  },
  trackableItem: {
    color: 'primary',
    width: 'inherit',
    backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
    '&:hover': {
      backgroundColor: TRACKABLE_ITEM.COLOR.HOVER,
      '@media (hover: none)': {
        backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
      },
    },
  },
  cancelTrackableItem: {
    padding: theme.spacing(0.75, 2),
    textTransform: 'uppercase',
    cursor: 'pointer',
    textDecoration: 'none',
    color: TRACKABLE_ITEM.COLOR.MAIN,
  },
}));

export default function DeliverFooter({
  disableDeliverButton, onDeliverSupply, onCancelDeliver, manuallyCreated, isLoading,
}) {
  const styles = deliverFooterStyle();

  const handleCancelDeliver = (event) => {
    event.stopPropagation();
    onCancelDeliver();
  };
  return (
    <Box display="flex" className={styles.container} width={1} bgcolor="common.white">
      <Box
        borderRadius={20}
        color="primary.main"
        onClick={handleCancelDeliver}
        className={manuallyCreated ? styles.cancelTrackableItem : styles.cancelButton}
      >
        <Typography variant="button"> CANCEL </Typography>
      </Box>
      <Box display="flex" flexGrow={1} justifyContent="flex-end">
        <DispatchButton
          disabled={disableDeliverButton}
          disableElevation
          onClick={onDeliverSupply}
          variant="cofc"
          className={manuallyCreated ? styles.trackableItem : styles.button}
          loading={isLoading}
          label="DELIVER"
        />
      </Box>
    </Box>
  );
}
