import React from 'react';
import {
  Box, Typography, CircularProgress, Card, CardMedia,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const newUserConfigStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.625, 0),
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  logo: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignContent: 'center',
    alignItems: 'center',
  },
  typography: {
    paddingTop: theme.spacing(2),
    alignContent: 'center',
    alignItems: 'center',
  },
  circular: {
    paddingTop: theme.spacing(2),
    alignContent: 'center',
    alignItems: 'center',
  },
  root: {
    borderRadius: 0,
    boxShadow: 'none',
  },
  media: {
    backgroundSize: 'contain',
  },
}));

const NewUserConfiguration = () => {
  const styles = newUserConfigStyle();
  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" justifyContent="center" width={1} className={styles.container}>
        <Box className={styles.typography} justifyContent="center">
          <Typography justifyContent="center">
            <Box className={styles.logo}>
              <Card sx={{ maxWidth: 100, maxHeight: 100 }} className={styles.root}>
                <CardMedia
                  className={styles.media}
                  component="img"
                  image="newUserLogo.png"
                  height="270"
                  width="270"
                />
              </Card>
            </Box>
            Configurating new user. Please stand by....
            <br />
            <Box className={styles.circular} display="flex" justifyContent="center">
              <CircularProgress color="primary" size={60} />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewUserConfiguration;
