import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Entry from '../components/JobDetail';
import {
  resetJobDetailState, jobById, getReport,
} from '../actions/jobs';
import { setNavigationStepbackButton } from '../actions/navigation';
import WithLoading from '../components/WithLoadingList';
import { mobileDetection } from '../services/utility';
import { useAuth0 } from '../auth0';

const EntryWithLoading = WithLoading(Entry);

const JobDetail = () => {
  const { jobId } = useParams();
  const {
    error, isLoading, jobData, errorDetails, isSuccess, isSuccessReport, loadingReport,
  } = useSelector((state) => state.jobsState);
  const browserSettings = useState(mobileDetection(window.navigator));
  const { getTokenSilently, user } = useAuth0();
  const [token, setToken] = useState('');
  const [loadReport, setLoadReport] = useState(isSuccessReport);

  const dispatch = useDispatch();

  (async () => {
    setToken(await getTokenSilently());
  })();

  useEffect(() => {
    const currentPage = 'Job Detail';
    dispatch(setNavigationStepbackButton(currentPage));
    dispatch(resetJobDetailState());
  }, []);

  const handleUsersReport = () => {
    const reportType = `${jobData.description.replaceAll('/', '').replace('- ', '').replaceAll(' ', '_')}`;
    dispatch(getReport(token, jobId, reportType));
    setLoadReport(!loadingReport);
  };

  useEffect(() => {
    if (user && token) {
      dispatch(jobById(jobId, token));
    }
  }, [dispatch, user, token]);

  useEffect(() => {
    if (isSuccessReport) {
      setTimeout(() => {
        setLoadReport(loadingReport);
      }, 500);
    }
  }, [loadReport, isSuccessReport, error]);

  return (
    <>
      <EntryWithLoading
        jobData={jobData}
        errorDetails={errorDetails}
        error={error}
        loading={isLoading}
        browserSettings={browserSettings}
        isSuccess={isSuccess}
        onClickUsersReport={handleUsersReport}
        loadingReport={loadReport}
        isSuccessReport={isSuccessReport}
      />
    </>
  );
};

export { JobDetail as default };
