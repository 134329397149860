import { Subject } from 'rxjs';

const subject = new Subject();

const barcodeListener = {
  sendBarcode: (barcode) => subject.next({ barcode, error: null, loading: false }),
  hasError: (error) => subject.next({ error, loading: false, barcode: '' }),
  isLoading: (loading) => subject.next({ loading, error: null, barcode: '' }),
  clearBarcode: () => subject.next(),
  getBarcode: () => subject.asObservable(),
};

export default barcodeListener;
