import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../assets/TextField';
import Button from '../../assets/Button';
import { status } from './constants';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  container: {
    marginTop: '40px',
  },
  caption: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.black,
    opacity: '0.6',
  },
}));

const ManualEntry = ({
  value, onChangeValue, isLoading, error, onPostLog, errorMessage,
}) => {
  const classes = useStyles();
  const handleChangeValue = (event) => {
    event.stopPropagation();
    onChangeValue(event.target.value);
  };

  return (
    <Container className={classes.container}>
      <TextField
        label="ID"
        variant="filled"
        value={value}
        onChange={handleChangeValue}
        autoComplete="false"
        helperText={error ? errorMessage : status.ENTER_ID}
        error={error}
        fullWidth
      />
      <Button
        className={classes.button}
        disabled={!value || isLoading}
        disableElevation
        onClick={onPostLog()}
        variant="cofc"
      >
        Take Custody
      </Button>
    </Container>
  );
};

ManualEntry.propTypes = {
  value: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPostLog: PropTypes.func.isRequired,
};

export { ManualEntry as default };
