const nav = {
  code: {
    TRACKABLE_ITEM_FORM: 0,
    BARCODE_SCANNER: 1,
    PATIENT_SEARCH: 2,
    PACE_CENTERS_SEARCH: 3,
  },
};

const status = {
  ITEM_ID: 'Enter barcode ID or an Unique Identifier',
  DESCRIPTION: 'Please enter a brief description of the item. It is recommended that you do not enter medication name for security purposes.',
  PACECENTER: 'Enter Center ID or name',
  PATIENT: 'Enter Patient\'s first or last name (Optional)',
  SEARCH_PATIENT: 'Please enter the patient\'s first or last name',
  ADDRESS: 'Enter a valid Adress (Optional)',
  CITY: 'Enter the City (Optional)',
  STATE: 'Enter the State (Optional)',
  POSTAL_CODE: 'Enter the Postal Code (Optional)',
  BARCODE_ID: 'barcode ID',
};

const messages = {
  role: {
    MANAGER: 'You don\'t have access to edit this item.',
    OTHER: 'This item is not editable.',
  },
};

export { status, nav, messages };
