import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Button from './Button';

const DispatchButton = (props) => {
  const {
    color, onClick, loading, variant, disabled, className, disableElevation, label,
  } = props;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      className={className}
      disableElevation={disableElevation}
      color={color}
    >
      {loading && <CircularProgress color="inherit" size={16} />}
      {!loading && label}
    </Button>
  );
};

export { DispatchButton as default };
