import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Slide,
  Button,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 5,
    maxWidth: '380px',
  },
  button: {
    color: theme.palette.common.white,
    padding: theme.spacing(0.75, 2),
  },
  Typography: {
    align: 'center',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function SessionTimeoutDialog({
  open, countdown, onLogout, onContinue,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialog }}
      TransitionComponent={Transition}
    >
      <DialogTitle align="center">
        <Box fontWeight="fontWeightBold" justifyContent="center">
          Door2Door Session Expiration Notification
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <br />
        <Box fontWeight="fontWeightRegular" fontSize={15} textAlign="center" display="flex" justifyContent="center">
          <Typography variant="body2">
            Your Door2Door Session is about to expire.
            Please extend your current session, or logout.
          </Typography>
        </Box>
        <br />
        <Box fontWeight="fontWeightRegular" display="flex" justifyContent="center" fontSize={16} textAlign="center">
          <Typography variant="body2">
            <b>Time Remaning: </b>
            {' '}
            <span>{countdown}</span>
            {' '}
            seconds
          </Typography>
        </Box>
        <br />
        <Box display="flex" justifyContent="center">
          <DialogActions>
            <Button
              onClick={onContinue}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              EXTEND SESSION
            </Button>
            <Button
              onClick={onLogout}
              variant="contained"
            >
              LOGOUT
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
