/**
 * Method that returns a new object with the properties updated
 * @param {Object} oldObject, Contains all the  properties of the object
 * @param {Object} updatedProperties, Contains the properties to be replaced wit new values
 * @returns {Object}, New Object updated
 */
export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function mobileDetection(navigator) {
  let browser;
  let system;
  let isMobile;

  const sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf('Chrome') > -1) {
    browser = 'CHROME';
  } else if (sUsrAg.indexOf('Safari') > -1 && !sUsrAg.match(/\b(\w*CriOS\w*)\b/g) && !sUsrAg.match(/\b(\w*FxiOS\w*)\b/g)) {
    browser = 'SAFARI';
  } else {
    browser = 'OTHER';
  }

  if (/android/i.test(navigator.userAgent)) {
    system = 'ANDROID';
  } else if (/iPad|iPhone|iPod.+AppleWebKit/i.test(navigator.userAgent) && !window.MSStream) {
    system = 'IOS';
  } else {
    system = 'OTHER';
  }

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  } else {
    isMobile = false;
  }

  const isStandalone = !!navigator.standalone;

  return {
    browser,
    system,
    isMobile,
    isStandalone,
  };
}

export function removeItemFromList(arr, element) {
  const index = arr.findIndex((obj) => element === obj.id);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function formatDate(date = null) {
  const d = date !== null ? date : new Date();
  const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  return `${mo}${da}${ye}`;
}
