const placeholder = {
  SEARCH: 'by name or email',
};

const jobsListColumns = [
  { id: 'jobId', label: 'Job ID' },
  { id: 'description', label: 'Description' },
  { id: 'status', label: 'Status' },
  { id: 'type', label: 'Job Type' },
  { id: 'createdDate', label: 'Created Date' },
];

export { jobsListColumns, jobsListColumns as jobsListColumnsMobile, placeholder };
