export const GET_USERS = 'GET_USERS';
export const GET_USERS_PENDING = 'GET_USERS_PENDING';
export const GET_USERS_FULFILLED = 'GET_USERS_FULFILLED';
export const GET_USERS_REJECTED = 'GET_USERS_REJECTED';
export const DASHBOARD_RESET_STATE = 'DASHBOARD_RESET_STATE';
export const DASHBOARD_HANDLE_GO_BACK = 'DASHBOARD_HANDLE_GO_BACK';
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_PENDING = 'GET_ROLES_PENDING';
export const GET_ROLES_FULFILLED = 'GET_ROLES_FULFILLED';
export const GET_ROLES_REJECTED = 'GET_ROLES_REJECTED';
export const GET_USERS_BY_ROLE = 'GET_USERS_BY_ROLE';
export const GET_USERS_BY_ROLE_PENDING = 'GET_USERS_BY_ROLE_PENDING';
export const GET_USERS_BY_ROLE_FULFILLED = 'GET_USERS_BY_ROLE_FULFILLED';
export const GET_USERS_BY_ROLE_REJECTED = 'GET_USERS_BY_ROLE_REJECTED';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_PENDING = 'GET_USER_BY_ID_PENDING';
export const GET_USER_BY_ID_FULFILLED = 'GET_USER_BY_ID_FULFILLED';
export const GET_USER_BY_ID_REJECTED = 'GET_USER_BY_ID_REJECTED';
export const POST_DEACTIVATION_USER_BY_ID = 'POST_DEACTIVATION_USER_BY_ID';
export const POST_DEACTIVATION_USER_BY_ID_PENDING = 'POST_DEACTIVATION_USER_BY_ID_PENDING';
export const POST_DEACTIVATION_USER_BY_ID_FULFILLED = 'POST_DEACTIVATION_USER_BY_ID_FULFILLED';
export const POST_DEACTIVATION_USER_BY_ID_REJECTED = 'POST_DEACTIVATION_USER_BY_ID_REJECTED';
export const POST_ACTIVATION_USER_BY_ID = 'POST_ACTIVATION_USER_BY_ID';
export const POST_ACTIVATION_USER_BY_ID_PENDING = 'POST_ACTIVATION_USER_BY_ID_PENDING';
export const POST_ACTIVATION_USER_BY_ID_FULFILLED = 'POST_ACTIVATION_USER_BY_ID_FULFILLED';
export const POST_ACTIVATION_USER_BY_ID_REJECTED = 'POST_ACTIVATION_USER_BY_ID_REJECTED';
export const GET_PACE_CENTERS_LIST = 'GET_PACE_CENTERS_LIST';
export const GET_PACE_CENTERS_LIST_PENDING = 'GET_PACE_CENTERS_LIST_PENDING';
export const GET_PACE_CENTERS_LIST_FULFILLED = 'GET_PACE_CENTERS_LIST_FULFILLED';
export const GET_PACE_CENTERS_LIST_REJECTED = 'GET_PACE_CENTERS_LIST_REJECTED';
export const GET_SYSTEM_ROLES = 'GET_SYSTEM_ROLES';
export const GET_SYSTEM_ROLES_PENDING = 'GET_SYSTEM_ROLES_PENDING';
export const GET_SYSTEM_ROLES_FULFILLED = 'GET_SYSTEM_ROLES_FULFILLED';
export const GET_SYSTEM_ROLES_REJECTED = 'GET_SYSTEM_ROLES_REJECTED';
export const RESET_USER_CREATION_STATE = 'RESET_USER_CREATION_STATE';
export const RESET_PACE_CENTERS_SEARCH_STATE = 'RESET_PACE_CENTERS_SEARCH_STATE';
export const POST_SINGLE_USER_CREATION = 'POST_SINGLE_USER_CREATION';
export const POST_SINGLE_USER_CREATION_PENDING = 'POST_SINGLE_USER_CREATION_PENDING';
export const POST_SINGLE_USER_CREATION_FULFILLED = 'POST_SINGLE_USER_CREATION_FULFILLED';
export const POST_SINGLE_USER_CREATION_REJECTED = 'POST_SINGLE_USER_CREATION_REJECTED';
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const POST_RESET_STATE = 'POST_RESET_STATE';
export const POST_BULK_USERS_CREATION = 'POST_BULK_USERS_CREATION';
export const POST_BULK_USERS_CREATION_PENDING = 'POST_BULK_USERS_CREATION_PENDING';
export const POST_BULK_USERS_CREATION_FULFILLED = 'POST_BULK_USERS_CREATION_FULFILLED';
export const POST_BULK_USERS_CREATION_REJECTED = 'POST_BULK_USERS_CREATION_REJECTED';
export const POST_SINGLE_USER_EDITION = 'POST_SINGLE_USER_EDITION';
export const POST_SINGLE_USER_EDITION_PENDING = 'POST_SINGLE_USER_EDITION_PENDING';
export const POST_SINGLE_USER_EDITION_FULFILLED = 'POST_SINGLE_USER_EDITION_FULFILLED';
export const POST_SINGLE_USER_EDITION_REJECTED = 'POST_SINGLE_USER_EDITION_REJECTED';
export const USERS_REPORT_GENERATION = 'USERS_REPORT_GENERATION';
export const USERS_REPORT_GENERATION_PENDING = 'USERS_REPORT_GENERATION_PENDING';
export const USERS_REPORT_GENERATION_FULFILLED = 'USERS_REPORT_GENERATION_FULFILLED';
export const USERS_REPORT_GENERATION_REJECTED = 'USERS_REPORT_GENERATION_REJECTED';
export const SET_ROLE_TO_FILTER = 'SET_ROLE_TO_FILTER';
