import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '../../assets/Radio';
import { IN_PROGRESS, DELIVERED, ARCHIVED } from '../../config/constants';

const styles = makeStyles(() => ({
  box: {
    cursor: 'default',
  },
}));

export default function FilterByStatus({ filterValue, onChangeFilterValue }) {
  const classes = styles();
  const handleStatusValue = (event) => {
    event.stopPropagation();
    onChangeFilterValue(event.target.value);
  };

  return (
    <Box className={classes.box} component={FormControl} display="flex">
      <RadioGroup aria-label="status" name="status" value={filterValue} onChange={handleStatusValue}>
        <FormControlLabel className={classes.box} value="all" control={<Radio />} label="All status" />
        <FormControlLabel className={classes.box} value={DELIVERED} control={<Radio />} label="Delivered" />
        <FormControlLabel className={classes.box} value={IN_PROGRESS} control={<Radio />} label="Pending" />
        <FormControlLabel className={classes.box} value={ARCHIVED} control={<Radio />} label="Archived" />
      </RadioGroup>
    </Box>
  );
}
