import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  justify: {
    textAlign: 'justify',
  },
}));
export default function UserAgreementContent() {
  const styles = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={styles.justify}>
      <Typography variant="body1" component="div">
        <Box fontWeight="fontWeightBold" textAlign="center">
          Summary
          <br />
        </Box>
      </Typography>
      <Typography variant="body2" component="div">
        <Box fontWeight="fontWeightBold" textAlign="center">
          Your use of this software is for your personal, non-commercial use only. The software
          contains Tabula Rasa HealthCare proprietary information, and your use of this software
          does not grant you any right or license to further use of that proprietary information.
          Your use of this software is at your own risk. Tabula Rasa retains the sole right to
          terminate your access if, in its opinion, your use of the software violates these Terms of
          Use.
          <br />
          <br />
        </Box>
      </Typography>
      <Typography variant="body1" component="div">
        <Box fontWeight="fontWeightBold" textAlign="center">
          END USER TERMS OF USE
          <br />
          <br />
          THIS DOCUMENT GOVERNS YOUR ACCESS TO AND USE OF THIS SOFTWARE: READ IT IN ITS ENTIRETY.
          <br />
          <br />
        </Box>
      </Typography>
      <Typography variant="body2" component="div">
        <Box fontWeight="fontWeightBold" textAlign="center">
          Your use of this software is for your personal, non-commercial use only. The software
          contains Tabula Rasa HealthCare proprietary information, and your use of this software
          does not grant you any right or license to further use of that proprietary information.
          Your use of this software is at your own risk. Tabula Rasa retains the sole right to
          terminate your access if, in its opinion, your use of the software violates these Terms of
          Use.
        </Box>
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">1. GENERAL. </Box>
        These End User Terms of Use (the “Terms of Use”) is made by and between TABULA RASA OpCo,
        Inc., a Tabula Rasa HealthCare Company, a Delaware corporation (hereinafter “Tabula Rasa”),
        with offices at 228 Strawbridge Drive, Moorestown, New Jersey 08057, and you the user (you,
        your or User).
        <br />
        <br />
        This Agreement contains the terms and conditions that govern the use of Tabula Rasa’s
        website(s), including but not limited to the software known as EireneRx (a.k.a. ERx) and the
        CareKinesis - Door2Door application, located at www.eirenerx.com (“Website” or “Websites” or
        “Site”). BY ACCESSING, VISITING, BROWSING, USING OR ATTEMPTING TO INTERACT WITH OR USE ANY
        PART OF THIS WEBSITE, OR OTHER Tabula Rasa SOFTWARE, SERVICES, WEBSITES OR CONTENT
        (COLLECTIVELY “SERVICES”), YOU AGREE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY
        THIS AGREEMENT.
        <br />
        <br />
        IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT, DO NOT ACCESS OR USE ANY PART OF THIS
        WEBSITE. Tabula Rasa RESERVES THE RIGHT, WITH OR WITHOUT NOTICE, TO MAKE CHANGES TO THIS
        AGREEMENT AT TABULA RASA’S DISCRETION. THE CONTINUED USE OF ANY PART OF THIS WEBSITE
        CONSTITUTES YOUR ACCEPTANCE OF SUCH CHANGES.
        <br />
        <br />
        THE MOST CURRENT VERSION OF THIS AGREEMENT, WHICH SUPERCEDES ALL PREVIOUS VERSIONS, CAN BE
        REVIEWED BY GOING TO WWW.EIRENERX.COM.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">2. LIMITED LICENSE TO USE THE SERVICE. </Box>
        Subject to your compliance with the terms and conditions of these Terms of Use, Tabula Rasa
        grants you a limited, personal, non-exclusive, non-transferable, license to use and access
        the Service solely for your own personal purposes. Except for the licenses and rights
        expressly granted under these Terms of Service, Tabula Rasa does not grant you any other
        license or right, and Tabula Rasa reserves all such other licenses and rights.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">3. EXTERNAL SERVICES. </Box>
        As part of the Service, we may enable access to third-party services, websites or
        applications (the “External Services”). We are not responsible for examining or evaluating
        the content or accuracy of any External Services. You agree to use External Services at your
        own risk. External Services may be subject to additional policies, terms and conditions. We
        reserve the right to change, suspend, remove, disable or otherwise impose access
        restrictions on any External Services at any time without notice or liability to you.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">4. OTHER LICENSE RESTRICTIONS. </Box>
        You shall not (i) copy or modify the Services for any purpose; (ii) reverse engineer,
        decompile, modify, translate, disassemble or discover the source code for all or any portion
        of the Services; or (iii) distribute, disclose, market, rent, lease or otherwise transfer
        the Service to any other person or entity.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">5. INTELLECTUAL PROPERTY RIGHTS. </Box>
        Tabula Rasa retains all intellectual property rights in and to the Services and all related
        documentation (including all copyrights, patents, service marks, trademarks and other
        intellectual property rights), including but not limited to any and all updates,
        enhancements, customizations, revisions, modifications, future releases and any other
        changes, and all related information, material and documentation. Except for rights
        specifically provided to you in these Terms of Use, you assign to Tabula Rasa all other
        intellectual property rights you may now or in the future possess in the Service and related
        documentation, including all derivative works and improvements, and you agree to execute all
        documents, and take all actions, that may be necessary to confirm such rights. You also
        agree that Tabula Rasa retains all proprietary marks, legends and patent and copyright
        notices that appear on the Services and any related documentation delivered to you by Tabula
        Rasa and all whole or partial copies thereof.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">6. ACCESS TO THIS SITE. </Box>
        To access this site, site resources, links or other content, you may be asked to provide
        certain registration details or other information to create a user account (“Account”). It
        is a condition of your use of this site that all the information you provide will be
        correct, current, and complete. If Tabula Rasa believes the information you provide is not
        correct, current, or complete, Tabula Rasa has the right to deny access to this site, or to
        any of its resources, and to terminate or suspend your access at any time.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">7. ACCEPTABLE USE. </Box>
        You agree not to: (a) use the Service for service bureau or time-sharing purposes or in any
        other way allow third parties to exploit the Service; (b) provide your passwords or other
        log-in information to any third party; (c) share non-public features or content of the
        Service with any third party; or (d) access the Service in order to build a competitive
        product or service, to build a product using similar ideas, features, functions or graphics
        of the Service, or to copy any ideas, features, functions or graphics of the Service. In the
        event that we suspect any violation of these requirements, we may suspend your access to the
        Service without advanced notice, in addition to any other remedies we may have. We are not
        required to take any action for suspected violations, but may take any action that we see
        fit.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">8. UNAUTHORIZED ACCESS. </Box>
        You agree to take reasonable steps to prevent unauthorized access to your Account and the
        Services, including protecting your passwords and log-in information. If you suspect or know
        of any unauthorized access or use of your Account or the Services, you agree to notify use
        immediately. You are responsible and liable for any use of the Services through your Account
        whether authorized or unauthorized.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">9. FEEDBACK. </Box>
        You have no obligation to provide us with ideas, suggestions, or proposals (“Feedback”).
        However, if you submit Feedback to us, then you grant us a non-exclusive, worldwide,
        royalty-free license that is sub-licensable and transferrable, to make, use, sell, have
        made, offer to sell, import, reproduce, publicly display, distribute, modify, and publicly
        perform the Feedback.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">10. APPLICABLE USE OF SITE. </Box>
        You may use this site for purposes expressly permitted by this Site. As a condition of your
        use of Tabula Rasa’ Websites, you warrant to Tabula Rasa that you will not use the use the
        Websites for any purpose that is unlawful or prohibited by these terms, conditions, and
        notices. Use of certain features of this Site may require you to enter into certain other
        agreements with Tabula Rasa, including, but not limited to, a contract governing the use of
        the Services.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">11. NO CO-BRANDING OR FRAMING. </Box>
        You may not use or authorize any party to co-brand, or frame any Tabula Rasa websites
        without the express prior written permission of an authorized representative of Tabula Rasa.
        For purposes of these Terms of Use, “co-branding” means to display a name, logo, trademark,
        or other means of attribution or identification of any party in such a manner as is
        reasonably likely to give a user the impression that such other party has the right to
        display, publish, or distribute this site or content accessible within this site. For
        purposes of these Terms of Use, “framing” refers to displaying any Tabula Rasa webpage
        within a bordered area of another website, regardless of whether the address of the
        originating Tabula Rasa website is visible. Furthermore, you agree to cease any unauthorized
        co-branding or framing immediately, upon notice from Tabula Rasa.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">12. RIGHT TO TERMINATE ACCESS. </Box>
        Tabula Rasa reserves the right to monitor use of this site to determine compliance with
        these Terms of Use, as well as the right to edit, refuse to post or remove any information
        or materials, in whole or in part, at its sole discretion.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">13. DISCLOSURE UNDER LAW. </Box>
        Tabula Rasa reserves the right at all times to disclose any information as necessary to
        satisfy any applicable law, regulation, legal process or governmental request.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">14. PERSONALLY IDENTIFIABLE INFORMATION. </Box>
        In an effort to preserve your privacy, Tabula Rasa agrees that it will treat any personally
        identifying information that you submit through this site in accordance with the terms
        outlined in its Privacy Policy at www.tabularasahealthcare.com.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">15. DISCLAIMER & LIMITATIONS ON LIABILITY. </Box>
        You understand that Tabula Rasa cannot and does not guarantee or warrant that files
        available for downloading from the Tabula Rasa websites will be free of viruses, worms,
        Trojan horses or other code that may cause damage or harm to your computer(s) or network(s).
        You acknowledge that you will be solely responsible for implementing sufficient procedures
        and checkpoints to protect your computer(s) and network(s), and that you will maintain
        adequate means of backup of your personal data, external to this Website. Tabula Rasa
        further disclaims any responsibility to ensure that the Content located on its Websites is
        necessarily complete and up-to-date.
        <br />
        <br />
        While Tabula Rasa makes reasonable efforts to present accurate and reliable information on
        the Site, Tabula Rasa does not endorse, approve, or certify such information, nor does it
        guarantee the accuracy, completeness, efficacy, timeliness, or correct sequencing of such
        information. Any reliance upon any advice, opinion, statement or other information displayed
        or distributed through the Site is at your sole risk.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" textAlign="left">
          YOUR USE OF THIS SITE IS AT YOUR OWN RISK. THE CONTENT IS PROVIDED AS IS AND WITHOUT
          WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. Tabula Rasa DISCLAIMS ALL WARRANTIES,
          INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE, OR NON-INFRINGEMENT. Tabula Rasa DOES NOT WARRANT THAT THE FUNCTIONS OR CONTENT
          CONTAINED ON ANY Tabula Rasa WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS
          WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS. Tabula Rasa DOES NOT WARRANT OR MAKE ANY
          REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF ANY CONTENT IN TERMS OF ACCURACY,
          RELIABILITY, OR OTHERWISE. THE USER ACKNOWLEDGES THAT THE CONTENT MAY INCLUDE TECHNICAL
          INACCURACIES OR TYPOGRAPHICAL ERRORS, AND Tabula Rasa MAY MAKE CHANGES OR IMPROVEMENTS AT
          ANY TIME. YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN
          THE EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE OF THIS SITE OR ITS CONTENT. Tabula
          Rasa MAKES NO WARRANTIES THAT YOUR USE OF THE CONTENT WILL NOT INFRINGE THE RIGHTS OF
          OTHERS AND ASSUMES NO LIABILITY ORRESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH CONTENT.
          <br />
          <br />
          Tabula Rasa, ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT
          PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL NOT BE LIABLE FOR ANY
          INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR
          OTHER DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL
          DISTRESS, OR SIMILAR DAMAGES, EVEN IF Tabula Rasa HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES.
          <br />
          <br />
          IN NO EVENT WILL THE COLLECTIVE LIABILITY OF Tabula Rasa AND ITS SUBSIDIARIES, AFFILIATES,
          LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND
          DIRECTORS, REGARDLESS OF THE FORM OF ACTION (WHETHER IN CONTRACT, TORT, OR OTHERWISE),
          EXCEED THE GREATER OF $100 OR THE AMOUNT YOU HAVE PAID TO Tabula Rasa FOR THE APPLICABLE
          CONTENT OR SERVICE OUT OF WHICH LIABILITY AROSE.
        </Box>
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">16. INDEMNITY. </Box>
        You agree to indemnify and hold Tabula Rasa, its subsidiaries, affiliates, licensors,
        content providers, service providers, employees, agents, officers, directors, and
        contractors (the Indemnified Parties) harmless from any breach of these Terms of Use by you,
        including any use of Content other than as expressly authorized in these Terms of Use. You
        agree that the Indemnified Parties will have no liability in connection with any such breach
        or unauthorized use, and you agree to indemnify any and all resulting loss, damages,
        judgments, awards, costs, expenses, and attorneys’ fees of the Indemnified Parties in
        connection therewith. You will also indemnify and hold the Indemnified Parties harmless from
        and against any claims brought by third parties arising out of your use of the information
        accessed from this site.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">17. TRADEMARKS AND COPYRIGHTS. </Box>
        Trademarks, service marks, logos, and copyrighted works (hereinafter, “Intellectual
        Property”) appearing in this site are the property of Tabula Rasa or the party that provided
        the Intellectual Property to Tabula Rasa. Tabula Rasa and any party that provides
        Intellectual Property to Tabula Rasa retain all rights with respect to any of their
        respective Intellectual Property appearing in this site. All contents of Tabula Rasa’s
        websites are: Copyright © 2020 Tabula Rasa Healthcare, Inc. All rights reserved.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">18. COPYRIGHT INFRINGEMENT. </Box>
        If you believe that your work has been copied in a way that constitutes copyright
        infringement, please provide Tabula Rasa’s copyright agent the written information specified
        below. Please note that this procedure is exclusively for notifying Tabula Rasa and its
        affiliates that your copyrighted material has been infringed. Please include the following:
        <br />
        <br />
        An electronic or physical signature of the person authorized to act on behalf of the owner
        of the copyright interest:
        <br />
        <ul>
          <li>A description of the copyrighted work that you claim has been infringed upon;</li>
          <li>
            A description of where the material that you claim is infringing is located on the site,
            including the current website address;
          </li>
          <li>Your address, telephone number, and e-mail address;</li>
          <li>
            A statement by you that you have a good-faith belief that the disputed use is
            unauthorized by the copyright owner, its agent, or the law;
          </li>
          <li>
            A statement by you, made under penalty of perjury, that the above information in your
            notice is accurate and that you are the copyright owner or authorized to act on the
            copyright owner’s behalf.
          </li>
        </ul>
        <br />
        Tabula Rasa’ Copyright Agent for notice of claims of copyright infringement on its site can
        be reached as follows:
        <br />
        <br />
        Copyright Agent: General Counsel
        <br />
        Address: 228 Strawbridge Drive, Moorestown, NJ 08057
        <br />
        Phone: 1-856-840-4837
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">19. SECURITY. </Box>
        Any passwords used for this site are for individual use only. You will be responsible for
        the security of your password(s). From time to time, Tabula Rasa may require that you change
        your password. You are prohibited from using any services or facilities provided in
        connection with this site to compromise security or tamper with system resources and/or
        accounts. The use or distribution of tools designed for compromising security (e.g.,
        password crackers, rootkits, Trojan horses, or network probing tools) is strictly
        prohibited. If you become involved in any violation of system security, Tabula Rasa reserves
        the right to release your account details to system administrators at other websites and/or
        the authorities in order to assist them in resolving security incidents. Tabula Rasa
        reserves the right to investigate suspected violations of these Terms of Use. Tabula Rasa
        reserves the right to fully cooperate with any law enforcement authorities or court order
        requesting or direction Tabula Rasa to disclose the identity of anyone posting any e-mail
        messages, or publishing or otherwise making available any materials that are believed to
        violate these Terms of Use.
        <br />
        <br />
        <Box fontWeight="fontWeightBold" textAlign="left">
          BY ACCEPTING THIS AGREEMENT, YOU WAIVE ALL RIGHTS AND AGREE TO HOLD Tabula Rasa HARMLESS
          FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY Tabula Rasa DURING OR AS A RESULT OF
          ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY
          EITHER Tabula Rasa OR LAW ENFORCEMENT AUTHORITIES.
        </Box>
        <br />
        <br />
        <Box fontWeight="fontWeightBold" component="span">20. MISCELLANEOUS. </Box>
        These Terms of Use will be governed and interpreted pursuant to the laws of New Jersey,
        notwithstanding any principles of conflicts of law. You specifically consent to personal
        jurisdiction in New Jersey in connection with any dispute between you and Tabula Rasa
        arising out of these Terms of Use or pertaining to the subject matter hereof. The parties to
        these Terms of Use each agree that the exclusive venue for any dispute between the parties
        arising out of these Terms of Use will be in the state and federal courts in Burlington
        County, New Jersey.
        <br />
        <br />
        If any part of these Terms of Use is unlawful, void or unenforceable, that part will be
        deemed severable and will not affect the validity and enforceability of any remaining
        provisions. You agree that no joint venture, partnership, employment, or agency relationship
        exists between you and Tabula Rasa as a result of this agreement or use of Tabula Rasa’
        Websites. Any additional terms and conditions on this Site will govern the items to which
        they pertain. Tabula Rasa may revise these Terms of Use at any time by updating this
        posting.
        <Box fontWeight="fontWeightBold" fontStyle="italic" component="span">
          {' '}
          Terms of Use
          {' '}
        </Box>
        at any time by updating this posting.
        <br />
        <br />
        Last updated: May 15, 2020
      </Typography>
    </Box>
  );
}
