import React from 'react';
import { Typography, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PaginationTable from './PaginationTable';
import { userListColumnsMobile, userListByRoleColumns } from './constants';

const Styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.625, 0),
    margin: theme.spacing(2, 2.5),
    position: 'relative',
    width: 'inherit',
  },
  bottom: {
    padding: theme.spacing(5, 0),
  },
  root: {
    minWidth: 275,
    paddingBottom: '5px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 15,
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  pos: {
    marginBottom: 12,
  },
  line: {
    borderBottom: `${theme.spacing(0.105)}px solid grey`,
  },
}));

const MobileTable = ({
  list, pageData, handlePagination, handleOnClickTable, isFilterByRole, usersRequested,
}) => {
  const classes = Styles();
  const columns = !isFilterByRole && usersRequested ? userListColumnsMobile : userListByRoleColumns;
  const columnsIds = columns.map((e) => e.id);

  const handlerOnClick = (event, userId) => {
    event.stopPropagation();
    handleOnClickTable(userId);
  };

  const cardList = () => (
    list.map((row) => (
      <Card key={row.userId} className={classes.root} variant="outlined">
        <CardContent>
          {columnsIds.map((e) => (
            <Box
              key={Math.random()}
              onClick={(event) => handlerOnClick(event, row.userId)}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>
                    {columns.find((o) => o.id === e).label }
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.title} color="textSecondary">
                    {row[e]}
                  </Typography>
                </Grid>
              </Grid>
              <Box key={Math.random()} className={classes.line} display="flex" flexGrow={1} />
            </Box>
          ))}
        </CardContent>
      </Card>
    ))
  );
  return (
    <>
      <Box justifyContent="center" flexWrap="wrap">
        <Box>
          {cardList}
        </Box>
        {pageData !== null && (
        <Box display="flex" justifyContent="flex-end">
          <PaginationTable pageData={pageData} handlePagination={handlePagination} />
        </Box>
        )}
      </Box>
    </>
  );
};

export default MobileTable;
