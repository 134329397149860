import * as actionTypes from '../action_types/supplies';
import { getSupplies } from '../services/serviceClient';

const getSuppliesList = (page, token, queryParams = '') => ({
  type: actionTypes.GET_SUPPLIES,
  async payload() {
    const rawData = await getSupplies(page, token, queryParams);
    return { ...rawData, page };
  },
});

export const resetState = () => ({
  type: actionTypes.SUPPLIES_RESET_STATE,
});

export const addExpanded = (code) => ({
  type: actionTypes.SUPPLIES_ADD_EXPANDED,
  payload: {
    code,
  },
});

export const removeExpanded = (code) => ({
  type: actionTypes.SUPPLIES_REMOVE_EXPANDED,
  payload: {
    code,
  },
});

export const pageChange = (page) => ({
  type: actionTypes.SUPPLIES_PAGE_CHANGE,
  payload: {
    page,
  },
});

export const setPageOnFilter = () => ({
  type: actionTypes.SUPPLIES_SET_PAGE_ON_FILTER,
});

export default getSuppliesList;
