import React from 'react';
import { PWA } from '../config/constants';
import AddToHomeScreenDialog from './AddToHomeScreenDialog';

export default function AddToHomeScreen({
  openDialog, onChangeAcceptedPrompt,
  onChangeCancelPrompt, onChangeClickAwayPrompt, browserSettings,
}) {
  let dialogContent;
  let showButtons;
  let showAppleShareIcon;

  if (browserSettings[0].browser === PWA.BROWSER.CHROME
    && browserSettings[0].system === PWA.SYSTEM.ANDROID) {
    dialogContent = 'Would you like to install the app on your device?';
    showButtons = 'flex';
    showAppleShareIcon = 'none';
  } else if (browserSettings[0].browser === PWA.BROWSER.OTHER
    && browserSettings[0].system === PWA.SYSTEM.ANDROID) {
    dialogContent = 'In order to use the full feature set of the application, please use Chrome.';
    showButtons = 'none';
    showAppleShareIcon = 'none';
  } else if (browserSettings[0].browser === PWA.BROWSER.SAFARI
    && browserSettings[0].system === PWA.SYSTEM.IOS) {
    dialogContent = 'Install this application on your homescreen for a better experience.';
    showButtons = 'none';
    showAppleShareIcon = 'flex';
  } else if (browserSettings[0].browser !== PWA.BROWSER.SAFARI
    && browserSettings[0].system === PWA.SYSTEM.IOS) {
    dialogContent = 'In order to use the full feature set of the application, please use Safari.';
    showButtons = 'none';
    showAppleShareIcon = 'none';
  }

  if (!browserSettings[0].isMobile) {
    return null;
  }

  return (
    <AddToHomeScreenDialog
      openDialog={openDialog}
      onChangeAcceptedPrompt={onChangeAcceptedPrompt}
      onChangeCancelPrompt={onChangeCancelPrompt}
      onChangeClickAwayPrompt={onChangeClickAwayPrompt}
      dialogContent={dialogContent}
      showButtons={showButtons}
      showAppleShareIcon={showAppleShareIcon}
    />
  );
}
