// eslint-disable-next-line import/prefer-default-export
export const jobTypes = {
  USERS_IMPORT: 'users_import',
  USERS_EXPORT: 'users_export',
};

export const jobStatus = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
  FAILED: 'failed',
};
