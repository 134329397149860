import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactJson from 'react-json-view';

const errorDetailStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.1, 1.5),
  },
}));

const ErrorDetailsSection = ({ details, isMobile }) => {
  const styles = errorDetailStyle();
  return (
    <Box className={styles.container}>
      <ReactJson
        src={details}
        name={null}
        root={null}
        iconStyle="circle"
        collapseStringsAfterLength={isMobile ? 10 : 100}
        indentWidth={1}
        enableClipboard={false}
        displayDataTypes={false}
      />
    </Box>
  );
};

export default ErrorDetailsSection;
