export const GET_ROL_REPORT_VALIDATION = 'GET_ROL_REPORT_VALIDATION';
export const GET_ROL_REPORT_VALIDATION_PENDING = 'GET_ROL_REPORT_VALIDATION_PENDING';
export const GET_ROL_REPORT_VALIDATION_FULFILLED = 'GET_ROL_REPORT_VALIDATION_FULFILLED';
export const GET_ROL_REPORT_VALIDATION_REJECTED = 'GET_ROL_REPORT_VALIDATION_REJECTED';
export const REPORT_HANDLE_GO_BACK = 'REPORT_HANDLE_GO_BACK';
export const REPORT_RESET_STATE = 'REPORT_RESET_STATE';
export const GET_REPORT_LIST = 'GET_REPORT_LIST';
export const GET_REPORT_LIST_PENDING = 'GET_REPORT_LIST_PENDING';
export const GET_REPORT_LIST_FULFILLED = 'GET_REPORT_LIST_FULFILLED';
export const GET_REPORT_LIST_REJECTED = 'GET_REPORT_LIST_REJECTED';
export const SET_REPORT_PARAMS = 'SET_REPORT_PARAMS';
export const GET_REPORT_AS_CSV = 'GET_REPORT_AS_CSV';
export const GET_REPORT_AS_CSV_PENDING = 'GET_REPORT_AS_CSV_PENDING';
export const GET_REPORT_AS_CSV_FULFILLED = 'GET_REPORT_AS_CSV_FULFILLED';
export const GET_REPORT_AS_CSV_REJECTED = 'GET_REPORT_AS_CSV_REJECTED';
export const GET_REPORT_AS_PDF = 'GET_REPORT_AS_PDF';
export const GET_REPORT_AS_PDF_PENDING = 'GET_REPORT_AS_PDF_PENDING';
export const GET_REPORT_AS_PDF_FULFILLED = 'GET_REPORT_AS_PDF_FULFILLED';
export const GET_REPORT_AS_PDF_REJECTED = 'GET_REPORT_AS_PDF_REJECTED';
export const GET_FILTER_PACECENTERS = 'GET_FILTER_PACECENTERS';
export const GET_FILTER_PACECENTERS_PENDING = 'GET_FILTER_PACECENTERS_PENDING';
export const GET_FILTER_PACECENTERS_FULFILLED = 'GET_FILTER_PACECENTERS_FULFILLED';
export const GET_FILTER_PACECENTERS_REJECTED = 'GET_FILTER_PACECENTERS_REJECTED';
export const PACECENTERS_SEARCH_RESET = 'PACECENTERS_SEARCH_RESET';
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const USERS_REPORT_GENERATION = 'USERS_REPORT_GENERATION';
export const USERS_REPORT_GENERATION_PENDING = 'USERS_REPORT_GENERATION_PENDING';
export const USERS_REPORT_GENERATION_FULFILLED = 'USERS_REPORT_GENERATION_FULFILLED';
export const USERS_REPORT_GENERATION_REJECTED = 'USERS_REPORT_GENERATION_REJECTED';
