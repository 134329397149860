import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Button from '../../assets/Button';
import DispatchButton from '../../assets/DispatchButton';
import { report } from './constants';

const styles = makeStyles((theme) => ({
  buttonControl: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    [theme.breakpoints.down('320')]: {
      marginBottom: theme.spacing(2),
    },
  },
  downloadButton: {
    color: theme.palette.common.white,
    padding: theme.spacing(0.75, 2),
    marginTop: theme.spacing(1),
  },
  previewButton: {
    padding: theme.spacing(0.75, 2),
    marginTop: theme.spacing(1),
  },
  limitMessage: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const statusGeneration = ({
  loading, onClickDownload, onClickPreview, list, totalRows, browserSettings, smallDevices,
  loadingCSV, loadingPDF, disablePreviewButton, disableCSVButton, disablePDFButton,
}) => {
  const classes = styles();

  const handleOnClickDownload = (event) => {
    onClickDownload(event);
  };

  const handleOnClickPreview = (event) => {
    event.stopPropagation();
    onClickPreview();
  };

  return (
    <>
      {list !== null && list.length > 0
      && (
        <Box align="center">
          <br />
          <Typography>
            Please click on a button to proceed.
          </Typography>
          <br />
          <Container className={classes.buttonControl}>
            <Box display="flex" flexDirection={smallDevices ? 'column' : 'row'} justifyContent="space-evenly">
              <DispatchButton
                disabled={disableCSVButton}
                disableElevation
                onClick={() => handleOnClickDownload(report.format.csv)}
                variant="contained"
                color="primary"
                className={classes.downloadButton}
                loading={loading && loadingCSV}
                label="Download CSV"
              />
              <Box display={browserSettings[0].isMobile ? 'none' : ''}>
                <DispatchButton
                  disabled={disablePDFButton}
                  disableElevation
                  onClick={() => handleOnClickDownload(report.format.pdf)}
                  variant="contained"
                  color="secondary"
                  className={classes.downloadButton}
                  loading={loading && loadingPDF}
                  label="Download PDF"
                />
              </Box>
              <Button
                onClick={handleOnClickPreview}
                variant="contained"
                className={classes.previewButton}
                disabled={disablePreviewButton}
              >
                PREVIEW
              </Button>
            </Box>
          </Container>
        </Box>
      )}
      {list !== null && list.length === 0
        && (
        <Box align="center">
          <br />
          <Typography>
            The report generation does not have any record to show.
            Please check the filters applied and generate the report again.
          </Typography>
          <br />
        </Box>
        )}
      {totalRows >= report.limit
        && (
        <Box align="center" className={classes.limitMessage}>
          <Typography>
            The report requested does not show all the entries due to the entries
            limit being reached. Please filter the results further.
          </Typography>
        </Box>
        )}
    </>
  );
};

export default statusGeneration;
