import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import barcodeListener from '../../services/barcodeListener';
import { cameraPermissionError } from '../../actions/custody';
import BarcodeView from './BarcodeView';
import WithLoading from '../WithLoading';

const barcodeReaderStyle = makeStyles((theme) => ({
  container: {
    minHeight: 'inherit',
    boxSizing: 'border-box',
    position: 'relative',
    width: '95%',
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(2),
      width: '95%',
      minHeight: 'none',
    },
    [theme.breakpoints.up('md')]: {
      width: '95%',
    },
  },
  video: {
    width: 'inherit',
    border: `${theme.spacing(2)}px solid black`,
    minHeight: theme.spacing(32),
    maxHeight: theme.spacing(35),
    [theme.breakpoints.up('xs')]: {
      border: `${theme.spacing(1)}px solid black`,
      maxHeight: theme.spacing(18),
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(48),
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'none',
    },
  },
  hide: {
    display: 'none',
  },
}));
const BarcodeViewWithLoading = WithLoading(BarcodeView);

// Setting use of the camera for the device
const { Module } = global;
let startScan = null;
let stopScan = null;
let setVideo = null;
let video = null;
let canvas = null;
let ctx = null;

if (Module) {
  // Execute the application code when the WebAssembly module is ready.
  Module.onRuntimeInitialized = async () => {
    /**
     * wrap all C functions using cwrap. Note that we have to provide
     * crwap with the function signature.
     */
    const api = {
      scan_image: Module.cwrap('scan_image', '', [
        'number',
        'number',
        'number',
      ]),
      create_buffer: Module.cwrap('create_buffer', 'number', [
        'number',
        'number',
      ]),
      destroy_buffer: Module.cwrap('destroy_buffer', '', ['number']),
    };

    setVideo = () => {
      video = document.getElementById('live');
      // If not controls enable:
      /* video.addEventListener('canplaythrough', () => setTimeout(() => {
        video.play();
      }, 3_000)) */
      canvas = document.getElementById('canvas');
      ctx = canvas.getContext('2d');
    };

    let timerScan = null;
    // settings for the getUserMedia call
    const constraints = {
      video: {
        // the browser will try to honor this resolution, but it may end up being lower.
        // width: desiredWidth,
        // height: desiredHeight,
        facingMode: 'environment',
      },
    };

    function detectSymbols() {
      // grab a frame from the media source and draw it to the canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // get the image data from the canvas
      const image = ctx.getImageData(0, 0, canvas.width, canvas.height);

      // convert the image data to grayscale
      const grayData = [];
      const d = image.data;
      for (let i = 0, j = 0; i < d.length; i += 4, j += 1) {
        grayData[j] = (d[i] * 66 + d[i + 1] * 129 + d[i + 2] * 25 + 4096) >> 8;
      }

      // put the data into the allocated buffer on the wasm heap.
      const p = api.create_buffer(image.width, image.height);
      Module.HEAP8.set(grayData, p);

      // call the scanner function
      api.scan_image(p, image.width, image.height);

      // clean up
      // ( is used to demonstrate how you can manage Wasm heap memory from the js environment)
      api.destroy_buffer(p);
    }

    stopScan = () => {
      clearInterval(timerScan);

      const stream = video.srcObject;

      if (stream) {
        const track = stream.getVideoTracks()[0];
        if (track) {
          track.stop();
        }
      }

      video.srcObject = null;
    };

    startScan = () => {
      // open the webcam stream
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          // stream is a MediaStream object
          video.srcObject = stream;
          video.play();

          // If not controls enable:
          /* setTimeout(() => {
            video.play();
          }, 3_000) */

          // tell the canvas which resolution we ended up getting from the webcam
          const track = stream.getVideoTracks()[0];

          /* function onCapabilitiesReady() {
            if (
              track
              && typeof track.getCapabilities === 'function'
              && 'torch' in track.getCapabilities()
            ) {
              // Turn on flash
              // track.applyConstraints({ advanced: [{ torch: true }] });
            }
          }

          window.setTimeout(() => onCapabilitiesReady(track), 300); */

          const actualSettings = track.getSettings();
          canvas.width = actualSettings.width;
          // canvas.width = '288px';
          canvas.height = actualSettings.height;
          // canvas.height = '250';

          /**
           * every k milliseconds, we draw the contents of the video
           *  to the canvas and run the detector.
           */
          timerScan = setInterval(detectSymbols, 500);

          setTimeout(() => {
            barcodeListener.isLoading(false);
          }, 2000);
        })
        .catch((error) => {
          barcodeListener.isLoading(false);
          barcodeListener.hasError(error);
        });
    };

    // set the function that should be called whenever a barcode is detected
    Module.processResult = (symbol, data) => {
      if (data) {
        barcodeListener.sendBarcode(data);
      }
    };
  };
  Module.onRuntimeInitialized();
}

const BarcodeReader = ({ value, isLoading, onChangeValue }) => {
  const styles = barcodeReaderStyle();
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(null);
  const [isLoadingDevice, setIsLoading] = useState(true);

  const subscription = barcodeListener
    .getBarcode()
    .subscribe(({ barcode, loading, error }) => {
      if (barcode && value !== barcode) {
        onChangeValue(barcode);
      }
      setHasError(error);
      setIsLoading(loading);
    });

  useEffect(() => {
    if (hasError !== null) {
      dispatch(cameraPermissionError(hasError));
    }
  }, [hasError]);

  useEffect(() => {
    if (setVideo) {
      setVideo();
    }
  }, [setVideo]);
  useEffect(() => {
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    }
  })
  useEffect(() => {
    if (startScan) {
      startScan();
    }
  }, [startScan]);

  useEffect(
    () => () => {
      if (stopScan) {
        stopScan();
      }
      subscription.unsubscribe();
    },
    [stopScan],
  );

  return (
    <Box
      className={styles.container}
      display="flex"
      flexGrow={1}
      flexDirection="column"
    >
      <Box
        display="contents"
        className={isLoadingDevice || isLoading || hasError ? styles.hide : ''}
      >
        <video id="live" autoPlay playsInline controls className={styles.video} />
      </Box>
      <BarcodeViewWithLoading
        loading={isLoadingDevice || isLoading}
        error={hasError ? String(hasError) : null}
      />
      <Box className={styles.hide}>
        <canvas id="canvas" />
      </Box>
    </Box>
  );
};

export default BarcodeReader;
