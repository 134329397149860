import * as actionTypes from '../action_types/navigation';

export const setNavigationDrawer = (currentPageName) => ({
  type: actionTypes.SET_NAVIGATION_DRAWER,
  payload: {
    showNavigationDrawer: true,
    showStepBackButton: false,
    showCloseButton: false,
    currentPageName,
  },
});

export const setNavigationStepbackButton = (currentPageName) => ({
  type: actionTypes.SET_NAVIGATION_STEPBACK_BUTTON,
  payload: {
    showNavigationDrawer: false,
    showStepBackButton: true,
    showCloseButton: false,
    currentPageName,
  },
});

export const setNavigationCloseButton = (currentPageName) => ({
  type: actionTypes.SET_NAVIGATION_CLOSE_BUTTON,
  payload: {
    showNavigationDrawer: false,
    showStepBackButton: false,
    showCloseButton: true,
    currentPageName,
  },
});

export const setNavigationExtendSession = () => ({
  type: actionTypes.SET_NAVIGATION_EXTEND_SESSION,
  payload: {
    sessionExtended: false,
  },
});

export const setUserRoleOnNavigation = (userRole) => ({
  type: actionTypes.SET_NAVIGATION_USER_ROLE,
  payload: {
    userRole,
  },
});

export const setNewUserConfiguration = () =>  ({
  type: actionTypes.SET_NEW_USER_CONFIGURATION,
  payload: {
    newUserConfig: true,
  },
});

export default setNavigationDrawer;
