import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../assets/Button';
import VerticalStepper from './VerticalStepper';
import StatusBar from '../StatusBar';
import DeliveredTo from './DeliveredTo';
import Banner from '../Banner';
import { DELIVERED, LOG_TYPES, ROLES_TYPES } from '../../config/constants';
import { capitalizeFirstLetter } from '../../services/utility';

const supplyDeliveryStyle = makeStyles((theme) => ({
  headerContent: {
    paddingTop: theme.spacing(1),
    right: 0,
    left: 0,
    zIndex: 1000,
    top: theme.spacing(7),
  },
  button: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: '200px',
  },
  buttonControl: {
    width: '30%',
    [theme.breakpoints.up('1', '500')]: {
      width: '100%',
    },
    [theme.breakpoints.up('500', '700')]: {
      width: '50%',
    },
  },
}));

export default function SupplyDelivery({
  logs, tookCustody = false, supplyDelivered = false,
  disableDeliverButton, onHideBanner, editableItem, userRole,
  digitalSignature,
}) {
  const styles = supplyDeliveryStyle();
  let deliveredTo;
  let bannerText = '';
  const history = useHistory();
  const location = useLocation();

  if (tookCustody) {
    bannerText = `${capitalizeFirstLetter(logs.type)} ${logs.code} is in your custody now`;
  } else if (supplyDelivered) {
    bannerText = `${capitalizeFirstLetter(logs.type)} ${logs.code} was successfully delivered`;
  }

  if (logs.status !== DELIVERED && (logs.type === LOG_TYPES.PACKAGE
    || (logs.type === LOG_TYPES.ORDER && logs.patient))) {
    deliveredTo = <DeliveredTo {...logs} disableDeliverButton={disableDeliverButton} />;
  }

  const handleAddComment = () => {
    const commentPath = location.pathname.replace('/logs', '/comment');
    history.push(commentPath);
  };

  const handleEditItem = () => {
    const editPath = location.pathname.replace('/logs', '/edit');
    history.push(editPath);
  };

  const addComment = (
    <Box bgcolor="#f3f3f3" width={1} p={1.5} justifyContent="center" display="flex">
      <Box display="flex" className={styles.buttonControl} justifyContent="space-evenly">
        <Box
          display={!editableItem || userRole !== ROLES_TYPES.MANAGER ? 'none' : ''}
        >
          <Button
            variant="contained"
            color="secondary"
            className={styles.button}
            onClick={handleEditItem}
          >
            Edit Item
          </Button>

        </Box>
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            className={styles.button}
            onClick={handleAddComment}
          >
            Add A Comment
          </Button>

        </Box>


      </Box>
    </Box>
  );

  return (
    <Box display="flex" flexGrow={1} flexDirection="column">
      <Box className={styles.headerContent} bgcolor="common.white" position="sticky">
        <Banner
          show={(tookCustody || supplyDelivered) && logs.code}
          text={bannerText}
          onHideBanner={onHideBanner}
        />
        {logs.status && <StatusBar {...logs} />}
      </Box>
      <VerticalStepper digitalSignature={digitalSignature} {...logs} />
      <Box position="fixed" bottom="0" width={1}>
        {addComment}
        {deliveredTo}
      </Box>
    </Box>
  );
}
