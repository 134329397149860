import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FiltersMenu from '../components/FiltersMenu';
import { setNavigationStepbackButton } from '../actions/navigation';
import {
  setFiltersToShow, resetState as resetFilterState,
  handleGoBack, resetOnSearchState,
  clearElementFromListSate,
  setAllSuppliesFilters,
} from '../actions/filters';
import { setPageOnFilter } from '../actions/supplies';
import WithLoading from '../components/WithLoading';
import { useAuth0 } from '../auth0';
import { FILTER_TYPES, AUTH0_ROLES, ROLES_TYPES } from '../config/constants';
import { nav } from '../components/FiltersMenu/constants';

const FiltersMenuWithLoading = WithLoading(FiltersMenu);

export default function FiltersMenuContainer() {
  const history = useHistory();
  const page = useSelector((state) => state.suppliesState.page);
  const pageData = useSelector((state) => state.suppliesState.pageData);
  const loading = useSelector((state) => state.suppliesState.loading);
  const navigation = useSelector((state) => state.filtersState.navigation);
  const reset = useSelector((state) => state.filtersState.reset);
  const pendingDataToPaginate = useSelector((state) => state.suppliesState.pendingDataToPaginate);
  const patientFilterState = useSelector((state) => (state.filtersState[FILTER_TYPES.PATIENT] ? state.filtersState[FILTER_TYPES.PATIENT] : ''));
  const itemFilterState = useSelector((state) => (state.filtersState[FILTER_TYPES.ITEM] ? state.filtersState[FILTER_TYPES.ITEM] : ''));
  const custodianFilterState = useSelector((state) => (state.filtersState[FILTER_TYPES.CUSTODIAN] ? state.filtersState[FILTER_TYPES.CUSTODIAN] : ''));
  const centerFilterState = useSelector((state) => (state.filtersState[FILTER_TYPES.CENTER] ? state.filtersState[FILTER_TYPES.CENTER] : ''));
  const filters = useSelector((state) => state.filtersState);
  const [token, setToken] = useState('');
  const [successMenu, setSuccessMenu] = useState(false);
  const dispatch = useDispatch();
  const [filtersListToApply, setFiltersListToApply] = useState([]);

  const initialState = {
    custodian: false,
    patient: false,
    item: false,
    creation: filters[FILTER_TYPES.ACTIVITY].value.length > 0
    || filters[FILTER_TYPES.ACTIVITY].tmpValue.length > 0,
    activity: filters[FILTER_TYPES.UPDATED_DATE].value.length > 0,
    center: filters[FILTER_TYPES.CENTER].value.length > 0,
    entry: filters[FILTER_TYPES.ENTRY] !== 'all',
    status: filters[FILTER_TYPES.STATUS] !== 'all',
    sort: filters[FILTER_TYPES.SORT] !== 'created_date_desc',
  };

  const [onFilterChange, setOnFilterChange] = useState(initialState);

  const { getTokenSilently, user } = useAuth0();

  (async () => {
    setToken(await getTokenSilently());
  })();

  const handleClearFilters = () => {
    dispatch(resetFilterState());
  };

  useEffect(() => {
    if (successMenu) {
      history.replace('/');
    }
  }, [successMenu]);

  const handleOnFilterChange = (filterType, filterValue) => {
    if (filterValue.length > 0 && filterValue !== 'all') {
      setOnFilterChange({
        ...onFilterChange,
        [filterType]: true,
      });
    } else {
      setOnFilterChange({
        ...onFilterChange,
        [filterType]: false,
      });
    }
  };

  const handleFilterToApply = (filter) => {
    const filterList = filtersListToApply;
    const index1 = filterList.findIndex((obj) => filter.filterType === obj.filterType);
    if (index1 !== -1) {
      filterList[index1] = filter;
    } else if (filter.value.length > 0) {
      filterList.push(filter);
    }
    setFiltersListToApply(filterList);
    handleOnFilterChange(filter.filterType, filter.value);
  };

  const handleOnCLickDeleteElement = (filter, element) => {
    const { value } = filtersListToApply[filtersListToApply.findIndex((obj) => filter
      === obj.filterType)];
    const elementToDelete = {
      filterType: filter,
      element,
      value,
      tmpValue: value,
    };
    dispatch(clearElementFromListSate(elementToDelete));
  };

  useEffect(() => {
    if ((custodianFilterState.value.length > 0 || custodianFilterState.tmpValue.length > 0)) {
      handleFilterToApply({
        filterType: FILTER_TYPES.CUSTODIAN,
        value: custodianFilterState.tmpValue,
        tmpValue: custodianFilterState.tmpValue,
        expanded: custodianFilterState.expanded,
        other: custodianFilterState.value,
      });
    }
    if (patientFilterState.value.length > 0 || patientFilterState.tmpValue.length > 0) {
      handleFilterToApply({
        filterType: FILTER_TYPES.PATIENT,
        value: patientFilterState.tmpValue,
        tmpValue: patientFilterState.tmpValue,
        expanded: patientFilterState.expanded,
      });
    }
    if (itemFilterState.value.length > 0 || itemFilterState.tmpValue.length > 0) {
      handleFilterToApply({
        filterType: FILTER_TYPES.ITEM,
        value: itemFilterState.tmpValue,
        tmpValue: itemFilterState.tmpValue,
        expanded: itemFilterState.expanded,
      });
    }
    if (centerFilterState.value.length > 0 || centerFilterState.tmpValue.length > 0) {
      handleFilterToApply({
        filterType: FILTER_TYPES.CENTER,
        value: centerFilterState.tmpValue,
        tmpValue: centerFilterState.tmpValue,
        expanded: centerFilterState.expanded,
      });
    }
  }, [custodianFilterState, patientFilterState, itemFilterState, centerFilterState]);

  const handleApplyFilter = () => {
    dispatch(setPageOnFilter());
    dispatch(setAllSuppliesFilters({
      filtersListToApply,
    }));
    setSuccessMenu(true);
  };

  const handleClickSearchFilter = () => {
    dispatch(resetOnSearchState());
    dispatch(handleGoBack(nav.code.SEARCH_FILTER));
  };

  const accordionStatus = (filterType) => filtersListToApply.findIndex((obj) => filterType
  === obj.filterType) > -1;

  useEffect(() => {
    dispatch(setNavigationStepbackButton('Sorting & Filters'));
    handleOnFilterChange('status', 'pending');
  }, []);

  useEffect(() => {
    if (reset) {
      setFiltersListToApply([]);
      handleOnFilterChange('status', 'all');
      setOnFilterChange(initialState);
    }
  }, [reset]);

  useEffect(() => {
    if (user && token) {
      const roles = user[AUTH0_ROLES] ? user[AUTH0_ROLES] : [];
      dispatch(
        setFiltersToShow({
          filters: {
            [FILTER_TYPES.SORT]: true,
            [FILTER_TYPES.STATUS]: true,
            [FILTER_TYPES.CUSTODIAN]:
              (roles.length === 1 && roles[0] !== ROLES_TYPES.COMMON) || roles.length > 1,
            [FILTER_TYPES.PATIENT]: true,
            [FILTER_TYPES.ITEM]: true,
            [FILTER_TYPES.ACTIVITY]: true,
            [FILTER_TYPES.ENTRY]: true,
            [FILTER_TYPES.UPDATED_DATE]: true,
            [FILTER_TYPES.CENTER]:
              (roles.length === 1 && roles[0] !== ROLES_TYPES.COMMON) || roles.length > 1,
          },
        }),
      );
    }
  }, [dispatch, user, token]);

  useEffect(() => {
    filtersListToApply.forEach((e) => {
      handleOnFilterChange(e.filterType, e.value);
    });
  }, [filters, filtersListToApply]);

  return (
    <>
      {!reset
      && (
      <FiltersMenuWithLoading
        filters={filters}
        loading={loading}
        page={page}
        pageData={pageData}
        pendingDataToPaginate={pendingDataToPaginate}
        onClearFilters={handleClearFilters}
        onApplyFilter={handleApplyFilter}
        navigationValue={navigation}
        onClickSeachFilter={handleClickSearchFilter}
        filterToApply={handleFilterToApply}
        filtersListToApply={filtersListToApply}
        onClickDeleteElement={handleOnCLickDeleteElement}
        onAccordionStatus={accordionStatus}
        onFilterChange={onFilterChange}
      />
      )}
      {reset
      && (
      <FiltersMenuWithLoading
        filters={filters}
        loading={loading}
        page={page}
        pageData={pageData}
        pendingDataToPaginate={pendingDataToPaginate}
        onClearFilters={handleClearFilters}
        onApplyFilter={handleApplyFilter}
        navigationValue={navigation}
        onClickSeachFilter={handleClickSearchFilter}
        filterToApply={handleFilterToApply}
        filtersListToApply={filtersListToApply}
        onClickDeleteElement={handleOnCLickDeleteElement}
        onAccordionStatus={accordionStatus}
        onFilterChange={onFilterChange}
      />
      )}
    </>
  );
}
