import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/comments';

const initialState = {
  loading: false,
  error: null,
  isSuccess: false,
};

const resetState = (state) => updateObject(state, initialState);

const postLog = (state) => updateObject(state, { ...initialState, isSuccess: true });

const requestFailed = (state, action) => updateObject(state, {
  ...state,
  error: action.payload.message,
});

const requestStarted = (state) => updateObject(state, {
  ...state,
  loading: true,
});

export default createReducer(initialState, {
  [actionTypes.POST_COMMENT]: requestStarted,
  [actionTypes.POST_COMMENT_FULFILLED]: postLog,
  [actionTypes.POST_COMMENT_REJECTED]: requestFailed,
  [actionTypes.POST_COMMENT_RESET_STATE]: resetState,
});
