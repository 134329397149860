export const GET_JOBS = 'GET_JOBS';
export const GET_JOBS_PENDING = 'GET_JOBS_PENDING';
export const GET_JOBS_FULFILLED = 'GET_JOBS_FULFILLED';
export const GET_JOBS_REJECTED = 'GET_JOBS_REJECTED';

export const JOBS_RESET_STATE = 'JOBS_RESET_STATE';
export const JOB_DETAIL_RESET_STATE = 'JOB_DETAIL_RESET_STATE';

export const JOBS_HANDLE_GO_BACK = 'JOBS_HANDLE_GO_BACK';

export const GET_JOB_DETAIL = 'GET_JOB_DETAIL';
export const GET_JOB_DETAIL_PENDING = 'GET_JOB_DETAIL_PENDING';
export const GET_JOB_DETAIL_FULFILLED = 'GET_JOB_DETAIL_FULFILLED';
export const GET_JOB_DETAIL_REJECTED = 'GET_JOB_DETAIL_REJECTED';

export const GET_USERS_REPORT = 'GET_USERS_REPORT';
export const GET_USERS_REPORT_PENDING = 'GET_USERS_REPORT_PEMDING';
export const GET_USERS_REPORT_FULFILLED = 'GET_USERS_REPORT_FULFILLED';
export const GET_USERS_REPORT_REJECTED = 'GET_USERS_REPORT_REJECTED';

export const SET_JOB_TYPE_LIST = 'SET_JOB_TYPE_LIST';
