import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetState as resetCustodyState } from '../actions/custody';
import { resetState as resetDeliverState } from '../actions/deliver';
import getLogsByOrder, { resetState as resetLogsState } from '../actions/logs';
import SupplyDelivery from '../components/SupplyDelivery';
import WithLoading from '../components/WithLoading';
import { setNavigationStepbackButton } from '../actions/navigation';
import { useAuth0 } from '../auth0';
import { ITEM_STATUS } from '../config/constants';

const SupplyDeliveryWithLoading = WithLoading(SupplyDelivery);

export default function SupplyDeliveryContainer() {
  const { orderId } = useParams();
  const { getTokenSilently, user } = useAuth0();
  const logs = useSelector((state) => state.logsState);
  const {
    history, manuallyCreated, signature, status,
  } = useSelector((state) => state.logsState);
  const { userRole } = useSelector((state) => state.navigationState);
  const isCustodySuccess = useSelector((state) => state.custodyState.isSuccess);
  const isDeliverSuccess = useSelector((state) => state.deliverState.isSuccess);
  const disableDeliverButton = !logs || !logs.user
    || !user || user.sub !== logs.user.id || !logs.location;
  const [editableItem, setEditableItem] = useState(false);
  const [digitalSignature, setDigitalSignature] = useState(null);
  const dispatch = useDispatch();

  const handleHideBanner = () => {
    if (isCustodySuccess) {
      dispatch(resetCustodyState());
    } else if (isDeliverSuccess) {
      dispatch(resetDeliverState());
    }
  };

  useEffect(() => {
    if (signature !== null && digitalSignature === null) {
      setDigitalSignature(signature);
    }
  }, [signature]);

  useEffect(() => {
    if (orderId) {
      dispatch(setNavigationStepbackButton('Package'));
      (async () => {
        const token = await getTokenSilently();
        dispatch(getLogsByOrder(orderId, token));
      })();
    }
  }, [orderId, dispatch]);

  useEffect(() => {
    if (history !== []) {
      setEditableItem(manuallyCreated && status === ITEM_STATUS.PENDING
        && history.filter((e) => e.status === ITEM_STATUS.PENDING).length === 1);
    }
  }, [history]);

  useEffect(() => () => dispatch(resetLogsState()), []);

  return (
    <SupplyDeliveryWithLoading
      logs={logs}
      loading={logs.loading}
      error={logs.error}
      disableDeliverButton={disableDeliverButton}
      tookCustody={isCustodySuccess}
      supplyDelivered={isDeliverSuccess}
      onHideBanner={handleHideBanner}
      editableItem={editableItem}
      userRole={userRole}
      digitalSignature={digitalSignature}
    />
  );
}
