import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import UserList from './UserList';

const dashboardStyle = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2.5),
  },
  attest: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  canvas: {
    margin: theme.spacing(0, 0, 7, 0),
  },
}));

export default function Dashboard({
  error,
  loading,
  page,
  list,
  pageData,
  handlePagination,
  browserSettings,
  disableSearch,
  handleOnClickTable,
  handleOnCLickNewUser,
  handleOnClickBulkUsers,
  handleOnCLickJobsList,
  handleOnClickUsersReport,
  alerts,
  jobId,
  jobDescription,
  onChangeValue,
  value,
  rolesList,
  onChangeRolesFilter,
  isFilterByRole,
  usersRequested,
}) {
  const styles = dashboardStyle();
  return (
    <>
      <Container>
        <Box className={styles.container}>
          {
      !error && (
        <UserList
          error={error}
          page={page}
          list={list}
          loading={loading}
          pageData={pageData}
          handlePagination={handlePagination}
          browserSettings={browserSettings}
          disableSearch={disableSearch}
          handleOnClickTable={handleOnClickTable}
          handleOnCLickNewUser={handleOnCLickNewUser}
          handleOnClickBulkUsers={handleOnClickBulkUsers}
          handleOnCLickJobsList={handleOnCLickJobsList}
          handleOnClickUsersReport={handleOnClickUsersReport}
          alerts={alerts}
          jobId={jobId}
          jobDescription={jobDescription}
          onChangeValue={onChangeValue}
          value={value}
          rolesList={rolesList}
          onChangeRolesFilter={onChangeRolesFilter}
          isFilterByRole={isFilterByRole}
          usersRequested={usersRequested}
        />
      )
      }
        </Box>
      </Container>
    </>
  );
}
