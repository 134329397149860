import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/supplies';

const initialState = {
  list: [],
  loading: false,
  error: null,
  pendingDataToPaginate: false,
  page: 0,
  pageData: null,
  openList: [],
};

const getSuppliesList = (state, action) => {
  const tmpState = updateObject(state, {
    list: action.payload.supplyDeliveries,
    loading: false,
    error: null,
    page: action.payload.page,
    pageData: action.payload.pageData,
  });
  return updateObject(tmpState, {
    pendingDataToPaginate: action.payload.pageData.total > tmpState.list.length,
  });
};

const requestFailed = (state, action) => updateObject(state, {
  ...initialState,
  error: action.payload.message,
});

const pendingSupplies = (state) => updateObject(state, {
  loading: true,
  pendingDataToPaginate: false,
  error: null,
});

const resetState = (state) => updateObject(state, {
  list: [],
});

const addExpanded = (state, action) => updateObject(state, {
  openList: [...state.openList, action.payload.code],
});

const removeExpanded = (state, action) => {
  const tempOpenList = [...state.openList];
  const index = tempOpenList.indexOf(action.payload.code);
  if (index > -1) {
    tempOpenList.splice(index, 1);
  }
  return updateObject(state, {
    openList: tempOpenList,
  });
};

const pageChange = (state) => updateObject(state, {
  openList: [],
});

const setPageOnFilter = (state) => (updateObject(state, {
  page: 0,
}));

export default createReducer(initialState, {
  [actionTypes.GET_SUPPLIES_PENDING]: pendingSupplies,
  [actionTypes.GET_SUPPLIES_FULFILLED]: getSuppliesList,
  [actionTypes.GET_SUPPLIES_REJECTED]: requestFailed,
  [actionTypes.SUPPLIES_RESET_STATE]: resetState,
  [actionTypes.SUPPLIES_ADD_EXPANDED]: addExpanded,
  [actionTypes.SUPPLIES_REMOVE_EXPANDED]: removeExpanded,
  [actionTypes.SUPPLIES_PAGE_CHANGE]: pageChange,
  [actionTypes.SUPPLIES_SET_PAGE_ON_FILTER]: setPageOnFilter,
});
