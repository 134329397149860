import React from 'react';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { makeStyles } from '@material-ui/core/styles';

const commentBubbleStyle = makeStyles((theme) => ({
  icon: {
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
  comment: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  number: {
    marginLeft: theme.spacing(0.25),
  },
}));

const CommentBubble = ({ comments }) => {
  const styles = commentBubbleStyle();

  let amount = (comments || []).length;
  if (amount > 9) amount = '9+';
  return amount ? (
    <div className={styles.comment}>
      <ChatBubbleIcon fontSize="small" className={styles.icon} />
      <span className={styles.number}>{amount}</span>
    </div>
  ) : null;
};

export default CommentBubble;
