import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
    color: 'secondary.main',
  },
  underline: {
    '&::after': {
      borderBottom: `2px solid ${theme.palette.secondary.light}`,
    },
  },
  formText: {
    '&$formLabelFocused': { color: theme.palette.secondary.main },
  },
  formLabelFocused: {
  },
}));

const RolMenuItem = ({
  rolesList, value, onChangeValue,
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    onChangeValue(event);
  };

  const MenuItemList = rolesList
    .map((rol) => (
      <MenuItem
        key={rol.id}
        value={rol.name}
      >
        {rol.name}
      </MenuItem>
    ));

  return (

    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel
        classes={{
          root: classes.formText,
          focused: classes.formLabelFocused,
        }}
        id="demo-simple-select-filled-label"
      >
        Role
      </InputLabel>
      <Select
        className={classes.underline}
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={value.rol}
        name="rol"
        onChange={handleChange}
      >
        {MenuItemList}
      </Select>
    </FormControl>

  );
};

export default RolMenuItem;
