const nav = {
  code: {
    USER_EDITION_FORM: 0,
    PACE_CENTERS_SEARCH: 1,
  },
};

const helperText = {
  FULLNAME: 'Enter the new user full name',
  EMAIL: 'Enter a valid email for the new user',
  PACE_CENTERS: 'please click on the SEARCH button and select the paceCenters to the new user',
  PACE_CENTERS_SEARCH: 'Please enter the Pacecenter ID or name',
};

export { helperText, nav };
