import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '../../assets/Button';
import { REPORT_TYPES } from '../../config/constants';
import TextField from '../../assets/TextField';
import StatusGeneration from './StatusGeneration';
import { status } from './constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
    color: 'primary.main',
  },
  formLabelFocused: {
  },
  button: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  buttonReport: {
    color: theme.palette.common.white,
    padding: theme.spacing(0.75, 2),
  },
  box: {
    width: '49.8%',
  },
  searchButton: {
    right: theme.spacing(-1),
    fontWeight: 'bold',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  textInput: {
    width: '98%',
    marginRight: theme.spacing(3),
    spacing: 2,
  },
  readAgreement: {
    margin: theme.spacing(3, 0, 4, 0),
    textAlign: 'justify',
  },
  inputRootLabel: {
    '&$disabled': {
      color: '#616161',
    },
  },
  inputRootText: {
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  disabled: {},
  buttonControl: {
    width: '100%',
  },
}));

export default function Menu({
  value, onChangeValue, handleStartDateRange, handleEndDateRange,
  browserSettings, onClickGenerateReport, loadingReport, onGenerateReport,
  onClickDownload, onClickPreview, list, pageData, smallDevices, onClickSearchButtonCenter,
  loadingCSV, loadingPDF, disablePreviewButton, disableCSVButton, disablePDFButton,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    onChangeValue(event);
  };

  const handleChangeStartDate = (event) => {
    handleStartDateRange(event);
  };

  const handleChangeEndDate = (event) => {
    handleEndDateRange(event);
  };

  return (
    <>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel
          classes={{
            focused: classes.formLabelFocused,
          }}
          id="demo-simple-select-filled-label"
        >
          Report Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={value.reportType}
          name="reportType"
          onChange={handleChange}
        >
          <MenuItem
            value={REPORT_TYPES.DELIVERY_STATUS.VALUE}
          >
            {REPORT_TYPES.DELIVERY_STATUS.LABEL}
          </MenuItem>
          <MenuItem
            value={REPORT_TYPES.EVENTS_LOG.VALUE}
          >
            {REPORT_TYPES.EVENTS_LOG.LABEL}
          </MenuItem>
        </Select>
      </FormControl>
      <br />
      <br />
      <Box display="flex" flexGrow={0.8}>
        <TextField
          disabled
          label="Center"
          variant="filled"
          value={value.paceCenter}
          name="paceCenter"
          autoComplete="false"
          helperText={status.PACECENTERS}
          InputLabelProps={{
            classes: {
              root: classes.inputRootLabel,
              disabled: classes.disabled,
            },
          }}
          inputProps={{
            classes: {
              root: classes.inputRootText,
              disabled: classes.disabled,
            },
          }}
          fullWidth
        />
        <Box
          className={classes.searchButton}
          justifyContent="center"
          flexGrow="0.2"
        >
          <Button
            color="primary"
            onClick={onClickSearchButtonCenter}
            className={classes.searchButton}
          >
            {' '}
            SEARCH CENTER
          </Button>
        </Box>
      </Box>
      <br />
      <br />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box display="flex" justifyContent="center">
          <DatePicker
            value={value.startDateRange}
            maxDate={value.endDateRange}
            format="MM/dd/yyyy"
            label="Start Date"
            id="startDateRange"
            onChange={handleChangeStartDate}
            fullWidth
          />
          <DatePicker
            value={value.endDateRange}
            minDate={value.startDateRange}
            maxDate={new Date()}
            format="MM/dd/yyyy"
            label="End Date"
            id="endDateRange"
            onChange={handleChangeEndDate}
            fullWidth
          />
        </Box>
      </MuiPickersUtilsProvider>
      <br />
      <Button
        className={classes.button}
        disabled={(value.reportType === ''
        || value.paceCenter === '')}
        disableElevation
        onClick={onClickGenerateReport}
        variant="cofc"
      >
        GENERATE REPORT
      </Button>
      <br />
      <br />
      {onGenerateReport
       && (
       <StatusGeneration
         loading={loadingReport}
         onClickDownload={onClickDownload}
         onClickPreview={onClickPreview}
         list={list}
         totalRows={pageData ? pageData.total : 0}
         browserSettings={browserSettings}
         smallDevices={smallDevices}
         loadingCSV={loadingCSV}
         loadingPDF={loadingPDF}
         disablePreviewButton={disablePreviewButton}
         disableCSVButton={disableCSVButton}
         disablePDFButton={disablePDFButton}
       />
       )}
    </>
  );
}
