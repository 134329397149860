import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Entry from '../components/UserEdition';
import {
  getRoles, getPaceCentersList, handleGoBack, postUserEdition,
  resetPaceCentersSearchState, userById,
} from '../actions/dashboard';
import { setNavigationStepbackButton } from '../actions/navigation';
import WithLoading from '../components/WithLoadingList';
import { mobileDetection } from '../services/utility';
import { nav } from '../components/UserCreation/constants';
import { useAuth0 } from '../auth0';

const EntryWithLoading = WithLoading(Entry);

const initialState = {
  username: '',
  email: '',
  rol: '',
  paceCenters: [],
};

const UserEditionContainer = () => {
  const { userId } = useParams();
  const history = useHistory();
  const [entry, setEntry] = useState(initialState);
  const {
    error, formError, rolesList, searchOutcome, paceCentersList, paceCenters,
    navigation, loading, totalOutcome, pendingDataToPaginate, userData, userRol,
    loadingEdition, page, isSuccess, successEdition, isLoading,
  } = useSelector((state) => state.dashboardState);
  const browserSettings = useState(mobileDetection(window.navigator));
  const { getTokenSilently, user } = useAuth0();
  const [token, setToken] = useState('');
  const [enableButton, setEnableButton] = useState(true);
  const [searchValue, handleSearchValue] = useState('');
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [disableSearch, setDisableSearch] = useState(true);
  const [disableRole, setDisableRole] = useState(false);
  const [selectedPaceCentersList, setSelectedPaceCentersList] = useState(paceCentersList);
  const [filterValue, handleFilterValue] = useState([]);
  const [errors, setErrors] = useState({});
  const [username, setUsername] = useState(initialState.username);
  const [highlightText, handleHighlightText] = useState('');
  const [pag, setPag] = useState(0);
  const [loadingUserData, setLoadingUserData] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const currentPage = 'User Edition';
    dispatch(setNavigationStepbackButton(currentPage));
  }, []);

  useEffect(() => {
    if (searchValue === '' && !disableSearch) {
      setDisableSearch(true);
    } else if (searchValue !== '' && disableSearch) {
      setDisableSearch(false);
    }
  }, [searchValue]);

  const getToken = async () => {
    setToken(await getTokenSilently());
  };

  const getUserData = async () => {
    await dispatch(userById(userId, token));
  };

  useEffect(() => {
    if (user && token) {
      getUserData();
      dispatch(getRoles(token));
    } else {
      getToken();
    }
  }, [dispatch, user, token]);

  useEffect(() => {
    setSelectedPaceCentersList(paceCenters);
    if (isSuccess && userRol !== null) {
      setEntry({
        ...entry,
        username: userData.name,
        email: userData.email,
        rol: userRol.name,
        paceCenters,
      });
    } else if (isSuccess && userData !== null) {
      setEntry({
        ...entry,
        username: userData.name,
        email: userData.email,
        paceCenters,
      });
    }
    if (userData !== null && isSuccess) {
      setTimeout(() => {
        setLoadingUserData(false);
      }, 850);
    }
    if (userRol && userData !== undefined && userData !== []) {
      setDisableRole(userData.userId === user.sub);
    }
  }, [userRol, userData]);

   useEffect(() => {
    if (!isLoading && !isSuccess && error !== null) {
      setLoadingUserData(false);
    }
  }, error);

  const validate = () => {
    const temp = { ...errors };
    if (entry.email !== '') {
      const re = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
      temp.email = re.test(entry.email)
        ? ''
        : 'Email is not valid.';
    }
    setErrors({
      ...temp,
    });
  };

  useEffect(() => {
    if (entry.username !== '' && entry.email !== '' && entry.rol !== '') {
      if (entry.rol !== 'Admin' && entry.paceCenters.length === 0) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    } else {
      setEnableButton(true);
    }
    validate();
  }, [entry]);

  useEffect(() => {
    if (successEdition) {
      history.goBack();
    }
  }, [successEdition]);

  const handleChangeEntry = (event) => {
    setEntry({
      ...entry,
      [event.target.name]: event.target.value,
    });

    if (event.target.name === 'email') {
      validate();
    }
  };

  const handleSearch = async () => {
    handleHighlightText(searchValue);
    handleFilterValue([]);
    dispatch(resetPaceCentersSearchState());
    setPag(0);
    await dispatch(getPaceCentersList(searchValue, 0, token));
    setSearchCompleted(true);
  };

  const handlePagination = () => {
    setPag(pag + 1);
    dispatch(getPaceCentersList(searchValue, pag, token));
  };

  const handleOnCLickDeleteElement = async (element) => {
    setSelectedPaceCentersList(selectedPaceCentersList.filter((obj) => obj.id !== element));
    setEntry({
      ...entry,
      paceCenters: entry.paceCenters.filter((obj) => obj.id !== element),
    });
  };

  const handleOnClickPaceCenters = () => {
    handleSearchValue('');
    handleFilterValue([]);
    setSearchCompleted(false);
    dispatch(resetPaceCentersSearchState());
    dispatch(handleGoBack(nav.code.PACE_CENTERS_SEARCH));
  };

  const handleOnClickFooterButton = () => {
    const filterList = searchOutcome.filter((obj) => filterValue.includes(obj.id));
    const tmpValues = selectedPaceCentersList;
    filterList.forEach((e) => {
      if (selectedPaceCentersList.findIndex((o) => o.id === e.id) === -1) {
        tmpValues.push(e);
      }
    });
    setSelectedPaceCentersList(tmpValues);
    setEntry({
      ...entry,
      paceCenters: selectedPaceCentersList,
    });
    dispatch(handleGoBack(nav.code.USER_CREATION_FORM));
    setSearchCompleted(false);
  };

  const onPostEdition = () => {
    validate();
    setUsername(entry.username);
    const log = {
      username: entry.username,
      email: entry.email,
      rolId: rolesList.filter((e) => e.name === entry.rol)[0].id,
      paceCenters: entry.rol !== 'Admin' ? entry.paceCenters.map((e) => `Organization/${e.remoteId}`) : null,
    };
    if (errors.email === '') {
      dispatch(postUserEdition(userId, log, token));
    }
  };

  useEffect(() => {
    if (formError !== null && !successEdition) {
      setErrors({
        ...errors,
        email: formError,
      });
    }
  }, [formError]);

  return (
    <EntryWithLoading
      value={entry}
      onChangeValue={handleChangeEntry}
      searchOutcome={searchOutcome}
      totalOutcome={totalOutcome}
      filterValue={filterValue}
      onChangeFilterValue={handleFilterValue}
      onChangeSearchValue={handleSearchValue}
      paceCentersList={selectedPaceCentersList}
      handlePaceCentersList={setSelectedPaceCentersList}
      rolesList={rolesList}
      error={error}
      loading={loadingUserData}
      browserSettings={browserSettings}
      enableButton={enableButton}
      onClickSearch={handleSearch}
      onClickSearchButton={handleOnClickPaceCenters}
      onPagination={handlePagination}
      searchCompleted={searchCompleted}
      status={false}
      navigation={navigation}
      onClickDeleteElement={handleOnCLickDeleteElement}
      searchLoading={loading}
      pendingDataToPaginate={pendingDataToPaginate}
      onClickFooterButton={handleOnClickFooterButton}
      disableFooterButton={filterValue.length === 0}
      onPostEdition={onPostEdition}
      errors={errors}
      loadingEdition={loadingEdition}
      username={username}
      highlightText={highlightText}
      startPage={page}
      formError={formError}
      disableRole={disableRole}
    />
  );
};

export { UserEditionContainer as default };
