import { reportLimit } from '../../config/variables';

const nav = {
  code: {
    MENU: 0,
    PREVIEW_TABLE: 1,
    PACE_CENTERS_SEARCH: 2,
  },
};

const status = {
  PACECENTERS: 'Enter Center ID or name',
};

const report = {
  limit: reportLimit !== undefined ? reportLimit : 524288,
  format: {
    csv: 'CSV',
    pdf: 'PDF',
  },
};

const supplyDeliveryColumns = [
  { id: 'center', label: 'Center' },
  { id: 'itemType', label: 'Item Type' },
  { id: 'itemNumber', label: 'Item Number' },
  { id: 'creationDate', label: 'Creation Date (UTC)' },
  { id: 'patient', label: 'Patient' },
  { id: 'status', label: 'Status' },
  { id: 'custodian', label: 'Custodian/Person who delivered' },
  { id: 'deliveryDate', label: 'Delivered Date/Time (UTC)' },
  { id: 'signature', label: 'Signature' },
];

const supplyDeliveryColumnsMobile = [
  { id: 'center', label: 'Center' },
  { id: 'itemType', label: 'Item Type' },
  { id: 'itemNumber', label: 'Item Number' },
  { id: 'creationDate', label: 'Creation Date (UTC)' },
  { id: 'patient', label: 'Patient' },
  { id: 'status', label: 'Status' },
  { id: 'custodian', label: 'Custodian' },
  { id: 'deliveryDate', label: 'Delivered Date (UTC)' },
  { id: 'signature', label: 'Signature' },
];

const logsColumns = [
  { id: 'center', label: 'Center' },
  { id: 'itemType', label: 'Item Type' },
  { id: 'itemNumber', label: 'Item Number' },
  { id: 'eventDate', label: 'Event Date (UTC)' },
  { id: 'user', label: 'User' },
  { id: 'action', label: 'Action' },
];

export {
  nav, supplyDeliveryColumns, supplyDeliveryColumnsMobile, report, logsColumns, status,
};
