import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import SupplyItem from './SupplyItem';

const supplyBundleListStyle = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(0.625),
    flexGrow: 1,
  },
  contentBundle: {
    paddingTop: theme.spacing(2),
  },
  contentItem: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(0.625),
  },

}));

export default function SupplyBundleList({ list, SupplyBundleComponent }) {
  const styles = supplyBundleListStyle();
  const suppliesItems = list.map((supply, index) => {
    let content = null;
    const isLast = index === (list.length - 1);
    if (supply.isBundle) {
      content = <SupplyBundleComponent {...supply} />;
    } else {
      content = <SupplyItem {...supply} />;
    }
    return (
      <Box key={supply.code} display="flex" width={1}>
        <Box display="flex" flexDirection="column">
          <Box borderLeft={1} borderColor="secondary.main" height={supply.isBundle ? 28 : 23} />
          <Box
            borderTop={1}
            borderColor="secondary.main"
            width={9}
            borderLeft={isLast ? 0 : 1}
            flexGrow={1}
          />
        </Box>
        <Box display="flex" flexDirection="column" width={1} className={supply.isBundle ? styles.contentBundle : styles.contentItem}>
          {content}
        </Box>
      </Box>
    );
  });
  return (
    <Box display="flex" className={styles.container} flexDirection="column">
      {suppliesItems}
    </Box>
  );
}
