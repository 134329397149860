import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Entry from '../components/Dashboard';
import {
  resetState, getUsersList, addAlert, getUsersListByRole, setRoleToFilter,
} from '../actions/dashboard';
import { setNavigationStepbackButton } from '../actions/navigation';
import WithLoading from '../components/WithLoading';
import { mobileDetection } from '../services/utility';
import { useAuth0 } from '../auth0';

const EntryWithLoading = WithLoading(Entry);

const initialState = {
  searchValue: '',
};

const Dashboard = () => {
  const { userRole } = useSelector((state) => state.navigationState);
  const {
    error, isLoading, value, list, pageData, page, role,
    alerts, jobDescription, jobId, loadingGeneration,
    successGeneration, Admin, Manager, Common,
  } = useSelector((state) => state.dashboardState);
  const browserSettings = useState(mobileDetection(window.navigator));
  const { getTokenSilently } = useAuth0();
  const [token, setToken] = useState('');
  const [entry, setEntry] = useState(initialState);
  const [isSearching, setIsSearching] = useState(false);
  const [roleFiltered, setRoleFiltered] = useState(null);
  const [isFilterByRole, setIsFilterByRole] = useState(false);
  const [usersRequested, setUsersRequested] = useState(false);
  const navHistory = useHistory();
  const rolesList = {
    Admin,
    Manager,
    Common,
  };

  (async () => {
    setToken(await getTokenSilently());
  })();

  const dispatch = useDispatch();

  useEffect(() => {
    const currentPage = 'User Management';
    dispatch(setNavigationStepbackButton(currentPage));
    dispatch(resetState());
  }, []);

  useEffect(() => {
    if (userRole !== null) {
      (async () => {
        const token2 = await getTokenSilently();
        setUsersRequested(true);
        dispatch(getUsersList(value, page, token2));
      })();
    }
  },
  [userRole]);

  useEffect(() => {
    if (Admin.checked === false
      && Manager.checked === false
      && Common.checked === false
      && roleFiltered !== null) {
      setRoleFiltered(null);
      setIsFilterByRole(false);
      dispatch(getUsersList(value, 0, token));
      setUsersRequested(true);
    } else if (Admin.checked || Manager.checked || Common.checked) {
      setEntry(initialState);
      dispatch(getUsersListByRole(roleFiltered, 0, token));
    }
  },
  [Admin, Manager, Common]);

  const handlePagination = (pag) => {
    if (roleFiltered !== null) {
      dispatch(getUsersListByRole(roleFiltered, pag, token));
    } else {
      dispatch(getUsersList(entry.searchValue, pag, token));
    }
  };

  useEffect(() => {
    if ((entry.searchValue !== '' && role === null) || (entry.searchValue === '' && isSearching && role === null)) {
      setUsersRequested(true);
      setIsSearching(true);
      handlePagination(0);
    }
  }, [entry]);

  useEffect(() => {
    if (successGeneration && !loadingGeneration) {
      dispatch(addAlert(jobDescription));
    }
  }, [successGeneration, loadingGeneration]);

  const onClickTable = (userId) => {
    navHistory.push(`/settings/users/${userId}`);
  };

  const onClickNewUser = () => {
    navHistory.push('/settings/users/creation/single');
  };

  const onClickBulkUsers = () => {
    navHistory.push('/settings/users/creation/bulk');
  };

  const onClickJobsList = () => {
    navHistory.push('/settings/jobs');
  };

  const onClickUsersReportGeneration = () => {
    navHistory.push('/settings/users/report/generation');
  };

  const handleChangeEntry = (text) => {
    setIsFilterByRole(false);
    setRoleFiltered(null);
    setUsersRequested(false);
    dispatch(setRoleToFilter(null));
    if (entry.searchValue !== text) {
      setEntry({
        ...entry,
        searchValue: text,
      });
    }
  };

  const handleOnChangeRoleFilter = (roleAssigned) => {
    setUsersRequested(false);
    setRoleFiltered(roleAssigned);
    setIsFilterByRole(true);
    dispatch(setRoleToFilter(roleAssigned));
  };

  return (
    <>
      <EntryWithLoading
        error={error}
        loading={isLoading}
        page={page}
        list={list}
        pageData={pageData}
        handlePagination={handlePagination}
        browserSettings={browserSettings}
        onChangeValue={handleChangeEntry}
        value={entry}
        handleOnClickTable={onClickTable}
        handleOnCLickNewUser={onClickNewUser}
        handleOnClickBulkUsers={onClickBulkUsers}
        handleOnCLickJobsList={onClickJobsList}
        handleOnClickUsersReport={onClickUsersReportGeneration}
        alerts={alerts}
        jobDescription={jobDescription}
        jobId={jobId}
        rolesList={rolesList}
        onChangeRolesFilter={handleOnChangeRoleFilter}
        isFilterByRole={isFilterByRole}
        usersRequested={usersRequested}
      />
    </>
  );
};

export { Dashboard as default };
