import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '../../assets/Button';
import DispatchButton from '../../assets/DispatchButton';
import TextField from '../../assets/TextField';
import { status } from './constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
    color: 'primary.main',
  },
  formLabelFocused: {
  },
  buttonReport: {
    color: theme.palette.common.white,
    padding: theme.spacing(0.75, 2),
  },
  box: {
    width: '49.8%',
  },
  searchButton: {
    right: theme.spacing(-1),
    fontWeight: 'bold',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  textInput: {
    width: '98%',
    marginRight: theme.spacing(3),
    spacing: 2,
  },
  readAgreement: {
    margin: theme.spacing(3, 0, 4, 0),
    textAlign: 'justify',
  },
  inputRootLabel: {
    '&$disabled': {
      color: '#616161',
    },
  },
  inputRootText: {
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  disabled: {},
  buttonControl: {
    width: '100%',
  },
  boxButton: {
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    width: '100%',
    color: 'primary',
    alignContent: 'center',
    backgroundColor: theme.palette.secondary.light,
    disabled: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  boxFormControlLabel: {
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
}));

export default function UserGenerationForm({
  value, onChangeValue, onClickGenerateReport, onClickSearchButtonCenter,
  loadingReport, disablePaceCenterSearch, disableGenerateReport,
}) {
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" flexGrow={0.8}>
        <TextField
          disabled
          label="Center"
          variant="filled"
          value={value.paceCenter}
          name="paceCenter"
          autoComplete="false"
          helperText={status.PACECENTERS}
          InputLabelProps={{
            classes: {
              root: classes.inputRootLabel,
              disabled: classes.disabled,
            },
          }}
          inputProps={{
            classes: {
              root: classes.inputRootText,
              disabled: classes.disabled,
            },
          }}
          fullWidth
        />
        <Box
          className={classes.searchButton}
          justifyContent="center"
          flexGrow="0.2"
        >
          <Button
            color="secondary"
            onClick={onClickSearchButtonCenter}
            className={classes.searchButton}
            disabled={disablePaceCenterSearch}
          >
            {' '}
            SEARCH CENTER
          </Button>
        </Box>
      </Box>
      <br />
      <Grid container justifyContent="flex-start">
        <Box className={classes.boxButton}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={value.allValues}
                onChange={onChangeValue}
                inputProps={{ 'aria-label': 'controlled' }}
                name="allValues"
              />
            )}
            label="Please click on the checkbox if you want to retrieve the users for all centers"
          />
        </Box>
      </Grid>
      <br />
      <Box className={classes.boxFormControlLabel}>
        <DispatchButton
          className={classes.button}
          disableElevation
          onClick={onClickGenerateReport}
          variant="cofc"
          loading={loadingReport}
          label="Generate Report"
          disabled={disableGenerateReport}
        />
      </Box>
    </Box>
  );
}
