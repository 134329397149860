import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { useAuth0 } from '../auth0';

const PrivateRoute = ({
  component: Component, children, path, ...rest
}) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    // If the user is not authenticates, redirect to auth0 login page.
    loginWithRedirect({ appState: { targetUrl: path } });
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  if (children) {
    return (
      <Route path={path} {...rest}>
        {isAuthenticated && children}
      </Route>
    );
  }

  const render = (props) => {
    if (isAuthenticated) {
      return <Component {...props} />;
    }

    return null;
  };

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
