import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import FilterChip from './FilterChip';
import { ROLES_TYPES } from '../../config/constants';

const filterMenuStyle = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 0),
  },
  label: {
    marginTop: theme.spacing(3),
  },
  clearFilterButton: {
    marginBottom: theme.spacing(0.8),
    cursor: 'pointer',
    fontSize: theme.spacing(1.5),
  },
}));

export default function RolesMenu({ filters, onChangeFilter }) {
  const styles = filterMenuStyle();
  const filtersChips = Object.values(ROLES_TYPES)
    .filter((filter) => filters[filter] && filters[filter].show).map((filter) => {
      const properties = {};
      if (filter === ROLES_TYPES.ADMIN && filters[filter].checked) {
        properties.color = 'primary';
      } if (filter === ROLES_TYPES.MANAGER && filters[filter].checked) {
        properties.color = 'primary';
      } else if (filter === ROLES_TYPES.COMMON && filters[filter].checked) {
        properties.color = 'primary';
      }
      properties.text = filters[filter].label;
      return (
        <FilterChip
          key={filter}
          chipKey={filter}
          onChangeFilter={onChangeFilter}
          {...properties}
        />
      );
    });

  return (
    <Box className={styles.container}>
      <Box display="flex" flexWrap="wrap">
        {filtersChips}
      </Box>
    </Box>
  );
}
