import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import FooterButton from '../FooterButton';
import TextField from '../../assets/TextField';
import RadioButtonList from './RadioButtonList';
import WithLoading from '../WithLoading';
import { status } from './constants';
import { CHARACTER_LIMIT } from '../../config/constants';

const patientStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(4, 2.5, 12, 2.5),
  },
  fieldset: {
    border: 'none',
    padding: '0',
  },
  button: {
    right: theme.spacing(-1),
    fontWeight: 'bold',
  },
  selectedElements: {
    margin: theme.spacing(1),
  },
}));

const FiltersWithLoading = WithLoading(RadioButtonList);

export default function SearchPaceCenter({
  filterValue, onChangeFilterValue, loading, error, searchOutcome,
  searchValue, pendingDataToPaginate, onChangeSearchValue,
  onClickSearch, onPagination, disableFooterButton, searchCompleted,
  disableSearch, onClickFooter,
}) {
  const styles = patientStyles();
  const handleSearchValue = (event) => {
    event.stopPropagation();
    onChangeSearchValue(event.target.value);
  };
  const handleClickSearch = (event) => {
    event.stopPropagation();
    onClickSearch();
  };

  return (
    <>
      <Box className={styles.container} display="flex" flexGrow={1}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box display="flex" flexWrap="nowrap">
            <Box display="flex" flexGrow={0.8} component="fieldset" className={styles.fieldset}>
              <TextField
                label="Enter center ID or Name"
                variant="filled"
                value={searchValue}
                onChange={handleSearchValue}
                helperText={status.PACECENTERS}
                fullWidth
                inputProps={{ maxLength: CHARACTER_LIMIT.PACECENTER }}
              />
            </Box>
            <Box
              className={styles.searchButton}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow="0.2"
            >
              <Button
                color="secondary"
                onClick={handleClickSearch}
                className={styles.button}
                disabled={disableSearch}
              >
                SEARCH
              </Button>
            </Box>
          </Box>
          { searchOutcome
          && (
          <FiltersWithLoading
            loading={loading}
            error={error}
            list={searchOutcome}
            filterValue={filterValue}
            pendingDataToPaginate={pendingDataToPaginate}
            onPagination={onPagination}
            onChangeFilterValue={onChangeFilterValue}
            searchCompleted={searchCompleted}
            isListEmpty={false}
          />
          )}
        </Box>
      </Box>
      <FooterButton
        textLabel="APPLY"
        bgcolor={grey[50]}
        onClickFooterButton={onClickFooter}
        disableFooterButton={disableFooterButton}
      />
    </>
  );
}
