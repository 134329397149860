import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const TextField = (props) => {
  const theme = createTheme({
    palette: {
      error: {
        main: '#FF0C3E',
      },
    },
    overrides: {
      MuiFilledInput: {
        underline: {
          '&:before': {
            borderBottomColor: 'rgba(0,0,0,0)',
          },
          '&:after': {
            borderBottomColor: 'rgba(0,0,0,0)',
          },
          '&:hover:before': {
            borderBottomColor: 'rgba(0,0,0,0)',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: '#616161',
          zIndex: '0 !important',
          '&$focused': {
            color: '#616161',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MuiTextField {...props} />
    </ThemeProvider>
  );
};

export { TextField as default };
