import React from 'react';
import Box from '@material-ui/core/Box';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '../../assets/TextField';
import CheckboxList from './CheckboxList';
import WithLoading from '../WithLoading';
import FooterButton from '../FooterButton';
import { helperText } from './constants';

const paceCentersStyle = makeStyles((theme) => ({
  fieldset: {
    border: 'none',
    padding: '0',
  },
  button: {
    right: theme.spacing(-1),
    fontWeight: 'bold',
  },
  selectedElements: {
    margin: theme.spacing(1),
  },
  selectButon: {
    margin: theme.spacing(0.8, 0),
    cursor: 'pointer',
    fontSize: theme.spacing(1.7),
  },
  box: {
    display: 'flex',
    cursor: 'default',
  },
  spreadBox: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(4, 2.5, 12, 2.5),
  },
}));

const FiltersWithLoading = WithLoading(CheckboxList);

export default function PaceCentersSearch({
  filterValue, onChangeFilterValue, loading, error, searchOutcome,
  searchValue, matchSearchText, highlightText, pendingDataToPaginate, onChangeSearchValue,
  onClickSearch, onPagination, disableSearch, totalOutcome, onSearchCompleted,
  onClickFooterButton, disableFooterButton,
}) {
  const styles = paceCentersStyle();
  const handleSearchValue = (event) => {
    event.stopPropagation();
    onChangeSearchValue(event.target.value);
  };
  const handleClickSearch = (event) => {
    event.stopPropagation();
    onClickSearch();
  };

  const handleCheckElement = (element, checked) => {
    const tmpElements = [...filterValue];
    const elementIndex = tmpElements.indexOf(element);
    if (checked && elementIndex === -1) {
      tmpElements.push(element);
    } else {
      tmpElements.splice(elementIndex, 1);
    }
    onChangeFilterValue(tmpElements);
  };

  const handleClearAll = (event) => {
    event.stopPropagation();
    onChangeFilterValue([]);
  };

  return (
    <>
      <Box className={styles.container} display="flex" flexGrow={1}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box display="flex" flexWrap="nowrap">
            <Box display="flex" flexGrow={0.8} component="fieldset" className={styles.fieldset}>
              <TextField
                label="PaceCenter"
                variant="filled"
                value={searchValue}
                onChange={handleSearchValue}
                helperText={helperText.PACE_CENTERS_SEARCH}
                fullWidth
              />
            </Box>
            <Box
              className={styles.searchButton}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow="0.2"
            >
              <Button
                color="secondary"
                onClick={handleClickSearch}
                className={styles.button}
                disabled={disableSearch}
              >
                SEARCH
              </Button>
            </Box>
          </Box>
          {totalOutcome > 0 && onSearchCompleted
        && (
        <Box className={styles.selectedElements}>
          <Typography color="textSecondary" variant="body2" component="div">
            Total:
            {' '}
            {totalOutcome}
            {' '}
            {totalOutcome > 1 ? 'results' : 'result'}
          </Typography>
        </Box>
        )}
          {filterValue.length > 0
        && (
        <Box
          component="span"
          m={1}
          className={`${styles.spreadBox} ${styles.box}`}
        >
          <Box>
            <Typography color="textSecondary" variant="body2">
              You have
              {' '}
              {filterValue.length}
              {' '}
              {filterValue.length > 1 ? 'elements' : 'element'}
              {' '}
              selected
            </Typography>
          </Box>
          <Box display="flex">
            <Box
              className={styles.selectButon}
              color="primary.main"
              onClick={handleClearAll}
            >
              Clear all
            </Box>
          </Box>
        </Box>
        )}
          { searchOutcome
      && (
      <FiltersWithLoading
        loading={loading}
        error={error}
        list={searchOutcome}
        highlightText={highlightText}
        filterValue={filterValue}
        matchSearchText={matchSearchText}
        pendingDataToPaginate={pendingDataToPaginate}
        onCheckElement={handleCheckElement}
        onPagination={onPagination}
      />
      )}
        </Box>
      </Box>
      <FooterButton
        textLabel="SELECT"
        bgcolor={grey[50]}
        onClickFooterButton={onClickFooterButton}
        disableFooterButton={disableFooterButton}
      />
    </>
  );
}
