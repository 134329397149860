import * as actionTypes from '../action_types/custody';
import { postLog } from '../services/serviceClient';

const postLogToOrder = (log, token) => ({
  type: actionTypes.POST_LOGS,
  async payload() {
    const rawData = await postLog(log, token);
    return rawData;
  },
});

const resetState = () => ({
  type: actionTypes.POST_LOGS_RESET_STATE,
});

const resetStateOnError = (error) => ({
  type: actionTypes.POST_LOGS_RESET_ON_ERROR,
  payload: {
    error,
  },
});

const addAlert = (alert) => ({
  type: actionTypes.ADD_ALERT,
  payload: {
    alert,
  },
});

const removeAlert = (alert) => ({
  type: actionTypes.REMOVE_ALERT,
  payload: {
    alert,
  },
});

const cameraPermissionError = (error) => ({
  type: actionTypes.CAMERA_PERMISSION_ERROR,
  payload: {
    error,
  },
});

export {
  postLogToOrder, resetState, addAlert, removeAlert, resetStateOnError, cameraPermissionError,
};
