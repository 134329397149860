import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import Alert from '@material-ui/lab/Alert';
import TextField from '../../assets/TextField';
import BarcodeReader from '../BarcodeReader';
import { status, scan } from './constants';
import { CAMERA } from '../../config/constants';

const barcodeEntryStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 1.75),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(-2),
    },
  },
  tittle: {
    marginBottom: theme.spacing(2),
  },
  scanAgain: {
    padding: theme.spacing(0.75, 5),
    textTransform: 'uppercase',
    cursor: 'pointer',
    textDecoration: 'none',
    width: 'fit-content',
  },
  scanning: {
    width: 'inherit',
    boxSizing: 'content-box',
    minHeight: theme.spacing(25),
    [theme.breakpoints.up('xs')]: {
      minHeight: theme.spacing(32),
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      maxWidth: 'none',
      minHeight: theme.spacing(32),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
      minHeight: theme.spacing(40),
    },
  },
  marginTop: {
    marginTop: theme.spacing(7.5),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  container: {
    marginTop: '0px',
  },
  caption: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.black,
    opacity: '0.6',
  },
  marginTop: {
    marginTop: theme.spacing(7.5),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  inputRootLabel: {
    '&$disabled': {
      color: '#616161',
    },
  },
  inputRootText: {
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  disabled: {},
}));

export default function BarcodeEntry({
  value,
  isScanning,
  isLoading,
  error,
  errorMessage,
  isSuccess,
  onChangeValue,
  onIsScanning,
  onPostLogAutomatically,
  onPostLog,
  permissionError,
  onPlaySound,
}) {
  const classes = useStyles();
  const styles = barcodeEntryStyle();
  let textLabel = '';
  let scanBox = '';
  const [onError, setOnError] = useState(false);
  const [scanStyle, setScanStyle] = useState(true);

  const ExecutionOnPostLog = async () => {
    onPostLog()();
  };

  useEffect(() => {
    if (value) {
      if (isScanning) {
        ExecutionOnPostLog();
      }
    }
  }, [value]);

  useEffect(() => {
    if (error) {
      setOnError(true);
      setScanStyle(true);
      onPlaySound(scan.code.BAD_BARCODE);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setOnError(false);
      onIsScanning(false);
      setScanStyle(false);
      onPlaySound(scan.code.GOOD_BARCODE);
    }
  }, [isSuccess]);

  const handleChangeValue = (newValue) => {
    onChangeValue(newValue);
    onPostLogAutomatically(true);
  };
  const handleScanAgain = (event) => {
    event.stopPropagation();
    onIsScanning(true);
    setScanStyle(true);
  };
  if ((isScanning || onError) && !permissionError) {
    textLabel = 'Scanning';
    scanBox = (
      <BarcodeReader
        value={value}
        onChangeValue={handleChangeValue}
        isLoading={isLoading || isSuccess}
      />
    );
  } else if (permissionError) {
    scanBox = (
      <Box display="flex" justifyContent="center" width={1} className={styles.container}>
        <Alert severity="error">{CAMERA.ERROR.MESSAGE}</Alert>
      </Box>
    );
  } else if (!permissionError) {
    textLabel = 'Scanning finished';
    scanBox = (
      <Box
        borderRadius={20}
        className={styles.scanAgain}
        color="primary.main"
        bgcolor={grey[50]}
        onClick={handleScanAgain}
      >
        <Typography variant="button"> SCAN AGAIN </Typography>
      </Box>
    );
  }
  return (
    <>
      <Box className={styles.container} display="flex" flexGrow={1} flexDirection="column">
        <Box display="flex" flexGrow={1} justifyContent="center">
          <Typography variant="body1" component="div" className={scanStyle ? '' : styles.tittle}>
            {textLabel}
          </Typography>
        </Box>
        <Box display="flex" flexGrow={1} justifyContent="center" width={1}>
          <Box
            bgcolor={scanStyle ? '' : 'common.black'}
            className={styles.scanning}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {scanBox}
          </Box>
        </Box>
        {!permissionError
          && (
            <TextField
              disabled
              className={classes.marginTop}
              InputLabelProps={{
                classes: {
                  root: classes.inputRootLabel,
                  disabled: classes.disabled,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.inputRootText,
                  disabled: classes.disabled,
                },
              }}
              label="ID"
              variant="filled"
              value={value}
              autoComplete="false"
              helperText={error ? errorMessage : status.ENTER_ID}
              error={error}
              fullWidth
            />
          )}
      </Box>
    </>
  );
}
