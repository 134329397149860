import * as actionTypes from '../action_types/reports';
import {
  getRolReportValidation,
  getReport,
  getCSVReport,
  getPDFReport,
  getPaceCenters,
  generateUsersReport,
} from '../services/serviceClient';

const rolReportValidations = (token, isUserReport) => ({
  type: actionTypes.GET_ROL_REPORT_VALIDATION,
  async payload() {
    const rawData = await getRolReportValidation(token, isUserReport);
    return rawData;
  },
});

const resetState = () => ({
  type: actionTypes.REPORT_RESET_STATE,
});

const resetPaceCenterSearchState = () => ({
  type: actionTypes.PACECENTERS_SEARCH_RESET,
});

const handleGoBack = (navigation) => ({
  type: actionTypes.REPORT_HANDLE_GO_BACK,
  payload: {
    navigation,
  },
});

const getReportList = (page, token, reportType, queryParams = '') => ({
  type: actionTypes.GET_REPORT_LIST,
  async payload() {
    const rawData = await getReport(page, token, reportType, queryParams);
    return {
      pageData: rawData.pageData,
      list: rawData.supplyDeliveries !== undefined ? rawData.supplyDeliveries : rawData.logs,
      total: rawData.total,
      page,
    };
  },
});

const getReportAsCSV = (token, reportType, queryParams = '') => ({
  type: actionTypes.GET_REPORT_AS_CSV,
  async payload() {
    const rawData = await getCSVReport(token, reportType, queryParams);
    return { ...rawData };
  },
});

const getReportAsPDF = (token, reportType, queryParams = '') => ({
  type: actionTypes.GET_REPORT_AS_PDF,
  async payload() {
    const rawData = await getPDFReport(token, reportType, queryParams);
    return { ...rawData };
  },
});

const setReportParams = ({ params }) => ({
  type: actionTypes.SET_REPORT_PARAMS,
  payload: { ...params },
});

const getFilterPaceCenters = (keyword, page, token) => ({
  type: actionTypes.GET_FILTER_PACECENTERS,
  async payload() {
    const rawData = await getPaceCenters(keyword, page, token);

    const result = {
      list: [...rawData.organizations],
      pageData: {
        total: rawData.pageData.total,
        pageSize: 10,
      },
      page,
    };

    return result;
  },
});

const usersReportGeneration = (token, organizationId = '') => ({
  type: actionTypes.USERS_REPORT_GENERATION,
  async payload() {
    const rawData = await generateUsersReport(token, organizationId);
    return { jobId: rawData.jobId, description: rawData.description };
  },
});

const addAlert = (alert) => ({
  type: actionTypes.ADD_ALERT,
  payload: {
    alert,
  },
});

export {
  rolReportValidations, resetState, resetPaceCenterSearchState, handleGoBack,
  getReportList, getReportAsCSV, getReportAsPDF, setReportParams, getFilterPaceCenters,
  usersReportGeneration, addAlert,
};
