import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Report from '../components/Reports';
import { setNavigationStepbackButton } from '../actions/navigation';
import {
  rolReportValidations, handleGoBack,
  getReportList, setReportParams,
  getReportAsCSV, getReportAsPDF,
  getFilterPaceCenters, resetPaceCenterSearchState,
} from '../actions/reports';
import WithLoadingList from '../components/WithLoadingList';
import { mobileDetection } from '../services/utility';
import { nav } from '../components/Reports/constants';
import { useAuth0 } from '../auth0';
import { REPORT_TYPES } from '../config/constants';

const ReportWithLoading = WithLoadingList(Report);

const initialState = {
  reportType: '',
  paceCenter: '',
  paceCenterId: '',
  startDateRange: [],
  endDateRange: [],
};

const ReportContainer = () => {
  const [startDateRange, setStartDateRange] = useState(new Date());
  const [endDateRange, setEndDateRange] = useState(new Date());
  const [entry, setEntry] = useState(initialState);
  const [searchValue, handleSearchValue] = useState('');
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [filterValue, handleFilterValue] = useState('');
  const [disableFooterButton, handleDisableFooterButton] = useState(true);
  const [disablePreviewButton, handleDisablePreviewButton] = useState(false);
  const [disableCSVButton, handleDisableCSVButton] = useState(false);
  const [disablePDFButton, handleDisablePDFButton] = useState(false);
  const browserSettings = useState(mobileDetection(window.navigator));
  const {
    loading,
    error,
    loadingReport,
    navigation,
    page,
    pageData,
    list,
    queryParams,
    searchOutcome,
    isLoading,
    pendingDataToPaginate,
    loadingCSV,
    loadingPDF,
    downloadCompleted,
  } = useSelector((state) => state.reportState);
  const {
    isSuccessPaceCenter,
    paceCenters,
  } = useSelector((state) => state.trackableState);
  const { isAuthenticated, getTokenSilently } = useAuth0();
  const [generateReport, setGenerateReport] = useState(false);

  const [token, setToken] = useState('');
  const dispatch = useDispatch();

  (async () => {
    setToken(await getTokenSilently());
  })();

  useEffect(() => {
    dispatch(setNavigationStepbackButton('Reports'));
  }, []);

  useEffect(() => {
    setEntry({
      ...entry,
      startDateRange,
      endDateRange,
    });
  }, [startDateRange, endDateRange]);

  const getRolReportValidations = async () => {
    dispatch(rolReportValidations(await getTokenSilently(), false));
  };

  const handleGenerateReport = () => {
    const params = {
      reportType: entry.reportType,
      paceCenter: entry.paceCenterId,
      startDateRange: entry.startDateRange,
      endDateRange: entry.endDateRange,
    };
    dispatch(setReportParams({ params }));
  };

  useEffect(() => {
    if (queryParams.length > 0 && navigation === nav.code.MENU) {
      dispatch(getReportList(0, token, entry.reportType, queryParams));
    }
  }, [queryParams, generateReport, setGenerateReport]);

  useEffect(() => {
    if (list !== null) {
      setGenerateReport(true);
    } else {
      setGenerateReport(false);
    }
  }, [list]);

  const handleChangeEntry = (event) => {
    setEntry({
      ...entry,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnClickDownload = (format) => {
    if (format === REPORT_TYPES.FORMAT.CSV) {
      handleDisablePDFButton(true);
      handleDisablePreviewButton(true);
      dispatch(getReportAsCSV(token, entry.reportType, queryParams));
    } else {
      handleDisableCSVButton(true);
      handleDisablePreviewButton(true);
      dispatch(getReportAsPDF(token, entry.reportType, queryParams));
    }
  };

  const handleOnClickPreview = () => {
    dispatch(handleGoBack(nav.code.PREVIEW_TABLE));
  };

  const handleOnClickSearchButtonCenter = () => {
    dispatch(resetPaceCenterSearchState());
    handleSearchValue('');
    setSearchCompleted(false);
    handleFilterValue('');
    dispatch(handleGoBack(nav.code.PACE_CENTERS_SEARCH));
  };

  useEffect(() => {
    if (isAuthenticated && !loading) {
      (async () => {
        getRolReportValidations();
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (downloadCompleted) {
      handleDisablePreviewButton(false);
      handleDisablePDFButton(false);
      handleDisableCSVButton(false);
    }
  }, [downloadCompleted]);

  const handleStartDateRange = (event) => {
    setStartDateRange(event);
  };

  const handleEndDateRange = (event) => {
    setEndDateRange(event);
  };

  const handlePagination = (pag) => {
    dispatch(getReportList(pag, token, entry.reportType, queryParams));
  };

  const handleSearch = async () => {
    await dispatch(getFilterPaceCenters(searchValue, 0, token));
    setSearchCompleted(true);
  };

  const handleCentersPagination = (pag) => {
    dispatch(getFilterPaceCenters(searchValue, pag, token));
  };

  const handleOnClickFooter = () => {
    setEntry({
      ...entry,
      paceCenter: searchOutcome.filter((e) => e.id === filterValue)[0].name,
      paceCenterId: searchOutcome.filter((e) => e.id === filterValue)[0].id,
    });
    dispatch(handleGoBack(nav.code.MENU));
    setSearchCompleted(false);
  };

  useEffect(() => {
    if (filterValue !== '') { handleDisableFooterButton(false); } else { handleDisableFooterButton(true); }
  }, [filterValue]);

  return (
    <>
      {isSuccessPaceCenter && paceCenters.length > 0
      && (
        <ReportWithLoading
          value={entry}
          onChangeValue={handleChangeEntry}
          navigationValue={navigation}
          error={error}
          paceCenters={paceCenters}
          onClickGenerateReport={handleGenerateReport}
          handleStartDateRange={handleStartDateRange}
          handleEndDateRange={handleEndDateRange}
          browserSettings={browserSettings}
          loadingReport={loadingReport}
          onGenerateReport={generateReport}
          onClickDownload={handleOnClickDownload}
          onClickPreview={handleOnClickPreview}
          onClickSearchButtonCenter={handleOnClickSearchButtonCenter}
          page={page}
          list={list}
          pageData={pageData}
          handlePagination={handlePagination}
          searchValue={searchValue}
          onChangeSearchValue={handleSearchValue}
          onClickSearch={handleSearch}
          searchCompleted={searchCompleted}
          isLoading={isLoading}
          handleCentersPagination={handleCentersPagination}
          searchOutcome={searchOutcome}
          filterValue={filterValue}
          onChangeFilterValue={handleFilterValue}
          onClickFooter={handleOnClickFooter}
          pendingDataToPaginate={pendingDataToPaginate}
          disableFooterButton={disableFooterButton}
          loadingCSV={loadingCSV}
          loadingPDF={loadingPDF}
          disablePreviewButton={disablePreviewButton}
          disableCSVButton={disableCSVButton}
          disablePDFButton={disablePDFButton}
        />
      )}
    </>
  );
};

export { ReportContainer as default };
