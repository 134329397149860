import * as actionTypes from '../action_types/comments';
import { postComment } from '../services/serviceClient';

export const addCommentToOrder = (orderId, comment, token) => ({
  type: actionTypes.POST_COMMENT,
  async payload() {
    const rawData = await postComment(orderId, comment, token);
    return rawData;
  },
});

export const resetState = () => ({
  type: actionTypes.POST_COMMENT_RESET_STATE,
});
