import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import { removeAlert } from '../../actions/custody';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const AUTOREMOVE_TIMER = 10000;

const useStyles = makeStyles((theme) => ({
  goToLog: {
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'undeline',
  },
}));

const AlertWrap = ({ element }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(removeAlert(element));
    }, AUTOREMOVE_TIMER);
  }, []);

  const goToLog = () => {
    history.replace(`/orders/${element}/logs`);
  };
  return (
    <Box mt={1}>
      <Alert severity="success">
        {`${element} is in your custody - `}
        <a className={classes.goToLog} onClick={goToLog}>
          View
        </a>
      </Alert>
    </Box>
  );
};

export { AlertWrap as default };
