import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { grey } from '@material-ui/core/colors';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  DELIVERED, IN_PROGRESS, PACKAGED, ARCHIVED, LOG_TYPES, TRACKABLE_ITEM,
} from '../config/constants';

const statusMap = {
  [PACKAGED]: 'pending',
  [IN_PROGRESS]: 'pending',
  [DELIVERED]: 'delivered',
  [ARCHIVED]: 'archived',
};

const statusBarStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.1, 1.5),
  },
  status: {
    textTransform: 'uppercase',
  },
  code: {
    marginLeft: theme.spacing(1.25),
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  statusTrackableItem: {
    textTransform: 'uppercase',
    color: TRACKABLE_ITEM.COLOR.MAIN,
  },
  trackableItemContainer: {
    padding: theme.spacing(0.1, 1.5),
    backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
  },
  packageStyle: {
    width: '200px',
    [theme.breakpoints.up(370)]: {
      width: '250px',
    },
    [theme.breakpoints.up(530)]: {
      width: '400px',
    },
    [theme.breakpoints.up(600)]: {
      minWidth: '500px',
    },
  },
}));

export default function StatusBar({
  code, status, type, manuallyCreated,
}) {
  const styles = statusBarStyle();
  let icon = <FiberManualRecordIcon style={{ fontSize: 20 }} />;
  let statusStyle = '';
  const theme = useTheme();

  if (type !== LOG_TYPES.PACKAGE) {
    statusStyle = 'secondary.main';
  } else {
    statusStyle = 'primary.main';
  }

  if (status === DELIVERED) {
    icon = <FiberManualRecordOutlinedIcon style={{ fontSize: 20 }} />;
    statusStyle = grey[600];
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      className={manuallyCreated && status !== DELIVERED
        ? styles.trackableItemContainer : styles.container}
      color="common.white"
      bgcolor={statusStyle}
      height={`${theme.spacing(4.25)}px`}
    >
      <Box display="flex" flexGrow={1} alignItems="center">
        <Box display="flex">
          {icon}
        </Box>
        <Typography variant="body2" className={styles.packageStyle} noWrap>
          <Box fontFamily="PT Mono" className={styles.code} component="span">
            {type}
            -
            {code}
          </Box>
        </Typography>
      </Box>
      {
        type === LOG_TYPES.PACKAGE
        && (
        <Box
          color={statusStyle}
          bgcolor="common.white"
          p={`${theme.spacing(0.375)}px`}
          width={`${theme.spacing(9.375)}px`}
          borderRadius={`${theme.spacing(0.25)}px`}
          fontSize="0.75rem"
          height={`${theme.spacing(2.5)}px`}
        >
          <Typography variant="inherit" align="center" component="div">
            <span className={manuallyCreated && status !== DELIVERED
              ? styles.statusTrackableItem : styles.status}
            >
              {statusMap[status]}
            </span>
          </Typography>
        </Box>
        )
    }
    </Box>
  );
}
