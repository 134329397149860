import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SignatureCanvas from 'react-signature-canvas';
import { Box, Typography } from '@material-ui/core';
import { TRACKABLE_ITEM } from '../../config/constants';

const signatureStyle = makeStyles((theme) => ({
  container: {
    bottom: 0,
    padding: theme.spacing(1.2, 1.5, 2.5, 1.5),
    position: 'fixed',
  },
  signPad: {
    width: '100%',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      height: 150,
    },
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
  },
  cancelButton: {
    padding: theme.spacing(0.2, 2),
    textTransform: 'uppercase',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  cancelTrackableItem: {
    padding: theme.spacing(0.2, 2),
    textTransform: 'uppercase',
    cursor: 'pointer',
    textDecoration: 'none',
    color: TRACKABLE_ITEM.COLOR.MAIN,
  },
  box: {
    display: 'flex',
  },
  spreadBox: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function DigitalSignature({
  manuallyCreated, signatureRef,
  onSaveSignature, onClearSignature,
}) {
  const styles = signatureStyle();

  return (
    <>
      <Box>
        <Box
          component="span"
          m={1}
          className={`${styles.spreadBox} ${styles.box}`}
        >
          <Box>
            <Typography>Signature (optional)</Typography>
          </Box>
          <Box
            borderRadius={20}
            color="primary.main"
            onClick={onClearSignature}
            className={manuallyCreated ? styles.cancelTrackableItem : styles.cancelButton}
          >
            <Typography
              variant="button"
            >
              CLEAR
            </Typography>
          </Box>
        </Box>
        <SignatureCanvas
          canvasProps={{
            className:
            styles.signPad,
            style: { border: '1px solid #000000' },
          }}
          minWidth={0.5}
          maxWidth={2.5}
          ref={signatureRef}
          onEnd={() => (
            onSaveSignature()
          )}
        />
      </Box>
    </>
  );
}
