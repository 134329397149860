import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import NavigationContainer from './containers/NavigationContainer';
import SuppliesListContainer from './containers/SuppliesListContainer';
import SupplyDeliveryContainer from './containers/SupplyDeliveryContainer';
import CommentContainer from './containers/CommentContainer';
import DeliverPackageContainer from './containers/DeliverPackageContainer';
import TakeCustodyContainer from './containers/TakeCustodyContainer';
import FiltersMenuContainer from './containers/FiltersMenuContainer';
import TrackableItemContainer from './containers/TrackableItemContainer';
import DashboardContainer from './containers/DashboardContainer';
import ReportContainer from './containers/ReportContainer';
import UserDetailContainer from './containers/UserDetailContainer';
import UserCreationContainer from './containers/UserCreationContainer';
import UserEditionContainer from './containers/UserEditionContainer';
import JobsContainer from './containers/JobsContainer';
import JobDetailContainer from './containers/JobDetailContainer';
import UserReportContainer from './containers/UserReportContainer';
import Page404 from './components/Page404';
import theme from './theme';
import PrivateRoute from './components/PrivateRoute';
import { auth0Domain, auth0ClientId, auth0Audience } from './config/variables';
import { AUTH0_SCOPE } from './config/constants';
import { Auth0Provider } from './auth0';

function App({loginDomain, loginClient, loginAudience, loginType}) {
  return (
    <Router>
      <Auth0Provider
        domain={loginDomain}
        client_id={loginClient}
        audience={loginAudience}
        login_type={loginType}
        scope={AUTH0_SCOPE}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NavigationContainer>
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <Switch>
                  <PrivateRoute exact path="/">
                    <SuppliesListContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/eirenerx">
                  </PrivateRoute>
                  <PrivateRoute exact path="/external">
                  </PrivateRoute>
                  <PrivateRoute exact path="/filter">
                    <FiltersMenuContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/orders/:orderId/logs">
                    <SupplyDeliveryContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/orders/:orderId/deliver">
                    <DeliverPackageContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/orders/:orderId/comment">
                    <CommentContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/orders/:orderId/edit">
                    <TrackableItemContainer editMode />
                  </PrivateRoute>
                  <PrivateRoute exact path="/take_custody">
                    <TakeCustodyContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/add_trackable_item">
                    <TrackableItemContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/report">
                    <ReportContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/settings/users">
                    <DashboardContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/settings/users/:userId">
                    <UserDetailContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/settings/users/:userId/edit">
                    <UserEditionContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/settings/users/creation/single">
                    <UserCreationContainer bulk={false} />
                  </PrivateRoute>
                  <PrivateRoute exact path="/settings/users/creation/bulk">
                    <UserCreationContainer bulk />
                  </PrivateRoute>
                  <PrivateRoute exact path="/settings/users/report/generation">
                    <UserReportContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/settings/jobs">
                    <JobsContainer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/settings/jobs/:jobId">
                    <JobDetailContainer />
                  </PrivateRoute>
                  <Route>
                    <Page404 />
                  </Route>
                </Switch>
              </Box>
            </Box>
          </NavigationContainer>
        </ThemeProvider>
      </Auth0Provider>
    </Router>
  );
}
export default App;
