import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import UserEditionForm from './UserEditionForm';
import { nav } from './constants';
import PaceCentersSearch from './PaceCentersSearch';

const dashboardStyle = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2.5),
  },
  attest: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  canvas: {
    margin: theme.spacing(0, 0, 7, 0),
  },
}));

export default function UserEdition({
  value,
  onChangeValue,
  paceCentersList,
  rolesList,
  error,
  loading,
  browserSettings,
  formError,
  enableButton,
  onClickSearch,
  onClickSearchButton,
  onPagination,
  searchCompleted,
  navigation,
  searchLoading,
  onChangeSearchValue,
  searchOutcome,
  totalOutcome,
  filterValue,
  onChangeFilterValue,
  pendingDataToPaginate,
  onClickFooterButton,
  disableFooterButton,
  onClickDeleteElement,
  onPostEdition,
  errors,
  loadingEdition,
  highlightText,
  startPage,
  disableRole,
}) {
  const styles = dashboardStyle();

  return (
    <>
      <Box>
        {!error && navigation === nav.code.USER_EDITION_FORM && !loading
          && (
          <Container disableGutters>
            <Box className={styles.container}>
              <UserEditionForm
                value={value}
                onChangeValue={onChangeValue}
                paceCentersList={paceCentersList}
                rolesList={rolesList}
                loading={loading}
                browserSettings={browserSettings}
                formError={formError}
                enableButton={enableButton}
                onClickSearchButton={onClickSearchButton}
                onPagination={onPagination}
                searchCompleted={searchCompleted}
                onClickDeleteElement={onClickDeleteElement}
                onPostEdition={onPostEdition}
                errors={errors}
                loadingEdition={loadingEdition}
                disableRole={disableRole}
              />
            </Box>
          </Container>
          )}
        <>
          { !error && navigation === nav.code.PACE_CENTERS_SEARCH && !loading
          && (
          <PaceCentersSearch
            value={value}
            onChangeValue={onChangeValue}
            onChangeSearchValue={onChangeSearchValue}
            searchOutcome={searchOutcome}
            totalOutcome={totalOutcome}
            filterValue={filterValue}
            onChangeFilterValue={onChangeFilterValue}
            paceCentersList={paceCentersList}
            loading={searchLoading}
            browserSettings={browserSettings}
            formError={formError}
            enableButton={enableButton}
            onClickSearch={onClickSearch}
            onPagination={onPagination}
            searchCompleted={searchCompleted}
            pendingDataToPaginate={pendingDataToPaginate}
            onClickFooterButton={onClickFooterButton}
            disableFooterButton={disableFooterButton}
            highlightText={highlightText}
            startPage={startPage}
          />
          )}
        </>
      </Box>
    </>
  );
}
