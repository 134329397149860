import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import lightGreen from '@material-ui/core/colors/lightGreen';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroller';
import Checkbox from '../../assets/Checkbox';

const checkboxStyle = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(4, 2.5),
    color: 'black !important',
  },
  boderBottom: {
    borderBottom: `${theme.spacing(0.125)}px solid black`,
  },
  selectButon: {
    margin: theme.spacing(0.8, 0),
    cursor: 'pointer',
    fontSize: theme.spacing(1.7),
  },
  marginTop: {
    marginTop: theme.spacing(1.7),
  },
}));

export default function CheckboxList({
  list,
  filterValue,
  matchSearchText,
  highLightText,
  pendingDataToPaginate,
  onCheckElement,
  onPagination,
  startPage,
}) {
  const styles = checkboxStyle();
  const handleCheckElement = (event) => {
    event.stopPropagation();
    onCheckElement(event.target.name, event.target.checked);
  };

  const hightlightLabel = (text) => {
    if (matchSearchText && highLightText && text.includes(highLightText)) {
      const label = text.replace(highLightText, `|${highLightText}|`);
      const splitText = label.split('|');
      return splitText.map((subtext) => {
        if (subtext === highLightText) {
          return (
            <Box bgcolor={lightGreen[100]} component="span">
              {subtext}
            </Box>
          );
        }
        return subtext;
      });
    }
    return text;
  };

  const checkboxElements = list.map((element, index) => {
    const isLast = index === list.length - 1;
    const label = element.name ? element.name : String(element.id);

    return (
      <Box key={element.id}>
        <FormControlLabel
          control={(
            <Checkbox
              name={String(element.id)}
              checked={filterValue.includes(String(element.id))}
              onChange={handleCheckElement}
              color="primary"
            />
          )}
          label={(
            <Typography variant="subtitle2">
              {hightlightLabel(label)}
            </Typography>
          )}
        />
        {!isLast && (
          <Box className={styles.boderBottom} display="flex" flexGrow={1} />
        )}
      </Box>
    );
  });

  return (
    <Box component={FormControl} display="flex" flexGrow={1}>
      <Box className={filterValue.length === 0 ? styles.marginTop : ''}>
        <InfiniteScroll
          pageStart={startPage}
          loadMore={onPagination}
          hasMore={pendingDataToPaginate}
        >
          {checkboxElements}
        </InfiniteScroll>
      </Box>
    </Box>
  );
}
