import React from 'react';
import { Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { status } from './constants';
import Button from '../../assets/Button';
import DispatchButton from '../../assets/DispatchButton';
import TextField from '../../assets/TextField';
import { TRACKABLE_ITEM, CHARACTER_LIMIT } from '../../config/constants';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    width: '100%',
    color: 'primary',
    backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
    '&:hover': {
      backgroundColor: TRACKABLE_ITEM.COLOR.HOVER,
      '@media (hover: none)': {
        backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
      },
    },
  },
  secondaryButton: {
    marginTop: theme.spacing(3),
    width: '100%',
    color: 'black',
    backgroundColor: TRACKABLE_ITEM.COLOR.SECONDARY,
    '&:hover': {
      backgroundColor: TRACKABLE_ITEM.COLOR.SECONDARY_HOVER,
      '@media (hover: none)': {
        backgroundColor: TRACKABLE_ITEM.COLOR.SECONDARY,
      },
    },
  },
  searchButton: {
    right: theme.spacing(-1),
    fontWeight: 'bold',
  },
  fieldset: {
    border: 'none',
    padding: '0',
  },
  container: {
    marginTop: '40px',
  },
  caption: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.black,
    opacity: '0.6',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  textInput: {
    width: '98%',
    marginRight: theme.spacing(3),
    spacing: 2,
  },
  readAgreement: {
    margin: theme.spacing(3, 0, 4, 0),
    textAlign: 'justify',
  },
  inputRootLabel: {
    '&$disabled': {
      color: '#616161',
    },
  },
  inputRootText: {
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  disabled: {},
  buttonControl: {
    width: '100%',
  },
}));

const TrackableItemForm = ({
  value, onChangeValue, error, onPostTrackableItem,
  errorMessage, enableButton, isLoading, onClickScan, onClickPatient,
  onEditionMode, onClickCancelEdition, onClickSearchButtonCenter,
  disableSearchCenter,
}) => {
  const classes = useStyles();
  const handleChangeValue = (event) => {
    event.stopPropagation();
    onChangeValue(event);
  };

  const buttons = (

    onEditionMode === true
      ? (
        <Box display="flex" justifyContent="space-between">
          <Button
            className={classes.secondaryButton}
            disabled={enableButton || isLoading}
            disableElevation
            onClick={onClickCancelEdition()}
            variant="cofc"
          >
            CANCEL
          </Button>
          <DispatchButton
            className={classes.button}
            disabled={enableButton || isLoading}
            disableElevation
            onClick={onPostTrackableItem()}
            variant="cofc"
            loading={isLoading}
            label="SAVE ITEM"
          />
        </Box>
      )
      : (
        <Box>
          <DispatchButton
            className={classes.button}
            disabled={enableButton || isLoading}
            disableElevation
            onClick={onPostTrackableItem()}
            variant="cofc"
            loading={isLoading}
            label="ADD NEW ITEM"
          />
        </Box>
      )
  );

  return (
    <Box>
      <Container className={classes.container}>
        <Box>
          <Box display="flex" flexGrow={0.8}>
            <TextField
              label="Item ID"
              variant="filled"
              value={value.itemId}
              name="itemId"
              onChange={handleChangeValue}
              autoComplete="false"
              helperText={error ? errorMessage : status.ITEM_ID}
              error={error}
              fullWidth
              inputProps={{ maxLength: CHARACTER_LIMIT.ITEM }}
            />
            <Box
              className={classes.searchButton}
              justifyContent="center"
              flexGrow="0.2"
            >
              <Button color="secondary" onClick={onClickScan} className={classes.searchButton}>  BARCODE SCAN </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <br />
        </Box>
        <Box>
          <TextField
            label="Description"
            variant="filled"
            value={value.description}
            name="description"
            onChange={handleChangeValue}
            autoComplete="false"
            helperText={status.DESCRIPTION}
            fullWidth
            inputProps={{ maxLength: CHARACTER_LIMIT.DESCRIPTION }}
          />
        </Box>
        <Box>
          <br />
        </Box>
        <Box display="flex" flexGrow={0.8}>
          <TextField
            disabled
            label="Center"
            variant="filled"
            value={value.paceCenter}
            name="paceCenter"
            autoComplete="false"
            helperText={status.PACECENTER}
            InputLabelProps={{
              classes: {
                root: classes.inputRootLabel,
                disabled: classes.disabled,
              },
            }}
            inputProps={{
              classes: {
                root: classes.inputRootText,
                disabled: classes.disabled,
              },
            }}
            fullWidth
          />
          <Box
            className={classes.searchButton}
            justifyContent="center"
            flexGrow="0.2"
          >
            <Button
              color="secondary"
              onClick={onClickSearchButtonCenter}
              className={classes.searchButton}
              disabled={disableSearchCenter}
            >
              {' '}
              SEARCH CENTER
            </Button>
          </Box>
        </Box>
        <Box>
          <br />
        </Box>
        <Box>
          <Box display="flex" flexGrow={0.8}>
            <TextField
              disabled
              label="Patient Name"
              variant="filled"
              value={value.patientName}
              name="patientName"
              onChange={handleChangeValue}
              autoComplete="false"
              helperText={status.PATIENT}
              InputLabelProps={{
                classes: {
                  root: classes.inputRootLabel,
                  disabled: classes.disabled,
                },
              }}
              inputProps={{
                classes: {
                  root: classes.inputRootText,
                  disabled: classes.disabled,
                },
                maxLength: CHARACTER_LIMIT.PATIENT_NAME,
              }}
              fullWidth
            />
            <Box
              className={classes.searchButton}
              justifyContent="center"
              flexGrow="0.2"
            >
              <Button
                color="secondary"
                onClick={onClickPatient}
                className={classes.searchButton}
              >
                {' '}
                SEARCH PATIENT
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <br />
        </Box>
        <Box>
          <TextField
            label="Delivery Address"
            variant="filled"
            value={value.patientName !== '' ? value.deliveryLine : ''}
            disabled={value.patientName === ''}
            name="deliveryLine"
            onChange={handleChangeValue}
            autoComplete="false"
            helperText={status.ADDRESS}
            fullWidth
            inputProps={{ maxLength: CHARACTER_LIMIT.ADDRESS }}
          />
        </Box>
        <Box>
          <br />
        </Box>
        <Box>
          <TextField
            label="City"
            variant="filled"
            value={value.patientName !== '' ? value.deliveryCity : ''}
            disabled={value.patientName === ''}
            name="deliveryCity"
            onChange={handleChangeValue}
            autoComplete="false"
            helperText={status.CITY}
            fullWidth
            inputProps={{ maxLength: CHARACTER_LIMIT.CITY }}
          />
        </Box>
        <Box>
          <br />
        </Box>
        <Box display="flex" justifyContent="center">
          <TextField
            label="State"
            variant="filled"
            value={value.patientName !== '' ? value.deliveryState : ''}
            disabled={value.patientName === ''}
            name="deliveryState"
            onChange={handleChangeValue}
            autoComplete="false"
            helperText={status.STATE}
            fullWidth
            inputProps={{
              classes: {
                root: classes.textInput,
              },
              maxLength: CHARACTER_LIMIT.STATE,
            }}
          />
          <TextField
            label="Postal Code"
            variant="filled"
            value={value.patientName !== '' ? value.deliveryPostalCode : ''}
            disabled={value.patientName === ''}
            name="deliveryPostalCode"
            onChange={handleChangeValue}
            autoComplete="false"
            helperText={status.POSTAL_CODE}
            fullWidth
            inputProps={{ maxLength: CHARACTER_LIMIT.POSTAL_CODE }}
          />
        </Box>
        <Box>
          <br />
        </Box>
        <Box>
          {buttons}
        </Box>
      </Container>
    </Box>
  );
};

export { TrackableItemForm as default };
