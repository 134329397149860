export const POST_TRACKABLE_ITEM = 'POST_TRACKABLE_ITEM';
export const POST_TRACKABLE_ITEM_PENDING = 'POST_TRACKABLE_ITEM_PENDING';
export const POST_TRACKABLE_ITEM_FULFILLED = 'POST_TRACKABLE_ITEM_FULFILLED';
export const POST_TRACKABLE_ITEM_REJECTED = 'POST_TRACKABLE_ITEM_REJECTED';

export const POST_TRACKABLE_ITEM_RESET_STATE = 'POST_TRACKABLE_ITEM_RESET_STATE';
export const POST_TRACKABLE_ITEM_RESET_ON_ERROR = 'POST_TRACKABLE_RESET_ON_ERROR';

export const GET_TRACKABLE_PACE_CENTERS = 'GET_TRACKABLE_PACE_CENTERS';
export const GET_TRACKABLE_PACE_CENTERS_PENDING = 'GET_TRACKABLE_PACE_CENTERS_PENDING';
export const GET_TRACKABLE_PACE_CENTERS_FULFILLED = 'GET_TRACKABLE_PACE_CENTERS_FULFILLED';
export const GET_TRACKABLE_PACE_CENTERS_REJECTED = 'GET_TRACKABLE_PACE_CENTERS_REJECTED';

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';

export const TRACKABLE_ITEM_RESET_STATE = 'TRACKABLE_ITEM_RESET_STATE';

export const TRACKABLE_ITEM_HANDLE_GO_BACK = 'TRACKABLE_ITEM_HANDLE_GO_BACK';

export const GET_FILTER_PATIENTS = 'GET_FILTER_PATIENTS';
export const GET_FILTER_PATIENTS_PENDING = 'GET_FILTER_PATIENTS_PENDING';
export const GET_FILTER_PATIENTS_FULFILLED = 'GET_FILTER_PATIENTS_FULFILLED';
export const GET_FILTER_PATIENTS_REJECTED = 'GET_FILTER_PATIENTS_REJECTED';

export const POST_EDIT_TRACKABLE_ITEM = 'POST_EDIT_TRACKABLE_ITEM';
export const POST_EDIT_TRACKABLE_ITEM_PENDING = 'POST_EDIT_TRACKABLE_ITEM_PENDING';
export const POST_EDIT_TRACKABLE_ITEM_FULFILLED = 'POST_EDIT_TRACKABLE_ITEM_FULFILLED';
export const POST_EDIT_TRACKABLE_ITEM_REJECTED = 'POST_EDIT_TRACKABLE_ITEM_REJECTED';

export const GET_FILTER_PACECENTERS = 'GET_FILTER_PACECENTERS';
export const GET_FILTER_PACECENTERS_PENDING = 'GET_FILTER_PACECENTERS_PENDING';
export const GET_FILTER_PACECENTERS_FULFILLED = 'GET_FILTER_PACECENTERS_FULFILLED';
export const GET_FILTER_PACECENTERS_REJECTED = 'GET_FILTER_PACECENTERS_REJECTED';
