import React from 'react';
import { SvgIcon } from '@material-ui/core';

const BarcodeIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M0 15.1428V0.857117H0.703125V15.1428H0ZM1.0491 15.1327V0.857117H1.40625V15.1327H1.0491ZM2.10938 15.1327V0.857117H2.45535V15.1327H2.10938ZM3.8616 15.1327V0.857117H4.20758V15.1327H3.8616ZM5.26785 15.1327V0.857117H5.9598V15.1327H5.26785ZM7.02008 15.1327V0.857117H7.36605V15.1327H7.02008ZM7.7232 15.1327V0.857117H8.06918V15.1327H7.7232ZM8.42633 15.1327V0.857117H8.7723V15.1327H8.42633ZM9.82145 15.1327V0.857117H10.5246V15.1327H9.82145ZM11.5737 15.1327V0.857117H12.2768V15.1327H11.5737ZM12.9799 15.1327V0.857117H13.683V15.1327H12.9799ZM14.3862 15.1327V0.857117H15.0893V15.1327H14.3862ZM15.4353 15.1327V0.857117H16.1384V15.1327H15.4353ZM17.1987 15.1327V0.857117H18.2478V15.1327H17.1987ZM18.5938 15.1327V0.857117H18.9509V15.1327H18.5938ZM19.2969 15.1428V0.857117H20V15.1428H19.2969Z" fillOpacity="1" />
  </SvgIcon>
);

export { BarcodeIcon as default };
