import * as actionTypes from '../action_types/logs';
import getLogsByOrderId from '../services/serviceClient';

const getLogsByOrder = (orderId, token) => ({
  type: actionTypes.GET_LOGS,
  async payload() {
    const rawData = await getLogsByOrderId(orderId, token);
    return rawData;
  },
});

export const resetState = () => ({
  type: actionTypes.LOGS_RESET_STATE,
});

export default getLogsByOrder;
