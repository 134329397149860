import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '200px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const Button = (muiButtonProps) => {
  const classes = useStyles();
  const { variant } = muiButtonProps;
  if (variant === 'cofc') {
    const props = {
      ...muiButtonProps,
      className: classes.root,
      variant: null,
    };
    if (muiButtonProps.className) {
      props.className = `${classes.root} ${muiButtonProps.className}`;
    }
    return (
      <MuiButton {...props} />
    );
  }
  return <MuiButton {...muiButtonProps} />;
};

export { Button as default };
