import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/jobs';
import { JOB_TYPES } from '../config/constants';

const initialState = {
  jobId: null,
  list: [],
  isLoading: false,
  error: null,
  pendingDataToPaginate: false,
  page: 0,
  pageData: null,
  isSuccess: false,
  navigation: 0,
  jobData: [],
  errorDetails: [],
  queryParams: '',
  [JOB_TYPES.USERS_IMPORT]: {
    show: true,
    checked: false,
    label: 'Users Import',
  },
  [JOB_TYPES.USERS_EXPORT]: {
    show: true,
    checked: false,
    label: 'Users Export',
  },
  findByFilter: false,
  loadingReport: false,
  isSuccessReport: false,
};

const setFilter = (state, actualJobType, jobType) => {
  if (actualJobType !== jobType) {
    return initialState[actualJobType];
  }

  return {
    show: state[jobType].show,
    checked: !state[jobType].checked,
    label: state[jobType].label,
  };
};

const setQueryParams = (state) => {
  const queryParams = [];
  if (state[JOB_TYPES.USERS_IMPORT].checked) {
    queryParams.push('jobType=users_import');
  }
  if (state[JOB_TYPES.USERS_EXPORT].checked) {
    queryParams.push('jobType=users_export');
  }
  return queryParams.join('&');
};

const resetState = (state) => updateObject(state, {
  jobId: null,
  list: [],
  isLoading: false,
  error: null,
  pendingDataToPaginate: false,
  page: 0,
  pageData: null,
  isSuccess: false,
  navigation: 0,
  queryParams: '',
  [JOB_TYPES.USERS_IMPORT]: {
    show: true,
    checked: false,
    label: 'Users Import',
  },
  [JOB_TYPES.USERS_EXPORT]: {
    show: true,
    checked: false,
    label: 'Users Export',
  },
  findByFilter: false,
});

const resetJobDetailState = (state) => updateObject(state, {
  error: null,
  jobData: [],
  errorDetails: [],
  isLoading: false,
  isSuccess: false,
  loadingReport: false,
});

const requestJobs = (state) => updateObject(state, {
  isLoading: true,
});

const getJobs = (state, action) => updateObject(state, {
  list: action.payload.list,
  error: null,
  page: action.payload.page,
  pageData: action.payload.pageData,
  isLoading: false,
  isSuccess: true,
});

const requestFailed = (state, action) => updateObject(state, {
  isLoading: false,
  isSuccess: false,
  error: action.payload.response.data.error.details,
});

const requestJobById = (state) => updateObject(state, {
  ...state,
  loadingReport: true,
});

const getJobById = (state, action) => updateObject(state, {
  error: null,
  jobData: action.payload.job,
  errorDetails: action.payload.details,
  loadingReport: false,
  isSuccess: true,
});

const handleGoBack = (state, action) => updateObject(state,
  { navigation: action.payload.navigation });

const setJobType = (state, action) => {
  const tmpState = updateObject(state, {
    [JOB_TYPES.USERS_IMPORT]: setFilter(state, JOB_TYPES.USERS_IMPORT, action.payload.jobType),
    [JOB_TYPES.USERS_EXPORT]: setFilter(state, JOB_TYPES.USERS_EXPORT, action.payload.jobType),
  });
  return updateObject(tmpState, {
    queryParams: setQueryParams(tmpState),
    findByFilter: true,
  });
};

const requestUsersReport = (state) => updateObject(state, {
  loadingReport: true,
  isSuccessReport: false,
});

const usersReport = (state) => updateObject(state, {
  error: null,
  loadingReport: false,
  isSuccessReport: true,
});

export default createReducer(initialState, {
  [actionTypes.JOBS_RESET_STATE]: resetState,
  [actionTypes.JOB_DETAIL_RESET_STATE]: resetJobDetailState,
  [actionTypes.GET_JOBS_PENDING]: requestJobs,
  [actionTypes.GET_JOBS_FULFILLED]: getJobs,
  [actionTypes.GET_JOBS_REJECTED]: requestFailed,
  [actionTypes.GET_JOB_DETAIL_PENDING]: requestJobById,
  [actionTypes.GET_JOB_DETAIL_FULFILLED]: getJobById,
  [actionTypes.GET_JOB_DETAIL_REJECTED]: requestFailed,
  [actionTypes.JOBS_HANDLE_GO_BACK]: handleGoBack,
  [actionTypes.SET_JOB_TYPE_LIST]: setJobType,
  [actionTypes.GET_USERS_REPORT_PENDING]: requestUsersReport,
  [actionTypes.GET_USERS_REPORT_FULFILLED]: usersReport,
  [actionTypes.GET_USERS_REPORT_REJECTED]: requestFailed,
});
