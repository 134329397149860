import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { Pagination } from '@material-ui/lab';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const paginationStyle = {
  paddingRight: '10px',
  marginTop: '15px',
  marginBottom: '15px',
};

const PaginationTable = ({ pageData, handlePagination }) => {
  const [pageTotal, setPageTotal] = useState(0);
  const widthSize = useMediaQuery('(min-width:540px)');

  useEffect(() => {
    const pagesNum = Math.ceil(pageData.total / pageData.pageSize);
    setPageTotal(pagesNum);
  });

  const handleChange = (event, value) => {
    handlePagination(value - 1);
  };

  return (
    <Box>
      <Pagination
        count={pageTotal}
        page={pageData.page + 1}
        onChange={handleChange}
        showFirstButton
        showLastButton
        style={paginationStyle}
        siblingCount={1}
        size={widthSize ? 'medium' : 'small'}
      />
    </Box>
  );
};

export default PaginationTable;
