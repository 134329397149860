import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SupplyItem from './SupplyItem';
import SupplyBundle from './SupplyBundle';
import AddLogButton from './AddLogButton';
import { LOG_TYPES } from '../../config/constants';
import PaginationList from './PaginationList';

const SuppliesListStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.625, 0),
    margin: theme.spacing(0, 2.5),
    position: 'relative',
  },
  element: {
    padding: theme.spacing(1.875, 0),
  },
  custodian: {
    marginRight: theme.spacing(1),
  },
  package: {
    padding: theme.spacing(1.25, 0),
  },
  zeroStateContent: {
    textAlign: 'center',
    marginTop: `${theme.spacing(1.25)}px !important`,
  },
  addLogStyle: {
    position: 'fixed',
    right: theme.spacing(2.5),
    bottom: theme.spacing(2.5),
  },
}));

export default function SuppliesList({
  supplies,
  loading,
  error,
  pageData,
  handlePagination,
}) {
  const styles = SuppliesListStyle();
  const suppliesItems = supplies.map((supply) => {
    let content = null;
    if (supply.type === LOG_TYPES.PACKAGE) {
      content = <SupplyItem {...supply} />;
    } else {
      content = <SupplyBundle {...supply} />;
    }
    return (
      <Box
        key={supply.code}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        className={`${styles.element}`}
        width={1}
        border={1}
        borderTop={0}
        borderLeft={0}
        borderRight={0}
        borderColor={grey[300]}
      >
        {content}
      </Box>
    );
  });
  return (
    <Box
      alignItems="flex-start"
      className={styles.container}
      flexDirection="column"
      flexGrow={1}
    >
      {supplies.length > 0 && (
        <>
          <Box
            display="flex"
            flexGrow={1}
            className={styles.package}
            width={1}
            border={1}
            borderTop={0}
            borderLeft={0}
            borderRight={0}
            borderColor={grey[300]}
            color={grey[600]}
          >
            <Box>
              <Typography variant="body2" component="div">
                Package/Patient/Activity
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              flexGrow={1}
              className={styles.custodian}
            >
              <Typography variant="body2" component="div">
                CUSTODIAN
              </Typography>
            </Box>
          </Box>
          {suppliesItems}
          <PaginationList
            pageData={pageData}
            handlePagination={handlePagination}
          />
        </>
      )}
      {supplies.length === 0 && !loading && !error && (
        <Box display="flex" flexGrow={1} justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="50%"
          >
            <br />
            <Typography variant="body1" component="div">
              List is empty
            </Typography>
            <Typography
              variant="body2"
              component="div"
              className={styles.zeroStateContent}
            >
              Take custody of a package and it will show up here.
            </Typography>
          </Box>
        </Box>
      )}
      <Link to="/take_custody" className={styles.addLogStyle}>
        <AddLogButton />
      </Link>
    </Box>
  );
}
