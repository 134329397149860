import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArchiveIcon from '@material-ui/icons/Archive';
import { makeStyles } from '@material-ui/core/styles';
import JobsListDesktop from './JobsListDesktop';
import JobsListMobile from './JobsListMobile';
import FilterMenu from './FilterMenu';

const userListStyle = makeStyles((theme) => ({
  fieldset: {
    border: 'none',
    padding: '0',
    bottomPading: '2',
  },
  desktopBox: {
    width: '50%',
  },
  mobileBox: {
    width: '100%',
  },
  spreadBox: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  containerMessage: {
    padding: theme.spacing(2.5),
  },
  secondaryButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  actionBox: {
    marginTop: theme.spacing(1),
  },
  actionLine: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  buttonBox: {
    marginTop: theme.spacing(3),
    flexGrow: 1,
  },
  actionButton: {
    maxWidth: '250px',
    minWidth: '250px',
    marginRight: theme.spacing(2),
  },
  descriptionBox: {
    marginRight: theme.spacing(1),
  },
  dividerSection: {
    marginTop: theme.spacing(2),
  },
  filterMenuAligment: {
    justifyContent: 'end',
  },
}));

const JobsList = ({
  error, page, list, loading, pageData, handlePagination,
  browserSettings, handleOnClickTable, filterList, onChangeFilter,
}) => {
  const styles = userListStyle();

  const jobsListToRender = (
    browserSettings[0].isMobile
      ? (
        <JobsListMobile
          error={error}
          page={page}
          list={list}
          loading={loading}
          pageData={pageData}
          handlePagination={handlePagination}
          handleOnClickTable={handleOnClickTable}
        />
      )
      : (
        <JobsListDesktop
          error={error}
          page={page}
          list={list}
          loading={loading}
          pageData={pageData}
          handlePagination={handlePagination}
          handleOnClickTable={handleOnClickTable}
        />
      )
  );

  return (
    <Box>
      <Box>
        <FilterMenu
          filters={filterList}
          onChangeFilter={onChangeFilter}
        />
      </Box>
      <>
        {loading === false && list.length > 0
      && (
        <Box>
          <Box>
            {jobsListToRender}
          </Box>
        </Box>
      )}
        {loading === false && list.length === 0
      && (
      <Grid container justifyContent="center">
        <Box className={styles.container}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <ArchiveIcon
                fontSize="large"
              />
            </Grid>
          </Grid>
          <br />
          <Typography
            variant="body2"
            component="div"
            alignitems="center"
          >
            Not current Jobs on the List
          </Typography>
        </Box>
      </Grid>
      )}
      </>
    </Box>
  );
};

export default JobsList;
