import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Entry from '../components/Jobs';
import { getJobsList, setJobType } from '../actions/jobs';
import { setNavigationStepbackButton } from '../actions/navigation';
import WithLoading from '../components/WithLoading';
import { mobileDetection } from '../services/utility';
import { useAuth0 } from '../auth0';

const EntryWithLoading = WithLoading(Entry);

const Jobs = () => {
  const {
    error, isLoading, list, pageData, page, queryParams, findByFilter, usersImport, usersExport,
  } = useSelector((state) => state.jobsState);
  const browserSettings = useState(mobileDetection(window.navigator));
  const { getTokenSilently, user } = useAuth0();
  const [token, setToken] = useState('');
  const filterList = {
    usersImport,
    usersExport,
  };
  const navHistory = useHistory();

  const dispatch = useDispatch();

  (async () => {
    setToken(await getTokenSilently());
  })();

  useEffect(() => {
    const currentPage = 'Jobs Status';
    dispatch(setNavigationStepbackButton(currentPage));
  }, []);

  useEffect(() => {
    if (user && token) {
      dispatch(getJobsList(page, token, queryParams));
    }
  }, [dispatch, user, token]);

  useEffect(() => {
    if (user && token && findByFilter && queryParams !== '') {
      dispatch(getJobsList(0, token, queryParams));
    } else if (user && token && findByFilter && !usersImport.checked && !usersExport.checked) {
      dispatch(getJobsList(0, token, queryParams));
    }
  }, [queryParams]);

  const handlePagination = (pag) => {
    dispatch(getJobsList(pag, token, queryParams));
  };

  const onClickTable = (jobId) => {
    navHistory.push(`/settings/jobs/${jobId}`);
  };

  const handleOnChangeFilter = (jobType) => {
    dispatch(setJobType(jobType));
  };

  return (
    <>
      <EntryWithLoading
        error={error}
        loading={isLoading}
        page={page}
        list={list}
        pageData={pageData}
        handlePagination={handlePagination}
        browserSettings={browserSettings}
        handleOnClickTable={onClickTable}
        filterList={filterList}
        onChangeFilter={handleOnChangeFilter}
      />
    </>
  );
};

export { Jobs as default };
