import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatDate } from './utility';
import { hostApi, auth0Audience } from '../config/variables';
import { REPORT_TYPES } from '../config/constants';

function requestData(url, token) {
  return axios
    .get(hostApi + url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);
}

function postData(url, data, token) {
  return axios
    .post(hostApi + url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);
}

function postCSV(url, data, token) {
  return axios
    .post(hostApi + url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'text/csv',
      },
    })
    .then((response) => response.data);
}

function fetchCSV(url, reportType, token) {
  return fetch(hostApi + url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/octet-stream',
    },
    responseType: 'blob',
  })
    .then((response) => response.blob())
    .then((blob) => {
      if (reportType === 'delivery') {
        const exportName = REPORT_TYPES.DELIVERY_STATUS.LABEL.replace(' ', '_').toLowerCase();
        saveAs(blob, `${exportName}_report_${formatDate()}.csv`);
      } if (reportType === 'logs') {
        const exportName = REPORT_TYPES.EVENTS_LOG.LABEL.replace(' ', '_').toLowerCase();
        saveAs(blob, `${exportName}_report_${formatDate()}.csv`);
      } else {
        saveAs(blob, `${reportType}.csv`);
      }
    });
}

function fetchPDF(url, reportType, token) {
  return fetch(hostApi + url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    responseType: 'json',
  })
    .then((response) => response.json())
    .then((data) => {
      const exportName = reportType === 'delivery'
        ? REPORT_TYPES.DELIVERY_STATUS.LABEL.replace(' ', '_').toLowerCase() : REPORT_TYPES.EVENTS_LOG.LABEL.replace(' ', '_').toLowerCase();
      const columns = reportType === 'delivery'
        ? REPORT_TYPES.DELIVERY_STATUS.COLUMNS : REPORT_TYPES.EVENTS_LOG.COLUMNS;
      const doc = new JsPDF({
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
      });

      doc.autoTable({
        columns,
        body: data,
        styles: {
          overflow: 'linebreak',
        },
        columnStyles: exportName === REPORT_TYPES.DELIVERY_STATUS.EXPORT_NAME
          ? REPORT_TYPES.DELIVERY_STATUS.COLUMNS_STYLES : REPORT_TYPES.EVENTS_LOG.COLUMNS_STYLES,
      });

      doc.save(`${exportName}_report_${formatDate()}.pdf`);
    });
}

export function setUserAgreementAuth0(userId, token) {
  const instance = axios.create({
    baseURL: auth0Audience,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const url = `users/${encodeURIComponent(userId)}`;
  const data = {
    user_metadata: {
      userAgreement: true,
      dateAcceptingAgreement: moment(Date.now()).format('MMM DD, YYYY'),
    },
  };
  return instance.patch(url, data).then((response) => response.data);
}

export function getSupplies(page, token, queryParams = '') {
  const url = `/internal/supplyDeliveries?page=${page}${
    queryParams ? `&${queryParams}` : ''
  }`;
  return requestData(url, token);
}

export default function getLogsByOrderId(id, token) {
  const url = `/internal/logs/${id}`;
  return requestData(url, token);
}

export function postLog(log, token) {
  const url = '/internal/logs';
  return postData(url, log, token);
}

export function getPaceCentersAuthorized(token) {
  const url = '/internal/paceCenters/authorized';
  return requestData(url, token);
}

export function getRolReportValidation(token, isUserReport) {
  const url = `/internal/rolReportValidation?isUserReport=${isUserReport}`;
  return requestData(url, token);
}

export function postTrackableItem(log, token) {
  const url = '/internal/trackableItem';
  return postData(url, log, token);
}

export function postDeliverSupply(id, log, token) {
  const url = `/SupplyDelivery/${id}/deliver`;
  return postData(url, log, token);
}

export function getPatientsFilter(keyword, page, token) {
  const url = `/internal/patients?page=${page}${
    keyword ? `&name=${keyword}` : ''
  }`;
  return requestData(url, token);
}

export function getItemsFilter(keyword, page, token) {
  const url = `/internal/supplyDeliveries/${keyword}/?page=${page}${
    keyword ? `&id=${keyword}` : ''
  }`;
  return requestData(url, token);
}

export function getCustodiansFilter(keyword, page, token) {
  const url = `/internal/custodians?page=${page}${
    keyword ? `&name=${keyword}` : ''
  }`;
  return requestData(url, token);
}

export function postComment(orderId, comment, token) {
  const url = `/SupplyDelivery/${orderId}/comment`;
  return postData(url, comment, token);
}

export function getReport(page, token, reportType, queryParams = '') {
  const url = `/internal/report/${reportType}/preview/?page=${page}${
    queryParams ? `&${queryParams}` : ''
  }`;
  return requestData(url, token);
}

export function getCSVReport(token, reportType, queryParams = '') {
  const url = `/internal/report/${reportType}/csv/?${
    queryParams ? `&${queryParams}` : ''
  }`;
  return fetchCSV(url, reportType, token);
}

export function getPDFReport(token, reportType, queryParams = '') {
  const url = `/internal/report/${reportType}/pdf/?${
    queryParams ? `&${queryParams}` : ''
  }`;
  return fetchPDF(url, reportType, token);
}

export function postEditTrackableItem(log, orderId, token) {
  const url = `/internal/trackableItem/${orderId}/edit `;
  return postData(url, log, token);
}

export function getUsers(keyword, page, token) {
  const url = `/internal/users?page=${page}${
    keyword ? `&searchValue=${keyword.replaceAll('+', '|||')}` : ''
  }`;
  return requestData(url, token);
}

export function getUsersByRole(role, page, token) {
  const url = `/internal/usersInRole?role=${role}&page=${page}`;
  return requestData(url, token);
}

export function getUserById(keyword, token) {
  const url = `/internal/users/${keyword}`;
  return requestData(url, token);
}

export function postDeactivateUserById(userId, token) {
  const url = `/internal/users/${userId}/deactivate`;
  return requestData(url, token);
}

export function postActivateUserById(userId, token) {
  const url = `/internal/users/${userId}/activate`;
  return requestData(url, token);
}

export function getPaceCenters(keyword, page, token) {
  const url = `/internal/paceCenters?page=${page}${
    keyword ? `&searchValue=${keyword}` : ''
  }`;
  return requestData(url, token);
}

export function getSystemRoles(token) {
  const url = '/internal/roles';
  return requestData(url, token);
}

export function postNewUser(log, token) {
  const url = '/internal/users';
  return postData(url, log, token);
}

export function postNewBulkUsers(keyword, log, token) {
  const url = `/internal/users/bulk?description=${keyword}`;
  return postCSV(url, log, token);
}

export function postEditionUser(userId, log, token) {
  const url = `/internal/users/${userId}/edition`;
  return postData(url, log, token);
}

export function getJobs(page, token, queryParams) {
  const url = `/internal/jobs?page=${page}${
    queryParams ? `&${queryParams}` : ''
  }`;
  return requestData(url, token);
}

export function getJobById(keyword, token) {
  const url = `/internal/jobs/${keyword}`;
  return requestData(url, token);
}

export function generateUsersReport(token, organizationId) {
  const url = `/internal/generation/users/csv?organizationId=${organizationId}`;
  return requestData(url, token);
}

export function getUsersReport(token, keyword, reportType) {
  const url = `/internal/jobs/${keyword}/csv`;
  return fetchCSV(url, reportType, token);
}
