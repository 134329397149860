import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { useHistory } from 'react-router-dom';

export const Auth0Context = React.createContext();

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({
  children,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const history = useHistory();

  const onRedirectCallback = (appState) => history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        ...initOptions,
        onRedirectCallback,
        redirect_uri: window.location.origin + '/' + initOptions.login_type,
      });
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')
        && window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticatedFromHook = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticatedFromHook);

      if (isAuthenticatedFromHook) {
        const userFromHook = await auth0FromHook.getUser();
        setUser(userFromHook);
      }

      setLoading(false);
    };

    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);

    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    const popUpUser = await auth0Client.getUser();
    setUser(popUpUser);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);

    await auth0Client.handleRedirectCallback();
    const redirectUser = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(redirectUser);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: () => {
          localStorage.setItem('loginType', null);
          auth0Client.logout({ returnTo: window.location.origin })
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
