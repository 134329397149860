import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import JobInfo from './JobInfo';

const userdetailStyle = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2.5),
  },
  attest: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  canvas: {
    margin: theme.spacing(0, 0, 7, 0),
  },
}));

export default function JobDetail({
  jobData,
  errorDetails,
  error,
  loading,
  browserSettings,
  isSuccess,
  onClickUsersReport,
  loadingReport,
  isSuccessReport,
}) {
  const styles = userdetailStyle();
  return (
    !error && !loading && isSuccess && (
      <Container>
        <Box className={styles.container}>
          <JobInfo
            jobData={jobData}
            errorDetails={errorDetails}
            error={error}
            loading={loading}
            browserSettings={browserSettings}
            onClickUsersReport={onClickUsersReport}
            loadingReport={loadingReport}
            isSuccessReport={isSuccessReport}
          />
        </Box>
      </Container>
    )
  );
}
