import * as actionTypes from '../action_types/trackable';
import {
  getPaceCentersAuthorized,
  postTrackableItem,
  getPatientsFilter,
  postEditTrackableItem,
  getPaceCenters,
} from '../services/serviceClient';

const getOrganizations = (token) => ({
  type: actionTypes.GET_TRACKABLE_PACE_CENTERS,
  async payload() {
    const rawData = await getPaceCentersAuthorized(token);
    return rawData;
  },
});

const postItem = (log, token) => ({
  type: actionTypes.POST_TRACKABLE_ITEM,
  async payload() {
    const rawData = await postTrackableItem(log, token);
    return rawData;
  },
});

const editItem = (log, orderId, token) => ({
  type: actionTypes.POST_EDIT_TRACKABLE_ITEM,
  async payload() {
    const rawData = await postEditTrackableItem(log, orderId, token);
    return rawData;
  },
});

const resetState = () => ({
  type: actionTypes.TRACKABLE_ITEM_RESET_STATE,
});

const postResetState = () => ({
  type: actionTypes.POST_TRACKABLE_ITEM_RESET_STATE,
});

const addAlert = (alert) => ({
  type: actionTypes.ADD_ALERT,
  payload: {
    alert,
  },
});

const removeAlert = (alert) => ({
  type: actionTypes.REMOVE_ALERT,
  payload: {
    alert,
  },
});

const handleGoBack = (navigation) => ({
  type: actionTypes.TRACKABLE_ITEM_HANDLE_GO_BACK,
  payload: {
    navigation,
  },
});

const getFilterPatients = (keyword, page, token) => ({
  type: actionTypes.GET_FILTER_PATIENTS,
  async payload() {
    const rawData = await getPatientsFilter(keyword, page, token);
    let result = { list: [], pageData: { total: 0 }, page };

    result = { list: [...rawData.patients], pageData: { ...rawData.pageData }, page };

    return result;
  },
});

const getFilterPaceCenters = (keyword, page, token) => ({
  type: actionTypes.GET_FILTER_PACECENTERS,
  async payload() {
    const rawData = await getPaceCenters(keyword, page, token);

    const result = {
      list: [...rawData.organizations],
      pageData: {
        total: rawData.pageData.total,
        pageSize: 10,
      },
      page,
    };

    return result;
  },
});

export {
  getOrganizations, postItem, resetState, addAlert, removeAlert,
  postResetState, handleGoBack, getFilterPatients, editItem, getFilterPaceCenters,
};
