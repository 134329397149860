import React from 'react';
import List from '@material-ui/core/List';
import Alert from '@material-ui/lab/Alert';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Box, Container } from '@material-ui/core';
import DispatchButton from '../../assets/DispatchButton';
import ErrorDetailsSection from './ErrorDetailsSection';
import { jobTypes, jobStatus } from './constants';

const userInfoStyle = makeStyles((theme) => ({
  item: {
    contentAlign: 'center',
  },
  container: {
    paddingTop: theme.spacing(0.5),
  },
  bottomContent: {
    paddingBottom: theme.spacing(1),
  },
  boxButton: {
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    width: '100%',
    color: 'primary',
    alignContent: 'center',
    backgroundColor: theme.palette.secondary.light,
    disabled: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
}));

const JobInfo = ({
  jobData,
  errorDetails,
  browserSettings,
  loading,
  onClickUsersReport,
  loadingReport,
}) => {
  const styles = userInfoStyle();

  const renderSummary = () => (
    <Box>
      <Divider component="li" variant="inset" />
      <Box className={styles.container}>
        <li>
          <Typography
            sx={{ mt: 0.5, ml: 9 }}
            display="block"
          >
            Summary
          </Typography>
        </li>
        <ListItem className={styles.item}>
          <ListItemText primary="Total" secondary={jobData.summary.total} />
          <ListItemText primary="Inserted" secondary={jobData.summary.inserted} />
        </ListItem>
        <ListItem className={styles.item}>
          <ListItemText primary="Failed" secondary={jobData.summary.failed} />
          <ListItemText primary="Updated" secondary={jobData.summary.updated} />
        </ListItem>
      </Box>
    </Box>
  );

  const renderErrorDetails = () => (
    <Box>
      <Divider component="li" variant="inset" />
      <Box className={styles.container}>
        <li>
          <Typography
            sx={{ mt: 0.5, ml: 9 }}
            display="block"
          >
            Error Details
          </Typography>
        </li>
        <ListItem>
          <Container>
            <ErrorDetailsSection
              details={errorDetails}
              isMobile={browserSettings[0].isMobile}
            />
          </Container>
        </ListItem>
      </Box>
    </Box>
  );

  return (
    <Container disableGutters>
      {jobData.type === jobTypes.USERS_EXPORT && jobData.status === jobStatus.COMPLETED
      && (
        <Alert severity="info">
          {jobData.csvUrl === null
          && (
            <Typography variant="inherit">
              No records match the filter criteria for the generated report.
              <p />
            </Typography>

          )}
          This Job will be deleted on
          {' '}
          {jobData.expiredDate}
          . After that date this content will no longer be accessible.
        </Alert>
      )}
      <>
        {!loading
    && (
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
        }}
      >
        <ListItem className={styles.item}>
          <ListItemText primary="Status" secondary={jobData.status} />
          <ListItemText primary="Job Type" secondary={jobData.type} />
        </ListItem>
        <Divider component="li" />
        <ListItem className={styles.item}>
          <ListItemText primary="Description" secondary={jobData.description} />
        </ListItem>
        <Divider component="li" />
        <ListItem className={styles.item}>
          <ListItemText primary="Created Date" secondary={jobData.createdDate} />
          <ListItemText primary="Last Updated Date" secondary={jobData.updatedDate} />
        </ListItem>
        {jobData.summary !== undefined && jobData.summary !== null
        && jobData.type === jobTypes.USERS_IMPORT
        && (
          <Box>
            { renderSummary }
          </Box>
        )}
        {errorDetails !== [] && errorDetails !== null
        && (
          <Box>
            { renderErrorDetails }
          </Box>
        )}
      </List>
    )}
        {jobData.type === jobTypes.USERS_EXPORT
        && jobData.status === jobStatus.COMPLETED && jobData.csvUrl !== null
        && (
          <Box className={styles.boxButton}>
            <DispatchButton
              className={styles.button}
              disableElevation
              onClick={onClickUsersReport}
              variant="cofc"
              loading={loadingReport}
              label="Download Report"
            />
          </Box>
        )}
      </>
    </Container>
  );
};

export default JobInfo;
