import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Howl, Howler } from 'howler';
import Entry from '../components/TakeCustody';
import { nav, scan } from '../components/TakeCustody/constants';
import {
  postLogToOrder, resetState, addAlert, resetStateOnError,
} from '../actions/custody';
import { setNavigationStepbackButton } from '../actions/navigation';
import { IN_PROGRESS } from '../config/constants';
import { useAuth0 } from '../auth0';

const TakeCustody = () => {
  const [entry, setEntry] = useState('');
  const [navigation, setNavigation] = useState(nav.code.MANUAL_ENTRY);
  const handleChangeEntry = (value) => setEntry(`${value}`.trim().toUpperCase());
  const {
    isLoading, error, isSuccess, permissionError,
  } = useSelector((state) => state.custodyState);
  const [isScanning, setIsScanning] = useState(true);
  const [postLogAutomatically, handlePostLogAutomatically] = useState(false);
  const goodCodeSound = new Howl({
    src: [scan.sound.GOOD_BARCODE],
    autoplay: true,
    loop: false,
    mute: true,
  });
  const badCodeSound = new Howl({
    src: [scan.sound.BAD_BARCODE],
    autoplay: true,
    loop: false,
    mute: true,
  });

  const history = useHistory();

  const dispatch = useDispatch();

  const { user, getTokenSilently } = useAuth0();

  const handlePostLog = () => async () => {
    const log = {
      complete: false,
      status: IN_PROGRESS,
      supplyDeliveryId: entry,
      user: user.name,
    };
    const token = await getTokenSilently();
    if (!isLoading) {
      dispatch(postLogToOrder(log, token));
    }
  };

  const playDummySounds = () => {
    Howler.volume(0);
    goodCodeSound.mute(false);
    badCodeSound.mute(false);
    goodCodeSound.play();
    badCodeSound.play();
  };

  useEffect(() => {
    document.addEventListener('touchstart', () => {
      // Hack for iOS 15, which suspends audio when app is in background,
      // and mutes audio when there is an incoming call.
      Howler.ctx.resume();
    })
    Howler.volume(0);
  }, []);

  const playSound = (sound) => {
    Howler.volume(1);
    if (sound) {
      goodCodeSound.mute(false);
      goodCodeSound.play();
    } else {
      badCodeSound.mute(false);
      badCodeSound.play();
    }
  };

  const handleNavigation = (newValue) => {
    setNavigation(newValue);
    handleChangeEntry('');
    setIsScanning(true);
    dispatch(resetState());
  };

  const handleIsScanning = (scanning) => {
    setIsScanning(scanning);
    if (scanning) {
      handleChangeEntry('');
      dispatch(resetState());
    }
  };

  const handleScanOnError = (err) => {
    dispatch(resetStateOnError(err));
  };

  useEffect(() => {
    dispatch(setNavigationStepbackButton('New custody'));
    dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(addAlert(entry));
      dispatch(resetState());
      setEntry('');
    }
  }, [isSuccess, entry, history]);

  useEffect(() => {
    if (error) {
      handlePostLogAutomatically(false);
      if (navigation) {
        handleScanOnError(error);
      } else {
        handleIsScanning(false);
      }
    }
  }, [error]);

  useEffect(() => {
    if (postLogAutomatically && entry) {
      handlePostLog();
    }
  }, [postLogAutomatically, entry]);

  return (
    <Container disableGutters>
      <Entry
        value={entry}
        navigationValue={navigation}
        error={error || false}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isScanning={isScanning}
        onPostLogAutomatically={handlePostLogAutomatically}
        onChangeNavigation={handleNavigation}
        onPostLog={handlePostLog}
        onChangeValue={handleChangeEntry}
        onIsScanning={handleIsScanning}
        permissionError={permissionError || false}
        onPlaySound={playSound}
        playDummySounds={playDummySounds}
      />
    </Container>
  );
};

export { TakeCustody as default };
