import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import BarcodeReader from '../BarcodeReader';
import { CAMERA } from '../../config/constants';

const barcodeEntryStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 1.75),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(-2),
    },
  },
  tittle: {
    marginBottom: theme.spacing(2),
  },
  scanAgain: {
    padding: theme.spacing(0.75, 5),
    textTransform: 'uppercase',
    cursor: 'pointer',
    textDecoration: 'none',
    width: 'fit-content',
  },
  scanning: {
    width: 'inherit',
    boxSizing: 'content-box',
    minHeight: theme.spacing(35),
    [theme.breakpoints.up('xs')]: {
      minHeight: theme.spacing(20),
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      maxWidth: 'none',
      minHeight: theme.spacing(40),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
      minHeight: theme.spacing(55),
    },
  },
  marginTop: {
    marginTop: theme.spacing(7.5),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function BarcodeEntry({
  value,
  isLoading,
  onChangeValue,
  permissionError,
}) {
  const styles = barcodeEntryStyle();

  const handleChangeValue = (newValue) => {
    onChangeValue(newValue);
  };

  return (
    <>
      {permissionError
        ? (
          <Box className={styles.container} display="flex" flexGrow={1} flexDirection="column">
            <Box display="flex" justifyContent="center" width={1} className={styles.container}>
              <Alert severity="error">{CAMERA.ERROR.MESSAGE}</Alert>
            </Box>
          </Box>
        )
        : (
          <Box className={styles.container} display="flex" flexGrow={1} flexDirection="column">
            <Box display="flex" flexGrow={1} justifyContent="center">
              <Typography variant="body1" component="div">
                Scanning
              </Typography>
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="center" width={1}>
              <Box
                className={styles.scanning}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <BarcodeReader
                  value={value}
                  onChangeValue={handleChangeValue}
                  isLoading={isLoading}
                />
              </Box>
            </Box>
          </Box>
        )}
    </>
  );
}
