import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Entry from '../components/UserDetail';
import {
  resetState, userById, activateUserById, deactivateUserById,
} from '../actions/dashboard';
import { setNavigationStepbackButton } from '../actions/navigation';
import WithLoading from '../components/WithLoadingList';
import { mobileDetection } from '../services/utility';
import { useAuth0 } from '../auth0';

const EntryWithLoading = WithLoading(Entry);

const UserDetail = () => {
  const { userId } = useParams();
  const {
    error, isLoading, userData, userRol, paceCenters, isSuccess,
  } = useSelector((state) => state.dashboardState);
  const browserSettings = useState(mobileDetection(window.navigator));
  const { getTokenSilently, user } = useAuth0();
  const [token, setToken] = useState('');
  const [disableStatus, setDisableStatus] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const navHistory = useHistory();

  const dispatch = useDispatch();

  (async () => {
    setToken(await getTokenSilently());
  })();

  useEffect(() => {
    const currentPage = 'User Detail';
    dispatch(setNavigationStepbackButton(currentPage));
    dispatch(resetState());
  }, []);

  useEffect(() => {
    if (user && token && userId !== 'creation' && userId !== 'report') {
      dispatch(userById(userId, token));
    } else if (userId === 'creation') {
      navHistory.push('/settings/users/creation/single');
    } else if (userId === 'report') {
      navHistory.push('/settings/users/report/generation');
    }
  }, [dispatch, user, token]);

  useEffect(() => {
    if (userRol && userData !== undefined && userData !== []) {
      setDisableStatus(userData.email === user.email);
    }
  }, [userRol]);

  const handleOptions = (eventAction) => {
    if (eventAction !== 'Edit') {
      setOpenStatusDialog(true);
    } else {
      navHistory.push(`/settings/users/${userId}/edit`);
    }
  };

  const handleOnCancelDialog = () => {
    setOpenStatusDialog(false);
  };

  const handleOnChangeStatusDialog = async () => {
    if (userData.status === 'Active') {
      await dispatch(deactivateUserById(userId, token));
    } else {
      await dispatch(activateUserById(userId, token));
    }
    setOpenStatusDialog(false);
  };

  return (
    <>
      <EntryWithLoading
        userData={userData}
        userRol={userRol}
        userStatus={userData.status}
        paceCentersList={paceCenters}
        error={error}
        loading={isLoading}
        browserSettings={browserSettings}
        disableStatus={disableStatus}
        handleEventOptions={handleOptions}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={openStatusDialog}
        onCancelDialog={handleOnCancelDialog}
        onChangeStatusDialog={handleOnChangeStatusDialog}
        isSuccess={isSuccess}
      />
    </>
  );
};

export { UserDetail as default };
