import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      main: '#82BC00',
    },
    secondary: {
      main: '#530070',
    },
    trackableItem: {
      main: '#F77F00',
      hover: '#F9A54C',
    },
    underline: {
      default: '#00002A',
    },
  },
});

export default theme;
