import * as actionTypes from '../action_types/jobs';
import { getJobs, getJobById, getUsersReport } from '../services/serviceClient';

const resetState = () => ({
  type: actionTypes.JOBS_RESET_STATE,
});

const resetJobDetailState = () => ({
  type: actionTypes.JOB_DETAIL_RESET_STATE,
});

const handleGoBack = (navigation) => ({
  type: actionTypes.JOBS_HANDLE_GO_BACK,
  payload: {
    navigation,
  },
});

const getJobsList = (page, token, queryParams) => ({
  type: actionTypes.GET_JOBS,
  async payload() {
    const rawData = await getJobs(page, token, queryParams);
    let result = { list: [], pageData: { total: 0 }, page };

    result = {
      list: [...rawData.auth0Jobs],
      pageData: { total: rawData.pageData.total, page, pageSize: 10 },
      page,
    };

    return result;
  },
});

const setJobType = (jobType) => ({
  type: actionTypes.SET_JOB_TYPE_LIST,
  payload: {
    jobType,
  },
});

const jobById = (keyword, token) => ({
  type: actionTypes.GET_JOB_DETAIL,
  async payload() {
    const rawData = await getJobById(keyword, token);
    let result = { job: [], details: [] };

    result = {
      job: {
        id: rawData.id,
        jobId: rawData.jobId,
        createdDate: rawData.createdDate,
        updatedDate: rawData.updatedDate,
        description: rawData.description,
        status: rawData.status,
        summary: rawData.summary,
        type: rawData.type,
        csvUrl: rawData.csv_url,
        expiredDate: rawData.expiredDate,
      },
      details: rawData.error_details,
    };
    return result;
  },
});

const getReport = (token, keyword, jobType) => ({
  type: actionTypes.GET_USERS_REPORT,
  async payload() {
    const rawData = await getUsersReport(token, keyword, jobType);
    return { ...rawData };
  },
});

export {
  resetState, resetJobDetailState, handleGoBack, getJobsList, jobById, getReport, setJobType,
};
