import React from 'react';
import Box from '@material-ui/core/Box';
import Menu from './Menu';

export default function FiltersMenu({
  filters,
  onClearFilters,
  onApplyFilter,
  navigationValue,
  onClickSeachFilter,
  filterToApply,
  filtersListToApply,
  onClickDeleteElement,
  onAccordionStatus,
  onFilterChange,
}) {
  return (
    <Box>
      <Menu
        filters={filters}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
        navigationValue={navigationValue}
        onClickSeachFilter={onClickSeachFilter}
        filterToApply={filterToApply}
        filtersListToApply={filtersListToApply}
        onClickDeleteElement={onClickDeleteElement}
        onAccordionStatus={onAccordionStatus}
        onFilterChange={onFilterChange}
      />
    </Box>
  );
}
