import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import JobsList from './JobsList';

const dashboardStyle = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2.5),
  },
  attest: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  canvas: {
    margin: theme.spacing(0, 0, 7, 0),
  },
}));

export default function Jobs({
  error,
  loading,
  page,
  list,
  pageData,
  handlePagination,
  browserSettings,
  handleOnClickTable,
  filterList,
  onChangeFilter,
}) {
  const styles = dashboardStyle();
  return (
    <>
      <Container>
        <Box className={styles.container}>
          {
      !error && (
        <JobsList
          error={error}
          page={page}
          list={list}
          loading={loading}
          pageData={pageData}
          handlePagination={handlePagination}
          browserSettings={browserSettings}
          handleOnClickTable={handleOnClickTable}
          filterList={filterList}
          onChangeFilter={onChangeFilter}
        />
      )
      }
        </Box>
      </Container>
    </>
  );
}
