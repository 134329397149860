import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import getLogsByOrder from '../actions/logs';
import { addCommentToOrder, resetState } from '../actions/comments';
import { setNavigationStepbackButton } from '../actions/navigation';
import { useAuth0 } from '../auth0';
import Comment from '../components/Comment';

export default function CommentContainer() {
  const { orderId } = useParams();
  const { getTokenSilently } = useAuth0();
  const logs = useSelector((state) => state.logsState);
  const loading = useSelector((state) => state.commentsState.loading);
  const isSuccess = useSelector((state) => state.commentsState.isSuccess);
  const [comment, setComment] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmitComment = () => {
    if (comment.length) {
      const fn = async () => {
        const token = await getTokenSilently();
        dispatch(
          addCommentToOrder(orderId, { comment, type: logs.type }, token)
        );
      };
      fn();
    }
  };

  const handleChangeComment = (value) => setComment(value);

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetState());
      history.goBack();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (orderId) {
      dispatch(setNavigationStepbackButton('Comment'));
      (async () => {
        const token = await getTokenSilently();
        dispatch(getLogsByOrder(orderId, token));
      })();
    }
  }, [orderId, dispatch]);

  return (
    <Comment
      logs={logs}
      comment={comment}
      loading={loading}
      onChangeComment={handleChangeComment}
      onSubmitComment={handleSubmitComment}
      onCancel={handleCancel}
    />
  );
}
