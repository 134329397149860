import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import TuneIcon from '@material-ui/icons/Tune';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  tuneButton: {
    marginRight: theme.spacing(0),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContent: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),

  },
  marginUsername: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

function NavigationDrawer({
  currentPage, user, isAuthenticated, logout,
  paceCenters, onRedirectFilter, userRole,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const adminMenu = userRole.findIndex((e) => e === 'Admin') > -1 ? [
    { label: 'Packages', redirectTo: '/' },
    { label: 'Add Trackable Item', redirectTo: '/add_trackable_item' },
    { label: 'User Management', redirectTo: '/settings/users' },
  ] : [{ label: 'Packages', redirectTo: '/' }];

  const ManagerMenu = paceCenters ? [
    { label: 'Packages', redirectTo: '/' },
    { label: 'Add Trackable Item', redirectTo: '/add_trackable_item' },
    { label: 'Reports', redirectTo: '/report' },
  ] : [{ label: 'Packages', redirectTo: '/' }];

  const regularMenu = paceCenters ? [
    { label: 'Packages', redirectTo: '/' },
    { label: 'Add Trackable Item', redirectTo: '/add_trackable_item' },
  ] : [{ label: 'Packages', redirectTo: '/' }];

  let menu;

  if (userRole.findIndex((e) => e === 'Admin') > -1) {
    menu = adminMenu;
  } else if (userRole.findIndex((e) => e === 'Manager') > -1) {
    menu = ManagerMenu;
  } else {
    menu = regularMenu;
  }

  const handleDrawerToggle = () => {
    if (window.screen.width <= theme.breakpoints.values.sm) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleOnRedirectFilter = (e) => {
    e.stopPropagation();
    onRedirectFilter();
  };

  const drawer = (
    <div>
      <Box display="flex" flexDirection="column" className={classes.drawerContent}>
        <Avatar alt={user.name} src={user.picture} className={classes.avatar} />
        <Typography variant="h6" className={classes.marginUsername}>
          {user.name}
        </Typography>
      </Box>
      <Divider />
      <List>
        {menu.map((menuItem) => (
          <ListItem
            button
            component={Link}
            to={menuItem.redirectTo}
            key={menuItem.label}
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={menuItem.label} />
          </ListItem>
        ))}
        { isAuthenticated
        && (
        <ListItem button onClick={logout}>
          <ListItemText primary="Logout" />
        </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <>
      <AppBar className={classes.appBar} color="inherit">
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography align="center" variant="h6">
            {currentPage}
          </Typography>
          <Box display="flex" flexGrow={1} />
          {/* Checking for admin role here to hide the filter menu button, check to be removed once fix is implemented for pulling all packages */}
          <Box alignItems="right" display={!paceCenters || (userRole.findIndex((e) => e === 'Admin') > -1) ? 'none' : ''}>
            <IconButton
              color="primary"
              aria-controls="menu-appbar"
              edge="end"
              className={classes.tuneButton}
              onClick={handleOnRedirectFilter}
            >
              <TuneIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="nav bar">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}

export default NavigationDrawer;
