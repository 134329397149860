import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../assets/Button';
import App from '../App';
import { auth0Domain, auth0ExternalClientId, auth0ClientId, auth0Audience } from '../config/variables';
import statusGeneration from './Reports/StatusGeneration';
import {updateLoginType} from '../actions/login'
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia } from '@material-ui/core';
import { mobileDetection } from '../services/utility';

const loginButtonStyle = makeStyles((theme) => ({
  loginInfo: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    textAlign: 'center', 
    minHeight: '100vh'
  },
  logo: {
    position:'absolute',
    top:'210px'
  },
  logoMobile: {
    position:'absolute',
    top:'210px',
  },
  header: {
    position:'absolute',
    top:'410px'
  },
  headerMobile: {
    position:'absolute',
    top:'410px',
    width: '250px'
  },
  eirenerxLoginButton: {
    color: theme.palette.common.white,
    textTransform: 'none',
    backgroundColor: '#82BC00',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    position:'absolute',
    top:'480px',
    width:'268px'
  },
  eirenerxLoginButtonMobile: {
    color: theme.palette.common.white,
    textTransform: 'none',
    backgroundColor: '#82BC00',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    position:'absolute',
    top:'480px',
    width:'268px',
    left:'70px'
  },
  externalLoginButton: {
    color: theme.palette.common.white,
    textTransform: 'none',
    backgroundColor: '#82BC00',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    position:'absolute',
    top:'525px',
    width:'268px',
  },
  externalLoginButtonMobile: {
    color: theme.palette.common.white,
    textTransform: 'none',
    backgroundColor: '#82BC00',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    position:'absolute',
    top:'525px',
    width:'268px',
    left:'70px'
  },
}));

export default function LoginButton({
  textLabel, domain, client_id, audience
}) {
  const styles = loginButtonStyle();
  const login = useSelector((state) => state.loginState);
  const dispatch = useDispatch();
  const [loginType, setLoginType] = useState();
  const browserSettings = useState(mobileDetection(window.navigator));

  const handleAuthLoginEirenerx = (event) => {
    dispatch(
      updateLoginType('eirenerx')
    );
  };
  const handleAuthLoginExternal = (event) => {
    dispatch(
      updateLoginType('external')
    );
  };

  useEffect(() => {
    if (window.location.pathname === '/eirenerx' || localStorage.getItem('loginType') === 'eirenerx'){
      localStorage.setItem('loginType', 'eirenerx');
      handleAuthLoginEirenerx();
    } else if (window.location.pathname === '/external' || localStorage.getItem('loginType') === 'external'){
      localStorage.setItem('loginType', 'external');
      handleAuthLoginExternal();
    }
  }, []);

  return (
    <div>
    {login.loginType ? null : 
      <div className={styles.loginInfo}>
        <img className={browserSettings[0].isMobile ? styles.logoMobile : styles.logo}src="logo192.png" />
        <h3 className={browserSettings[0].isMobile ? styles.headerMobile : styles.header}>Welcome to CK Door2Door</h3>
        <Button className={browserSettings[0].isMobile ? styles.eirenerxLoginButtonMobile : styles.eirenerxLoginButton} onClick={handleAuthLoginEirenerx}>Login - EireneRx Users</Button>
        <Button className={browserSettings[0].isMobile ? styles.externalLoginButtonMobile : styles.externalLoginButton} onClick={handleAuthLoginExternal}>Login - Door2Door Only Users</Button> 
      </div>
    }
    {login.loginType === 'eirenerx' ? 
      <App 
        loginDomain={auth0Domain}
        loginClient={auth0ClientId}
        loginAudience={auth0Audience}
        loginType={login.loginType}
      /> : (login.loginType === 'external' ?
      <App 
        loginDomain={auth0Domain}
        loginClient={auth0ExternalClientId}
        loginAudience={auth0Audience}
        loginType={login.loginType}
      /> : null)
    }
    </div>
  );
}