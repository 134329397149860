import React from 'react';
import { Box, Typography, FilledInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import StatusBar from '../StatusBar';
import Button from '../../assets/Button';
import { TRACKABLE_ITEM, UNDERLINE, CHARACTER_LIMIT } from '../../config/constants';

const commentStyles = makeStyles((theme) => ({
  headerContent: {
    paddingTop: theme.spacing(1),
    right: 0,
    left: 0,
    zIndex: 1000,
    top: theme.spacing(7),
  },
  bottomContent: {
    padding: theme.spacing(4, 2),
  },
  button: {
    color: 'primary',
  },
  buttonTrackableItem: {
    backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
    '&:hover': {
      backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
      '@media (hover: none)': {
        backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
      },
    },
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid ${UNDERLINE.COLOR}`,
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  trackableItemUnderline: {
    '&:before': {
      borderBottom: `1px solid ${UNDERLINE.COLOR}`,
    },
    '&:after': {
      borderBottom: `2px solid ${TRACKABLE_ITEM.COLOR.MAIN}`,
    },
  },
}));

const Comment = ({
  logs = [],
  comment,
  onChangeComment,
  onSubmitComment,
  onCancel,
  loading,
}) => {
  const styles = commentStyles();
  const { manuallyCreated } = logs;

  const handleChangeComment = (e) => {
    onChangeComment(e.target.value);
  };

  return (
    <Box display="flex" flexGrow={1} flexDirection="column">
      <Box
        className={styles.headerContent}
        bgcolor="common.white"
        position="sticky"
      >
        {logs.status && <StatusBar {...logs} />}
      </Box>
      <Box mx={2.5} mt={4.75}>
        <Box mb={1.25}>
          <Typography variant="body2" component="div">
            COMMENT
          </Typography>
        </Box>
        <FilledInput
          value={comment}
          onChange={handleChangeComment}
          classes={manuallyCreated
            ? { root: styles.trackableItemUnderline }
            : { root: styles.underline }}
          aria-describedby="item-comment"
          placeholder="Enter your comment here"
          rows={9}
          autoFocus
          multiline
          fullWidth
          inputProps={{ maxLength: CHARACTER_LIMIT.COMMENTS }}
        />
      </Box>
      <Box position="fixed" bottom="0" width={1}>
        <Box
          display="flex"
          className={styles.bottomContent}
          justifyContent="space-between"
          bgcolor={grey[50]}
        >
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            variant="cofc"
            disabled={loading || !comment.length}
            onClick={onSubmitComment}
            className={manuallyCreated ? styles.buttonTrackableItem : styles.button}
          >
            Save Comment
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Comment;
