import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { nav as filterNav } from '../FiltersMenu/constants';

const useStyles = makeStyles((theme) => ({

  menuButton: {
    marginRight: theme.spacing(2),
  },
  clearFilterButton: {
    cursor: 'pointer',
    fontSize: theme.spacing(1.6),
  },
}));

function NavigationNav({
  currentPage, buttonType, onGoBack, onClearFilters, filterMenuNavigation,
}) {
  const classes = useStyles();
  const button = buttonType === 'stepbackButton' ? <ArrowBackIcon /> : <CloseIcon />;
  const handleDrawerToggle = () => {
    onGoBack();
  };

  return (
    <AppBar position="fixed" color="inherit">
      <Toolbar>
        <IconButton
          color="default"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          {button}
        </IconButton>
        <Typography variant="h6" noWrap>
          {currentPage}
        </Typography>
        <Box display="flex" flexGrow={1} />
        <Box
          className={classes.clearFilterButton}
          color="primary.main"
          onClick={onClearFilters}
          alignItems="right"
          display={currentPage === 'Sorting & Filters' && filterMenuNavigation === filterNav.code.FILTER_MENU ? 'flex' : 'none'}
        >
          CLEAR ALL
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationNav;
