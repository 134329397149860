export const SET_SUPPLIES_FILTERS = 'SET_SUPPLY_FILTERS';
export const SET_ALL_SUPPLIES_FILTERS = 'SET_ALL_SUPPLIES_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_ON_SEARCH_FILTERS = 'RESET_ON_SEARCH_FILTERS';
export const RESET_ON_BACK_MENU_FILTERS = 'RESET_ON_BACK_MENU_FILTERS';
export const SHOW_FILTERS = 'SHOW_FILTERS';

export const GET_FILTER_ELEMENTS_BY_TYPE = 'GET_FILTER_ELEMENTS_BY_TYPE';
export const GET_FILTER_ELEMENTS_BY_TYPE_PENDING = 'GET_FILTER_ELEMENTS_BY_TYPE_PENDING';
export const GET_FILTER_ELEMENTS_BY_TYPE_FULFILLED = 'GET_FILTER_ELEMENTS_BY_TYPE_FULFILLED';
export const GET_FILTER_ELEMENTS_BY_TYPE_REJECTED = 'GET_FILTER_ELEMENTS_BY_TYPE_REJECTED';

export const FILTER_MENU_HANDLE_GO_BACK = 'FILTER_MENU_HANDLE_GO_BACK';
export const FILTER_TO_EXPAND = 'FILTER_TO_EXPAND';
export const CLEAR_ELEMENT_FROM_LIST = 'CLEAR_ELEMENT_FROM_LIST';
