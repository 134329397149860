import React from 'react';
import { grey } from '@material-ui/core/colors';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const Checkbox = withStyles({
  root: {
    color: grey[600],
    '&$checked': {
      color: grey[500],
    },
  },
  colorPrimary: {
    color: grey[800],
    '&$checked': {
      color: grey[700],
    },
  },
  checked: {},
})((props) => <MuiCheckbox color="default" {...props} />);

export default Checkbox;
