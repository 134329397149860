import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import {
  IN_PROGRESS, DELIVERED, PACKAGED, ARCHIVED,
} from '../../config/constants';
import CommentBubble from './CommentBubble';

const deliverToStyle = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
  },
  code: {
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  icon: {
    marginLeft: theme.spacing(0.625),
  },
  underlineBold: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  custodian: {
    marginRight: theme.spacing(0.75),
  },
  packageStyle: {
    width: '200px',
    [theme.breakpoints.up(280)]: {
      width: '100px',
    },
    [theme.breakpoints.up(330)]: {
      width: '130px',
    },
    [theme.breakpoints.up(500)]: {
      width: '180px',
    },
    [theme.breakpoints.up(600)]: {
      width: '240px',
    },
    [theme.breakpoints.up(650)]: {
      width: '250px',
    },
    [theme.breakpoints.up(750)]: {
      width: '270px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '470px',
    },
    [theme.breakpoints.up(1100)]: {
      width: '500px',
    },
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  custodianStyle: {
    width: '80px',
    textAlign: 'right',
    [theme.breakpoints.up(370)]: {
      width: '85px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(550)]: {
      width: '100px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(600)]: {
      width: '110px',
      textAlign: 'right',
    },
  },
  defaultWidth: {
    [theme.breakpoints.up(300)]: {
      width: '80px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(370)]: {
      width: '85px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(550)]: {
      width: '100px',
      textAlign: 'right',
    },
    [theme.breakpoints.up(600)]: {
      width: '110px',
      textAlign: 'right',
    },
  },
  patientStyle: {
    width: '200px',
    [theme.breakpoints.up(280)]: {
      width: '120px',
    },
    [theme.breakpoints.up(330)]: {
      width: '180px',
    },
    [theme.breakpoints.up(500)]: {
      width: '180px',
    },
    [theme.breakpoints.up(600)]: {
      width: '240px',
    },
    [theme.breakpoints.up(650)]: {
      width: '250px',
    },
    [theme.breakpoints.up(750)]: {
      width: '270px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '470px',
    },
    [theme.breakpoints.up(1100)]: {
      width: '500px',
    },
    lineHeight: 'normal',
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: [
      'PT Mono',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#82BC00',
    },
    secondary: {
      main: '#530070',
    },
  },
});

export default function SupplyItem({
  user, status, code, date, patient, comments, manuallyCreated,
}) {
  const styles = deliverToStyle();
  const longWidth = useMediaQuery('(min-width:1100px)');
  const desktopWidth = useMediaQuery('(min-width:600px)');
  const tableWidth = useMediaQuery('(min-width:770px)');
  let statusStyle = null;
  let custodian = null;
  const underlineBoldText = (text) => <span className={styles.underlineBold}>{text}</span>;
  if (status === IN_PROGRESS || status === PACKAGED || status === ARCHIVED) {
    if (manuallyCreated) {
      statusStyle = '#F77F00';
    } else {
      statusStyle = 'primary.main';
    }
    custodian = user;
  } else if (status === DELIVERED) {
    statusStyle = grey[600];
    custodian = 'Delivered';
  }

  const custStyle = (codes) => {
    if ((!desktopWidth || !tableWidth) && codes.length > 17 && custodian.length > 20) {
      return styles.custodianStyle;
    } if (!desktopWidth && (codes.length > 17 || custodian.length > 20)) {
      return styles.defaultWidth;
    }
    return '';
  };

  return (
    <Box
      flexGrow={1}
      display="flex"
      className={styles.container}
      component={Link}
      to={`/orders/${code}/logs`}
    >
      <Box
        flexGrow={1}
        alignItems="space-between"
      >
        <Box
          flexGrow={0.5}
        >
          <Box display="flex" alignItems="flex-end">
            <ThemeProvider theme={theme}>
              <Box fontFamily="PT Mono" className={styles.code} color={statusStyle} display="flex">
                <Typography
                  variant="body2"
                  component="div"
                  className={(code.length > 17 && !longWidth) || (code.length > 40 && longWidth)
                    ? styles.packageStyle : styles.code}
                  noWrap
                >
                  PACKAGE-
                  {code}
                </Typography>
                <CommentBubble comments={comments} />
              </Box>
            </ThemeProvider>
          </Box>
          <Box color="text.primary">
            {patient
          && (
          <>
            <Box display="flex" alignItems="flex-end">
              <Box display="flex">
                <Typography variant="body2" className={patient !== undefined ? styles.patientStyle : styles.code} noWrap>
                  To:
                  {' '}
                  {underlineBoldText(patient)}
                </Typography>
              </Box>
            </Box>
          </>
          )}
            {date
            && (
            <>
              Updated
              {' '}
              {moment(date).calendar()}
            </>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" width="auto" flexGrow={0.5} flexShrink={0} alignContent="start" className={styles.custodian}>
        <Typography variant="body2" component="span" noWrap className={custStyle(code)}>
          {custodian}
        </Typography>
      </Box>
    </Box>
  );
}
