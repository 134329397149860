const nav = {
  code: {
    USER_CREATION_FORM: 0,
    PACE_CENTERS_SEARCH: 1,
  },
};

const helperText = {
  FULLNAME: 'Enter the new user full name',
  EMAIL: 'Enter a valid email for the new user',
  PACE_CENTERS: 'please click on the SEARCH button and select the paceCenters to the new user',
  PACE_CENTERS_SEARCH: 'Please enter the Pacecenter ID or name',
  DESCRIPTION: 'Please enter a brief description for the bulk users file',
};

const characterLimit = {
  DESCRIPTION: 40,
};

export { helperText, nav, characterLimit };
