export const POST_LOGS = 'POST_LOGS';
export const POST_LOGS_PENDING = 'POST_LOGS_PENDING';
export const POST_LOGS_FULFILLED = 'POST_LOGS_FULFILLED';
export const POST_LOGS_REJECTED = 'POST_LOGS_REJECTED';

export const POST_LOGS_RESET_STATE = 'POST_LOGS_RESET_STATE';
export const POST_LOGS_RESET_ON_ERROR = 'POST_LOGS_RESET_ON_ERROR';

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';

export const CAMERA_PERMISSION_ERROR = 'CAMERA_PERMISSION_ERROR';
