import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PaginationTable from './PaginationTable';
import { supplyDeliveryColumns, logsColumns } from './constants';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '12px',
  },
  cellStyle: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const DesktopTable = ({
  reportType, list, pageData, handlePagination,
}) => {
  const classes = useStyles();
  const columns = reportType === 'delivery' ? supplyDeliveryColumns : logsColumns;
  const columnsIds = columns.map((e) => e.id);
  return (
    <>
      <Box className={classes.container}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => (
                <TableRow
                  hover
                  key={Math.random()}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {columnsIds !== undefined
                  && (
                    columnsIds.map((e) => <TableCell align="left" key={e} className={classes.cellStyle}>{row[e]}</TableCell>)
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {pageData !== null && (
        <Box display="flex" justifyContent="flex-end">
          <PaginationTable pageData={pageData} handlePagination={handlePagination} />
        </Box>
      )}
    </>
  );
};

export default DesktopTable;
