
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 900,
    minWidth: 300,
    [theme.breakpoints.between(1, 500)]: {
      minWidth: 150,
    },
    [theme.breakpoints.between(500, 768)]: {
      minWidth: 200,
    },
    border: 'none',
    boxShadow: 'none',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundSize: 'contain',
  },
  paddingTop: theme.spacing(2),
}));

export default function ShowDigitalSignature({ image }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={image}
      />
    </Card>
  );
}
