import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const loadingStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 0),
  },
}));

function WithLoadingList(Component) {
  return function WihLoadingComponent({ loading, error, ...props }) {
    const styles = loadingStyles();
    return (
      <Box display="flex" flexDirection="column" flexGrow={1}>
        {(!loading && !error)
          && (
            <Component {...props} loading={loading} error={error} />
          )}
        {loading
          && (
          <Box display="flex" alignItems="center" width={1} flexDirection="column" className={styles.container}>
            <CircularProgress />
          </Box>
          )}
        {error
          && (
          <Box display="flex" justifyContent="center" width={1} className={styles.container}>
            <Alert severity="error">{error}</Alert>
          </Box>
          )}
      </Box>
    );
  };
}
export default WithLoadingList;
