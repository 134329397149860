import React, { useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
}
  from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ButtonAsset from '../../assets/Button';
import FilterChip from './FilterChip';
import FiltersContainer from '../../containers/FiltersContainer';
import { nav } from './constants';
import { capitalizeFirstLetter } from '../../services/utility';
import { FILTER_TYPES } from '../../config/constants';

const filterMenuStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.625, 0),
    margin: theme.spacing(0, 2.5),
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  accordion: {
    margin: theme.spacing(0.5, 0),
  },
  label: {
    marginTop: theme.spacing(3),
  },
  clearFilterButton: {
    marginBottom: theme.spacing(0.8),
    cursor: 'pointer',
    fontSize: theme.spacing(1.5),
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  box: {
    cursor: 'default',
  },
}));

const useStyles = makeStyles((theme) => ({
  hideBorder: {
    '&.MuiExpansionPanel-root:before': {
      display: 'none',
    },
    cursor: 'default',
    fontSize: theme.spacing(1.6),
  },
  expandedPanel: {
    color: theme.palette.primary.main,
  },
  searchPanel: {
    color: theme.palette.secondary.main,
  },
  expandIcon: {
    transform: 'rotate(0deg) !important',
    '&$expanded': {
      transform: 'rotate(0deg) !important',
    },
  },
}));

const myTheme = createTheme({
  overrides: {
    MuiAccordionSummary: {
      root: {
        '&:hover:not(.Mui-disabled)': {
          cursor: 'default',
        },
        expandIcon: {
          transform: 'rotate(0deg) !important',
          '&$expanded': {
            transform: 'rotate(0deg) !important',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#82BC00',
    },
    secondary: {
      main: '#530070',
    },
  },
});

export default function Menu({
  filters, onApplyFilter, navigationValue,
  onClickSeachFilter, filterToApply, onClickDeleteElement,
  onFilterChange,
}) {
  const classes = filterMenuStyle();
  const accordionStyles = useStyles();
  const [searchFilter, setSearchFilter] = useState('');

  const handleSelect = (e, filter) => {
    e.stopPropagation();
    onClickSeachFilter();
    setSearchFilter(filter);
  };

  const handleExpandIcon = (filter, expanded) => {
    if (filter !== FILTER_TYPES.ITEM && filter !== FILTER_TYPES.PATIENT
    && filter !== FILTER_TYPES.CUSTODIAN && filter !== FILTER_TYPES.CENTER) {
      let colorToSet = 'inherit';
      if (filter !== FILTER_TYPES.STATUS && filter !== FILTER_TYPES.ENTRY
        && filter !== FILTER_TYPES.SORT && (filters[filter].value.length > 0
          || filters[filter].tmpValue.length > 0)) {
        colorToSet = 'primary';
      }
      if ((filter === FILTER_TYPES.STATUS || filter === FILTER_TYPES.ENTRY)
      && (filters[filter].value !== 'all' || filters[filter].tmpValue !== 'all')) {
        colorToSet = 'primary';
      } else if (filters[filter].value === 'all' || filters[filter].tmpValue === 'all') {
        colorToSet = 'inherit';
      }
      return <ExpandMoreIcon color={colorToSet} />;
    }
    return (
      <SearchIcon color={expanded ? 'primary' : 'secondary'} onClick={(e) => handleSelect(e, filter)} />
    );
  };

  const handleExpandedChange = (filter) => {
    if (onFilterChange[filter] && filters[filter].tmpValue !== 'all' && filters[filter].tmpValue.length > 0) {
      return true;
    }
    return false;
  };

  const accordionDetail = (filter) => (
    <Fragment
      key={filter}
    >
      { (filter === FILTER_TYPES.STATUS || filter === FILTER_TYPES.ACTIVITY
      || filter === FILTER_TYPES.ENTRY || filter === FILTER_TYPES.UPDATED_DATE
      || filter === FILTER_TYPES.SORT)
      && (<FiltersContainer filterToRender={filter} filterToApply={filterToApply} />) }
      { filter === FILTER_TYPES.ITEM
      && (
      <FilterChip
        listValues={filters[filter].tmpValue !== []
          ? filters[filter].tmpValue : filters[filter].value}
        onClickDeleteElement={onClickDeleteElement}
        filterToRender={filter}
      />
      )}
      { filter === FILTER_TYPES.CUSTODIAN
      && (
      <FilterChip
        listValues={filters[filter].tmpValue !== []
          ? filters[filter].tmpValue : filters[filter].value}
        onClickDeleteElement={onClickDeleteElement}
        filterToRender={filter}
      />
      )}
      { filter === FILTER_TYPES.PATIENT
      && (
      <FilterChip
        listValues={filters[filter].tmpValue !== []
          ? filters[filter].tmpValue : filters[filter].value}
        onClickDeleteElement={onClickDeleteElement}
        filterToRender={filter}
      />
      )}
      { filter === FILTER_TYPES.CENTER
      && (
      <FilterChip
        listValues={filters[filter].tmpValue !== []
          ? filters[filter].tmpValue : filters[filter].value}
        onClickDeleteElement={onClickDeleteElement}
        filterToRender={filter}
      />
      )}
    </Fragment>
  );

  const accordionList = Object.values(FILTER_TYPES)
    .filter((filter) => filters[filter] && filters[filter].show).map((filter) => (
      <Box
        key={filter}
        className={classes.accordion}
      >
        <>
          {(filter === FILTER_TYPES.ITEM || filter === FILTER_TYPES.PATIENT
        || filter === FILTER_TYPES.CUSTODIAN || filter === FILTER_TYPES.CENTER)
        && (
        <Accordion
          className={accordionStyles.hideBorder}
          key={filter}
          expanded={filters[filter].value.length > 0 || filters[filter].tmpValue.length > 0}
          elevation={0}
        >
          <ThemeProvider theme={myTheme}>
            <AccordionSummary
              expandIcon={handleExpandIcon(filter, filters[filter].value.length > 0
                || filters[filter].tmpValue.length > 0)}
              classes={{
                expanded: accordionStyles.expandedPanel,
                expandIcon: accordionStyles.expandIcon,
              }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              key={filter}
            >
              <Typography className={classes.heading}>{capitalizeFirstLetter(filter)}</Typography>
            </AccordionSummary>
          </ThemeProvider>
          <AccordionDetails key={filter}>
            {accordionDetail(filter)}
          </AccordionDetails>
        </Accordion>
        )}
          {(filter !== FILTER_TYPES.ITEM && filter !== FILTER_TYPES.PATIENT
        && filter !== FILTER_TYPES.CUSTODIAN && filter !== FILTER_TYPES.CENTER)
        && (
        <Accordion
          className={accordionStyles.hideBorder}
          defaultExpanded={filters[filter].expanded && filters[filter].tmpValue.length > 0
            && filters[filter].tmpValue !== 'all' && filters[filter].tmpValue !== 'created_date_desc'}
          key={filter}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={onFilterChange[filter] && filters[filter].tmpValue !== 'all' && filters[filter].tmpValue !== 'created_date_desc'
            && filters[filter].tmpValue.length > 0 ? <ExpandMoreIcon color="primary" /> : <ExpandMoreIcon color="inherit" />}
            classes={{
              expanded: handleExpandedChange ? accordionStyles.expandedPanel : 'default',
              content: onFilterChange[filter] && filters[filter].tmpValue !== 'all'
              && filters[filter].tmpValue !== 'created_date_desc'
              && filters[filter].tmpValue.length > 0 ? accordionStyles.expandedPanel : 'default',
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
            key={filter}
          >
            <Typography className={classes.heading}>{capitalizeFirstLetter(filter)}</Typography>
          </AccordionSummary>
          <AccordionDetails key={filter}>
            {accordionDetail(filter)}
          </AccordionDetails>
        </Accordion>
        )}
          <Divider />
        </>
      </Box>
    ));

  return (
    <>
      {navigationValue === nav.code.FILTER_MENU
      && (
        <Container disableGutters>
          <Box
            alignItems="flex-start"
            className={classes.container}
            flexDirection="column"
            flexGrow={1}
          >
            <Box className={classes.accordion}>
              <div className={classes.root}>
                {accordionList}
                <ButtonAsset
                  className={classes.button}
                  onClick={onApplyFilter}
                  variant="cofc"
                >
                  APPLY
                </ButtonAsset>
              </div>
            </Box>
          </Box>
        </Container>
      )}
      {navigationValue === nav.code.SEARCH_FILTER
    && (
      <Box>
        <FiltersContainer filterToRender={searchFilter} filterToApply={filterToApply} />
      </Box>
    )}
    </>
  );
}
