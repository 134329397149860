import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ShowDigitalSignature from './ShowDigitalSignature';
import {
  PACKAGED, IN_PROGRESS, DELIVERED, STEP_TYPES, ARCHIVED,
} from '../../config/constants';

let MAX_LENGTH;

const stepStyle = makeStyles((theme) => ({
  stepHeader: {
    marginTop: theme.spacing(0.625),
  },
  labelContainer: {
    marginBottom: theme.spacing(1.25),
  },
  stepContent: {
    borderLeft: '1px solid #757575',
    marginLeft: theme.spacing(1.5),
    minHeight: theme.spacing(5),
    height: '100%',
  },
  dashedLine: {
    borderLeft: '1px dashed #757575',
    height: theme.spacing(3.75),
  },
  hideLine: {
    borderLeft: 0,
  },
  timeContainer: {
    paddingTop: theme.spacing(0.25),
    fontSize: theme.spacing(1.5),
    width: theme.spacing(7.5),
    display: 'block',
  },
  pointLine: {
    height: theme.spacing(1.375),
    width: theme.spacing(1.375),
    borderRadius: theme.spacing(1.875),
    transform: 'translateX(-45%)',
    boxSizing: 'border-box',
    marginLeft: theme.spacing(1.5),
  },
  paddingTimeline: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.375),
  },
  underlineAndBold: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  paddingExpandButton: {
    cursor: 'pointer',
  },
  iconLine: {
    height: theme.spacing(1.375),
    width: theme.spacing(1.375),
    transform: 'translate(-20%, -50%)',
    color: theme.palette.primary.main,
  },
  trackableItem: {
    color: theme.palette.trackableItem,
  },
  signatureContainer: {
    paddingTop: theme.spacing(1),
  },
  commentStyle: {
    width: '200px',
    [theme.breakpoints.up(280)]: {
      width: '120px',
    },
    [theme.breakpoints.up(330)]: {
      width: '180px',
    },
    [theme.breakpoints.up(500)]: {
      width: '230px',
    },
    [theme.breakpoints.up(600)]: {
      width: '240px',
    },
    [theme.breakpoints.up(650)]: {
      width: '250px',
    },
    [theme.breakpoints.up(750)]: {
      width: '270px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '600px',
    },
  },
}));

export default function Step({
  last, itemStatus, parent, type, data, manuallyCreated, digitalSignature,
}) {
  const [expanded, setExpanded] = useState(false);
  const { time } = data;
  const descriptionFront = manuallyCreated ? ' Package' : ' Packaged';
  const descriptionTail = manuallyCreated ? ' manually entered' : ' shipment';
  const veryLongWidth = useMediaQuery('(min-width:1400px)');
  const longWidth = useMediaQuery('(min-width:1100px)');
  const mediumWidth = useMediaQuery('(min-width:900px)');
  const ipadWidth = useMediaQuery('(min-width:760px)');
  const generitTableWidth = useMediaQuery('(min-width:600px)');
  const largePhoneWidth = useMediaQuery('(min-width:320px)');
  const smallWidth = useMediaQuery('(min-width:320px)');

  if (veryLongWidth) {
    MAX_LENGTH = 130;
  } else if (longWidth) {
    MAX_LENGTH = 100;
  } else if (mediumWidth) {
    MAX_LENGTH = 85;
  } else if (ipadWidth) {
    MAX_LENGTH = 65;
  } else if (generitTableWidth) {
    MAX_LENGTH = 60;
  } else if (largePhoneWidth) {
    MAX_LENGTH = 28;
  } else if (smallWidth) {
    MAX_LENGTH = 10;
  } else {
    MAX_LENGTH = 5;
  }
  const styles = stepStyle();
  let content = null;
  let logContainer = null;
  let commentContainer = null;
  let stepContentStyle = null;
  let bgcolorLastPoint = grey[400];
  const underlineAndBoldText = (text) => <span className={styles.underlineAndBold}>{text}</span>;
  const getLocation = (location) => {
    if (location && location.address && location.address.line) {
      return (
        <>
          {' at '}
          {underlineAndBoldText(location.address.line)}
        </>
      );
    }
    return '';
  };

  if (type === STEP_TYPES.LOG || (type === STEP_TYPES.COMMENT && data.actionType === 'edition')) {
    const { status, location, personName } = data;
    if (status === PACKAGED) {
      content = (
        <>
          {descriptionFront}
          {parent
            ? (
              <>
                {' '}
                in
                {' '}
                <Box color="secondary.main" component="span">
                  {underlineAndBoldText(`${parent.type.toUpperCase()}-${parent.code}`)}
                </Box>
              </>
            )
            : descriptionTail}
          {getLocation(location)}
        </>
      );
    } else if (status === IN_PROGRESS) {
      content = (
        <>
          {underlineAndBoldText(personName)}
          {' '}
          took custody
          {getLocation()}
        </>
      );
    } else if (status === ARCHIVED) {
      content = (
        <>
          Archived
        </>
      );
    } else if (status === DELIVERED) {
      content = (
        <>
          {
          personName
            ? 'Delivered to'
            : 'Delivered last package'
        }
          {' '}
          {underlineAndBoldText(personName)}
          {getLocation()}
        </>
      );
    } else if (type === STEP_TYPES.COMMENT) {
      const { user } = data;
      content = (
        <>
          {underlineAndBoldText(user.name || 'Anonym')}
          {' '}
          edited the item
        </>
      );
    }
    logContainer = (
      <Typography variant="body2" color="textPrimary">
        <span className={styles.labelContainer}>
          {content}
        </span>
      </Typography>
    );

    if (status === DELIVERED && digitalSignature !== null) {
      logContainer = (
        <Box>
          <Typography variant="body2" color="textPrimary">
            <span className={styles.labelContainer}>
              {content}
            </span>
          </Typography>
          <Box className={styles.signatureContainer}>
            <ShowDigitalSignature image={digitalSignature} />
          </Box>
          <Box fontStyle="italic">
            <Typography variant="body2" color="textPrimary">
              <span className={styles.labelContainer}>
                Delivery Signature
              </span>
            </Typography>
          </Box>
        </Box>
      );
    }
  } else if (type === STEP_TYPES.COMMENT && data.actionType === 'comment') {
    const { comment, user } = data;
    content = `" ${comment} "`;
    if (comment.length > MAX_LENGTH && !expanded) {
      content = `"${comment.slice(0, MAX_LENGTH - 3)}..."`;
    }

    commentContainer = (
      <>
        <Box component="span">
          {underlineAndBoldText(user.name || 'Anonym')}
          {' made a comment: '}
        </Box>
        <Box fontStyle="italic" component="span" content="flex">
          {content}
        </Box>
        <Typography
          variant="body2"
          component="span"
        >
          {
            comment.length > MAX_LENGTH && comment.split(' ').filter((e) => e.length > MAX_LENGTH).length < 1
            && (
              <>
                {' '}
                <Box component={Link} onClick={() => setExpanded(!expanded)} fontWeight="fontWeightBold" className={styles.paddingExpandButton}>
                  {expanded
                    ? 'Less'
                    : 'See More'}
                </Box>
              </>
            )
          }
        </Typography>
      </>
    );
  }
  if (last) {
    stepContentStyle = styles.dashedLine;
    if (itemStatus === DELIVERED) {
      stepContentStyle = styles.hideLine;
    }
    if (manuallyCreated) {
      bgcolorLastPoint = styles.trackableItem;
    } else {
      bgcolorLastPoint = 'primary.main';
    }
  }

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column" flexShrink={0}>
        <Typography variant="body2" align="right">
          <Box component="span" display="block" className={styles.timeContainer}>{moment(time).format('h:mm A')}</Box>
        </Typography>
      </Box>
      <div className={styles.paddingTimeline}>
        <div className={styles.stepHeader}>
          {type === STEP_TYPES.LOG || (STEP_TYPES.COMMENT && data.actionType === 'edition')
            ? <Box className={styles.pointLine} bgcolor={bgcolorLastPoint} border={2} borderColor="common.white" />
            : (
              <Box className={styles.pointLine} bgcolor="common.white" border={2} borderColor="common.white">
                <ChatBubbleIcon fontSize="small" style={{ color: bgcolorLastPoint }} className={styles.iconLine} />
              </Box>
            )}
        </div>
        <div className={`${styles.stepContent} ${stepContentStyle}`} />
      </div>
      <Box flexGrow={1} pb={2.5}>
        {
          type === STEP_TYPES.LOG || (STEP_TYPES.COMMENT && data.actionType === 'edition')
            ? logContainer
            : commentContainer
      }
      </Box>
    </Box>
  );
}
