import moment from 'moment';
import { updateObject, createReducer, removeItemFromList } from '../services/utility';
import { FILTER_TYPES, SORT } from '../config/constants';
import * as actionTypes from '../action_types/filters';

const initialState = {
  [FILTER_TYPES.STATUS]: {
    show: false,
    value: 'in-progress',
    expanded: true,
    tmpValue: 'in-progress',
  },
  [FILTER_TYPES.CUSTODIAN]: {
    show: false,
    value: [],
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.PATIENT]: {
    show: false,
    value: [],
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.ITEM]: {
    show: false,
    value: [],
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.ACTIVITY]: {
    show: false,
    value: [],
    startDate: [],
    endDate: [],
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.ENTRY]: {
    show: false,
    value: 'all',
    expanded: false,
    tmpValue: 'all',
  },
  [FILTER_TYPES.UPDATED_DATE]: {
    show: false,
    value: [],
    startDate: [],
    endDate: [],
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.CENTER]: {
    show: false,
    value: [],
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.SORT]: {
    show: false,
    value: 'created_date_desc',
    expanded: false,
    tmpValue: 'created_date_desc',
  },
  searchOutcome: [],
  totalOutcome: 0,
  pendingDataToPaginate: false,
  queryParams: 'status=in-progress&sortType=created_at&sortOrder=DESC',
  loading: false,
  error: null,
  success: false,
  reset: false,
  navigation: 0,
  resetOnBack: false,
};

const defineParamsValues = (type, values) => values.map((value) => `${type}=${value.id}`);

const setQueryParams = (state) => {
  const queryParams = [];
  if (state[FILTER_TYPES.STATUS].value !== 'all') {
    queryParams.push(`${FILTER_TYPES.STATUS}=${encodeURIComponent(state[FILTER_TYPES.STATUS].value)}`);
  }
  if (state[FILTER_TYPES.CUSTODIAN].value.length > 0) {
    const elements = defineParamsValues(FILTER_TYPES.CUSTODIAN,
      state[FILTER_TYPES.CUSTODIAN].value);
    elements.forEach((element) => {
      queryParams.push(element);
    });
  }
  if (state[FILTER_TYPES.PATIENT].value.length > 0) {
    const elements = defineParamsValues(FILTER_TYPES.PATIENT,
      state[FILTER_TYPES.PATIENT].value);
    elements.forEach((element) => {
      queryParams.push(element);
    });
  }
  if (state[FILTER_TYPES.ITEM].value.length > 0) {
    const elements = defineParamsValues('id',
      state[FILTER_TYPES.ITEM].value);
    elements.forEach((element) => {
      queryParams.push(element);
    });
  }
  if (state[FILTER_TYPES.ACTIVITY].value.length > 0) {
    queryParams.push(`startDate=${moment(state[FILTER_TYPES.ACTIVITY].startDate).format('YYYY-MM-DD')}`);
    queryParams.push(`endDate=${moment(state[FILTER_TYPES.ACTIVITY].endDate).format('YYYY-MM-DD')}`);
  }
  if (state[FILTER_TYPES.ENTRY].value !== 'all') {
    queryParams.push(`manuallyCreated=${encodeURIComponent(state[FILTER_TYPES.ENTRY].value === 'manual')}`);
  }
  if (state[FILTER_TYPES.UPDATED_DATE].value.length > 0) {
    queryParams.push(`updatedStartDate=${moment(state[FILTER_TYPES.UPDATED_DATE].startDate).format('YYYY-MM-DD')}`);
    queryParams.push(`updatedEndDate=${moment(state[FILTER_TYPES.UPDATED_DATE].endDate).format('YYYY-MM-DD')}`);
  }
  if (state[FILTER_TYPES.CENTER].value.length > 0) {
    const elements = defineParamsValues(FILTER_TYPES.CENTER,
      state[FILTER_TYPES.CENTER].value);
    elements.forEach((element) => {
      queryParams.push(element);
    });
  }
  if (state[FILTER_TYPES.SORT].value.length > 0) {
    if (state[FILTER_TYPES.SORT].value === SORT.CREATED_DATE_ASC) {
      queryParams.push(`sortType=${SORT.PARAMS.TYPE.CREATED_DATE}`);
      queryParams.push(`sortOrder=${SORT.PARAMS.ORDER.ASC}`);
    } else if (state[FILTER_TYPES.SORT].value === SORT.CREATED_DATE_DESC) {
      queryParams.push(`sortType=${SORT.PARAMS.TYPE.CREATED_DATE}`);
      queryParams.push(`sortOrder=${SORT.PARAMS.ORDER.DESC}`);
    } else if (state[FILTER_TYPES.SORT].value === SORT.UPDATED_DATE_ASC) {
      queryParams.push(`sortType=${SORT.PARAMS.TYPE.UPDATED_DATE}`);
      queryParams.push(`sortOrder=${SORT.PARAMS.ORDER.ASC}`);
    } else if (state[FILTER_TYPES.SORT].value === SORT.UPDATED_DATE_DESC) {
      queryParams.push(`sortType=${SORT.PARAMS.TYPE.UPDATED_DATE}`);
      queryParams.push(`sortOrder=${SORT.PARAMS.ORDER.DESC}`);
    }
  }
  return queryParams.join('&');
};

const filterToMap = (state, filter, filtersToApply) => {
  const index = filtersToApply.findIndex((obj) => filter === obj.filterType);
  if (index !== -1) {
    return {
      value: filtersToApply[index].value,
      startDate: filtersToApply[index].startDate,
      endDate: filtersToApply[index].endDate,
      expanded: true,
      tmpValue: filtersToApply[index].value,
      show: state[filter].show,
    };
  }
  return state[filter];
};

const setFilter = (state, action) => {
  const tmpState = updateObject(state, {
    [action.filterType]: {
      show: state[action.filterType].show,
      expanded: true,
      ...action.payload,
    },
    success: true,
  });
  return updateObject(tmpState, {
    queryParams: setQueryParams(tmpState),
  });
};

const setAllFilter = (state, action) => {
  const tmpState = updateObject(state, {
    [FILTER_TYPES.STATUS]: filterToMap(state, FILTER_TYPES.STATUS, action.filtersListToApply),
    [FILTER_TYPES.CUSTODIAN]: filterToMap(state, FILTER_TYPES.CUSTODIAN, action.filtersListToApply),
    [FILTER_TYPES.PATIENT]: filterToMap(state, FILTER_TYPES.PATIENT, action.filtersListToApply),
    [FILTER_TYPES.ITEM]: filterToMap(state, FILTER_TYPES.ITEM, action.filtersListToApply),
    [FILTER_TYPES.ACTIVITY]: filterToMap(state, FILTER_TYPES.ACTIVITY, action.filtersListToApply),
    [FILTER_TYPES.ENTRY]: filterToMap(state, FILTER_TYPES.ENTRY, action.filtersListToApply),
    [FILTER_TYPES.UPDATED_DATE]: filterToMap(state, FILTER_TYPES.UPDATED_DATE,
      action.filtersListToApply),
    [FILTER_TYPES.CENTER]: filterToMap(state, FILTER_TYPES.CENTER, action.filtersListToApply),
    [FILTER_TYPES.SORT]: filterToMap(state, FILTER_TYPES.SORT, action.filtersListToApply),
    sucess: true,
  });

  return updateObject(tmpState, {
    queryParams: setQueryParams(tmpState),
  });
};

const showFilters = (state, action) => updateObject(state, {
  [FILTER_TYPES.STATUS]: {
    show: action.payload[FILTER_TYPES.STATUS],
    value: state[FILTER_TYPES.STATUS].value,
    expanded: state[FILTER_TYPES.STATUS].expanded,
    tmpValue: state[FILTER_TYPES.STATUS].tmpValue,
  },
  [FILTER_TYPES.CUSTODIAN]: {
    show: action.payload[FILTER_TYPES.CUSTODIAN],
    value: state[FILTER_TYPES.CUSTODIAN].value,
    expanded: false,
    tmpValue: state[FILTER_TYPES.CUSTODIAN].tmpValue,
  },
  [FILTER_TYPES.PATIENT]: {
    show: action.payload[FILTER_TYPES.PATIENT],
    value: state[FILTER_TYPES.PATIENT].value,
    expanded: state[FILTER_TYPES.PATIENT].expanded,
    tmpValue: state[FILTER_TYPES.PATIENT].tmpValue,
  },
  [FILTER_TYPES.ITEM]: {
    show: action.payload[FILTER_TYPES.ITEM],
    value: state[FILTER_TYPES.ITEM].value,
    expanded: state[FILTER_TYPES.ITEM].expanded,
    tmpValue: state[FILTER_TYPES.ITEM].tmpValue,
  },
  [FILTER_TYPES.ACTIVITY]: {
    show: action.payload[FILTER_TYPES.ACTIVITY],
    value: state[FILTER_TYPES.ACTIVITY].value,
    startDate: state[FILTER_TYPES.ACTIVITY].startDate,
    endDate: state[FILTER_TYPES.ACTIVITY].endDate,
    expanded: state[FILTER_TYPES.ACTIVITY].expanded,
    tmpValue: state[FILTER_TYPES.ACTIVITY].tmpValue,
  },
  [FILTER_TYPES.ENTRY]: {
    show: action.payload[FILTER_TYPES.ENTRY],
    value: state[FILTER_TYPES.ENTRY].value,
    expanded: state[FILTER_TYPES.ENTRY].expanded,
    tmpValue: state[FILTER_TYPES.ENTRY].tmpValue,
  },
  [FILTER_TYPES.UPDATED_DATE]: {
    show: action.payload[FILTER_TYPES.UPDATED_DATE],
    value: state[FILTER_TYPES.UPDATED_DATE].value,
    startDate: state[FILTER_TYPES.UPDATED_DATE].startDate,
    endDate: state[FILTER_TYPES.UPDATED_DATE].endDate,
    expanded: state[FILTER_TYPES.UPDATED_DATE].expanded,
    tmpValue: state[FILTER_TYPES.UPDATED_DATE].tmpValue,
  },
  [FILTER_TYPES.CENTER]: {
    show: action.payload[FILTER_TYPES.CENTER],
    value: state[FILTER_TYPES.CENTER].value,
    expanded: state[FILTER_TYPES.CENTER].expanded,
    tmpValue: state[FILTER_TYPES.CENTER].tmpValue,
  },
  [FILTER_TYPES.SORT]: {
    show: action.payload[FILTER_TYPES.SORT],
    value: state[FILTER_TYPES.SORT].value,
    expanded: state[FILTER_TYPES.SORT].expanded,
    tmpValue: state[FILTER_TYPES.SORT].tmpValue,
  },
  searchOutcome: [],
  pendingDataToPaginate: false,
  success: false,
  reset: false,
  navigation: 0,
  resetOnBack: false,
});

const getFilterElementsByType = (state, action) => {
  const tmpState = updateObject(state, {
    searchOutcome: action.payload.page > 0
      ? state.searchOutcome.concat(action.payload.list) : action.payload.list,
    totalOutcome: action.payload.pageData.total,
    loading: false,
    error: null,
  });
  return updateObject(tmpState, {
    pendingDataToPaginate: action.payload.pageData.total > tmpState.searchOutcome.length,
  });
};

const pendingSearchOutcome = (state) => updateObject(state, {
  pendingDataToPaginate: false,
  loading: true,
  error: null,
});

const requestFailed = (state, action) => updateObject(state, {
  searchOutcome: [],
  pendingDataToPaginate: false,
  loading: false,
  error: action.payload.message,
});

const resetFilters = (state) => updateObject(state, {
  [FILTER_TYPES.STATUS]: {
    value: 'all',
    show: state[FILTER_TYPES.STATUS].show,
    expanded: false,
    tmpValue: 'all',
  },
  [FILTER_TYPES.CUSTODIAN]: {
    value: [],
    show: state[FILTER_TYPES.CUSTODIAN].show,
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.PATIENT]: {
    value: [],
    show: state[FILTER_TYPES.PATIENT].show,
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.ITEM]: {
    value: [],
    show: state[FILTER_TYPES.ITEM].show,
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.ACTIVITY]: {
    value: [],
    startDate: [],
    endDate: [],
    show: state[FILTER_TYPES.ACTIVITY].show,
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.ENTRY]: {
    value: 'all',
    show: state[FILTER_TYPES.ENTRY].show,
    expanded: false,
    tmpValue: 'all',
  },
  [FILTER_TYPES.UPDATED_DATE]: {
    value: [],
    startDate: [],
    endDate: [],
    show: state[FILTER_TYPES.UPDATED_DATE].show,
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.CENTER]: {
    value: [],
    show: state[FILTER_TYPES.CENTER].show,
    expanded: false,
    tmpValue: [],
  },
  [FILTER_TYPES.SORT]: {
    value: 'created_date_desc',
    show: state[FILTER_TYPES.SORT].show,
    expanded: false,
    tmpValue: 'created_date_desc',
  },
  searchOutcome: [],
  pendingDataToPaginate: false,
  queryParams: 'sortType=created_at&sortOrder=DESC',
  loading: false,
  error: null,
  success: false,
  reset: true,
  navigation: 0,
  resetOnBack: false,
});

const resetOnSearchFilters = (state) => updateObject(state, {
  searchOutcome: [],
  totalOutcome: 0,
  pendingDataToPaginate: false,
});

const resetOnBackMenuFilters = (state) => (updateObject(state, {
  [FILTER_TYPES.STATUS]: {
    value: state[FILTER_TYPES.STATUS].value,
    show: state[FILTER_TYPES.STATUS].show,
    expanded: state[FILTER_TYPES.STATUS].value === 'all' ? false : state[FILTER_TYPES.STATUS].expanded,
    tmpValue: state[FILTER_TYPES.STATUS].value,
  },
  [FILTER_TYPES.ENTRY]: {
    value: state[FILTER_TYPES.ENTRY].value,
    show: state[FILTER_TYPES.ENTRY].show,
    expanded: state[FILTER_TYPES.STATUS].value === 'all' ? false : state[FILTER_TYPES.ENTRY].expanded,
    tmpValue: state[FILTER_TYPES.ENTRY].value,
  },
  [FILTER_TYPES.ACTIVITY]: {
    value: state[FILTER_TYPES.ACTIVITY].value,
    show: state[FILTER_TYPES.ACTIVITY].show,
    expanded: state[FILTER_TYPES.ACTIVITY].value !== [],
    tmpValue: state[FILTER_TYPES.ACTIVITY].value,
    startDate: state[FILTER_TYPES.ACTIVITY].startDate,
    endDate: state[FILTER_TYPES.ACTIVITY].endDate,
  },
  [FILTER_TYPES.CENTER]: {
    value: state[FILTER_TYPES.CENTER].value,
    show: state[FILTER_TYPES.CENTER].show,
    expanded: false,
    tmpValue: state[FILTER_TYPES.CENTER].value.length > 0
      ? state[FILTER_TYPES.CENTER].value : [],
  },
  [FILTER_TYPES.UPDATED_DATE]: {
    value: state[FILTER_TYPES.UPDATED_DATE].value,
    show: state[FILTER_TYPES.UPDATED_DATE].show,
    expanded: state[FILTER_TYPES.UPDATED_DATE].value !== [],
    tmpValue: state[FILTER_TYPES.UPDATED_DATE].value,
    startDate: state[FILTER_TYPES.UPDATED_DATE].startDate,
    endDate: state[FILTER_TYPES.UPDATED_DATE].endDate,
  },
  [FILTER_TYPES.ITEM]: {
    value: state[FILTER_TYPES.ITEM].value,
    show: state[FILTER_TYPES.ITEM].show,
    expanded: false,
    tmpValue: state[FILTER_TYPES.ITEM].value.length > 0 ? state[FILTER_TYPES.ITEM].value : [],
  },
  [FILTER_TYPES.CUSTODIAN]: {
    value: state[FILTER_TYPES.CUSTODIAN].value,
    show: state[FILTER_TYPES.CUSTODIAN].show,
    expanded: false,
    tmpValue: state[FILTER_TYPES.CUSTODIAN].value.length > 0
      ? state[FILTER_TYPES.CUSTODIAN].value : [],
  },
  [FILTER_TYPES.PATIENT]: {
    value: state[FILTER_TYPES.PATIENT].value,
    show: state[FILTER_TYPES.PATIENT].show,
    expanded: false,
    tmpValue: state[FILTER_TYPES.PATIENT].value.length > 0
      ? state[FILTER_TYPES.PATIENT].value : [],
  },
  [FILTER_TYPES.SORT]: {
    value: state[FILTER_TYPES.SORT].value,
    show: state[FILTER_TYPES.SORT].show,
    expanded: state[FILTER_TYPES.STATUS].value === 'created_date_desc' ? false : state[FILTER_TYPES.ENTRY].expanded,
    tmpValue: state[FILTER_TYPES.SORT].value.length > 0
      ? state[FILTER_TYPES.SORT].value : [],
  },
  resetOnBack: true,
  reset: false,
}));

const setFiltersExpanded = (state, action) => (updateObject(state, {
  [action.filterType]: {
    value: state[action.filterType].value,
    show: state[action.filterType].show,
    tmpValue: action.payload.tmpValue,
    expanded: true,
  },
  reset: false,
}));

const clearElementFromList = (state, action) => (updateObject(state, {
  [action.filterType]: {
    value: action.payload.value,
    show: state[action.filterType].show,
    tmpValue: removeItemFromList(action.payload.tmpValue, action.payload.element),
    expanded: removeItemFromList(action.payload.tmpValue,
      action.payload.element).length > 0,
    startDate: state[action.filterType].startDate,
    endDate: state[action.filterType].endDate,
  },
  reset: false,
}));

const handleGoBack = (state, action) => updateObject(state,
  { navigation: action.payload.navigation });

export default createReducer(initialState, {
  [actionTypes.SET_SUPPLIES_FILTERS]: setFilter,
  [actionTypes.RESET_FILTERS]: resetFilters,
  [actionTypes.SHOW_FILTERS]: showFilters,
  [actionTypes.GET_FILTER_ELEMENTS_BY_TYPE_PENDING]: pendingSearchOutcome,
  [actionTypes.GET_FILTER_ELEMENTS_BY_TYPE_FULFILLED]: getFilterElementsByType,
  [actionTypes.GET_FILTER_ELEMENTS_BY_TYPE_REJECTED]: requestFailed,
  [actionTypes.FILTER_MENU_HANDLE_GO_BACK]: handleGoBack,
  [actionTypes.SET_ALL_SUPPLIES_FILTERS]: setAllFilter,
  [actionTypes.RESET_ON_SEARCH_FILTERS]: resetOnSearchFilters,
  [actionTypes.FILTER_TO_EXPAND]: setFiltersExpanded,
  [actionTypes.RESET_ON_BACK_MENU_FILTERS]: resetOnBackMenuFilters,
  [actionTypes.CLEAR_ELEMENT_FROM_LIST]: clearElementFromList,
});
