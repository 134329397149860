import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import MuiTooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import {
  makeStyles, withStyles,
} from '@material-ui/core/styles';
import Button from '../../assets/Button';
import { TRACKABLE_ITEM } from '../../config/constants';

const deliverToStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1.25, 1.5),
  },
  button: {
    width: 'inherit',
  },
  TrackableItem: {
    color: 'primary',
    width: 'inherit',
    backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
    '&:hover': {
      backgroundColor: TRACKABLE_ITEM.COLOR.HOVER,
      '@media (hover: none)': {
        backgroundColor: TRACKABLE_ITEM.COLOR.MAIN,
      },
    },
  },
  typographyStyle: {
    width: '200px',
    [theme.breakpoints.up(280)]: {
      width: '100px',
    },
    [theme.breakpoints.up(330)]: {
      width: '230px',
    },
    [theme.breakpoints.up(500)]: {
      width: '280px',
    },
    [theme.breakpoints.up(600)]: {
      width: '300px',
    },
    [theme.breakpoints.up(750)]: {
      width: '500px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '800px',
    },
    fontSize: '16px',
    lineHeight: 'normal',
  },
  code: {
    fontSize: '16px',
    lineHeight: 'normal',
  },
}));

const Tooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(14),
  },
}))(MuiTooltip);

export default function DeliveredTo({
  location, code, disableDeliverButton, patient, manuallyCreated,
}) {
  const styles = deliverToStyle();
  const labelText = disableDeliverButton ? 'Only the custodian can deliver the item' : '';
  return (
    <Box display="flex" className={styles.container} bgcolor={grey[50]}>
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">
          Should be delivered to:
        </Typography>
        <Box display="flex" alignItems="flex-end">
          <Typography variant="h6" className={patient !== null && patient.length > 40 ? styles.typographyStyle : styles.code} noWrap>
            {patient !== null ? patient : 'Unknown'}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Typography
            variant="body1"
            className={location !== null && location.address !== null
            && location.address.line !== null
            && location.address.line[0].length > 40 ? styles.typographyStyle : styles.code}
            noWrap
          >
            {location && location.address && location.address.line}
          </Typography>
        </Box>

      </Box>
      <Box display="flex" flexGrow={1} justifyContent="flex-end">
        <div>
          <Tooltip
            disableFocusListener
            disableTouchListener
            placement="top-start"
            title={labelText}
          >
            <Box component="span" display="block">
              <Button
                disabled={disableDeliverButton}
                disableElevation
                component={Link}
                to={`/orders/${code}/deliver`}
                variant="cofc"
                className={manuallyCreated ? styles.TrackableItem : styles.button}
                color="primary"
              >
                DELIVER
              </Button>
            </Box>
          </Tooltip>
        </div>
      </Box>
    </Box>
  );
}
