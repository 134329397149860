import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const stepperDateStyle = makeStyles((theme) => ({
  dateTimeline: {
    padding: theme.spacing(0, 0.375),
    marginLeft: theme.spacing(2.875),
    boxSizing: 'border-box',
  },
  paddingTimelineDate: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.375),
  },
  connectorVertical: {
    minHeight: theme.spacing(5),
    height: '100%',
    marginLeft: theme.spacing(9),
    marginBottom: theme.spacing(-1.25),
  },
}));

export default function StepperDate({ date }) {
  const styles = stepperDateStyle();
  return (
    <Box display="flex" className={styles.container}>
      <Box display="flex" flexDirection="column">
        <Typography variant="body2" component="div">
          <Box className={styles.dateTimeline} color="secondary.main" border={1} borderColor="secondary.main" bgcolor="background.default">
            {moment(date).format('MMM DD, YYYY')}
          </Box>
        </Typography>
        <div className={styles.paddingTimelineDate}>
          <Box className={styles.connectorVertical} borderLeft={1} borderColor={grey[600]} />
        </div>
      </Box>
    </Box>
  );
}
