import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Pagination } from '@material-ui/lab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { pageChange } from '../../actions/supplies';

const paginationStyle = {
  paddingRight: '50px',
  marginTop: '15px',
  marginBottom: '15px',
};

const PaginationList = ({ pageData, handlePagination }) => {
  const [pageTotal, setPageTotal] = useState(0);
  const widthSize = useMediaQuery('(min-width:540px)');
  const dispatch = useDispatch();

  useEffect(() => {
    const pagesNum = Math.ceil(pageData.total / pageData.pageSize);
    setPageTotal(pagesNum);
  });

  const handleChange = (event, value) => {
    handlePagination(value - 1);
    dispatch(pageChange(value - 1));
  };

  return (
    <Pagination
      count={pageTotal}
      page={pageData.page + 1}
      onChange={handleChange}
      showFirstButton
      showLastButton
      style={paginationStyle}
      size={widthSize ? 'medium' : 'small'}
    />
  );
};

export default PaginationList;
