import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/dashboad';
import { ROLES_TYPES } from '../config/constants';

const initialState = {
  isLoading: false,
  error: null,
  isSuccess: false,
  total: 0,
  navigation: 0,
  page: 0,
  pageData: null,
  list: [],
  value: null,
  userData: [],
  role: null,
  userRol: null,
  paceCenters: [],
  formError: null,
  paceCentersList: [],
  searchOutcome: [],
  totalOutcome: 0,
  rolesList: [],
  loading: false,
  pendingDataToPaginate: false,
  loadingCreation: false,
  successCreation: false,
  loadingEdition: false,
  successEdition: false,
  loadingGeneration: false,
  successGeneration: false,
  alerts: [],
  userId: null,
  jobId: null,
  jobDescription: null,
  [ROLES_TYPES.ADMIN]: {
    show: true,
    checked: false,
    label: 'Admin',
  },
  [ROLES_TYPES.MANAGER]: {
    show: true,
    checked: false,
    label: 'Manager',
  },
  [ROLES_TYPES.COMMON]: {
    show: true,
    checked: false,
    label: 'Common',
  },
};

const setFilter = (state, actualRole, role) => {
  if (actualRole !== role) {
    return initialState[actualRole];
  }

  return {
    show: state[role].show,
    checked: !state[role].checked,
    label: state[role].label,
  };
};

const getUsers = (state, action) => updateObject(state, {
  list: action.payload.list,
  error: null,
  page: action.payload.page,
  pageData: action.payload.pageData,
  isLoading: false,
  isSuccess: true,
});

const getUserById = (state, action) => updateObject(state, {
  error: null,
  userData: action.payload.user,
  userRol: action.payload.rol,
  paceCenters: action.payload.paceCenters,
  isLoading: false,
  isSuccess: true,
});

const requestFailed = (state, action) => updateObject(state, {
  ...initialState,
  isLoading: false,
  isSuccess: false,
  error: action.payload.response.data.error.details,
});

const resetState = (state) => updateObject(state, initialState);

const resetUserCreationState = (state) => updateObject(state, {
  paceCentersList: [],
  searchOutcome: [],
  totalOutcome: 0,
  loading: false,
  pendingDataToPaginate: false,
});

const requestUsers = (state) => updateObject(state, {
  ...initialState,
  paceCentersList: [],
  isLoading: true,
});

const requestUserById = (state) => updateObject(state, {
  ...initialState,
  isLoading: true,
});

const requestDeactivationByUserId = (state) => updateObject(state, {
  isLoading: true,
});

const requestActivationByUserId = (state) => updateObject(state, {
  isLoading: true,
});

const requestPaceCentersList = (state) => updateObject(state, {
  pendingDataToPaginate: false,
  loading: true,
  error: null,
});

const requestRoles = (state) => updateObject(state, {
  ...initialState,
  isLoading: true,
});

const DeactivateByUserId = (state, action) => updateObject(state, {
  userData: action.payload.user,
  isSuccess: true,
  isLoading: false,
});

const ActivateByUserId = (state, action) => updateObject(state, {
  userData: action.payload.user,
  isSuccess: true,
  isLoading: false,
});

const getRoles = (state, action) => updateObject(state, {
  error: null,
  rolesList: action.payload.roles,
  isLoading: false,
  isSuccess: true,
});

const getPaceCenterList = (state, action) => {
  const tmpState = updateObject(state, {
    searchOutcome: action.payload.page > 0
      ? state.searchOutcome.concat(action.payload.list) : action.payload.list,
    totalOutcome: action.payload.pageData.total,
    loading: false,
    error: null,
    isSuccess: true,
    page: action.payload.page,
  });

  return updateObject(tmpState, {
    pendingDataToPaginate: action.payload.pageData.total > tmpState.searchOutcome.length,
  });
};

const requestSingleUserCreation = (state) => updateObject(state, {
  formError: null,
  loadingCreation: true,
});

const requestSingleUserEdition = (state) => updateObject(state, {
  formError: null,
  loadingEdition: true,
});

const singleUserCreation = (state, action) => updateObject(state, {
  formError: null,
  loadingCreation: false,
  successCreation: true,
  userId: action.payload.userId,
});

const singleUserEdition = (state) => updateObject(state, {
  formError: null,
  loadingEdition: false,
  successEdition: true,
});

const requestBulkUsersCreation = (state) => updateObject(state, {
  formError: null,
  loadingCreation: true,
  successCreation: false,
});

const bulkUsersCreation = (state, action) => updateObject(state, {
  formError: null,
  loadingCreation: false,
  successCreation: true,
  jobId: action.payload.jobId,
  jobDescription: action.payload.description,
});

const handleGoBack = (state, action) => updateObject(state,
  { navigation: action.payload.navigation });

const resetPaceCentersSearchState = (state) => updateObject(state, {
  error: null,
  searchOutcome: [],
  totalOutcome: 0,
  loading: false,
  pendingDataToPaginate: false,
  page: 0,
});

const singleUserCreationFailed = (state, action) => updateObject(state, {
  loadingCreation: false,
  successCreation: false,
  formError: action.payload.response.data.error.details,
});

const singleUserEditionFailed = (state, action) => updateObject(state, {
  loadingEdition: false,
  successEdition: false,
  formError: action.payload.response.data.error.details,
});

const bulkUsersCreationFailed = (state, action) => updateObject(state, {
  loadingCreation: false,
  successCreation: false,
  formError: action.payload.response.data.error.details,
});

const addAlert = (state, action) => updateObject(state, {
  alerts: [...state.alerts, action.payload.alert],
});

const removeAlert = (state, action) => updateObject(state, {
  alerts: state.alerts.filter((alert) => alert !== action.payload.alert),
});

const clearAlerts = (state) => updateObject(state, { alerts: initialState.alerts });

const postResetState = (state) => updateObject(state, {
  ...initialState,
  alerts: state.alerts,
  rolesList: state.rolesList,
  successCreation: state.successCreation,
});

const requestUsersReportGeneration = (state) => updateObject(state, {
  formError: null,
  loadingCreation: true,
  successGeneration: false,
});

const usersReportGeneration = (state, action) => updateObject(state, {
  formError: null,
  loadingGeneration: false,
  successGeneration: true,
  jobId: action.payload.jobId,
  jobDescription: action.payload.description,
});

const requestUsersbyRole = (state) => updateObject(state, {
  paceCentersList: [],
  isLoading: true,
});

const getUsersByRole = (state, action) => updateObject(state, {
  list: action.payload.list,
  error: null,
  page: action.payload.page,
  pageData: action.payload.pageData,
  isLoading: false,
  isSuccess: true,
});

const setRoleToFilter = (state, action) => updateObject(state, {
  [ROLES_TYPES.ADMIN]: setFilter(state, ROLES_TYPES.ADMIN, action.payload.role),
  [ROLES_TYPES.MANAGER]: setFilter(state, ROLES_TYPES.MANAGER, action.payload.role),
  [ROLES_TYPES.COMMON]: setFilter(state, ROLES_TYPES.COMMON, action.payload.role),
});

export default createReducer(initialState, {
  [actionTypes.GET_USERS_PENDING]: requestUsers,
  [actionTypes.GET_USERS_FULFILLED]: getUsers,
  [actionTypes.GET_USERS_REJECTED]: requestFailed,
  [actionTypes.DASHBOARD_RESET_STATE]: resetState,
  [actionTypes.GET_USER_BY_ID_PENDING]: requestUserById,
  [actionTypes.GET_USER_BY_ID_FULFILLED]: getUserById,
  [actionTypes.GET_USER_BY_ID_REJECTED]: requestFailed,
  [actionTypes.DASHBOARD_HANDLE_GO_BACK]: handleGoBack,
  [actionTypes.POST_DEACTIVATION_USER_BY_ID_PENDING]: requestDeactivationByUserId,
  [actionTypes.POST_DEACTIVATION_USER_BY_ID_FULFILLED]: DeactivateByUserId,
  [actionTypes.POST_DEACTIVATION_USER_BY_ID_REJECTED]: requestFailed,
  [actionTypes.POST_ACTIVATION_USER_BY_ID_PENDING]: requestActivationByUserId,
  [actionTypes.POST_ACTIVATION_USER_BY_ID_FULFILLED]: ActivateByUserId,
  [actionTypes.POST_ACTIVATION_USER_BY_ID_REJECTED]: requestFailed,
  [actionTypes.GET_PACE_CENTERS_LIST_PENDING]: requestPaceCentersList,
  [actionTypes.GET_PACE_CENTERS_LIST_FULFILLED]: getPaceCenterList,
  [actionTypes.GET_PACE_CENTERS_LIST_REJECTED]: requestFailed,
  [actionTypes.GET_ROLES_PENDING]: requestRoles,
  [actionTypes.GET_ROLES_FULFILLED]: getRoles,
  [actionTypes.GET_ROLES_REJECTED]: requestFailed,
  [actionTypes.RESET_USER_CREATION_STATE]: resetUserCreationState,
  [actionTypes.RESET_PACE_CENTERS_SEARCH_STATE]: resetPaceCentersSearchState,
  [actionTypes.POST_SINGLE_USER_CREATION_PENDING]: requestSingleUserCreation,
  [actionTypes.POST_SINGLE_USER_CREATION_FULFILLED]: singleUserCreation,
  [actionTypes.POST_SINGLE_USER_CREATION_REJECTED]: singleUserCreationFailed,
  [actionTypes.ADD_ALERT]: addAlert,
  [actionTypes.REMOVE_ALERT]: removeAlert,
  [actionTypes.CLEAR_ALERTS]: clearAlerts,
  [actionTypes.POST_RESET_STATE]: postResetState,
  [actionTypes.POST_BULK_USERS_CREATION_PENDING]: requestBulkUsersCreation,
  [actionTypes.POST_BULK_USERS_CREATION_FULFILLED]: bulkUsersCreation,
  [actionTypes.POST_BULK_USERS_CREATION_REJECTED]: bulkUsersCreationFailed,
  [actionTypes.POST_SINGLE_USER_EDITION_PENDING]: requestSingleUserEdition,
  [actionTypes.POST_SINGLE_USER_EDITION_FULFILLED]: singleUserEdition,
  [actionTypes.POST_SINGLE_USER_EDITION_REJECTED]: singleUserEditionFailed,
  [actionTypes.USERS_REPORT_GENERATION_PENDING]: requestUsersReportGeneration,
  [actionTypes.USERS_REPORT_GENERATION_FULFILLED]: usersReportGeneration,
  [actionTypes.USERS_REPORT_GENERATION_REJECTED]: requestFailed,
  [actionTypes.GET_USERS_BY_ROLE_PENDING]: requestUsersbyRole,
  [actionTypes.GET_USERS_BY_ROLE_FULFILLED]: getUsersByRole,
  [actionTypes.GET_USERS_BY_ROLE_REJECTED]: requestFailed,
  [actionTypes.SET_ROLE_TO_FILTER]: setRoleToFilter,
});
