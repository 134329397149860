import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../assets/Button';

const FooterButtonStyle = makeStyles((theme) => ({
  container: {
    bottom: 0,
    padding: theme.spacing(2.5, 1.5),
    position: 'fixed',
  },
  button: {
    padding: theme.spacing(0.75, 2),
  },
}));

export default function FooterButton({
  textLabel, bgcolor, onClickFooterButton, disableFooterButton,
}) {
  const styles = FooterButtonStyle();
  const handleApplyFilter = (event) => {
    event.stopPropagation();
    onClickFooterButton();
  };
  return (
    <Box display="flex" className={styles.container} width={1} bgcolor={bgcolor}>
      <Box display="flex" flexGrow={1} justifyContent="center">
        <Button
          disableElevation
          disabled={disableFooterButton}
          onClick={handleApplyFilter}
          variant="cofc"
          className={styles.button}
        >
          {textLabel}
        </Button>
      </Box>
    </Box>
  );
}
