import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Box, Container, ListItemAvatar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import UserAvatar from './UserAvatar';
import PaceCentersSection from './PaceCentersSection';
import OptionsMenu from './OptionsMenu';

const userInfoStyle = makeStyles((theme) => ({
  item: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  itemText: {
    paddingLeft: theme.spacing(0),
    maxWidth: '50%',
  },
  container: {
    paddingTop: theme.spacing(1),
  },
  bottomContent: {
    paddingBottom: theme.spacing(0.5),
  },
}));

const UserInfo = ({
  userData,
  userRol,
  userStatus,
  paceCentersList,
  loading,
  handleEventOptions,
  disableStatus,
  anchorEl,
  setAnchorEl,
}) => {
  const styles = userInfoStyle();
  return (
    <>
      {userData.bulkCreation && userData.lastLogin.length === 0
      && (
        <Box>
          <Alert severity="info">
            This user has been created by a users bulk import process.
            The role will be assigned upon the first login.
          </Alert>
        </Box>
      )}
      {!loading && userRol !== undefined
    && (
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
        }}
      >
        <li>
          <Box width={1}>
            <Box
              display="flex"
              className={styles.bottomContent}
              justifyContent="space-between"
            >
              <Typography
                sx={{ mt: 1.5, ml: 2 }}
                display="block"
                variant="h6"
              >
                {userData.name}
              </Typography>
              <OptionsMenu
                status={userStatus}
                HandleEventOptions={handleEventOptions}
                disableStatus={disableStatus}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
              />
            </Box>
          </Box>
        </li>
        <li />
        <Divider component="li" />
        <ListItem className={styles.item}>
          <ListItemText primary="Email" secondary={userData.email} />
          <ListItemAvatar>
            <UserAvatar userName={String(userData.name)} />
          </ListItemAvatar>
        </ListItem>
        <Divider component="li" />
        <ListItem className={styles.item}>
          <ListItemText primary="Username" secondary={userData.username} />
        </ListItem>
        <Divider component="li" />
        <ListItem className={styles.item}>
          <ListItemText className={styles.itemText} primary="Created Date" secondary={userData.createdDate} />
          <ListItemText className={styles.itemText} primary="Last Login" secondary={userData.lastLogin} />
        </ListItem>
        <Divider component="li" />
        <ListItem className={styles.item}>
          <ListItemText className={styles.itemText} primary="Status" secondary={userData.status} />
          <ListItemText className={styles.itemText} primary="Role" secondary={userRol ? userRol.name : null} />
        </ListItem>
        <Divider component="li" variant="inset" />
        <Box className={styles.container}>
          <li>
            <Typography
              sx={{ mt: 0.5, ml: 9 }}
              display="block"
            >
              paceCenters
            </Typography>
          </li>
          <ListItem>
            <Container>
              <PaceCentersSection
                paceCentersList={paceCentersList}
                editMode={false}
              />
            </Container>
          </ListItem>
        </Box>
      </List>
    )}
    </>
  );
};

export default UserInfo;
