
import React from 'react';
import { grey } from '@material-ui/core/colors';
import MuiRadio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';

const Checkbox = withStyles({
  root: {
    color: `${grey[800]} !important`,
  },
})((props) => <MuiRadio {...props} />);

export default Checkbox;
