import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/deliver';

const initialState = {
  isLoading: false,
  error: null,
  isSuccess: false,
};

const postLogToDeliver = (state) => updateObject(state, {
  ...initialState,
  isSuccess: true,
});

const requestFailed = (state, action) => updateObject(state, {
  ...initialState,
  error: action.payload.message,
});

const resetState = (state) => updateObject(state, initialState);

const sendLog = (state) => updateObject(state, {
  ...initialState,
  isLoading: true,
});

export default createReducer(initialState, {
  [actionTypes.POST_DELIVER_PENDING]: sendLog,
  [actionTypes.POST_DELIVER_FULFILLED]: postLogToDeliver,
  [actionTypes.POST_DELIVER_REJECTED]: requestFailed,
  [actionTypes.POST_DELIVER_RESET_STATE]: resetState,
});
