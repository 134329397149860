import React from 'react';
import PaceCentersChips from './PaceCentersChips';

const PaceCentersSection = ({ paceCentersList, editMode }) => (
  <>
    <PaceCentersChips
      paceCentersList={paceCentersList}
      editMode={editMode}
    />
  </>
);

export default PaceCentersSection;
