import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import StatusBar from '../StatusBar';
import DeliverFooter from './DeliverFooter';
import Checkbox from '../../assets/Checkbox';
import PatientLocation from './PatientLocation';
import { DELIVER_TYPES } from '../../config/constants';
import CaregiverForm from './CaregiverForm';
import DigitalSignature from './DigitalSignature';

const deliverPackageStyle = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2.5),
  },
  attest: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  canvas: {
    margin: theme.spacing(0, 0, 7, 0),
  },
  typographyStyle: {
    width: '200px',
    [theme.breakpoints.up(280)]: {
      width: '150px',
    },
    [theme.breakpoints.up(330)]: {
      width: '250px',
    },
    [theme.breakpoints.up(350)]: {
      width: '270px',
    },
    [theme.breakpoints.up(500)]: {
      width: '350px',
    },
    [theme.breakpoints.up(600)]: {
      width: '400px',
    },
    [theme.breakpoints.up(750)]: {
      width: '500px',
    },
    [theme.breakpoints.up(1000)]: {
      width: '800px',
    },
    lineHeight: 'normal',
  },
  code: {
    lineHeight: 'normal',
  },
}));

export default function DeliverPackage({
  logs,
  loading,
  error,
  deliverTo,
  giverName,
  caregiverFirstname,
  caregiverLastname,
  atttestGiver,
  disableCheckbox,
  disableDeliverButton,
  onChangeDeliverTo,
  onCancelDeliver,
  onChangeCaregiverFirstname,
  onChangeCaregiverLastname,
  onAttestGiver,
  onDeliverSupply,
  signatureRef,
  onSaveSignature,
  onClearSignature,
  isLoading,
}) {
  const labelText = `I attest this was given to ${giverName}`;
  const styles = deliverPackageStyle();
  const { manuallyCreated, location } = logs;
  const showRadioPatient = location.name === null ? 'none' : '';

  const handleChangeDeliverTo = (event) => {
    event.stopPropagation();
    onChangeDeliverTo(event.target.value);
  };
  const handleAttestGiver = (event) => {
    event.stopPropagation();
    onAttestGiver(event.target.checked);
  };
  return (
    <>
      {!loading && !error
      && (
      <Box display="flex" flexDirection="column" flexGrow={1} className={styles.paddingTop}>
        <StatusBar {...logs} />
        <Box className={styles.container}>
          <Box>
            <Typography variant="body1">
              Who is receiving this package?
            </Typography>
          </Box>
          <Box display="flex" flexGrow={1}>
            <Box component={FormControl} display="flex" flexGrow={1}>
              <RadioGroup aria-label="giver" name="giver" value={deliverTo} onChange={handleChangeDeliverTo}>
                <Box display={showRadioPatient}>
                  <FormControlLabel value={DELIVER_TYPES.PATIENT} control={<Radio color="default" />} label="Patient" />
                  <PatientLocation location={logs.location} />
                </Box>
                <Box>
                  <FormControlLabel value={DELIVER_TYPES.CAREGIVER} control={<Radio color="default" />} label={manuallyCreated && location.name === null ? 'Recipient' : 'Caregiver'} />
                </Box>
                <CaregiverForm
                  disableForm={deliverTo === DELIVER_TYPES.PATIENT}
                  caregiverFirstname={caregiverFirstname}
                  caregiverLastname={caregiverLastname}
                  onChangeCaregiverFirstname={onChangeCaregiverFirstname}
                  onChangeCaregiverLastname={onChangeCaregiverLastname}
                />
              </RadioGroup>
            </Box>
          </Box>
          <Box className={styles.attest}>
            <FormControlLabel
              control={<Checkbox name="attest" checked={atttestGiver} onChange={handleAttestGiver} disabled={disableCheckbox} />}
              label={(
                <Box display="flex" alignItems="flex-end">
                  <Typography variant="subtitle2" className={styles.typographyStyle} noWrap>{labelText}</Typography>
                </Box>
            )}
            />
          </Box>
          <Box
            className={styles.canvas}
            display="center"
            flexGrow={1}
            flexDirection="column"
            justifyContent="center"
          >
            <DigitalSignature
              manuallyCreated={logs.manuallyCreated}
              signatureRef={signatureRef}
              onSaveSignature={onSaveSignature}
              onClearSignature={onClearSignature}
            />
          </Box>
        </Box>
        <DeliverFooter
          disableDeliverButton={disableDeliverButton}
          onCancelDeliver={onCancelDeliver}
          onDeliverSupply={onDeliverSupply}
          manuallyCreated={logs.manuallyCreated}
          isLoading={isLoading}
        />
      </Box>
      )}
    </>
  );
}
