import moment from 'moment';
import { updateObject, createReducer } from '../services/utility';
import * as actionTypes from '../action_types/reports';

const initialState = {
  error: null,
  isSuccessPaceCenter: false,
  isSuccessTrackableItem: false,
  alerts: [],
  navigation: 0,
  loading: false,
  loadingReport: false,
  rolValidation: false,
  pendingDataToPaginate: false,
  page: 0,
  pageData: null,
  queryParams: [],
  reportType: null,
  startDate: null,
  endDate: null,
  paceCenter: null,
  list: null,
  searchOutcome: [],
  totalOutcome: 0,
  isSuccess: false,
  isLoading: false,
  jobId: null,
  jobDescription: null,
  loadingGeneration: false,
  successGeneration: false,
  loadingCSV: false,
  loadingPDF: false,
  downloadCompleted: false,
};

const setQueryParams = (state) => {
  const queryParams = [];
  if (state.reportType !== null) {
    queryParams.push(`reportType=${encodeURIComponent(state.reportType)}`);
  }
  if (state.paceCenter !== null) {
    queryParams.push(`paceCenter=${encodeURIComponent(state.paceCenter)}`);
  }
  if (state.startDate !== null) {
    queryParams.push(`startDate=${moment(state.startDate).format('YYYY-MM-DD')}`);
  }
  if (state.endDate !== null) {
    queryParams.push(`endDate=${moment(state.endDate).format('YYYY-MM-DD')}`);
  }
  return queryParams.join('&');
};

const resetState = (state) => updateObject(state, {
  ...initialState,
  successGeneration: false,
  alerts: state.alerts,
});

const sendRolReportValidation = () => updateObject(
  {
    ...initialState,
    loading: true,
  },
);

const getRolReportValidation = (state) => updateObject(state, {
  ...initialState,
  rolValidation: true,
  loading: false,
});

const requestFailed = (state, action) => {
  let error;

  if (action.payload.response !== undefined) {
    error = action.payload.response.data.error.details;
  } else {
    error = action.payload.message;
  }
  return updateObject(state, {
    loading: false,
    error,
  });
};

const handleGoBack = (state, action) => updateObject(state,
  { navigation: action.payload.navigation });

const setReportParams = (state, action) => {
  const tmpState = updateObject(state, {
    ...state,
    reportType: action.payload.reportType,
    paceCenter: action.payload.paceCenter,
    startDate: action.payload.startDateRange,
    endDate: action.payload.endDateRange,
  });
  return updateObject(tmpState, {
    queryParams: setQueryParams(tmpState),
  });
};

const sendReportList = (state) => updateObject(state,
  {
    loadingReport: true,
  });

const getReportList = (state, action) => {
  const tmpState = updateObject(state, {
    list: action.payload.list,
    loadingReport: false,
    error: null,
    page: action.payload.page,
    pageData: action.payload.pageData,
  });
  return updateObject(tmpState, {
    pendingDataToPaginate: action.payload.pageData.total > tmpState.list.length,
  });
};

const requestPaceCentersList = (state) => updateObject(state, {
  pendingDataToPaginate: false,
  isLoading: true,
  error: null,
});

const getPaceCenterList = (state, action) => {
  const tmpState = updateObject(state, {
    searchOutcome: action.payload.page > 0
      ? state.searchOutcome.concat(action.payload.list) : action.payload.list,
    totalOutcome: action.payload.pageData.total,
    isLoading: false,
    error: null,
    isSuccess: true,
    page: action.payload.page,
  });

  return updateObject(tmpState, {
    pendingDataToPaginate: action.payload.pageData.total > tmpState.searchOutcome.length,
  });
};

const resetPaceCenterState = (state) => updateObject(state, {
  ...state,
  error: null,
  searchOutcome: [],
  totalOutcome: 0,
  isSuccess: false,
  isLoading: false,
  page: 0,
});

const addAlert = (state, action) => updateObject(state, {
  successGeneration: false,
  alerts: [...state.alerts, action.payload.alert],
});

const removeAlert = (state, action) => updateObject(state, {
  alerts: state.alerts.filter((alert) => alert !== action.payload.alert),
});

const clearAlerts = (state) => updateObject(state, { alerts: initialState.alerts });

const requestUsersReportGeneration = (state) => updateObject(state, {
  error: null,
  loadingGeneration: true,
  successGeneration: false,
});

const usersReportGeneration = (state, action) => updateObject(state, {
  error: null,
  loadingGeneration: false,
  successGeneration: true,
  jobId: action.payload.jobId,
  jobDescription: action.payload.description,
});

const sendCSVReport = (state) => updateObject(state,
  {
    loadingReport: true,
    loadingCSV: true,
    loadingPDF: false,
    downloadCompleted: false,
  });

const getCSVReport = (state) => updateObject(state,
  {
    loadingReport: false,
    loadingCSV: false,
    loadingPDF: false,
    downloadCompleted: true,
  });

const sendPDFReport = (state) => updateObject(state,
  {
    loadingReport: true,
    loadingCSV: false,
    loadingPDF: true,
    downloadCompleted: false,
  });

const getPDFReport = (state) => updateObject(state,
  {
    loadingReport: false,
    loadingCSV: false,
    loadingPDF: false,
    downloadCompleted: true,
  });

export default createReducer(initialState, {
  [actionTypes.GET_ROL_REPORT_VALIDATION_PENDING]: sendRolReportValidation,
  [actionTypes.GET_ROL_REPORT_VALIDATION_FULFILLED]: getRolReportValidation,
  [actionTypes.GET_ROL_REPORT_VALIDATION_REJECTED]: requestFailed,
  [actionTypes.REPORT_HANDLE_GO_BACK]: handleGoBack,
  [actionTypes.REPORT_RESET_STATE]: resetState,
  [actionTypes.SET_REPORT_PARAMS]: setReportParams,
  [actionTypes.GET_REPORT_LIST_PENDING]: sendReportList,
  [actionTypes.GET_REPORT_LIST_FULFILLED]: getReportList,
  [actionTypes.GET_REPORT_LIST_REJECTED]: requestFailed,
  [actionTypes.GET_FILTER_PACECENTERS_PENDING]: requestPaceCentersList,
  [actionTypes.GET_FILTER_PACECENTERS_FULFILLED]: getPaceCenterList,
  [actionTypes.GET_FILTER_PACECENTERS_REJECTED]: requestFailed,
  [actionTypes.PACECENTERS_SEARCH_RESET]: resetPaceCenterState,
  [actionTypes.ADD_ALERT]: addAlert,
  [actionTypes.REMOVE_ALERT]: removeAlert,
  [actionTypes.CLEAR_ALERTS]: clearAlerts,
  [actionTypes.USERS_REPORT_GENERATION_PENDING]: requestUsersReportGeneration,
  [actionTypes.USERS_REPORT_GENERATION_FULFILLED]: usersReportGeneration,
  [actionTypes.USERS_REPORT_GENERATION_REJECTED]: requestFailed,
  [actionTypes.GET_REPORT_AS_CSV_PENDING]: sendCSVReport,
  [actionTypes.GET_REPORT_AS_CSV_FULFILLED]: getCSVReport,
  [actionTypes.GET_REPORT_AS_CSV_REJECTED]: requestFailed,
  [actionTypes.GET_REPORT_AS_PDF_PENDING]: sendPDFReport,
  [actionTypes.GET_REPORT_AS_PDF_FULFILLED]: getPDFReport,
  [actionTypes.GET_REPORT_AS_PDF_REJECTED]: requestFailed,
});
