import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserReport from '../components/UserReport';
import { setNavigationStepbackButton } from '../actions/navigation';
import {
  handleGoBack, getFilterPaceCenters,
  usersReportGeneration, rolReportValidations, addAlert, resetState,
} from '../actions/reports';
import WithLoadingList from '../components/WithLoadingList';
import { mobileDetection } from '../services/utility';
import { nav } from '../components/Reports/constants';
import { useAuth0 } from '../auth0';

const UserReportWithLoading = WithLoadingList(UserReport);

const initialState = {
  allValues: false,
  paceCenter: '',
  paceCenterId: '',
};

const UserReportContainer = () => {
  const [entry, setEntry] = useState(initialState);
  const [searchValue, handleSearchValue] = useState('');
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [filterValue, handleFilterValue] = useState('');
  const [disableFooterButton, handleDisableFooterButton] = useState(true);
  const [disablePaceCenterSearch, handleDisablePaceCenterSearch] = useState(false);
  const [disableGenerateReport, handleDisableGenerateReport] = useState(true);
  const browserSettings = useState(mobileDetection(window.navigator));
  const {
    loading,
    error,
    navigation,
    page,
    pageData,
    list,
    searchOutcome,
    isLoading,
    pendingDataToPaginate,
    loadingGeneration,
    successGeneration,
    jobId,
    jobDescription,
    alerts,
  } = useSelector((state) => state.reportState);
  const {
    isSuccessPaceCenter,
    paceCenters,
  } = useSelector((state) => state.trackableState);
  const { isAuthenticated, getTokenSilently } = useAuth0();
  const [generateReport, setGenerateReport] = useState(false);

  const [token, setToken] = useState('');
  const dispatch = useDispatch();

  const getRolReportValidations = async () => {
    dispatch(rolReportValidations(await getTokenSilently(), true));
  };

  useEffect(() => {
    dispatch(setNavigationStepbackButton('Users Report'));
    dispatch(resetState());
  }, []);

  useEffect(() => {
    if (isAuthenticated && !loading) {
      (async () => {
        setToken(await getTokenSilently());
        getRolReportValidations();
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (list !== null) {
      setGenerateReport(true);
    } else {
      setGenerateReport(false);
    }
  }, [list]);

  const handleChangeEntry = (event) => {
    if (event.target.name === 'allValues') {
      setEntry({
        paceCenter: '',
        paceCenterId: '',
        allValues: !entry.allValues,
      });
      handleDisableGenerateReport(entry.allValues);
      handleDisablePaceCenterSearch(!entry.allValues);
      handleFilterValue('');
    } else {
      setEntry({
        ...entry,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleOnClickSearchButtonCenter = () => {
    handleSearchValue('');
    setSearchCompleted(false);
    handleFilterValue('');
    dispatch(handleGoBack(nav.code.PACE_CENTERS_SEARCH));
  };

  const handleSearch = async () => {
    await dispatch(getFilterPaceCenters(searchValue, 0, token));
    setSearchCompleted(true);
  };

  const handleCentersPagination = (pag) => {
    dispatch(getFilterPaceCenters(searchValue, pag, token));
  };

  const handleOnClickFooter = () => {
    setEntry({
      ...entry,
      paceCenter: searchOutcome.filter((e) => e.id === filterValue)[0].name,
      paceCenterId: searchOutcome.filter((e) => e.id === filterValue)[0].id,
    });
    dispatch(handleGoBack(nav.code.MENU));
    setSearchCompleted(false);
  };

  useEffect(() => {
    if (filterValue) {
      handleDisableFooterButton(false);
      handleDisableGenerateReport(false);
    } else { handleDisableFooterButton(true); }
  }, [filterValue]);

  useEffect(() => {
    if (successGeneration && !loadingGeneration) {
      dispatch(addAlert(jobDescription));
      setEntry(initialState);
      handleFilterValue('');
      handleDisableGenerateReport(true);
      handleDisablePaceCenterSearch(false);
    }
  }, [successGeneration, loadingGeneration]);

  const handleOnClickGenerateReport = () => {
    dispatch(usersReportGeneration(token, entry.paceCenterId));
  };

  return (
    <>
      {isSuccessPaceCenter && paceCenters.length > 0
      && (
        <UserReportWithLoading
          value={entry}
          onChangeValue={handleChangeEntry}
          navigation={navigation}
          error={error}
          browserSettings={browserSettings}
          onGenerateReport={generateReport}
          onClickSearchButtonCenter={handleOnClickSearchButtonCenter}
          page={page}
          list={list}
          pageData={pageData}
          searchValue={searchValue}
          onChangeSearchValue={handleSearchValue}
          onClickSearch={handleSearch}
          searchCompleted={searchCompleted}
          isLoading={isLoading}
          handleCentersPagination={handleCentersPagination}
          searchOutcome={searchOutcome}
          filterValue={filterValue}
          onChangeFilterValue={handleFilterValue}
          onClickFooter={handleOnClickFooter}
          pendingDataToPaginate={pendingDataToPaginate}
          disableFooterButton={disableFooterButton}
          onClickGenerateReport={handleOnClickGenerateReport}
          disablePaceCenterSearch={disablePaceCenterSearch}
          disableGenerateReport={disableGenerateReport}
          successGeneration={successGeneration}
          loadingGeneration={loadingGeneration}
          jobId={jobId}
          alerts={alerts}
        />
      )}
    </>
  );
};

export { UserReportContainer as default };
