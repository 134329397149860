import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PaginationTable from './PaginationTable';
import { userListColumns, userListByRoleColumns } from './constants';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '12px',
  },
  ellipsis: {
    maxWidth: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
  body: {
    fontSize: 14,
  },
}))(TableRow);

const UserList = ({
  list, pageData, handlePagination, handleOnClickTable, isFilterByRole, usersRequested,
}) => {
  const classes = useStyles();
  const columns = !isFilterByRole && usersRequested ? userListColumns : userListByRoleColumns;
  const columnsIds = columns.map((e) => e.id);

  const handlerOnClick = (event, userId) => {
    event.stopPropagation();
    handleOnClickTable(userId);
  };

  return (
    <>
      <Box className={classes.container}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => (
                <StyledTableRow
                  hover
                  key={row.userId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={(event) => handlerOnClick(event, row.userId)}
                  classes={{
                    root: classes.row,
                  }}
                >
                  {columnsIds !== undefined
                  && (
                    columnsIds.map((e) => (
                      <TableCell
                        key={e}
                      >
                        <Typography className={classes.ellipsis}>
                          {row[e]}
                        </Typography>
                      </TableCell>
                    ))
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {pageData !== null && (
        <Box display="flex" justifyContent="flex-end">
          <PaginationTable pageData={pageData} handlePagination={handlePagination} />
        </Box>
      )}
    </>
  );
};

export default UserList;
