const placeholder = {
  SEARCH: 'by name, username or email',
};

const userListColumns = [
  { id: 'name', label: 'Name' },
  { id: 'username', label: 'Username' },
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Status' },
  { id: 'lastLogin', label: 'Last login' },
];

const userListColumnsMobile = [
  { id: 'name', label: 'Name' },
  { id: 'username', label: 'Username' },
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Status' },
  { id: 'lastLogin', label: 'Last Login' },
];

const userListByRoleColumns = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'role', label: 'Role' },
];

export {
  userListColumns,
  userListColumnsMobile,
  placeholder,
  userListByRoleColumns,
};
