const status = {
  PACECENTERS: 'Enter Center ID or name',
};

const nav = {
  code: {
    MENU: 0,
    PACE_CENTERS_SEARCH: 2,
  },
};

export { nav, status };
