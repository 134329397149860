import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import UserCreationForm from './UserCreationForm';
import { nav } from './constants';
import AlertWrap from './AlertWrap';
import PaceCentersSearch from './PaceCentersSearch';
import BulkUsersCreation from './BulkUsersCreation';

const dashboardStyle = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2.5),
  },
  attest: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  canvas: {
    margin: theme.spacing(0, 0, 7, 0),
  },
}));

export default function UserCreation({
  value,
  onChangeValue,
  paceCentersList,
  rolesList,
  error,
  loading,
  browserSettings,
  bulkCreation,
  formError,
  enableButton,
  onClickSearch,
  onClickSearchButton,
  onPagination,
  searchCompleted,
  navigation,
  searchLoading,
  onChangeSearchValue,
  searchOutcome,
  totalOutcome,
  filterValue,
  onChangeFilterValue,
  pendingDataToPaginate,
  onClickFooterButton,
  disableFooterButton,
  onClickDeleteElement,
  onPostNewUser,
  errors,
  alerts,
  loadingCreation,
  username,
  highlightText,
  startPage,
  onChangeFile,
  onPostNewBulkUsersCreation,
  successCreation,
  fileDescription,
  onChangeFileDescription,
  jobDescription,
}) {
  const styles = dashboardStyle();

  return (
    <>
      <Box>
        {!error && !bulkCreation && navigation === nav.code.USER_CREATION_FORM
          && (
          <Container disableGutters>
            <Box className={styles.container}>
              <UserCreationForm
                value={value}
                onChangeValue={onChangeValue}
                paceCentersList={paceCentersList}
                rolesList={rolesList}
                loading={loading}
                browserSettings={browserSettings}
                formError={formError}
                enableButton={enableButton}
                onClickSearchButton={onClickSearchButton}
                onPagination={onPagination}
                searchCompleted={searchCompleted}
                onClickDeleteElement={onClickDeleteElement}
                onPostNewUser={onPostNewUser}
                errors={errors}
                loadingCreation={loadingCreation}
              />
            </Box>
          </Container>
          )}
        <>
          { !error && !bulkCreation && navigation === nav.code.PACE_CENTERS_SEARCH
          && (
          <PaceCentersSearch
            value={value}
            onChangeValue={onChangeValue}
            onChangeSearchValue={onChangeSearchValue}
            searchOutcome={searchOutcome}
            totalOutcome={totalOutcome}
            filterValue={filterValue}
            onChangeFilterValue={onChangeFilterValue}
            paceCentersList={paceCentersList}
            loading={searchLoading}
            browserSettings={browserSettings}
            formError={formError}
            enableButton={enableButton}
            onClickSearch={onClickSearch}
            onPagination={onPagination}
            searchCompleted={searchCompleted}
            pendingDataToPaginate={pendingDataToPaginate}
            onClickFooterButton={onClickFooterButton}
            disableFooterButton={disableFooterButton}
            highlightText={highlightText}
            startPage={startPage}
          />
          )}
          {!error && bulkCreation && navigation === nav.code.USER_CREATION_FORM
          && (
            <Container disableGutters>
              <Box className={styles.container}>
                <BulkUsersCreation
                  onChangeFile={onChangeFile}
                  onPostNewBulkUsersCreation={onPostNewBulkUsersCreation}
                  loading={loading}
                  formError={formError}
                  enableButton={enableButton}
                  onClickSearchButton={onClickSearchButton}
                  onPagination={onPagination}
                  searchCompleted={searchCompleted}
                  onClickDeleteElement={onClickDeleteElement}
                  onPostNewUser={onPostNewUser}
                  errors={errors}
                  loadingCreation={loadingCreation}
                  fileDescription={fileDescription}
                  onChangeFileDescription={onChangeFileDescription}
                />
              </Box>
            </Container>
          )}
          <Container>
            {!loadingCreation && navigation === nav.code.USER_CREATION_FORM
            && alerts.map((alert) => (
              <AlertWrap
                key={alert}
                element={alert}
                username={username}
                jobDescription={jobDescription}
                bulkCreation={bulkCreation}
                successCreation={successCreation}
              />
            ))}
          </Container>
        </>
      </Box>
    </>
  );
}
